import styled, { css } from "styled-components";
import { Container, Section } from "../../sdk/Layout";
import { Profile } from "../../sdk/Elements";

import { Button } from "../../sdk/Button";
import { Columns, Column, Title, Heading } from "../../sdk/Layout";

export const Wrapper = styled(Section)`
    background-color: ${(props) => props.theme.grayLight};
    padding-top: 0;
`;

export const TopImage = styled.div`
    height: 200px;
    background-color: ${(props) => props.theme.white};
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`;

export const SellerCard = styled.div`
    width: 250px;
    max-width: 400px;
    margin-right: auto;
    margin-left: auto;
    background-color: ${(props) =>
        !props.theme.darkMode
            ? "rgba(255,255,255, 0.8)"
            : "rgba(24, 29, 38, 0.8)"};
    padding: 30px;
    padding-top: 0;
    border-radius: 10px;
    backdrop-filter: blur(5px);
    margin-top: -113px;
    text-align: center;
    .tag {
        font-size: 12px;
        font-weight: bold;
        background-color: ${(props) => props.theme.white};
        height: 30px;
        line-height: 30px;
        padding: 0px 10px;
        display: inline-block;
        border-radius: 0 0 5px 5px;
    }
    @media (max-width: 768px) {
        width: auto;
    }
`;

export const ProfileExtended = styled(Profile)`
    flex-direction: column;
    margin-top: 20px;
    position: relative;
    padding-bottom: 10px;
    &:before {
        content: "";
        display: block;
        bottom: 0;
        height: 1px;
        width: 100px;
        left: 50%;
        margin-left: -50px;
        position: absolute;
        background-color: ${(props) => props.theme.borderColor};
    }
    figure {
        width: 125px;
        height: 125px;
        padding: 0;
        img {
            object-fit: cover;
        }
    }
    h3 {
        font-weight: normal;
        font-size: 16px;
        margin-top: 10px;
    }
    p {
        font-weight: bold;
        font-size: 12px;
        margin-top: 5px;
        color: ${(props) => props.theme.gray};
    }
`;

export const SellerInfo = styled.ul`
    margin-top: 30px;
    padding-left: 25px;
    li {
        text-align: left;
        position: relative;
        &:not(:last-child) {
            margin-bottom: 20px;
        }
        svg {
            width: 20px;
            height: 20px;
            color: ${(props) => props.theme.borderColor};
            position: absolute;
            top: -2px;
            left: -25px;
        }
        h4 {
            font-weight: 600;
            font-size: 13px;
            margin-bottom: 3px;
        }
        p {
            font-size: 12px;
        }
    }
`;
export const Footer = styled.div`
    margin-top: 30px;
`;

const WhatsappMixin = css`
    background-color: #1ebea5;
    color: white !important;
`;
const CalendlyMixin = css`
    color: #09a4ff;

    svg {
        color: #09a4ff;
    }
`;

export const BrandBtn = styled.a`
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${(props) =>
        props.theme.darkMode ? "#4d4d4d" : props.theme.graySoft};
    border-radius: 30px;
    cursor: pointer;
    color: ${(props) => props.theme.primaryDark};
    font-size: 14px;
    font-weight: bold;
    opacity: 0.9;
    &:hover {
        opacity: 1;
    }
    &:not(:last-child) {
        margin-bottom: 15px;
    }
    svg {
        width: 28px;
        height: 28px;
        margin-right: 5px;
    }

    ${(props) => props.whatsapp && WhatsappMixin}
    ${(props) => props.calendly && CalendlyMixin}
`;

export const TextContainer = styled(Column)`
    padding: 10%;
    @media (min-width: 769px) {
        min-height: 560px;
    }
`;

export const Gallery = styled(Column)`
    width: 200px;

    ul {
        display: flex;
        flex-wrap: wrap;
        margin-top: -1%;
        margin-right: -1%;
        margin-left: -1%;
        margin-bottom: -1%;
        li {
            position: relative;
            width: 48%;
            margin: 1%;
            overflow: hidden;
            border-radius: 10px;
            cursor: pointer;
            &.full {
                width: 100%;
            }
            &:before {
                content: "";
                display: block;
                padding-top: 100%;
            }
            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            svg {
                position: absolute;
                top: 50%;
                left: 50%;
                z-index: 2;
                transform: translate(-50%, -50%);
                color: white;
                width: 50px;
                height: 50px;
                opacity: 0.8;
            }
            &:hover {
                svg {
                    opacity: 1;
                }
            }
        }
    }
    @media (max-width: 768px) {
        width: auto;
        ul {
            justify-content: center;
            li {
                width: 31.333% !important;
            }
        }
    }
`;

export const ContainerExtended = styled(Container)`
    padding-left: 300px;
`;

export const Banner = styled.figure`
    img {
        display: block;
        width: 100%;
        height: auto;
    }
`;

export const InfoIcon = styled.div`
    position: relative;
    padding-left: 100px;
    padding-top: 27px;
    i {
        width: 80px;
        height: 80px;
        border-radius: 100%;
        border: solid 1px ${(props) => props.theme.primaryDarkMode};
        position: absolute;
        left: 0;
        top: 0;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        svg {
            width: 30px;
            height: 30px;
            color: ${(props) => props.theme.primaryDarkMode};
        }
    }
    h3 {
        font-size: 20px;
    }
    h4 {
        font-size: 16px;
        padding-top: 10px;
    }
    ul {
        li {
            font-size: 16px;
            & + li {
                margin-top: 10px;
            }
        }
    }

    @media (max-width: 375px) {
        padding-left: 0;
        padding-top: 0;
        text-align: center;

        i {
            position: relative;
            margin-bottom: 20px;
        }
        ul {
            li {
                font-size: 14px;
            }
        }
    }
`;

export const Mosaic = styled.div`
    > div {
        display: flex;
        flex-wrap: wrap;

        div {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            position: relative;
            padding: 5%;
            background-color: ${(props) => props.theme.white};
            min-height: 300px;
            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        @media (min-width: 769px) {
            &:nth-child(even) {
                div:first-child {
                    order: 1;
                }
            }
            div {
                width: 50%;
            }
        }
    }
    @media (max-width: 786px) {
        > div {
            div {
                min-height: 250px;
            }
        }
    }
    @media (max-width: 375px) {
        > div {
            div {
                min-height: 180px;
            }
        }
    }
`;

export const PostList = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-right: -1%;
    margin-left: -1%;
    margin-top: -1%;
    margin-bottom: -1%;
`;

export const Post = styled.div`
    text-align: center;
    margin: 1%;
    width: 48%;
    &:hover {
        h3 {
            color: ${(props) => props.theme.primaryDarkMode};
        }
    }
    a {
        cursor: pointer;
        text-decoration: none;
    }
    figure {
        position: relative;
        display: block;
        &:before {
            content: "";
            display: block;
            padding-top: 60%;
        }
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    h3 {
        font-size: 18px;
        margin-top: 20px;
        color: ${(props) => props.theme.primaryDark};
    }

    @media (max-width: 768px) {
        h3 {
            font-size: 16px;
            margin-top: 10px;
        }
    }

    @media (max-width: 375px) {
        width: 98%;
        &:not(:last-child) {
            margin-bottom: 20px;
        }
    }
`;
