export default {
	yoursalesrep: "Tu vendedor asignado",
	positionTitle: "Ejec. Ventas Internacionales",
	aboutMe: "Sobre Mi",
	yearsOfService: "años de servicio",
	languages: "Idiomas",
	warehouse: "Bodega",
	contact: "Contactar",
	requestAMeeting: "Agendar reunión",
	presaleSystem: "Preventa",
	benefits: "Beneficios",
	socialResponsibility: "Responsabilidad social",
	moreInformation: "Más información",
	socialResponsibilities: [
		{
			img: "https://www.noritex.com/uplds/sYJ2R3L72Ni9ukQ9yPaneles_Solares_Noritex.jpg",
			alt: "Produccion de Energia - Responsabilidad social - Noritex",
			paragraph:
				"Noritex produce el 85% de nuestra energía, utilizamos energía solar para reducir la dependencia de fuentes de energía no renovables.",
		},
		{
			img: "https://www.noritex.com/uplds/rTskTZ8ay3KXXmhCpnavidad-responsabilidad-social.jpg",
			alt: "Navidad Ayuda - Responsabilidad social - Noritex",
			paragraph:
				"Cada año llevamos la magia de la Navidad a nuestros hijos.",
		},
	],
	moreInformationBlocks: [
		{
			img: "https://www.noritex.com/uplds/2024/7/20/Zw4upgDJ8Wb3dmEnq244-_Serenity_Collection_-_Portada.jpg",
			paragraph: "Nuestra próxima feria",
			link: "",
			alt: "Proxima feria de Noritex",
		},
		{
			img: "https://www.noritex.com/uplds/jpo73JjpaHvdfHTCtOficinas_de_Noritex.jpg",
			paragraph: "Aprende más sobre Noritex.com",
			link: "/nosotros",
			alt: "Historia de Noritex",
		},
	],

	salesSystem: [
		{
			title: "Ventas Directas",
			paragaph: [
				"Envío en contenedores directamente  nuestras fábrica.",
				"Puede elegir varias referencias de la misma fábrica.",
			],
			icon: "truck-container",
			benefits: [
				"Ahorro de flete.",
				"Envío directo al cliente del país del puerto.",
			],
		},
		{
			title: "Ventas desde Panama",
			paragaph: [
				"Despacho directo desde Panamá al puerto país cliente.",
				"Varias fábricas.",
			],
			icon: "boat",
			benefits: [
				"Cantidades menores por referencia.",
				"Referencias consolidadas.",
			],
		},
	],
};
