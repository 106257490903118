export default {
    title: "Showrooms",
    subTitle: "Visite Nuestro Showroom",
    paragraph: `Nuestras salas de exposición en Panama ya están abiertas. La seguridad de nuestros equipos y clientes sigue siendo nuestra prioridad número uno, por lo que hemos introducido algunas medidas nuevas para darle la bienvenida de manera segura y cómoda. Una vez dentro, le pedimos que siga el sistema unidireccional; habrá una señalización clara para guiarlo. Los miembros del equipo estarán ubicados en puntos de información para ayudarlo a una distancia segura. Mantenga siempre el distanciamiento social cuando esté dentro de la sala de exposición y no la visite si está enfermo o tiene tos o resfriado. Encontrarás esta información en la página de tu sala de exposición local. ¿No puedes ir a nuestras salas de exposición? No hay problema. Ahora ofrecemos citas en línea gratuitas.`,
	paragraphs: [
		"Visitar nuestro showroom le brinda una oportunidad única para explorar una exclusiva selección de productos de nueva colección y en tendencia, asegurando la exclusividad en sus tiendas.",
		"Contamos con más de 4,000 referencias disponibles en todos los estilos y categorías, permitiéndole adquirir las cantidades que desee según sus necesidades.",
		"Al comprar nuestras colecciones, podrá crear ambientes únicos y diferenciados en sus tiendas, garantizando experiencias de compra excepcionales para sus consumidores. Ofrecemos despachos consolidados desde Panamá, y directos desde Oriente que le permitirán ahorrar significativamente en flete.",
		"Si no tiene la disponibilidad de viajar, contamos con un showroom digital en tiempo real, el cual podrá recorrer de manera virtual y recibir atención personalizada por videollamada.",
		"Le invitamos a visitarnos y descubrir cómo nuestras colecciones pueden transformar sus tiendas.",
	],
    titleActions: "Seguridad Primero",
	titleVirtualShowroom: "Showroom Virtual",
    paragraphVirtualShowroom: "Recorre nuestro showroom virtual a través del siguiente mapa 3D",
    actions: [
        {
            title: "PROTOCOLO DE LIMPIEZA",
            icon: "hand-wash",
            paragraph:
                "Limpieza y desinfección cada 2 horas de todas las áreas, incluyendo puestos de trabajo, áreas de atención, entrega de mercancías, caja, mostradores, teléfonos, sillas de espera y cualquier otra superficie de alto contacto.",
        },
        {
            title: "DISTANCIAMIENTO SOCIAL",
            icon: "social-distance",
            paragraph:
                "Guardemos las normas de distanciamiento social recomendadas de 2 mts de distancia.",
        },
        {
            title: "VISITA GRUPAL",
            icon: "no-crowd",
            paragraph:
                "Para ayudarnos a evitar aglomeraciones, recomendamos agende su cita y visite nuestra sala de exposición con un máximo de 2 personas.",
        },
        // {
        //     title: "Vea sin tocar",
        //     icon: "do-not-touch",
        //     paragraph: "Por favor evite tocar los productos durante su visita.",
        // },
        {
            title: "KIT DE BIOSEGURIDAD",
            icon: "mask",
            paragraph:
                "Tenemos para cada cliente que nos visite, un Kit de Bioseguridad personal que incluye un juego de 10 mascarillas quirúrgicas de 3 capas, carátula protectora facial, gel de manos, alcohol atomizador personal al 70%.",
        },
        {
            title: "TRANSPORTE",
            icon: "car",
            paragraph:
                "Desinfección de los vehículos de transporte de los clientes después de realizar cada traslado. Limpieza diaria al finalizar la jornada laboral.  ",
        },
    ],
    direction: "Dirección",
    phoneNumber: "Teléfono",
    email: "Correo electrónico",
};
