import { isValidPhoneNumber } from "react-phone-number-input";

/**
 * minLength Val
 * @param  value
 * @param  minLength
 * @return
 */
const minLengthValidator = (value, minLength) => value.length >= minLength;

/**
 * Check to confirm that feild is required
 *
 * @param  value
 * @return
 */
const requiredValidator = (value) => value.trim() !== "";

/**
 * Email validation
 *
 * @param value
 * @return
 */
const emailValidator = (value) => {
	// eslint-disable-next-line no-useless-escape
	const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(String(value).toLowerCase());
};

function isNumeric(n) {
	return !Number.isNaN(parseFloat(n)) && Number.isFinite(n);
}

const validate = (value, rules) => {
	let isValid = true;

	// eslint-disable-next-line no-restricted-syntax, guard-for-in
	for (const rule in rules) {
		switch (rule) {
			case "minLength": isValid = isValid && minLengthValidator(value, rules[rule]); break;

			case "isRequired": isValid = isValid && requiredValidator(value); break;

			case "isEmail": isValid = isValid && emailValidator(value); break;

			case "isNumeric": isValid = isValid && isNumeric(value); break;

			case "isArray": isValid = isValid && value.length > 0; break;

			case "isPhone": isValid = isValid && isValidPhoneNumber(value); break;

			default: isValid = true;
		}
	}

	return isValid;
};

export default validate;
