import React, { useState } from "react";
import { Preloader } from "../../../sdk/Elements";
import { Title, Heading, PageContent } from "../../../sdk/Layout";
import Icons from "./Icons";
import { WrapperQuestions, Wrap, Accordeon, Question } from "../style";

function Questions({ faqs, isLoading, langJson }) {
    const [status, setStatus] = useState("");

    return (
        <WrapperQuestions>
            <Wrap>
                <Heading isCentered>
                    <h3>{langJson.otherQuestions}</h3>
                </Heading>
                <Accordeon>
                    {isLoading ? (
                        <>
                            <Question>
                                <Title as="h3" size="5">
                                    <Preloader />
                                    <Icons icon="chevron-right-thin"></Icons>
                                </Title>
                            </Question>
                            <Question>
                                <Title as="h3" size="5">
                                    <Preloader />
                                    <Icons icon="chevron-right-thin"></Icons>
                                </Title>
                            </Question>
                            <Question>
                                <Title as="h3" size="5">
                                    <Preloader />
                                    <Icons icon="chevron-right-thin"></Icons>
                                </Title>
                            </Question>
                            <Question>
                                <Title as="h3" size="5">
                                    <Preloader />
                                    <Icons icon="chevron-right-thin"></Icons>
                                </Title>
                            </Question>
                            <Question>
                                <Title as="h3" size="5">
                                    <Preloader />
                                    <Icons icon="chevron-right-thin"></Icons>
                                </Title>
                            </Question>
                            <Question>
                                <Title as="h3" size="5">
                                    <Preloader />
                                    <Icons icon="chevron-right-thin"></Icons>
                                </Title>
                            </Question>
                            <Question>
                                <Title as="h3" size="5">
                                    <Preloader />
                                    <Icons icon="chevron-right-thin"></Icons>
                                </Title>
                            </Question>
                        </>
                    ) : null}
                    {faqs.map((index) => (
                        <Question
                            key={index._id}
                            isActive={status === index._id}
                        >
                            <Title
                                as="h3"
                                size="5"
                                onClick={() => setStatus(index._id)}
                            >
                                {index.title}
                                <Icons icon={"chevron-right-thin"}></Icons>
                            </Title>
                            <div>
                                <PageContent>
                                    <p
                                        dangerouslySetInnerHTML={{
                                            __html: index.content,
                                        }}
                                    ></p>
                                </PageContent>
                            </div>
                        </Question>
                    ))}
                </Accordeon>
            </Wrap>
        </WrapperQuestions>
    );
}

export default Questions;
