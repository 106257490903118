export default {
    startAReturn: "How to start a claim",
    contactABrand: "Contact a Brand",
    cancelAnOrder: "Cancel an Order",
    productImages: "Product Images",
    frequentQuestions: "Frequently asked questions",
    reportDamagedItems: "Report damaged items",
    howCanWeHelpYou: "How can we help you?",
    inputPlaceholder: "Write here to find answers",
    otherQuestions: "Other questions",
};
