export default {
	privateLabelTitle: "Desarrolla tu marca privada con nosotros",
	privateLabelDescription:
		"Desarrolla tu marca privada con Noritex una empresa con mas de 50 años de experiencia en el desarrollo de marcas privadas.",
	title: "¿Qué productos podemos desarrollar por ti?",
	products: [
		"Decoracion de Hogar",
		"Productos de Escolar",
		"Ropa Deportiva",
		"Decoracion de Navidad",
		"Textil",
		"Figuras Religiosas",
	],
	formTitle: "Contáctanos",
};
