import styled from "styled-components";
import { Columns, Column } from "../../sdk/Layout";

export const Layout = styled.div`
    position: relative;
    left: 0;
    background-color: ${(props) => props.theme.blueLight};
    width: 100%;
    .form {
        margin-left: -100px;
        #leadform {
            padding: 0;
        }
        .left-column {
            display: none;
        }
        .right-column {
            padding: 50px;
            padding-left: 0;
        }
    }

    @media (max-width: 768px) {
        .form {
            margin-left: 0;
            margin-top: -100px;
            .right-column {
                padding: 20px;
            }
        }
    }
`;

export const ColumnsExtended = styled(Columns)`
  margin: 0 !important;
`;

export const Left = styled(Column)`
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: inset 0 -1000px rgba(17, 44, 85, 0.9);
  color: #fff;
  padding-left: 50px;
  padding-right: 120px;
  .inner {
    max-width: 510px;
    h1 {
      font-size: 45px;
      max-width: 450px;
      margin-bottom: 20px;
    }
    ul {
      li {
        position: relative;
        padding-left: 25px;
        font-size: 30px;
        &:not(:last-child) {
          margin-bottom: 10px;
        }
        &:before {
          content: '';
          display: block;
          width: 10px;
          height: 20px;
          border-right: solid 2px white;
          border-bottom: solid 2px white;
          position: absolute;
          left: 0;
          top: 5px;
          transform: rotate(45deg);
        }
      }
    }
  }

  @media (max-width: 768px) {
    padding: 20px;
    padding-right: 20%;
    padding-bottom: 100px;
    .inner {
        max-width: 100%;
        h1 {
          font-size: 24px;
        }
        ul {
          li {
            font-size: 18px;
            &:before {
              top: 0;
              transform: rotate(45deg) scale(.7);
            }
          }
        }
    }
  }
`;