import styled from "styled-components";
import {
    Container,
    Section,
    Title,
    Subtitle,
    PageContent,
} from "../sdk/Layout";
import { Control, Field } from "../sdk/Form";
import { Button } from "../sdk/Button";

export const Article = styled(Section)`
    background-color: ${(props) => props.theme.grayLight};
`;

export const Wrapper = styled(Container)`
    max-width: 900px !important;
`;

export const Top = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 0;
`;

export const Share = styled.div`
    span {
        cursor: pointer;
        display: inline-block;
        svg {
            width: 28px;
        }
    }
    div {
        display: none;
        svg {
            width: 20px;
            height: 20px;
            cursor: pointer;
            color: ${(props) => props.theme.gray};
            &:hover {
                color: ${(props) => props.theme.primaryDark};
            }
            &:not(:last-child) {
                margin-right: 5px;
            }
        }
    }
    min-width: 28px;
    min-height: 25px;
    @media (min-width: 760px) {
        &:hover {
            span {
                display: none;
            }
            div {
                display: block;
            }
        }
    }
`;

export const Body = styled.div`
    background-color: ${(props) => props.theme.white};
    ${Title} {
        padding: 50px;
        margin-bottom: 0;
        line-height: 1.4;
    }

    ${Subtitle} {
        color: ${(props) => props.theme.gray};
        font-family: tahoma, verdana, sans-serif;
        padding-top: 0 !important;
    }

    ${PageContent} {
        padding: 0 50px 50px 50px;
        p {
            line-height: 32px;
            font-size: 21px;
            color: ${(props) => props.theme.primaryDark};
            span,
            strong,
            a {
                /* color: ${(props) => props.theme.primaryDark}; */
                line-height: inherit !important;
                font-size: inherit !important;
            }
            a:hover {
                text-decoration: underline;
            }
            & + p {
                margin-top: 20px;
            }
        }
    }

    @media (max-width: 768px) {
        & ${Title} {
            padding: 30px;
            font-size: 3rem;
        }
        & ${Subtitle} {
            font-size: 1.8rem;
        }
        & ${PageContent} {
            padding: 0 30px 30px 30px;
        }
    }

    @media (max-width: 375px) {
        & ${Title} {
            padding: 20px;
            font-size: 2.2rem;
        }
        & ${Subtitle} {
            font-size: 1.6rem;
        }
        & ${PageContent} {
            padding: 0 10px 10px 10px;
            p {
                font-size: 18px;
                line-height: 28px;
            }
        }
    }
`;

export const Image = styled.figure`
    z-index: 1;
    margin-bottom: 30px;
    img {
        width: 100%;
        display: block;
    }
`;

export const Author = styled.div`
    display: flex;
    align-items: center;
    img {
        width: 48px;
        height: 48px;
        border-radius: 100%;
        overflow: hidden;
        object-fit: cover;
        object-position: center;
        margin-right: 10px;
        border: solid 1px ${(props) => props.theme.borderColor};
    }
    p {
        margin: 0;
        padding: 0;
        font-size: 14px;
        color: ${(props) => props.theme.primaryDark};
    }
    span {
        color: ${(props) => props.theme.gray};
        font-size: 10px;
        font-weight: bold;
        display: block;
        padding-top: 5px;
    }
`;

export const Status = styled.div`
    text-align: center;
    padding: 0 20px 100px 20px;
    position: relative;
    z-index: 2;
    margin-top: -100px;
    box-shadow: inset 0 -230px 110px -100px rgba(0, 0, 0, 0.6);
    background-image: url("https://www.noritex.com/DHiqKsgdpSEMEZ6t4placeholder.jpg");
    background-repeat: no-repeat;
    background-position: center 100px;
    background-size: 100%;
    > div {
        background-color: ${(props) => props.theme.white};
        padding: 8%;
        margin: auto;
        max-width: 700px;
        border-radius: 5px;
        box-shadow: 0 22px 32px -20px rgba(0, 0, 0, 0.4);
    }
    h2 {
        font-size: 20px;
        line-height: 26px;
        margin-bottom: 20px;
        a {
            color: ${(props) => props.theme.primaryDarkMode};
            text-decoration: underline;
        }
    }
    p {
        font-size: 16px;
        a {
            color: ${(props) => props.theme.primaryDarkMode};
            text-decoration: underline;
        }
    }

    form {
        padding-top: 30px;
        text-align: left;
        margin: auto;
        max-width: 300px;
    }

    @media (max-width: 520px) {
        margin-top: -50px;
        padding: 0 20px 50px 20px;
        h2 {
            font-size: 12px;
            line-height: 18px;
        }
        form {
            margin-top: 0;
        }
    }

    @media (max-width: 375px) {
        h2 {
            font-size: 16px;
            line-height: 22px;
        }
        p {
            font-size: 14px;
        }
    }
`;
