export default {
    yourShoppingCredit: "Su comisión ganada",
    pending: "Pendiente",
    pendingPlural: "Pendientes",
    earned: "Ganado",
    won: "Ganados",
    inelegible: "No Aprobados",
    showInviteDetails: "Mostrar detalles de la invitación",
    inviteDetails: "Detalles de tus invitación",

    inviteBrand:
        "Refiera clientes, gane una comisión de 5% en efectivo para su uso personal",
    inShoppingCredit: "",
    beTheFirst:
        "Al referir clientes para nuestras lineas, usted recibirá 5% de comisión en efectivo, una vez se haya confirmado exitosamente la compra realizada por su referido.*",
    inCredit: "",
    scucessSending: "Envío exitoso de invitación",
    enterEmail: "Correo electrónico de su referido",
    sendInvite: "Enviar invitación",

    disclamer: "*Aplican términos y condiciones.",
    seePolitica: "Ver la política",
    titlePopup: "Recibimos tus invitaciones!",
    subTitlePopup:
        " Por favor, complete la información de abajo para enviar la invitación.",

    selectReferralBrands: "Seleccione las marcas que desea referir.",
    mainTitle: "¡Gane con el programa de referidos de Noritex! ",
    hereIsHowItWorks: "Así funciona",
    icons: [
        {
            title: "REFIERA A CLIENTES",
            icon: "send-light",
            paragraph: "Ingrese el email y la información de su referido.",
        },
        {
            title: "RECIBA SU COMISIÓN",
            icon: "hand-holding-usd",
            paragraph:
                "Obtenga el 5% de comisión una vez se haya confirmado exitosamente la primera compra de su referido. Esta comisión la recibirá en efectivo por transferencia bancaria o PayPal.",
        },

        {
            title: "¡REPITA EL PROCESO!",
            icon: "repeat",
            paragraph:
                "¡No hay límites de referidos! Entre más referidos, mayor será su beneficio",
        },
    ],
    seeHowMuch: "¡Calcule aquí su comisión!  ",
    enterBrands: "Compra",
    calculate: "Calcular",
    potentialReferralCash: "comisión en efectivo",
};
