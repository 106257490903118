import React from "react";

function Icons({ icon, className }) {
    if (icon === "wharehouse") {
        return (
            <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fal"
                data-icon="warehouse-alt"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 640 512"
            >
                <path
                    fill="currentColor"
                    d="M528 352H352V240c0-8.8-7.2-16-16-16H112c-8.8 0-16 7.2-16 16v256c0 8.8 7.2 16 16 16h416c8.8 0 16-7.2 16-16V368c0-8.8-7.2-16-16-16zM320 480H128v-96h192v96zm0-128H128v-96h192v96zm192 128H352v-96h160v96zm98.6-361.7L338.6 3.7c-11.8-5-25.3-5-37.2 0l-272 114.6C11.5 125.8 0 143.2 0 162.5V504c0 4.4 3.6 8 8 8h16c4.4 0 8-3.6 8-8V162.5c0-6.5 3.8-12.2 9.8-14.8l272-114.6c3.9-1.7 8.5-1.7 12.4 0l272 114.6c6 2.5 9.8 8.3 9.8 14.8V504c0 4.4 3.6 8 8 8h16c4.4 0 8-3.6 8-8V162.5c0-19.3-11.5-36.7-29.4-44.2z"
                />
            </svg>
        );
    }
    if (icon === "refresh") {
        return (
            <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fal"
                data-icon="sync-alt"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
            >
                <path
                    fill="currentColor"
                    d="M457.373 9.387l-50.095 50.102C365.411 27.211 312.953 8 256 8 123.228 8 14.824 112.338 8.31 243.493 7.971 250.311 13.475 256 20.301 256h10.015c6.352 0 11.647-4.949 11.977-11.293C48.159 131.913 141.389 42 256 42c47.554 0 91.487 15.512 127.02 41.75l-53.615 53.622c-20.1 20.1-5.855 54.628 22.627 54.628H480c17.673 0 32-14.327 32-32V32.015c0-28.475-34.564-42.691-54.627-22.628zM480 160H352L480 32v128zm11.699 96h-10.014c-6.353 0-11.647 4.949-11.977 11.293C463.84 380.203 370.504 470 256 470c-47.525 0-91.468-15.509-127.016-41.757l53.612-53.616c20.099-20.1 5.855-54.627-22.627-54.627H32c-17.673 0-32 14.327-32 32v127.978c0 28.614 34.615 42.641 54.627 22.627l50.092-50.096C146.587 484.788 199.046 504 256 504c132.773 0 241.176-104.338 247.69-235.493.339-6.818-5.165-12.507-11.991-12.507zM32 480V352h128L32 480z"
                />
            </svg>
        );
    }
    if (icon === "ruler") {
        return (
            <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fal"
                data-icon="ruler-combined"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
            >
                <path
                    fill="currentColor"
                    d="M480 320H192V32c0-17.67-14.33-32-32-32H32C14.33 0 0 14.33 0 32v448c0 17.67 14.33 32 32 32h448c17.67 0 32-14.33 32-32V352c0-17.67-14.33-32-32-32zM32 32h128v64h-56c-4.42 0-8 3.58-8 8v16c0 4.42 3.58 8 8 8h56v48h-56c-4.42 0-8 3.58-8 8v16c0 4.42 3.58 8 8 8h56v48h-56c-4.42 0-8 3.58-8 8v16c0 4.42 3.58 8 8 8h56v41.38l-128 128V32zm448 448H54.62l128-128H224v56c0 4.42 3.58 8 8 8h16c4.42 0 8-3.58 8-8v-56h48v56c0 4.42 3.58 8 8 8h16c4.42 0 8-3.58 8-8v-56h48v56c0 4.42 3.58 8 8 8h16c4.42 0 8-3.58 8-8v-56h64v128z"
                />
            </svg>
        );
    }
    if (icon === "trend") {
        return (
            <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fal"
                data-icon="fire"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 384 512"
            >
                <path
                    fill="currentColor"
                    d="M216 24.01c0-23.8-31.16-33.11-44.15-13.04C76.55 158.25 200 238.73 200 288c0 22.06-17.94 40-40 40s-40-17.94-40-40V182.13c0-19.39-21.85-30.76-37.73-19.68C30.75 198.38 0 257.28 0 320c0 105.87 86.13 192 192 192s192-86.13 192-192c0-170.29-168-192.85-168-295.99zM192 480c-88.22 0-160-71.78-160-160 0-46.94 20.68-97.75 56-128v96c0 39.7 32.3 72 72 72s72-32.3 72-72c0-65.11-112-128-45.41-248C208 160 352 175.3 352 320c0 88.22-71.78 160-160 160z"
                />
            </svg>
        );
    }
    if (icon === "map") {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="481.498"
                height="589.941"
                viewBox="0 0 481.498 589.941"
            >
                <g
                    id="Grupo_12"
                    data-name="Grupo 12"
                    transform="translate(-231.514 -76.56)"
                >
                    <path
                        id="path810"
                        d="M279.027-105.192l-6.581-.408.408-9.079-3.405,2.271-5.309.77-3.041-2.268h-3.767l-.365-5.674-4.175-7.172,4.175-5.312s1.5-7.169,3.767-9.076,13.618-4.178,13.618-4.178l1.907-1.5,1.5-7.672,1.407-8.488-4.539-6.534v-3.405h5.13l-2.589-3.405v-2.541l7.672-.273,7.58-2.629.955,1.178s-.864,5.991,3.4,6.538,12.481-2.815,12.754-4.266c.273-1.407,5.4-4.539,5.4-4.539l-1.451-2-2.815.591-.543-4.813-5.13-5.4,11.347,2.815,3.406-1.954,4.539-.861s5.083-1.407,5.083-2.815a9.056,9.056,0,0,0-.361-2.633l4.9.908-1.407,3.132,3.406,2.541-1.407,6.538,2.541,7.351,4.539,2.862,3.993-3.132,8.353-1.181,3.54-.5.861-2.268h7.354l3.132,1.677,8.215-.591,6.217-12.981c2.633,2.089,4.539,4.266,5.356,6.4,3.767,9.8,5.265,18.884,18.155,23.423,12.846,4.539,33.089,13.072,41.217,13.254,17.79.361,44.615,21.2,30.636,35.948-24.692,26.008-18.927,25.691-23.467,47.66-4.536,21.921-4.9,34.4-35.175,40.848C391.819-22.041,404.483-5.337,395.4,9.007,388.235,20.4,380.064,32.066,373.256,39.6l-1.316-9.757L360.593,19.628l-3.406-.773-4.539-4.539-4.313.229.547-1.724L358.686.7l10.982-4.9.773-9.848-3.679-.909L367.4-25.4l-6.446-.773.773-6.035-4.9-5.312H345.84l-.364-12.073s0-1.043.091-2.45c.135-2.089.5-5.039,1.407-6.629a7.623,7.623,0,0,0,.773-5.309l-4.539-4.9v-4.539l-10.621.361-.364-5.309-1.907-1.5,2.271-1.5-1.5-8.35-7.58-1.134L315.2-96.522l-6.807-1.137-4.175-2.268-.726-12.846h-6.811l-11.347,7.942-6.308-.361"
                        transform="translate(230.258 448.309)"
                        fill="#335f90"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeWidth="0.5"
                    />
                    <path
                        id="path814"
                        d="M315.282-193.175l-6.217,12.981-8.215.591-3.132-1.677h-.182l3.041-8.536-3.405-4.809,4.087-6.855a69.37,69.37,0,0,1,14.023,8.306"
                        transform="translate(298.216 445.92)"
                        fill="#335f90"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeWidth="0.5"
                    />
                    <path
                        id="path818"
                        d="M317.025-99.761,318.341-90a51.592,51.592,0,0,1-5.086,4.991c-6.308,5.264-20.47-1.86-23.372-1.725l-1.228-2.451,1.5-15.522,3.267-10.348,4.313-.229,4.54,4.539,3.405.773,11.347,10.214"
                        transform="translate(285.173 577.912)"
                        fill="#335f90"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeWidth="0.5"
                    />
                    <path
                        id="path822"
                        d="M346.145-82.795l-3.267,10.348-1.5,15.522,1.229,2.451-.456.182c-3.858,5.174,17.385,16.659,4.131,28.007-7.489,6.4-26.282,2-26.1,10.982.408,17.024-16.247,9.8-16.247,13.21,0,4.31.179,20.56-2.271,24.962-3.767,6.811-15.886,7.945-12.12,16.294,3.814,8.306,15.525,8.671,3.041,20.791-15.431,14.931-12.117,24.054-9.076,31.773l-2.224.091-.047-.865s-12.117-2.632-15.886-2.632-4.9-3.767-4.9-3.767l-1.542-9.487a20.819,20.819,0,0,1-4.131-.365c-.543-.18-.635-2.994-.5-6.125.226-3.632.773-7.624,1.634-8.262,1.5-1.134,1.863-7.942,4.131-13.206a64.147,64.147,0,0,0,3.041-8.355l1.907-9.44-1.134-3.767,1.86-3.405-2.633-2.268,1.5-5.673-3-2.272-2.268-6.443,3.767-1.908s1.134-23.42,3.405-25.326,0-8.714-.364-13.98c-.408-5.312,2.633-7.579,5.265-12.12C274.07-52.386,274.8-62.6,274.8-62.6l-3-5.309-.408-10.578,4.539-3.767L275.2-95.867l2.633-.413,4.9-9.075-.365-9.8.773-4.54,6.038-3.4.769-7.58,5.265-5.674,3.041,1.134,4.9.361,3.406,4.539,2.268-6.035,6.855.361,2.224,3.814,6.807,5.265,4.539,2.269s11.347,6.811,15.157,8.715c3.77,1.906-4.536,14.387-4.536,14.387l11.708,1.138,4.9-2.68,6.082-4.539-.408-5.266,2.4.591,3.679.909-.773,9.848-10.983,4.9-9.8,12.12-.547,1.724"
                        transform="translate(232.448 545.65)"
                        fill="#335f90"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeWidth="0.5"
                    />
                    <path
                        id="path826"
                        d="M313.45-197.473l-4.087,6.855,3.405,4.809-3.041,8.536h-7.172L301.694-175l-3.54.5-8.944-16.386,5.4-2.859.861-10.348a107.508,107.508,0,0,1,17.976,6.625"
                        transform="translate(286.025 441.912)"
                        fill="#335f90"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeWidth="0.5"
                    />
                    <path
                        id="path830"
                        d="M328.025-113.129l-.638,10.439-2.4-.591.408,5.266-6.082,4.539-4.9,2.681L302.7-91.933s8.306-12.481,4.536-14.387c-3.81-1.9-15.157-8.715-15.157-8.715l-4.539-2.269-6.807-5.265-2.224-3.814h.361l4.9-14.752,17.021-1.5,5.4,2.859c-.091,1.407-.091,2.45-.091,2.45l.365,12.073h10.982l4.9,5.312-.773,6.035,6.446.773"
                        transform="translate(269.633 536.039)"
                        fill="#335f90"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeWidth="0.5"
                    />
                    <path
                        id="path834"
                        d="M301.209-186.085l8.944,16.386-8.353,1.181-3.993,3.132-4.539-2.862-2.541-7.351,1.407-6.538-3.406-2.541,1.407-3.132-4.9-.908a4.148,4.148,0,0,0-1.046-1.907,18.83,18.83,0,0,0-2.815-2.268s6.22-1.181,6.22-2.589-1.407-7.942-1.407-7.942l5.309-3.814c5.221,3.588,9.852,6.764,13.21,7.307a23.676,23.676,0,0,1,2.768.638l-.861,10.348-5.4,2.859"
                        transform="translate(274.025 437.106)"
                        fill="#335f90"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeWidth="0.5"
                    />
                    <path
                        id="path838"
                        d="M289.652-49.695l.047-.136c2.177.452,7.216-.364,9.44-.364,2.224-.045,4.809.864,5.447,3.04.591,1.908-6.811,3.224-8.627,4.132-5.4,2.541-6.261,1.407-11.934.816-.591-2.767-2.133-4.583.135-6.22a7.354,7.354,0,0,1,5.491-1.268"
                        transform="translate(276.459 677.585)"
                        fill="#335f90"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeWidth="0.5"
                    />
                    <path
                        id="path842"
                        d="M302.78-91.373l-6.855-.361L293.658-85.7l-3.406-4.539-4.9-.361-3.041-1.134-5.265,5.674-4.536.361-1.546-7.942-2.268-9.444,2.268-3.767-2.994-1.542-2.271-7.172-3.041-2.268,1.316-5.491,3.041-2.815-2.859-1.316.773-3.632v-4.539l2.086-2.633v-7.763l.547-3.584-2.271-4.175-1.36-4.131,6.308.361,11.347-7.942h6.811l.726,12.846,4.175,2.268,6.808,1.137,8.306,5.674,7.58,1.134,1.5,8.35-2.271,1.5,1.907,1.5.365,5.309,10.621-.361v4.539l4.539,4.9a7.623,7.623,0,0,1-.773,5.309c-.908,1.59-1.272,4.539-1.407,6.629l-5.4-2.859-17.021,1.5-4.9,14.752h-.361"
                        transform="translate(245.359 501.029)"
                        fill="#335f90"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeWidth="0.5"
                    />
                    <path
                        id="path846"
                        d="M289.77-158.534l-3.9-4.769,2.585-2.268-3.132-5.674,1.134-7.672,1.407-3.132-10.483.864-5.947-5.947h-9.079c-3.993,0-3.993-7.354-3.993-7.354l.27-5.127s-5.083-.273-3.945-2.862c1.134-2.541,3.675-7.081,3.675-7.081l5.174-5.535a45.84,45.84,0,0,1,13.48,2.224c6.808,3.041,11.347,9.076,26.464,6.082,9.531-1.907,20.24,5.447,29.137,11.573l-5.309,3.814s1.407,6.534,1.407,7.942-6.22,2.589-6.22,2.589a18.829,18.829,0,0,1,2.815,2.268,4.148,4.148,0,0,1,1.046,1.907,9.06,9.06,0,0,1,.361,2.633c0,1.407-5.083,2.815-5.083,2.815l-4.539.861-3.406,1.954-11.347-2.815,5.13,5.4.543,4.813,2.815-.591,1.451,2s-5.127,3.132-5.4,4.539c-.273,1.451-8.488,4.81-12.755,4.266s-3.4-6.538-3.4-6.538l-.955-1.178"
                        transform="translate(232.904 425.08)"
                        fill="#335f90"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeWidth="0.5"
                    />
                    <path
                        id="path850"
                        d="M269.406-39.082c1.134,8.719,26.464,20.065,12.12,21.56a19.07,19.07,0,0,1-2.359.138c-3.679-2.088-8.714-4.448-13.166-5.039l-1.087-23.736,2.224-.091a31,31,0,0,1,2.268,7.168"
                        transform="translate(248.82 683.627)"
                        fill="#335f90"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeWidth="0.5"
                    />
                    <path
                        id="path854"
                        d="M310.951-158.505l-7.58,2.629-7.672.273v2.541l2.589,3.405h-5.13v3.405l4.539,6.534-1.407,8.488-1.5,7.672-4.948-1.5,3.041-6.082-4.9-1.5H276.637l-3.81-6.808-2.636-1.5-3.767-4.539-4.219-.273a3.4,3.4,0,0,0-3-1.995c-4.539-.408-8.671.361-9.079-1.542-.27-1.32-6.126-5-9.44-6.946,5.265-5.039,10.348-14.435,9.079-23.285-1.5-10.621,9.849-19.7,13.615-23.467s10.578-12.12,19.653-12.12c.591,0,1.137,0,1.681.047l-5.174,5.535s-2.541,4.539-3.675,7.081c-1.137,2.589,3.945,2.862,3.945,2.862l-.27,5.127s0,7.354,3.993,7.354h9.079l5.947,5.947,10.483-.864-1.407,3.132-1.134,7.672,3.132,5.674-2.585,2.268,3.9,4.769"
                        transform="translate(211.723 425.051)"
                        fill="#335f90"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeWidth="0.5"
                    />
                    <path
                        id="path866"
                        d="M305.343-119.76l2.271,4.175L307.068-112v7.763l-2.086,2.633v4.539l-.773,3.632,2.859,1.316L304.027-89.3l-1.316,5.491-3.041,3.767-4.219.908a25.1,25.1,0,0,0-9.761-11.482c-28.006-16.659-39.353-54.47-44.619-59.007-5.309-4.539-4.536-18.927-4.536-23.467a14.239,14.239,0,0,1,.088-1.816l2.545,1.09,2.268,5.674-.365,6.035,7.945,1.5s1.86-5.674,4.539-7.533c2.633-1.907,7.172-4.948,8.306-4.948s9.44-7.172,8.306-8.671c-.726-1-.5-3.949-1.542-5.947l4.219.273,3.767,4.539,2.636,1.5,3.81,6.808H294.4l4.9,1.5-3.041,6.082,4.948,1.5-1.907,1.5s-11.347,2.271-13.618,4.178-3.767,9.076-3.767,9.076l-4.175,5.312,4.175,7.172.365,5.674h3.767l3.041,2.268,5.309-.77,3.405-2.271L297.4-124.3l6.581.408,1.36,4.131"
                        transform="translate(205.302 467.007)"
                        fill="#335f90"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeWidth="0.5"
                    />
                    <path
                        id="path870"
                        d="M282.643-114.892l-.77,7.58-6.038,3.4-.773,4.539.365,9.8-4.9,9.075-2.633.413.726,13.615L264.08-62.7,259-63.287c6.082-27.187,10.483-60.094,2-78.7l4.219-.908,3.041-3.767L271.3-144.4l2.271,7.172,2.994,1.542-2.268,3.767,2.268,9.444,1.546,7.942,4.536-.361"
                        transform="translate(239.759 529.861)"
                        fill="#335f90"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeWidth="0.5"
                    />
                    <path
                        id="path874"
                        d="M289.03-8.337c4.452.591,9.487,2.95,13.166,5.039-15.843.27-50.7-10.668-52.108-37.946-.226-4-.273-8.806-.138-14.206l10.986.136c-.135,3.131-.044,5.946.5,6.125a20.819,20.819,0,0,0,4.131.365l1.542,9.487s1.134,3.767,4.9,3.767S287.9-32.938,287.9-32.938l.047.865L289.03-8.337"
                        transform="translate(225.79 669.541)"
                        fill="#335f90"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeWidth="0.5"
                    />
                    <path
                        id="path878"
                        d="M278.411-102.558l3,5.309S280.682-87.037,278-82.5c-2.633,4.54-5.674,6.808-5.265,12.12.364,5.266,2.633,12.074.364,13.98S269.7-31.071,269.7-31.071l-3.767,1.908,2.268,6.443,3,2.272-1.5,5.673,2.633,2.268L270.469-9.1,271.6-5.336,269.7,4.1a64.147,64.147,0,0,1-3.041,8.355c-2.268,5.264-2.633,12.072-4.131,13.206-.861.638-1.407,4.63-1.634,8.262L249.9,33.791c.547-33.362,6.673-89.1,14.483-115.154,2.45-8.171,5.674-19.61,8.532-32.364l5.083.591.408,10.578"
                        transform="translate(225.836 580.3)"
                        fill="#335f90"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeWidth="0.5"
                    />
                    <path
                        id="path882"
                        d="M268.549-181.368c1.043,2,.817,4.948,1.542,5.947,1.134,1.5-7.172,8.671-8.306,8.671s-5.674,3.041-8.306,4.948c-2.68,1.86-4.539,7.533-4.539,7.533l-7.945-1.5.364-6.035-2.268-5.674-2.545-1.09c.273-5.721,2.089-17.476,7.445-20.878a20.381,20.381,0,0,0,3.041-2.406c3.314,1.951,9.17,5.626,9.44,6.946.408,1.9,4.539,1.134,9.079,1.542a3.4,3.4,0,0,1,3,1.995"
                        transform="translate(205.379 460.666)"
                        fill="#335f90"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeWidth="0.5"
                    />
                    <path
                        id="path886"
                        d="M267.7-203.571l-5.356,9.076c-2.859-4.536-.591-9.892-5.674-10.166-5.13-.317-7.672,2.541-7.672,2.541s1.134,10.486-6.264,4.813a80.223,80.223,0,0,0-10.439-6.72l2.224-5.9c5.312,2.676,10.031,2.541,17.294.726,7.989-2,15.886,5.63,15.886,5.63"
                        transform="translate(198.872 432.988)"
                        fill="#335f90"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeWidth="0.5"
                    />
                    <path
                        id="path902"
                        d="M245.861-204.3l-2.224,5.9c-3.085-1.677-5.717-2.95-7.715-4.084-3.949-2.271-9.622-1.951-9.622-5.083a12.859,12.859,0,0,0-1.407-5.221l8.215-.817,3.405,2.268,3.493-1.725c.182,3.679,1.364,6.264,4.448,8.036.456.27.955.5,1.407.726"
                        transform="translate(187.532 427.359)"
                        fill="#335f90"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeWidth="0.5"
                    />
                    <path
                        id="path906"
                        d="M244.689-222.675c.638,2.18.729,5.221.091,9.714-.817,5.812-1.5,10.3-1.316,13.8l-3.493,1.725-3.405-2.268-8.215.817a9.288,9.288,0,0,0-3.675-4.4c-1.364-.682-1.772-2-2.042-3.406l1.451-8.171,3.041-.726,3-.77,4.175-5.312,3.4,1.134,6.99-2.133"
                        transform="translate(184.074 413.465)"
                        fill="#335f90"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeWidth="0.5"
                    />
                    <path
                        id="path910"
                        d="M252.954-218.412l-6.99,2.133-3.4-1.134-4.175,5.312-3,.77-3.041.726-1.451,8.171c-.408-1.634-.729-3.358-2.224-4.266a8.579,8.579,0,0,0-2.18-.726l-.182-3.54-4.539-1.5-4.536-2.271,2.633-5.674L219.73-223l5.356-1.681a2.86,2.86,0,0,0,2.133.682c4.266,0,15.343-2.859,19.609-.591,2.676,1.407,5.083,2.589,6.126,6.173"
                        transform="translate(175.809 409.201)"
                        fill="#335f90"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeWidth="0.5"
                    />
                    <path
                        id="path914"
                        d="M315.019-193.081c5.947,0,18.155-3.4,16.16,1.681-1.59,4.04-5.765,11.573-7.763,17.476l-3.858-.729-4.9,3.77H306.3l-2.633,3.81,7.58,6.811-2.68,2.633-3.767,1.134H299.5l-.773,5.265c-5.309-4.766-10.712-11.711-15.748-10.078-6.264,2-45.118-2.268-56.192-15.613s-1.407-13.345-5.677-19.879c-4.219-6.49-38.851-49.61-43.663-57.829-4.857-8.259-13.072-7.125-6.534,4.222s23.555,39.445,25.826,41.713,4.539,10.8-4.266,2.271-9.943-19.019-14.753-19.565c-4.813-.587-13.028-7.125-12.481-8.806s5.944-1.995,5.083-3.675c-.773-1.59-10.669-20.47-16.7-27.642l13.116-1.59,18.927,8.306,20.065,1.907-.773-3.767,10.983.365,3,3.767s4.948,3.4,6.446,6.082c1.5,2.633,6.443,10.939,9.076,9.079,2.633-1.907,4.9-6.085,4.9-6.085h5.309l15.886,24.24,9.622,2.042a1.99,1.99,0,0,1-.27,1.181c-1.407,2.268-4.27,17.564-2.271,22.967,2,5.356,5.13,17.881,10.213,20.7,5.13,2.815,17.021,4.813,21.833,1.725,4.857-3.132,10.53-5.991,10.53-10.257,0-4.222-1.137-7.628,4.809-7.628"
                        transform="translate(78.387 344.517)"
                        fill="#335f90"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeWidth="0.5"
                    />
                    <path
                        id="path918"
                        d="M229.223-217.454l.182,3.54c-3.267-.817-8.988-1.5-14.071-2.815l4.813-4.5,4.536,2.271,4.539,1.5"
                        transform="translate(172.897 415.688)"
                        fill="#335f90"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeWidth="0.5"
                    />
                    <path
                        id="path922"
                        d="M222.136-230.942l3.858.729a20.615,20.615,0,0,0-1.043,4.357c-.408,4.583-1.316,8.124.135,9.575L219.73-214.6l-.226-3.5-2.268-9.076,4.9-3.77"
                        transform="translate(175.809 400.806)"
                        fill="#335f90"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeWidth="0.5"
                    />
                    <path
                        id="path926"
                        d="M229.369-216.881l.138,2.585-2.633,5.674-4.813,4.5a26.423,26.423,0,0,1-7.307-2.724,24.209,24.209,0,0,1-3.814-2.95l.773-5.265h5.309l3.767-1.134,2.68-2.633-7.58-6.811,2.633-3.81h8.353l2.268,9.076.226,3.5"
                        transform="translate(166.17 403.086)"
                        fill="#335f90"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeWidth="0.5"
                    />
                    <path
                        id="path858"
                        d="M275.653-230.981c1.713.949.724,2.3-1.036,3.112-1.8.811-3.876.225-5.727-.5-.677-.225-1.489-.764-1.308-1.442a1.316,1.316,0,0,1,.811-.811,9.026,9.026,0,0,1,7.172-.543l.087.181"
                        transform="translate(252.802 399.672)"
                        fill="#335f90"
                        stroke="#fff"
                        strokeWidth="0.5"
                    />
                    <path
                        id="path862"
                        d="M277.851-227.89c.5.315,1.127.811.992,1.4-.138.5-.724.721-1.217.855a98.4,98.4,0,0,1-19.165,2.615l-.362-11.631a60.294,60.294,0,0,1,19.752,6.763"
                        transform="translate(238.477 395.139)"
                        fill="#335f90"
                        stroke="#fff"
                        strokeWidth="0.5"
                    />
                    <path
                        id="path890"
                        d="M259.619-234.539a53.164,53.164,0,0,1,7.121.677l.362,11.631a92.915,92.915,0,0,1-14.608-.449c-.543-1.579,1.217-3.068,2.883-3.293s3.383.272,5.053-.09c1.623-.359,3.155-2.253,2.253-3.651a9.354,9.354,0,0,1-4.013-.5,3.244,3.244,0,0,1-2.029-3.155c.181-1.355,1.938-2.347,2.977-1.489v.315"
                        transform="translate(229.835 394.348)"
                        fill="#335f90"
                        stroke="#fff"
                        strokeWidth="0.5"
                    />
                    <path
                        id="path894"
                        d="M287.945-222.924a2.268,2.268,0,0,1-.949,2.887,6.2,6.2,0,0,1-3.246.811,58.452,58.452,0,0,1-11.906-.63,5.963,5.963,0,0,1-3.47-1.4c-1.127-1.127-1.217-2.977-2.119-4.376-1.938-2.93-6.266-2.344-9.74-2.93-3.608-.587-6.672-2.8-9.874-4.51s-6.991-3.021-10.417-1.757c-2.391.855-5.589,2.615-6.853.449-.9-1.576.677-3.517,2.391-4.057,1.757-.54,3.651-.315,5.365-.945,2.753-1.039,4.691-4.194,7.665-4.238,3.2-.047,5.277,3.651,8.389,4.553,1.894.54,3.876,0,5.817-.091,5.093-.181,9.737,2.84,14.022,5.727,4.6,3.155,9.2,6.313,13.844,9.469a3.429,3.429,0,0,1,1.083,1.036"
                        transform="translate(194.565 381.559)"
                        fill="#335f90"
                        stroke="#fff"
                        strokeWidth="0.5"
                    />
                    <path
                        id="path898"
                        d="M253-226.4a9.753,9.753,0,0,1-10.1-2.3l.09-.855a5.768,5.768,0,0,1,6.089-1.851A5.786,5.786,0,0,1,253-226.4"
                        transform="translate(215.446 399.69)"
                        fill="#335f90"
                        stroke="#fff"
                        strokeWidth="0.5"
                    />
                </g>
            </svg>
        );
    }

    return null;
}

export default Icons;
