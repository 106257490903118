import React from "react";
import PhoneInput from "react-phone-number-input";
import BrandsLogos from "../../components/BrandsLogos";
import ErrorMessage from "../../components/ErrorMessage/index";
import Blueberry from "../../../blueberry/index";
import { Helmet } from "react-helmet";
import ProductsSectionTemplate from "../../templates/ProductsSectionTemplate";
import withTracker from "../../../blueberry/connectPromise";
import {
	StatusBar,
	Info,
	Featured,
	Invite,
	Popup,
	Brands,
	Brand,
	HowSection,
	Calculator,
	Input,
	PleaseLogin,
	ControlExtended,
} from "./style";
import {
	Container,
	Section,
	Title,
	Subtitle,
	Columns,
	Column,
	Box,
	Heading,
} from "../../sdk/Layout";
import { Button, Icon } from "../../sdk/Button";
import { Field, Control, SelectBox } from "../../sdk/Form";
import SelectCountries from "../../components/SelectCountries";
import Icons from "./Icons";
import SocialIcon from "../../components/Icons2";
import ModalPortal from "../../components/ModalPortal";
import ModalDetails from "./components/ModalDetails";
import InternalLink from "../../components/InternalLink";
import validate from "../../utils/validator";
import { isNumeric, numberWithCommas } from "../../utils";
import lang_es from "./lang/lang_es";
import lang_en from "./lang/lang_en";
import lang_pt from "./lang/lang_pt";

function getLang(lang) {
    switch (lang) {
        case "es":
            return lang_es;
        case "en":
            return lang_en;
        case "pt":
            return lang_pt;
    }
}

const referralValue = 0.05;

class ReferralPage extends ProductsSectionTemplate {
	state = {
		isModalVisible: false,
		isModalDetalVisible: false,
		formControls: {
			firstName: {
				value: "",
				valid: false,
				touched: false,
				validationRules: {
					minLength: 3,
					isRequired: true,
				},
			},
			lastName: {
				value: "",
				valid: false,
				touched: false,
				validationRules: {
					minLength: 3,
					isRequired: true,
				},
			},
			companyName: {
				value: "",
				valid: false,
				touched: false,
				validationRules: {
					minLength: 3,
					isRequired: true,
				},
			},
			contactPhoneNumber: {
				value: "",
				valid: false,
				touched: false,
				validationRules: {
					isPhone: true,
					isRequired: true,
				},
			},
			email: {
				value: "",
				valid: false,
				touched: false,
				validationRules: {
					minLength: 3,
					isRequired: true,
					isEmail: true,
				},
			},
			brands: {
				value: ["wellness"],
				valid: true,
				touched: true,
				validationRules: {
					isRequired: false,
				},
			},
			country: {
				value: "",
				valid: false,
				touched: false,
				validationRules: {
					minLength: 3,
					isRequired: true,
				},
			},
			website: {
				value: "",
				valid: true,
				touched: false,
				validationRules: {
					minLength: 3,
					isRequired: false,
				},
			},
			instagram: {
				value: "",
				valid: true,
				touched: false,
				validationRules: {
					minLength: 3,
					isRequired: false,
				},
			},
			facebook: {
				value: "",
				valid: true,
				touched: false,
				validationRules: {
					minLength: 3,
					isRequired: false,
				},
			},
		},
		referrals: [],
		brandsToRefer: 20000,
	};

    static async getInitialData(props) {
        // const token = props.initialToken ? props.initialToken : (props.token ? props.token.token : "")
        let { lang } = props.match.params;

        const slugF = "referral-noritex";

        return new Promise((resolve, reject) => {
            return Blueberry.page({ slug: slugF, lang })
                .then((resp) => {
                    if (
                        resp &&
                        resp.customFields &&
                        resp.customFields.ambientBlock &&
                        resp.customFields.ambientBlock.length > 0
                    ) {
                        Blueberry.collectionChildren({
                            limit: 6,
                            slug: resp.customFields.ambientBlock[0].slug,
                        }).catch((err) => {});
                    }

                    if (
                        resp &&
                        resp.customFields &&
                        resp.customFields.recomendedProducts &&
                        resp.customFields.recomendedProducts !== ""
                    ) {
                        Blueberry.itemToUser({
                            limit: 25,
                            filter: resp.customFields.recomendedProducts,
                        }).catch(() => {});
                    }
                    resolve(resp);
                })
                .catch((err) => {
                    resolve({
                        title: "Refiere un Cliente - Noritex",
                        content: "",
                        protected: true,
                        status: "Refiere un Cliente",
                        seo: { title: "", description: "" },
                    });
                });
        });
    }

    componentDidMount() {
        this.getReferrals();
        if (analytics) analytics.track("View Referrals Page");
    }

    getReferrals() {
        Blueberry.getUserReferrals()
            .then((resp) => {
                this.setState({
                    referrals: resp,
                });
            })
            .catch(() => {});
    }

    validateForm(formElement) {
        if (formElement.valid && formElement.touched) return "is-valid";
        if (!formElement.valid && formElement.touched) return "is-invalid";
        return "";
    }

    setValueBrands = (event) => {
        const value = event.currentTarget.getAttribute("data-value");

        const updatedControls = {
            ...this.state.formControls,
        };
        const updatedFormElement = {
            ...updatedControls["brands"],
        };

        updatedFormElement.touched = true;

        if (updatedFormElement.value.indexOf(value) <= -1) {
            updatedFormElement.value = [...updatedFormElement.value, value];
        } else {
            updatedFormElement.value = updatedFormElement.value.filter(
                (index) => index !== value
            );
        }

        updatedControls["brands"] = updatedFormElement;

        this.setState({
            formControls: updatedControls,
        });
    };

    setValue = (event) => {
        const name = event.target.name;
        const value = event.target.value;

        const updatedControls = {
            ...this.state.formControls,
        };
        const updatedFormElement = {
            ...updatedControls[name],
        };

        updatedFormElement.value = value;
        updatedFormElement.valid = validate(
            value,
            updatedFormElement.validationRules
        );
        // updatedFormElement.valid = true;
        updatedFormElement.touched = true;

        updatedControls[name] = updatedFormElement;

        this.setState({
            formControls: updatedControls,
        });
    };

    getFormKey = () => {
        let form_key = "HciGGNh9tH3v5As5y";

        switch (this.props.match.params.lang) {
            case "en":
                form_key = "yiANcmg72b7LtA3N4";
                break;
            case "pt":
                form_key = "qbNKMEPAkR7mHHvPH";
                break;
            default:
                form_key = "HciGGNh9tH3v5As5y";
                break;
        }

        return form_key;
    };

    createReferral = (e) => {
        e.preventDefault();
        const { formControls } = this.state;
        const { user } = this.props.store;
        let hasErrors = false;
        let formData = {};
        const updatedFormElement = { ...formControls };

        for (let formElementId in formControls) {
            if (!formControls[formElementId].valid) {
                hasErrors = true;
                updatedFormElement[formElementId].touched = true;
            }
            formData[formElementId] = formControls[formElementId].value;
            // formData += `${formElementId}=${formControls[formElementId].value}&`;
        }

        if (hasErrors) {
            this.setState({ formControls: updatedFormElement });
            return;
        }

        if (user && user.profile) {
            formData[
                "referredBy"
            ] = `${user.profile.firstName} ${user.profile.lastName}`;
        }

        if (analytics) analytics.track("Created Referral");

        this.setState({ isUpdating: true });

        Blueberry.createReferral({ ...formData })
            .then((resp) => {
                this.setState({
                    isUpdating: false,
                    success: true,
                    isModalVisible: false,
                });
                this.getReferrals();
            })
            .catch((err) => {
                console.log(err);
                this.setState({ isUpdating: false, success: true });
            });

        Blueberry.formInsert({
            fields: formData,
            form_key: this.getFormKey(),
        })
            .then((resp) => {
                this.setState({ isUpdating: false, success: true });
            })
            .catch((err) => {
                this.setState({ isUpdating: false, success: true });
            });
    };

    toggleModal = () => {
        this.setState((prev) => ({ isModalVisible: !prev.isModalVisible }));
    };

    toggleDetailModal = () => {
        this.setState((prev) => ({
            isModalDetalVisible: !prev.isModalDetalVisible,
        }));
    };

    setBrandsToRefer = (e) => {
        const { value } = e.target;
        if (isNumeric(value) || value === "")
            this.setState({
                brandsToRefer: value === "" ? 0 : parseInt(value),
            });
    };

    removeHtml(html) {
        return html ? html.replace(/<\/?[^>]+(>|$)/g, "").trim() : "";
    }

    renderBody() {
        const { lang } = this.props.match.params;

        const {
            formControls,
            isModalVisible,
            isUpdating,
            success,
            brandsToRefer,
            referrals,
            isModalDetalVisible,
        } = this.state;
        const {
            firstName,
            lastName,
            email,
            brands,
            contactPhoneNumber,
            companyName,
            country,
            website,
            instagram,
            facebook,
        } = formControls;

        const langJson = getLang(lang);

        const data = this.props.data
            ? this.props.data
            : {
                  content: "",
                  title: "Refiere un Cliente",
                  seo: {
                      title: "Refiere un Cliente al por Mayor - Noritex",
                      page: "Refiere un Cliente al por mayor en Noritex. Una tienda en linea para comprar mayorista de decoracion",
                  },
              };
        const { pathname } = this.props.location;
        const title =
            data.seo && data.seo.title != "" ? data.seo.title : data.title;
        const description =
            data.seo && data.seo.description != ""
                ? data.seo.description
                : this.removeHtml(data.content);
        const image =
            data.seo && data.seo.images && data.seo.images.length > 0 != ""
                ? data.seo.images[0]
                : "https://www.noritex.com/facebook-ntx.jpg";
        const structured =
            data.seo && data.seo.strcutured != "" ? data.seo.strcutured : "";

        const baseUrl = this.stripLangDir(lang, pathname);
        const enUrl = this.addLangDir("en", pathname, baseUrl);
        const ptUrl = this.addLangDir("pt", pathname, baseUrl);
        const esUrl = this.addLangDir("es", pathname, baseUrl);

        // if (data.customFields) {
        // 	customfieldsKeys = Object.keys(data.customFields);
        // 	if (customfieldsKeys.indexOf("Slider") > -1) {
        // 		customfieldsKeys = customfieldsKeys.filter(
        // 			index => index !== "Slider"
        // 		);
        // 		topCustomFields.push("Slider");
        // 	}
        // }

        return (
            <>
                <Helmet htmlAttributes={{ lang: lang }}>
                    <title>{title}</title>
                    <meta name="description" content={description} />
                    <meta
                        name="keywords"
                        content="noritex,wholesale,decoracion,mayorista,hogar,navidad,textil,religioso"
                    />
                    {/*Alternate*/}
                    <link rel="alternate" hreflang="es" href={`${esUrl}`} />
                    <link rel="alternate" hreflang="en" href={`${enUrl}`} />
                    <link rel="alternate" hreflang="pt" href={`${ptUrl}`} />
                    <meta http-equiv="content-language" content={lang} />
                    {/*Facebook*/}
                    <meta property="og:site_name" content="Noritex" />
                    <meta
                        property="og:url"
                        content={`https://www.noritex.com${pathname}`}
                    />
                    <meta property="og:title" content={title} />
                    <meta property="og:image" content={image} />
                    <meta property="og:type" content="website" />
                    <meta property="og:description" content={description} />
                    {/*Twitter*/}
                    <meta name="twitter:title" content={title} />
                    <meta name="twitter:description" content={description} />
                    <script type="application/ld+json">
                        {`
	                        {
	                          "@context": "https://schema.org",
	                          "@type": "Organization",
	                          "url": "https://www.noritex.com/${lang}/",
	                          "name": "Noritex",
	                          "contactPoint": {
	                            "@type": "ContactPoint",
	                            "telephone": "+507-300-3003",
	                            "contactType": "Customer service"
	                          },
	                          "potentialAction": {
						        "@type": "SearchAction",
						        "target": "https://www.noritex.com/es/search?qry={search_term_string}",
						        "query-input": "required name=search_term_string"
						      }
	                        }
                    	`}
                    </script>
                    <script type="application/ld+json">{structured}</script>
                </Helmet>
                <StatusBar>
                    <Container>
                        <div>
                            <h2>{langJson.yourShoppingCredit}</h2>
                        </div>
                        <div>
                            <Info>
                                <li>
                                    <Icon>
                                        <Icons icon="hourglass-half"></Icons>
                                    </Icon>
                                    <div>
                                        <span>{langJson.pending}</span>
                                        <p>
                                            {referrals.pending
                                                ? referrals.pending.length
                                                : 0}
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <Icon>
                                        <Icons icon="coins"></Icons>
                                    </Icon>
                                    <div>
                                        <span>{langJson.earned}</span>
                                        <p>
                                            {referrals.won
                                                ? referrals.won.length
                                                : 0}
                                        </p>
                                    </div>
                                </li>
                            </Info>
                            <Button
                                small
                                white
                                onClick={this.toggleDetailModal}
                            >
                                {langJson.showInviteDetails}
                            </Button>
                        </div>
                    </Container>
                </StatusBar>

                <Featured>
                    <Section>
                        <Container>
                            <Invite>
                                <h1>{langJson.inviteBrand}</h1>
                                <h2>{langJson.beTheFirst}</h2>

                                {this.isLoggedIn() ? (
                                    <>
                                        <div className="empty-space" />
                                        {success ? (
                                            <ErrorMessage
                                                sucess={true}
                                                errorMessage={
                                                    langJson.scucessSending
                                                }
                                            />
                                        ) : null}
                                        <div className="input-form">
                                            <input
                                                type="email"
                                                name="email"
                                                onChange={this.setValue}
                                                value={email.value}
                                                placeholder={
                                                    langJson.enterEmail
                                                }
                                            />
                                            <button onClick={this.toggleModal}>
                                                {langJson.sendInvite}
                                            </button>
                                        </div>
                                    </>
                                ) : (
                                    <PleaseLogin>
                                        <p>
                                            {this._t("youMustEnter")}{" "}
                                            <strong>
                                                {this._t("youMustEnter2")}
                                            </strong>
                                        </p>
                                        <InternalLink to={`/account/login`}>
                                            <Button isFullWidth>Login</Button>
                                        </InternalLink>
                                    </PleaseLogin>
                                )}

                                <p>
                                    {langJson.disclamer}{" "}
                                    <InternalLink to="/pages/terminos-y-condiciones-clientes-referidos">
                                        {langJson.seePolitica}
                                    </InternalLink>
                                </p>
                            </Invite>
                        </Container>
                    </Section>
                </Featured>
                <ModalPortal>
                    {isModalVisible ? (
                        <Popup className={isModalVisible && "is-active"}>
                            <span onClick={this.toggleModal}></span>
                            <button onClick={this.toggleModal}></button>
                            <div>
                                <Box>
                                    <div>
                                        <Heading isCentered>
                                            <h3>{langJson.titlePopup}</h3>
                                        </Heading>

                                        <Subtitle as="p" size="6">
                                            {langJson.subTitlePopup}
                                        </Subtitle>

                                        <form>
                                            <Field>
                                                <Columns>
                                                    <Column>
                                                        <label>
                                                            {this._t(
                                                                "firstName"
                                                            )}
                                                        </label>
                                                        <Control isExpanded>
                                                            <input
                                                                name="firstName"
                                                                className={this.validateForm(
                                                                    firstName
                                                                )}
                                                                onChange={
                                                                    this
                                                                        .setValue
                                                                }
                                                                value={
                                                                    firstName.value
                                                                }
                                                                type="text"
                                                                required
                                                            />
                                                        </Control>
                                                    </Column>
                                                    <Column>
                                                        <label>
                                                            {this._t(
                                                                "lastName"
                                                            )}
                                                        </label>
                                                        <Control isExpanded>
                                                            <input
                                                                name="lastName"
                                                                className={this.validateForm(
                                                                    lastName
                                                                )}
                                                                onChange={
                                                                    this
                                                                        .setValue
                                                                }
                                                                value={
                                                                    lastName.value
                                                                }
                                                                type="text"
                                                                required
                                                            />
                                                        </Control>
                                                    </Column>
                                                </Columns>
                                            </Field>
                                            <Field>
                                                <Columns>
                                                    <Column>
                                                        <label>
                                                            {this._t("company")}
                                                        </label>
                                                        <Control isExpanded>
                                                            <input
                                                                name="companyName"
                                                                onChange={
                                                                    this
                                                                        .setValue
                                                                }
                                                                value={
                                                                    companyName.value
                                                                }
                                                                className={this.validateForm(
                                                                    companyName
                                                                )}
                                                                type="text"
                                                                required
                                                            />
                                                        </Control>
                                                    </Column>
                                                    <Column>
                                                        <label>
                                                            {this._t(
                                                                "phoneNumber"
                                                            )}
                                                        </label>
                                                        <ControlExtended isExpanded>
															<PhoneInput
																className={this.validateForm(contactPhoneNumber)}
																name="contactPhoneNumber"
																autoComplete="off"
																aria-label="Phone Number"
																value={contactPhoneNumber.value}
																onChange={(val) => this.setValue({
																	target: {
																		name: "contactPhoneNumber",
																		value: val ?? "",
																	},
																})}
															/>
                                                        </ControlExtended>
                                                    </Column>
                                                </Columns>
                                            </Field>
                                            <Field>
												<Columns>
													<Column>
														<label>Email</label>
														<Control isExpanded>
															<input
																name="email"
																className={this.validateForm(
																	email
																)}
																onChange={this.setValue}
																value={email.value}
																type="email"
																required
															/>
														</Control>
													</Column>
													<Column>
                                                        <label>{this._t("country")}</label>
														<Control isExpanded>
															<SelectBox isFullWidth>
																<SelectCountries 
																	name="country"
																	className={this.validateForm(country)}
																	onChange={this.setValue}
																	value={country.value}
																	placeholder=""
																/>
															</SelectBox>
														</Control>
                                                    </Column>
												</Columns>
                                            </Field>
                                            <Field>
                                                <Columns>
                                                    <Column>
                                                        <label>{this._t("website")}</label>
														<Field hasAddons>
															<Control>
																<Button static>
																	<SocialIcon icon="globe" />
																</Button>
															</Control>
															<Control isExpanded>
																<input
																	type="text"
																	placeholder=""
																	name="website"
																	onChange={this.setValue}
																	value={website.value}
																	className={this.validateForm(website)}
																/>
															</Control>
														</Field>
                                                    </Column>
													<Column>
                                                        <label>Instagram</label>
														<Field hasAddons>
															<Control>
																<Button static>
																	<SocialIcon icon="instagram" />
																</Button>
															</Control>
															<Control isExpanded>
																<input
																	type="text"
																	placeholder=""
																	name="instagram"
																	onChange={this.setValue}
																	value={instagram.value}
																	className={this.validateForm(instagram)}
																/>
															</Control>
														</Field>
                                                    </Column>
                                                </Columns>
                                            </Field>
											<Field>
                                                <Columns>
                                                    <Column>
                                                        <label>Facebook</label>
														<Field hasAddons>
															<Control>
																<Button static>
																	<SocialIcon icon="facebook" />
																</Button>
															</Control>
															<Control isExpanded>
																<input
																	type="text"
																	placeholder=""
																	name="facebook"
																	onChange={this.setValue}
																	value={facebook.value}
																	className={this.validateForm(facebook)}
																/>
															</Control>
														</Field>
                                                    </Column>
													<Column />
                                                </Columns>
                                            </Field>
                                            <Field>
                                                <Button
                                                    loading={isUpdating}
                                                    onClick={
                                                        this.createReferral
                                                    }
                                                    primary
                                                    responsive
                                                >
                                                    {langJson.sendInvite}
                                                </Button>
                                            </Field>
                                        </form>
                                    </div>
                                </Box>
                            </div>
                        </Popup>
                    ) : null}
                </ModalPortal>
                <ModalDetails
                    langJson={langJson}
                    isVisible={isModalDetalVisible}
                    referrals={referrals}
                    toggleModal={this.toggleDetailModal}
                />
                <HowSection>
                    <Container>
                        <Title as="h3" size="3">
                            {langJson.mainTitle}
                        </Title>
                        <Subtitle>{langJson.hereIsHowItWorks}</Subtitle>

                        <ul>
                            {langJson.icons.map((index, i) => (
                                <li key={`action-set-${i}`}>
                                    <Icons icon={index.icon}></Icons>
                                    <h4>{index.title}</h4>
                                    <p>
                                        {index.paragraph.replace(
                                            "${referralValue}",
                                            referralValue
                                        )}
                                    </p>
                                </li>
                            ))}
                        </ul>
                    </Container>
                </HowSection>

                <Calculator>
                    <Container>
                        <Section>
                            <Columns>
                                <Column>
                                    <Title as="h3" size="3">
                                        {langJson.seeHowMuch}
                                    </Title>
                                    <Input>
                                        <Title as="h4" size="5">
                                            {langJson.enterBrands}
                                        </Title>
                                        <input
                                            type="text"
                                            onChange={this.setBrandsToRefer}
                                            value={brandsToRefer}
                                        />
                                        <Button>{langJson.calculate}</Button>
                                        <span>
                                            <span></span>
                                        </span>
                                    </Input>
                                </Column>
                                <Column>
                                    <div>
                                        <Title as="h4" size="3">
                                            {langJson.potentialReferralCash}
                                        </Title>
                                        <Subtitle as="h5" size="3">
                                            $
                                            {numberWithCommas(
                                                brandsToRefer * referralValue
                                            )}
                                        </Subtitle>
                                    </div>
                                </Column>
                            </Columns>
                        </Section>
                    </Container>
                </Calculator>
            </>
        );
    }
}

export default withTracker()(ReferralPage);

// export default withSSR(Page);
