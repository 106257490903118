import React from "react";
import styled from "styled-components";
import SearchIcon from "../../components/Header/icons/SearchIcon";

const SearchForm = styled.form`
    width: 100%;
    position: relative;
    box-shadow: 0 3px 6px -6px rgba(0, 0, 0, 0.4);
    input {
        width: 100%;
        height: 40px;
        padding: 5px 30px 5px 10px;
        border-radius: 3px;
        font-size: 14px;
        color: black;
        border: solid 1px #e3e3e3;
        background-color: #fff;
        :focus {
            border-color: #bbbec1;
        }
    }
    button {
        position: absolute;
        top: 0;
        right: 0;
        background-color: transparent;
        color: #112c55;
        height: 100%;
        width: 35px;
        text-align: center;
        border: none;
        border-radius: 0 3px 3px 0;
        cursor: pointer;
        opacity: 0.8;
        svg {
            width: 16px;
            margin-top: 3px;
        }
        :hover {
            opacity: 1;
        }
    }
`;

function SearchBox({ placeholder, goToSearch }) {
    const [value, setValue] = React.useState("");

    const handleOnChange = (e) => {
        setValue(e.target.value);
    };

    const handleKeyDown = (event) => {
        if (event.keyCode === 13) {
            goToSearch(value);
        }
    };

    return (
        <SearchForm>
            <input
                type="input"
                value={value}
                onChange={handleOnChange}
                onKeyDown={handleKeyDown}
                placeholder={placeholder}
            />
            <button>
                <SearchIcon />
            </button>
        </SearchForm>
    );
}

export default SearchBox;
