import React from 'react';
import Header from './Header';
import { StyledLayout } from '../style';

export default function Layout({ lang, children, className }) {
  return (

    <StyledLayout className={className}>
      <Header lang={lang}/>
      {children}
    </StyledLayout>
  )
}

