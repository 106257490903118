export default {
    menu: {
        item1: 'Como participar',
        item2: 'Preguntas frequentes',
        item3: 'Solicitar informação',
    },
    hero: {
        pre: "ESTOQUE SEU NEGÓCIO COM AS NOVAS TENDÊNCIAS",
        title: "Feira de Decoração do Lar<br /> Concepts Life",
        sub: "De 18 de julho a 19 de agosto",
        btn: "Solicitar informação",
    },
    form: {
        heading: "Solicite mais informações",
        btn: "Solicitar informação",
    },
    stats: [
        {
            title: '+8k',
            subtitle: 'Productos'
        },
        {
            title: '+15',
            subtitle: 'Coleções'
        },
        {
            title: '+4',
            subtitle: 'Estlos de vida'
        },
    ],
    who: {
        heading: '¿CÓMO PARTICIPAR?',
        items: [
            {
                title: 'Antes que você comece',
                content: 'A Feira de Decoração do Lar Concepts Life é apenas para compras no atacado e é voltada para empresas com canais de vendas estabelecidos que investem a partir de USD 10.000 em mercadorias para suas lojas.',
                image: 'https://www.conceptslife.com/wp-content/uploads/2021/09/Diseno-sin-titulo-1.png'
            },
            {
                title: 'Inscrever-se',
                content: 'No formulário você pode deixar dados como nome da empresa, cargo que ocupa, tipo de negócio, entre outros... Lembrando que todos os campos são necessários para que nossos executivos possam entrar em contato com você.',
                image: 'https://www.conceptslife.com/wp-content/uploads/2021/09/Diseno-sin-titulo-5.png'
            },
            {
                title: 'Ser contatado',
                content: 'Em um período não superior a 48 horas, um de nossos executivos fornecerá as informações necessárias para acessar a feira. Você pode nos visitar na Cidade do Panamá, agendar uma videochamada ou fazer seu pedido online.',
                image: 'https://www.conceptslife.com/wp-content/uploads/2021/09/Diseno-sin-titulo-3.png'
            },
        ],
    },
    faqs: {
        heading: 'Preguntas frecuentes',
        items: [
            {
                title: 'Que requisitos devo cumprir para participar?',
                content: '<ol><li>Sua empresa deve ter experiência em compra e importação de produtos no atacado. </li><li>Além disso, deve ter lojas ou canais de vendas definidos (não em projeto).</li><li>O orçamento mínimo de compra é de US$ 10.000.</li></ol>'
            },
            {
                title: 'Que tipos de empresas podem participar? ',
                content: 'Nossa feira internacional de decoração é voltada para empresas como: lojas de departamentos, lojas especializadas em decoração, atacadistas, farmácias, supermercados, lojas de ferragens, lojas de móveis, pequenos varejistas, livrarias, lojas online, entre outros.'
            },
            {
                title: 'Eu compro da China, qual diferencial você pode me oferecer?',
                content: 'Na Noritex realizamos um processo de seleção de produtos com base em estudos aprofundados das tendências de decoração para o lar. A seleção de produtos que trazemos para oferecer ao seu negócio já é previamente refinada, para que você não perca tempo passando por centenas de fábricas na China. <br /><br /> Ao comprar conosco, você encontrará os produtos organizados em coleções baseadas nos estilos de vida dos consumidores, o que facilitará o processo de seleção dos produtos e a posterior exposição no seu ponto de venda, para que seus clientes encontrem total segurança as últimas tendências em decoração. '
            },
            {
                title: 'Quais benefícios você recebe ao fazer compras conosco no Panamá?',
                content: 'Devido à nossa localização geográfica, estando no Panamá, podemos gerenciar o envio de sua mercadoria de forma mais rápida. Também o ajudamos no processo de consolidação para que possa receber a sua mercadoria agrupada.'
            },
            {
                title: 'Posso fazer envios diretos dos produtos que selecionei na Feira da China?',
                content: 'Se preferir, você também pode gerenciar conosco o envio direto da China da mercadoria que você selecionou na feira para seu pedido em nosso site ou em nosso showroom a um preço melhor.'
            },
            {
                title: 'Tenho que comprar grandes quantidades por referência?',
                content: 'Não. Com a Noritex você pode comprar quantidades mínimas por referência sem correr o risco de comprar grandes estoques. Realizamos 4 feiras por ano, para que possa abastecer os seus pontos de venda a cada 3 meses, garantindo ao seu negócio a rotação de mercadoria e aos seus clientes a possibilidade de encontrar as últimas tendências em decoração nas suas lojas.'
            },
            {
                title: 'Posso participar independentemente da minha localização geográfica?',
                content: 'Sim, temos clientes em mais de 30 países. Além disso, após habilitarmos seu acesso personalizado ao nosso site, você terá a opção de entrar em nossa plataforma e processar seu pedido online 24 horas por dia, 7 dias por semana.'
            },
            {
                title: 'Posso visitar a feira pessoalmente/fisicamente? ',
                content: 'm, nosso Showroom está localizado na Cidade do Panamá, Panamá, a 5 minutos do Aeroporto Internacional de Tocumen. No momento em que nosso executivo de vendas entrar em contato com você, ele indicará o protocolo para que você possa coordenar sua visita presencial.'
            },
            {
                title: 'Se eu comprar online, receberei assistência de um representante de vendas?',
                content: 'Sim, uma vez aprovado o seu acesso ao nosso site, você receberá assistência de um executivo de vendas designado para acompanhá-lo durante todo o processo.'
            },
        ],
    },
    gallery: [
        {title: 'Espelhos', image: 'https://www.conceptslife.com/wp-content/uploads/2022/03/1-Espejos.jpg'},
        {title: 'Figuras decorativas', image: 'https://www.conceptslife.com/wp-content/uploads/2022/03/2-Figuras-decorativas.jpg'},
        {title: 'Almofadas', image: 'https://www.conceptslife.com/wp-content/uploads/2022/03/3-Cojines.jpg'},
        {title: 'Tabelas auxuliares', image: 'https://www.conceptslife.com/wp-content/uploads/2022/03/4-Mesas-auxiliares.jpg'},
        {title: 'Potes', image: 'https://www.conceptslife.com/wp-content/uploads/2022/03/5-Potes.jpg'},
        {title: 'Relójios', image: 'https://www.conceptslife.com/wp-content/uploads/2022/03/6-Relojes.jpg'},
        {title: 'Pinturas', image: 'https://www.conceptslife.com/wp-content/uploads/2022/03/7-Cuadros.jpg'},
        {title: 'Vasos', image: 'https://www.conceptslife.com/wp-content/uploads/2022/03/8-Floreros.jpg'},
        {title: 'Acessórios de mesa', image: 'https://www.conceptslife.com/wp-content/uploads/2022/03/9-Accesorios-de-mesa.jpg'},
        {title: 'Lâmpadas', image: 'https://www.conceptslife.com/wp-content/uploads/2022/03/10-lamparas.jpg'},
        {title: 'Pratos decorativos', image: 'https://www.conceptslife.com/wp-content/uploads/2022/03/11-placas-de-pared.jpg'},
        {title: 'Candelabro', image: 'https://www.conceptslife.com/wp-content/uploads/2022/03/12-Portavelas.jpg'},
    ],
    footer: 'Concepts Life é uma marca de '
};












