export default {
	title: "Showrooms",
	subTitle: "Visit Our Showroom",
	paragraph:
		"Our showrooms in Panama are now open, with your safety as our top priority. To ensure a safe and comfortable shopping experience, we have implemented several measures. Please follow our one-way system upon entering, as guided by clear signage. Our team members are ready to assist you at information points, maintaining safe distances. We kindly ask you to adhere to social distancing guidelines during your visit, and refrain from visiting if you are unwell. For more detailed information, please refer to your local showroom page. If you're unable to visit our showrooms in person, we offer free online appointments for your convenience.",
	paragraphs: [
		"Visiting our showroom provides you with a unique opportunity to explore an exclusive selection of new collection and trendy products, ensuring exclusivity in your stores.",
		"We have over 4,000 references available in all styles and categories, allowing you to purchase the quantities you need according to your requirements.",
		"By buying our collections, you will be able to create unique and differentiated environments in your stores, guaranteeing exceptional shopping experiences for your consumers. We offer consolidated shipments from Panama and direct shipments from the East, which will allow you to save significantly on freight.",
		"If you are not able to travel, we have a real-time digital showroom that you can visit virtually and receive personalized attention via video call.",
		"We invite you to visit us and discover how our collections can transform your stores.",
	],
	titleActions: "Safety first",
	titleVirtualShowroom: "Virtual Showroom",
	paragraphVirtualShowroom: "Explore our virtual showroom through the following 3D map",
	actions: [
		{
			title: "Cleaning station",
			icon: "hand-wash",
			paragraph:
				"Hand washing facilities are available at the entrance and exit of our showroom. We encourage you to utilize them for your safety",
		},
		{
			title: "Social distancing",
			icon: "social-distance",
			paragraph:
				"To ensure everyone's safety, please maintain a distance of at least 2 meters from others during your visit.",
		},
		{
			title: "You + 1",
			icon: "no-crowd",
			paragraph:
				"We request that you limit your party to one additional person from your household to aid in maintaining social distancing.",
		},
		{
			title: "See without touching",
			icon: "do-not-touch",
			paragraph:
				"As a precautionary measure, we advise against touching the products during your visit.",
		},
		{
			title: "Free masks",
			icon: "mask",
			paragraph:
				"Complimentary face masks are available for all team members, members, and clients to ensure a safer environment.",
		},
	],
	direction: "Address",
	phoneNumber: "Telephone",
	email: "Email",
};
