export default {
    yourShoppingCredit: "Your earned commission",
    pending: "Pending",
    pendingPlural: "Pending",
    earned: "Earned",
    won: "Won",
    inelegible: "Ineligible",
    showInviteDetails: "Show Invite Details",
    inviteDetails: "Your Invite Details",
    inviteBrand: "Refer customers, earn a commission in cash!",
    inShoppingCredit: "",

    beTheFirst:
        "When you refer clients to Noritex, you will receive a 5% commission in a wire transfer, once the purchase made by your referral has been successfully confirmed.*",
    inCredit: "",
    scucessSending: "Success Sending Invitation",
    enterEmail: "Email of your referral",
    sendInvite: "Send Invites",

    disclamer: "*Terms and Conditions May Apply",
    seePolitica: "View Terms",
    titlePopup: "We got your invites!",
    subTitlePopup:
        " Please complete the information below to send the invitation.",

    selectReferralBrands: "Select the brands you want to refer.",
    mainTitle: "This is the Noritex referral program!",
    hereIsHowItWorks: "Here's how it works",
    icons: [
        {
            title: "REFER A CLIENT",
            icon: "send-light",
            paragraph: "Enter the email and the information of your referral.",
        },
        {
            title: "GET PAID",
            icon: "hand-holding-usd",
            paragraph:
                "You will earn 5% commission once the first purchase of your referral has been successfully confirmed. You will receive this commission as a wire transfer or via PayPal.",
        },

        {
            title: "REPEAT",
            icon: "repeat",
            paragraph:
                "There are no referral limits! The more clients you refer, the more credit you get. ",
        },
    ],
    seeHowMuch: "See what you can make!",
    enterBrands: "Enter Referrals",
    calculate: "Calculate",
    potentialReferralCash: "Potential referral cash",
};
