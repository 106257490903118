import React from "react";
import CustomFields from "../../components/CustomFields";
import Blueberry from "../../../blueberry/index";
import Icons from "./components/Icons";
import { Helmet } from "react-helmet";
import ProductsSectionTemplate from "../../templates/ProductsSectionTemplate";
import withTracker from "../../../blueberry/connectPromise";
import {
    Items,
    Item,
    SectionExtended,
    ContainerExtended,
    Text,
    Map,
} from "./style";
import { Icon } from "../../sdk/Button";
import { Columns, Column, Title, Heading, Box } from "../../sdk/Layout";
import { Container, Section } from "../../sdk/Layout";
import InternalLink from "../../components/InternalLink";
import lang_es from "./lang/lang_es";
import lang_en from "./lang/lang_en";
import lang_pt from "./lang/lang_pt";

function getLang(lang) {
    switch (lang) {
        case "es":
            return lang_es;
        case "en":
            return lang_en;
        case "pt":
            return lang_pt;
    }
}

class Page extends ProductsSectionTemplate {
    state = {
        menus: [],
        days: "00",
        hours: "00",
        minutes: "00",
        seconds: "00",
    };

    static async getInitialData(props) {
        // const token = props.initialToken ? props.initialToken : (props.token ? props.token.token : "")
        let { lang } = props.match.params;

        const slugF = "why-us";

        return new Promise((resolve, reject) => {
            return Blueberry.page({ slug: slugF, lang })
                .then((resp) => {
                    if (
                        resp &&
                        resp.customFields &&
                        resp.customFields.ambientBlock &&
                        resp.customFields.ambientBlock.length > 0
                    ) {
                        Blueberry.collectionChildren({
                            limit: 6,
                            slug: resp.customFields.ambientBlock[0].slug,
                        }).catch((err) => {});
                    }

                    if (
                        resp &&
                        resp.customFields &&
                        resp.customFields.recomendedProducts &&
                        resp.customFields.recomendedProducts !== ""
                    ) {
                        Blueberry.itemToUser({
                            limit: 25,
                            filter: resp.customFields.recomendedProducts,
                        }).catch(() => {});
                    }
                    resolve(resp);
                })
                .catch((err) => {
                    resolve({
                        title: "Porque Nosotros - Noritex",
                        content: "",
                        protected: false,

                        seo: { title: "", description: "" },
                    });
                });
        });
    }

    removeHtml(html) {
        return html ? html.replace(/<\/?[^>]+(>|$)/g, "").trim() : "";
    }

    renderBody() {
        const { lang } = this.props.match.params;
        const { childCollection, recomendations } = this.props.store;

        const langJson = getLang(lang);

        const data = this.props.data
            ? this.props.data
            : {
                  content: "",
                  title: "Porque Nosotros - Noritex",
                  seo: {
                      title: "Porque Nosotros - Noritex",
                      page: "Porque trabajar con Noritex",
                  },
              };
        const { pathname } = this.props.location;
        const title =
            data.seo && data.seo.title != "" ? data.seo.title : data.title;
        const description =
            data.seo && data.seo.description != ""
                ? data.seo.description
                : this.removeHtml(data.content);
        const image =
            data.seo && data.seo.images && data.seo.images.length > 0 != ""
                ? data.seo.images[0]
                : "https://www.noritex.com/facebook-ntx.jpg";
        const structured =
            data.seo && data.seo.strcutured != "" ? data.seo.strcutured : "";

        const baseUrl = this.stripLangDir(lang, pathname);
        const enUrl = this.addLangDir("en", pathname, baseUrl);
        const ptUrl = this.addLangDir("pt", pathname, baseUrl);
        const esUrl = this.addLangDir("es", pathname, baseUrl);

        return (
            <>
                <Helmet htmlAttributes={{ lang: lang }}>
                    <title>{title}</title>
                    <meta name="description" content={description} />
                    <meta
                        name="keywords"
                        content="noritex,wholesale,decoracion,mayorista,hogar,navidad,textil,religioso"
                    />
                    {/*Alternate*/}
                    <link rel="alternate" hreflang="es" href={`${esUrl}`} />
                    <link rel="alternate" hreflang="en" href={`${enUrl}`} />
                    <link rel="alternate" hreflang="pt" href={`${ptUrl}`} />
                    <meta http-equiv="content-language" content={lang} />
                    {/*Facebook*/}
                    <meta property="og:site_name" content="Noritex" />
                    <meta
                        property="og:url"
                        content={`https://www.noritex.com${pathname}`}
                    />
                    <meta property="og:title" content={title} />
                    <meta property="og:image" content={image} />
                    <meta property="og:type" content="website" />
                    <meta property="og:description" content={description} />
                    {/*Twitter*/}
                    <meta name="twitter:title" content={title} />
                    <meta name="twitter:description" content={description} />

                    <script type="application/ld+json">{structured}</script>
                </Helmet>

                <Section>
                    <Heading isCentered>
                        <h1>{langJson.title}</h1>
                    </Heading>
                    <ContainerExtended>
                        <Items>
                            {langJson.actions.map((index, i) => (
                                <Item key={`action-why-us-${i}`}>
                                    <i>
                                        <Icons icon={index.icon}></Icons>
                                    </i>
                                    <p>{index.paragraph}</p>
                                </Item>
                            ))}
                        </Items>
                    </ContainerExtended>
                </Section>

                <SectionExtended>
                    <Container>
                        <Heading isCentered>
                            <h3>{langJson.subTitle}</h3>
                        </Heading>

                        <Columns>
                            <Text>
                                <div>
                                    <h4>{langJson.since}</h4>
                                    <h3>{langJson.distribution}</h3>
                                    {langJson.places.map((index, i) => (
                                        <React.Fragment
                                            key={`places-why-us-${i}`}
                                        >
                                            <br />
                                            <h2>{index}</h2>
                                        </React.Fragment>
                                    ))}
                                </div>
                            </Text>
                            <Map>
                                <Icons icon="map"></Icons>
                            </Map>
                        </Columns>
                    </Container>
                </SectionExtended>

                <CustomFields
                    customFields={data.customFields}
                    _t={this._t}
                    recomendations={recomendations}
                    location={this.props.location}
                    childCollection={childCollection}
                    gotoThankYouPage={this.gotoThankYouPage}
                    viewItem={this.viewItem}
                    toggleModalProduct={this.toggleModalProduct}
                />
            </>
        );
    }
}

export default withTracker()(Page);

// export default withSSR(Page);
