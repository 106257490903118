export default {
	hero: {
		title: "Desarrolla tu marca privada sin tener que invertir en infraestructura y personal",
		subtitle:
			"Noritex te ayuda a desarrollar tu marca privada aumentando tu rentabilidad, ahorrando tiempo y recursos. Obtienes un producto perfeccionado aprovechando todo el conocimiento y experiencia de 40 años en el mercado de desarrollo de marcas.",
		chunks: ["cadena de suministro", "marca"],
		btnLabel: "Regístrate y desarrolla nuevos productos",
	},
	title: "Recibe estos beneficios y más",
	data: [
		{
			icon: "truck-cargo-container",
			title: "Despachos directos del oriente o Panama",
			description:
				"Utilice Panamá o China como su almacén para mantener sus tiendas surtidas",
		},
		{
			icon: "refresh-circle",
			title: "Alta rotación de productos",
			description:
				"Compra menos cantidad de producto y rota mercancia más rápido",
		},

		{
			icon: "fire",
			title: "Lanzamiento constante de nuevas colecciones",
			description: "Apégate a las últimas tendencias del mercado",
		},
		{
			icon: "seal",
			title: "Productos con Calidad y respaldo",
			description:
				"Nos aseguramos de la calidad de la mercancia y respaldamos la calidad",
		},
		{
			icon: "cash-register",
			title: "Apoyo en el sellout",
			description:
				"Recibe apoyo en el sellout de la mercancía en el punto de venta",
		},
		{
			icon: "warehouse",
			title: "Distribución desde Panama o China",
			description:
				"Utiliza a Panamá como tu bodega, para mantener tus tiendas abastecidas",
		},

		{
			icon: "briefcase-account",
			title: "Simplifica la compra",
			description:
				"Compra de proveedores pre filtrados con alta calidad y de forma digital y ordenada",
		},
		{
			icon: "face-agent",
			title: "Asesoría en tu compra",
			description: "Recibe asesoría en la compra de tus productos",
		},
		{
			icon: "cash-fast",
			title: "Incrementa tu caja recibiendo crédito",
			description: "Financiamiento sin interés de 60 a 90 días.",
		},
		{
			icon: "bullseye-arrow",
			title: "Posicionamiento de su marca privada",
			description:
				"Cumplimos sus  lineamientos de Marca en costos, diseño y Etiquetado del producto",
		},
		{
			icon: "sofa-single",
			title: "Diferenciación",
			description:
				"A través de una amplia  gama de productos exclusivos y competitivos.",
		},
	],
	slider: [
		{
			title: "Decoración de hogar",
			text: "Desarrollamos tu marca de decoración de hogar con nosotros y obten acceso a miles de productos de diferentes estilos de vida",
			img: "https://www.noritex.com/uplds/2022/6/13/y8FT5mfMs5H7nBAR2CLL-bloque_colecciones_Cantaloupe.jpg",
		},
		{
			title: "Decoración de navidad",
			text: "Desarrollamos tu marca de decoración de navidad con nosotros y obten acceso a miles de productos con la ultima tendencia de la moda",
			img: "https://www.noritex.com/uplds/2022/0/27/7JFvsnHZSuktnyq3yarbol_dec5.jpg",
		},
		{
			title: "Productos de Escolar",
			text: "Desarrollamos tu marca de escolar con nosotros y obten acceso a una cartera de productos amplia con la última tendencia de la escolaridad",
			img: "https://www.noritex.com/uplds/2022/4/24/fy3G5rpSanXyR5eotCopia_de_DESTACADA.jpg",
		},
		{
			title: "Ropa y Accesorios Deportivos",
			text: "Desarrollamos tu marca de ropa de deportiva con nosotros y obten acceso a miles de productos con la ultima tendencia de la moda",
			img: "https://www.noritex.com/uplds/2022/6/22/qf9nASaEApA6WCvdMropa-deportiva-marca-privada.jpeg",
		},
		{
			title: "Imagenes religiosas",
			text: "Desarrollamos tu marca de figuras religiosas y obten los productos de la última de religión",
			img: "https://www.noritex.com/uplds/2021/10/1/JtNGzibbtarqyDvjT_Nacimiento_2.jpg",
		},
	],
};
