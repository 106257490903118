export default {
    startAReturn: "Como iniciar uma reclamação",
    contactABrand: "Contactar uma marca",
    cancelAnOrder: "Cancelar um pedido",
    productImages: "Product Images",
    reportDamagedItems: "Reportar itens danificados",
    howCanWeHelpYou: "Como podemos ajudá-lo?",
    inputPlaceholder: "Escreva aqui para encontrar respostas",
    otherQuestions: "Outras questões",
    frequentQuestions: "Perguntas mais frequentes",
};
