import React from "react";
import loadable from "@loadable/component";
import retry from "../../componentLoader";
import MainTemplate from "./MainTemplate";
import { LangContext } from "../context";
import Blueberry from "../../blueberry/index";
import ModalPortal from "../components/ModalPortal";
import BackToTop from "../components/BackToTop";
import DirectSupport from "../components/DirectSupport/DirectLoadableImport";

const ModalProduct = loadable(() =>
    retry(() =>
        import(/* webpackPrefetch: true */ "../components/ModalProduct2")
    )
);

if (typeof window !== "undefined" && window.__REGISTERED_LOGIN_HANDLERS__) {
    try {
        window.__REGISTERED_LOGIN_HANDLERS__.push(() => {
            console.log("registered all modal product");
            ModalProduct.preload();
        });
    } catch (err) {}
}

export default class ProductsSectionTemplate extends MainTemplate {
    productDefaultState = {
        isProductModalVisible: false,
        product: {},
        lastInCart: "",
        cookie_token: null,
    };

    addToCart = (productId, quantity, productObj, cookie_token) => {
        if (analytics) {
            analytics.track("Product Added", {
                product_id: productObj.slug,
                name:
                    productObj.title ||
                    productObj.title_eng ||
                    productObj.title_pt,
                quantity: quantity,
                ...productObj,
            });
        }
        return new Promise((resolve, reject) => {
            Blueberry.productAddToCart({
                productId,
                quantity,
                cookie_token: cookie_token
                    ? cookie_token
                    : this.state.cookie_token,
            })
                .then(() => {
                    if (window.navigator && window.navigator.vibrate) {
                        window.navigator.vibrate(300);
                    }
                    resolve();
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };

    toggleModalProduct = (product, cookie_token) => {
        this.setState((prevState) => {
            return { isProductModalVisible: true, product, cookie_token };
        });

        setTimeout(() => {
            if (analytics)
                analytics.track("Product Viewed", {
                    product_id: product.slug,
                    ...product,
                });
            Blueberry.itemToItem({
                slug: product.slug,
                limit: 10,
                scenario: "similaritems",
            });

            Blueberry.productAssociations({ slug: product.slug })
                .then((resp) => {
                    this.setState({
                        asociatedProducts: resp,
                    });
                })
                .catch(() => {
                    this.setState({
                        asociatedProducts: null,
                    });
                });
        }, 50);
    };

    closeModalProduct = () => {
        this.setState((prevState) => {
            return { isProductModalVisible: false, cookie_token: null };
        });
    };

    viewItem = (itemId, product, replace) => {
        if (product != null) {
            Blueberry.mapToProduct({ product });
        }
        itemId = itemId.replace("/", "-sz");
        let queryOutput = "?";
        if (this.props.location.search.indexOf("?") > -1) {
            queryOutput = this.props.location.search;
        }

        if (analytics) {
            analytics.track("Product Clicked", {
                product_id: itemId,
            });
        }

        if (this.customTracker) this.customTracker(itemId, product);

        if (queryOutput.indexOf("qry") > -1) {
            this.props.history[replace ? "replace" : "push"](
                `/${this.getBasePath()}/products/${itemId}${queryOutput}`
            );
        } else if (this.props.match.params.collection == null)
            this.props.history[replace ? "replace" : "push"](
                `/${this.getBasePath()}/products/${itemId}`
            );
        else if (this.props.match.params.globalTags)
            this.props.history[replace ? "replace" : "push"](
                `/${this.getBasePath()}/collection/${
                    this.props.match.params.collection
                }/${this.props.match.params.defaultTags}/${
                    this.props.match.params.globalTags
                }/products/${itemId}${queryOutput}`
            );
        else if (this.props.match.params.defaultTags)
            this.props.history[replace ? "replace" : "push"](
                `/${this.getBasePath()}/collection/${
                    this.props.match.params.collection
                }/${
                    this.props.match.params.defaultTags
                }/products/${itemId}${queryOutput}`
            );
        else
            this.props.history[replace ? "replace" : "push"](
                `/${this.getBasePath()}/collection/${
                    this.props.match.params.collection
                }/products/${itemId}${queryOutput}`
            );

        this.closeModalProduct();
    };

    retrieveProducts = (slug, currentPage, limit, tags, order) => {
        this.setWindowTop();
        this.setState({ isRetrivingProducts: true });
        Blueberry.productCollections({
            slug,
            currentPage,
            limit,
            tags,
            order,
        })
            .then((resp) => {
                this.setState({ isRetrivingProducts: false });
                if (analytics)
                    analytics.track("Product List Viewed", {
                        list_id: `${slug}_page${currentPage}_limit${limit}_order${order}`,
                        category: slug,
                        page: currentPage,
                        order,
                        limit,
                        filters: [...tags],
                        products: [...resp.products],
                    });
            })
            .catch((err) => {});
    };

    renderProductModal = (product, itemToItem) => {
        if (
            typeof window === "undefined" ||
            product == null ||
            !this.state.isProductModalVisible
        )
            return null;
        const { jsonSettings } = this.props;
        return (
            <ModalPortal>
                <ModalProduct
                    visible={this.state.isProductModalVisible}
                    close={this.closeModalProduct}
                    refetchOnPriceChange={this.refetchOnPriceChange}
                    toggleModalProduct={this.toggleModalProduct}
                    addToCart={this.addToCart}
                    removeItem={this.removeItem}
                    viewItem={this.viewItem}
                    product={product}
                    jsonSettings={jsonSettings}
                    recomendations={itemToItem}
                    asociatedProducts={this.state.asociatedProducts}
                    defaultQuantity={this.state.defaultQuantity}
                    relatedProducts={this._t("relatedProducts")}
                />
            </ModalPortal>
        );
    };

    render() {
        const { privateTemplate, menusData } = this.props;
        const { user, itemToItem, cart } = this.props.store;
        const { product } = this.state;
        const { lang, brandSlug } = this.props.match.params;

        let footerConfig = this.getFooterConfig(
            lang,
            user && user.id != null,
            menusData
        );

        return (
            <LangContext.Provider
                value={{
                    lang: lang ? lang : "es",
                    brandSlug,
                    userId: user ? user.id : null,
                }}
            >
                <BackToTop lang={lang} />
                <div>
                    {this.renderProductModal(product, itemToItem)}
                    {this.renderHeader()}
                    <section
                        id="main-content"
                        className="main__content site-main"
                    >
                        {!this.isOnline()
                            ? this.renderOffline()
                            : privateTemplate === true &&
                              (user == null || user.id == null)
                            ? this.renderPrivate()
                            : this.renderBody()}
                    </section>
                    {this.renderFooter(footerConfig)}
                </div>
                <DirectSupport user={user} cartGlobal={cart} />
                {this.renderExtraSections()}
            </LangContext.Provider>
        );
    }
}
