import React from "react";
import styled from "styled-components";
import { Container, Section, Heading, Box } from "../../../sdk/Layout";
import { Control, Field, FieldBody } from "../../../sdk/Form";
import { Button } from "../../../sdk/Button";
import { Message } from "../../../sdk/Elements";

const SectionExtended = styled(Section)`
    padding: 80px 10px;
    background-image: url(${"https://www.noritex.com/uplds/2021/6/28/KYSwMvfPHDuE5rQNnhome-decor-login.jpg"});
    background-size: cover;
    background-position: center;
    box-shadow: inset 0 400px 100px ${(props) => props.theme.white};
`;

const FormContainer = styled.div`
    max-width: 600px;
    margin: auto;
    padding: 30px;
    border-radius: 5px;
    background-color: ${(props) => props.theme.grayLight};
`;

function Form({
    setValue,
    validateForm,
    name,
    email,
    contactPhoneNumber,
    website,
    submitForm,
    success,
    isUpdating,
    langJson,
}) {
    return (
        <SectionExtended id="form-for-brands">
            <Heading isCentered>
                <h3>{langJson.form.title}</h3>
            </Heading>
            {success ? (
                <FormContainer>
                    <Message>
                        {langJson.form.message}
                        Gracias!! En este momento tenemos una gran cantidad de
                        aplicaciones de marcas de todo el mundo si hay un encaje
                        nos pondremos en contacto contigo para continuar con el
                        proceso.
                    </Message>
                </FormContainer>
            ) : (
                <FormContainer>
                    <Field>
                        <label>{langJson.form.website}</label>
                        <Control isExpanded>
                            <input
                                className={`${validateForm(website)}`}
                                name="website"
                                value={website.value}
                                onChange={setValue}
                                type="text"
                                autoComplete="off"
                            />
                        </Control>
                    </Field>
                    <Field>
                        <Field>
                            <label>{langJson.form.name}</label>
                            <Control isExpanded>
                                <input
                                    className={`${validateForm(name)}`}
                                    name="name"
                                    value={name.value}
                                    onChange={setValue}
                                    type="text"
                                    autoComplete="off"
                                />
                            </Control>
                        </Field>
                    </Field>
                    <Field>
                        <FieldBody>
                            <Field>
                                <label>{langJson.form.email}</label>
                                <Control isExpanded>
                                    <input
                                        className={`${validateForm(email)}`}
                                        name="email"
                                        value={email.value}
                                        onChange={setValue}
                                        type="text"
                                        autoComplete="off"
                                    />
                                </Control>
                            </Field>
                            <Field>
                                <label>{langJson.form.phone}</label>
                                <Control isExpanded>
                                    <input
                                        className={`${validateForm(
                                            contactPhoneNumber
                                        )}`}
                                        name="contactPhoneNumber"
                                        value={contactPhoneNumber.value}
                                        onChange={setValue}
                                        type="text"
                                        autoComplete="off"
                                    />
                                </Control>
                            </Field>
                        </FieldBody>
                    </Field>
                    <Field>
                        <Button
                            primary
                            isFullWidth
                            onClick={submitForm}
                            loading={isUpdating}
                        >
                            {langJson.form.button}
                        </Button>
                    </Field>
                </FormContainer>
            )}
        </SectionExtended>
    );
}

export default Form;
