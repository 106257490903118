export default {
    seoTitle: "Mayorea - Sell Wholesale Online to all of LATAM",
    seoDescription:
        "Mayorea helps brands discover thousands of customers in the LATAM region.",
    title: "Sell in Mayorea",
    subTitle: "Make your brand grow with more than 2,000 chains in LATAM",
    cta: "Apply to enter mayorea.com with your brand",
    ctaButton: "Apply →",
    actions: [
        {
            title: "Your customer network grows throughout LATAM",
            paragraph: `With a network of more than 2000 clients in LATAM, we specialize in the most complex markets in Latin America. We build a network of the largest stores from Mexico to Argentina.`,
            image: "https://www.noritex.com/uplds/2021/7/9/A4MwayTWrKYDRZywQliving-rooom.jpeg",
            alt: "Your customer network grows throughout LATAM",
        },
        {
            title: "Offer your sellers the best tools to sell",
            paragraph: `We offer your sales reps cutting-edge tools that help automate their workflows. By joining Mayorea, you will have access to our tools to help your team sell more.`,
            image: "https://images.mayorea.com/uplds/2021/9/12/QMdSoaNRoJJ6zYx4Syour_team_mayorea.jpg",
            alt: "Wholesale For Sellers - Tools for sellers",
        },
        {
            title: "Sell online with a self-service and ecommerce system",
            paragraph: `Sell online with a self-service system that allows customers to log in at any time with their personalized price level and tools`,
            image: "https://www.noritex.com/uplds/2021/7/9/CLqWKsyy74SafBmG2store-2.jpg",
            alt: "Custom price - Ecommerce Mayorea",
        },
    ],
    featuresTitle: "Wholesale all in one package",
    features: [
        // {
        //     image: "https://www.noritex.com/uplds/2021/7/9/Q9rKh4AWgoHLaTQhSui-1.jpg",
        //     title: "Order management system",
        //     pagraph: "Manage your orders in one place",
        // },
        {
            image: "https://www.noritex.com/uplds/2021/7/9/LP2tGMHnF7TYLBuXBui-2.jpg",
            title: "Customer Relations Manager",
            pagraph:
                "Get access to the latest CRM created especially for B2B customers",
        },
        // {
        //     image: "https://www.noritex.com/uplds/2021/7/9/93AYfCAP3LBNQTf3Cui-3.jpg",
        //     title: "Showroom ordering system",
        //     pagraph:
        //         "Take orders in the showroom and sincronize it real time with their cart",
        // },
        {
            image: "https://www.noritex.com/uplds/2021/7/9/2DHGpMKT82CKGZxirui-4.jpg",
            title: "Manage customer access",
            pagraph: "Manage customer access and permissions, all in one place",
        },
        {
            image: "https://www.noritex.com/uplds/2021/7/9/HHjqs2tkY6xTsgtppui-5.jpg",
            title: "Email automation",
            pagraph: "Get access to the latest in marketing automation",
        },
    ],
    form: {
        title: "Apply to be in Mayorea.com with your brand",
        website: "Website",
        name: "Name",
        email: "Email",
        phone: "Contact Phone Number",
        button: "Apply",
    },
};
