import React from "react";

const Listener = ({ user, action }) => {
    const [didRequest, setDidRequest] = React.useState(false);
    React.useEffect(() => {
        if (user && user.id && !didRequest) {
            action();
            setDidRequest(true);
        }
    }, [user]);
    return null;
};

export default Listener;
