import React from "react";

function Icons({ icon, className }) {
    if (icon === "phone-alt") {
        return (
            <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="far"
                data-icon="phone-alt"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                className="svg-inline--fa fa-phone-alt fa-w-16"
            >
                <path
                    fill="currentColor"
                    d="M484.25 330l-101.59-43.55a45.86 45.86 0 0 0-53.39 13.1l-32.7 40a311.08 311.08 0 0 1-124.19-124.12l40-32.7a45.91 45.91 0 0 0 13.1-53.42L182 27.79a45.63 45.63 0 0 0-52.31-26.61L35.5 22.89A45.59 45.59 0 0 0 0 67.5C0 313.76 199.68 512.1 444.56 512a45.58 45.58 0 0 0 44.59-35.51l21.7-94.22a45.75 45.75 0 0 0-26.6-52.27zm-41.59 134.09C225.08 463.09 49 287 48 69.3l90.69-20.9 42.09 98.22-68.09 55.71c46.39 99 98.19 150.63 197 197l55.69-68.11 98.19 42.11z"
                />
            </svg>
        );
    }
    if (icon === "map-marker-alt") {
        return (
            <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="far"
                data-icon="map-marker-alt"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 384 512"
                className="svg-inline--fa fa-map-marker-alt fa-w-12"
            >
                <path
                    fill="currentColor"
                    d="M192 0C85.903 0 0 86.014 0 192c0 71.117 23.991 93.341 151.271 297.424 18.785 30.119 62.694 30.083 81.457 0C360.075 285.234 384 263.103 384 192 384 85.903 297.986 0 192 0zm0 464C64.576 259.686 48 246.788 48 192c0-79.529 64.471-144 144-144s144 64.471 144 144c0 54.553-15.166 65.425-144 272zm-80-272c0-44.183 35.817-80 80-80s80 35.817 80 80-35.817 80-80 80-80-35.817-80-80z"
                />
            </svg>
        );
    }
    if (icon === "envelope") {
        return (
            <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="far"
                data-icon="envelope"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                className="svg-inline--fa fa-envelope fa-w-16"
            >
                <path
                    fill="currentColor"
                    d="M464 64H48C21.49 64 0 85.49 0 112v288c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V112c0-26.51-21.49-48-48-48zm0 48v40.805c-22.422 18.259-58.168 46.651-134.587 106.49-16.841 13.247-50.201 45.072-73.413 44.701-23.208.375-56.579-31.459-73.413-44.701C106.18 199.465 70.425 171.067 48 152.805V112h416zM48 400V214.398c22.914 18.251 55.409 43.862 104.938 82.646 21.857 17.205 60.134 55.186 103.062 54.955 42.717.231 80.509-37.199 103.053-54.947 49.528-38.783 82.032-64.401 104.947-82.653V400H48z"
                />
            </svg>
        );
    }
    if (icon === "hand-wash") {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
                <g id="Hand_Wash" data-name="Hand Wash">
                    <path
                        fill="currentColor"
                        className="cls-1"
                        d="M63,59H57.73a1,1,0,0,0-.61.21A13.67,13.67,0,0,1,48.88,62H28.64A1.59,1.59,0,0,1,27,60.66a1.48,1.48,0,0,1,.38-1.17A1.51,1.51,0,0,1,28.5,59a1,1,0,0,0,.25,0A1,1,0,0,0,29,59H39a1,1,0,0,0,0-2H29a1,1,0,0,0-.25,0,1,1,0,0,0-.25,0H25.64A1.59,1.59,0,0,1,24,55.66a1.48,1.48,0,0,1,.38-1.17A1.51,1.51,0,0,1,25.5,54H38a1,1,0,0,0,0-2H22.64A1.59,1.59,0,0,1,21,50.66a1.48,1.48,0,0,1,.38-1.17A1.51,1.51,0,0,1,22.5,49H37a1,1,0,0,0,0-2H25.5a1.51,1.51,0,0,1-1.11-.49A1.48,1.48,0,0,1,24,45.34,1.59,1.59,0,0,1,25.64,44H46.26a1,1,0,0,0,.92-.62A1,1,0,0,0,47,42.29L42.08,37.4a3,3,0,0,1,0-4.24,1,1,0,0,1,1.43,0l6.8,6.55a1,1,0,0,0,.37.23l.92.31a8.17,8.17,0,0,1,5.36,6,1,1,0,0,0,1,.78H63a1,1,0,0,0,0-2H58.7a10.17,10.17,0,0,0-6.46-6.64l-.71-.24-6.62-6.38a3.07,3.07,0,0,0-4.25,0,5,5,0,0,0,0,7.07L43.85,42H25.64A3.61,3.61,0,0,0,22,45.14,3.47,3.47,0,0,0,22.34,47,3.49,3.49,0,0,0,19,50.86,3.61,3.61,0,0,0,22.34,54,3.51,3.51,0,0,0,22,55.86,3.61,3.61,0,0,0,25.34,59,3.51,3.51,0,0,0,25,60.86,3.61,3.61,0,0,0,28.64,64H48.88a15.67,15.67,0,0,0,9.18-3H63a1,1,0,0,0,0-2Z"
                    />
                    <path
                        fill="currentColor"
                        className="cls-1"
                        d="M24.24,21A3,3,0,0,1,27,24v1a1,1,0,0,0,1,1h7a1,1,0,0,0,1-1V23.26A12.27,12.27,0,0,0,24.25,11,8.13,8.13,0,0,0,22,9.08V5a1,1,0,0,0-1-1H19V2h6a1,1,0,0,0,0-2H11a1,1,0,0,0,0,2h6V4H15a1,1,0,0,0-1,1V9.08a7.68,7.68,0,0,0-1.28.92H8A1,1,0,0,0,7,9H1a1,1,0,0,0-1,1V22a1,1,0,0,0,1,1H7a1,1,0,0,0,1-1h4.72A7.9,7.9,0,0,0,18,24,8,8,0,0,0,24.24,21ZM16,6h4V8.26A8.37,8.37,0,0,0,18,8a7.89,7.89,0,0,0-2,.26ZM2,21V11H6V21Zm20.92-1.57a6,6,0,0,1-9.11.86,1,1,0,0,0-.7-.29H8V12h5.11a1,1,0,0,0,.7-.29,6,6,0,0,1,9.11.86,1,1,0,0,0,.82.43A10.27,10.27,0,0,1,34,23.26V24H29a5,5,0,0,0-5-5h-.26A1,1,0,0,0,22.92,19.43Z"
                    />
                    <path
                        fill="currentColor"
                        className="cls-1"
                        d="M30.24,27.35c-.4.47-3.91,4.65-3.91,8a4.67,4.67,0,0,0,9.34,0c0-3.33-3.51-7.51-3.91-8A1,1,0,0,0,30.24,27.35ZM31,38a2.68,2.68,0,0,1-2.67-2.67c0-1.73,1.55-4.21,2.67-5.72,1.12,1.51,2.67,4,2.67,5.72A2.68,2.68,0,0,1,31,38Z"
                    />
                </g>
            </svg>
        );
    }
    if (icon === "social-distance") {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
                <g id="Social_Distance_6ft" data-name="Social Distance 6ft">
                    <path
                        fill="currentColor"
                        className="cls-1"
                        d="M32,29a1,1,0,0,0-1,1v2a1,1,0,0,0,2,0V30A1,1,0,0,0,32,29Z"
                    />
                    <path
                        fill="currentColor"
                        className="cls-1"
                        d="M32,23a1,1,0,0,0-1,1v2a1,1,0,0,0,2,0V24A1,1,0,0,0,32,23Z"
                    />
                    <path
                        fill="currentColor"
                        className="cls-1"
                        d="M32,35a1,1,0,0,0-1,1v2a1,1,0,0,0,2,0V36A1,1,0,0,0,32,35Z"
                    />
                    <path
                        fill="currentColor"
                        className="cls-1"
                        d="M32,41a1,1,0,0,0-1,1v2a1,1,0,0,0,2,0V42A1,1,0,0,0,32,41Z"
                    />
                    <path
                        fill="currentColor"
                        className="cls-1"
                        d="M32,47a1,1,0,0,0-1,1v2a1,1,0,0,0,2,0V48A1,1,0,0,0,32,47Z"
                    />
                    <path
                        fill="currentColor"
                        className="cls-1"
                        d="M32,53a1,1,0,0,0-1,1v2a1,1,0,0,0,2,0V54A1,1,0,0,0,32,53Z"
                    />
                    <path
                        fill="currentColor"
                        className="cls-1"
                        d="M32,59a1,1,0,0,0-1,1v2a1,1,0,0,0,2,0V60A1,1,0,0,0,32,59Z"
                    />
                    <path
                        fill="currentColor"
                        className="cls-1"
                        d="M43,17V14a3,3,0,0,0-3-3,3,3,0,0,0-2,.78A3,3,0,0,0,36,11a3,3,0,0,0-3,3v3a1,1,0,0,0,2,0V14a1,1,0,0,1,2,0v3a1,1,0,0,0,2,0V14a1,1,0,0,1,2,0v3a1,1,0,0,0,2,0Z"
                    />
                    <path
                        fill="currentColor"
                        className="cls-1"
                        d="M30,16H26.27a2,2,0,0,1,1-.81l1.64-.66A3.39,3.39,0,0,0,27.61,8H27a3,3,0,0,0-3,3,1,1,0,0,0,2,0,1,1,0,0,1,1-1h.61a1.38,1.38,0,0,1,.52,2.67l-1.64.66A3.93,3.93,0,0,0,24,17a1,1,0,0,0,1,1h5a1,1,0,0,0,0-2Z"
                    />
                    <path
                        fill="currentColor"
                        className="cls-1"
                        d="M10.08,4.38a1,1,0,0,0,.21.33l3,3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42L13.41,5H50.59l-1.3,1.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0l3-3a1,1,0,0,0,.21-.33,1,1,0,0,0,0-.76,1,1,0,0,0-.21-.33l-3-3a1,1,0,0,0-1.42,1.42L50.59,3H13.41l1.3-1.29A1,1,0,1,0,13.29.29l-3,3a1,1,0,0,0-.21.33A1,1,0,0,0,10.08,4.38Z"
                    />
                    <path
                        fill="currentColor"
                        className="cls-1"
                        d="M15.44,25A5.93,5.93,0,0,0,17,21V18A6,6,0,0,0,5,18v3a5.93,5.93,0,0,0,1.56,4A7,7,0,0,0,0,32V44a3,3,0,0,0,3,3,2.81,2.81,0,0,0,1-.18V59.4A4.21,4.21,0,0,0,2,63a1,1,0,0,0,1,1h7a1,1,0,0,0,1-1v0a1,1,0,0,0,1,1h7a1,1,0,0,0,1-1,4.17,4.17,0,0,0-2-3.6V46.82A2.81,2.81,0,0,0,19,47a3,3,0,0,0,3-3V32A7,7,0,0,0,15.44,25ZM7,18a4,4,0,0,1,8,0v3a4,4,0,0,1-8,0ZM20,44a1,1,0,0,1-1,1,1,1,0,0,1-.38-.08l-.08,0A1,1,0,0,1,18,44V31a1,1,0,0,0-2,0V60a1,1,0,0,0,.54.89l.23.12a2.23,2.23,0,0,1,1,1H13L12,44a1,1,0,0,0-2,0L9.05,62H4.23a2.25,2.25,0,0,1,1-1l.21-.11A1,1,0,0,0,6,60V31a1,1,0,0,0-2,0V44a1,1,0,0,1-.54.88l-.08,0A1,1,0,0,1,3,45a1,1,0,0,1-1-1V32a5,5,0,0,1,5-5h8a5,5,0,0,1,5,5Z"
                    />
                    <path
                        fill="currentColor"
                        className="cls-1"
                        d="M57.44,25A5.93,5.93,0,0,0,59,21V18a6,6,0,0,0-12,0v3a5.93,5.93,0,0,0,1.56,4A7,7,0,0,0,42,32V44a3,3,0,0,0,3,3,2.81,2.81,0,0,0,1-.18V59.4A4.21,4.21,0,0,0,44,63a1,1,0,0,0,1,1h7a1,1,0,0,0,1-1v0a1,1,0,0,0,1,1h7a1,1,0,0,0,1-1,4.17,4.17,0,0,0-2-3.6V46.82A2.81,2.81,0,0,0,61,47a3,3,0,0,0,3-3V32A7,7,0,0,0,57.44,25ZM49,18a4,4,0,0,1,8,0v3a4,4,0,0,1-8,0ZM62,44a1,1,0,0,1-1,1,1,1,0,0,1-.38-.08l-.08,0A1,1,0,0,1,60,44V31a1,1,0,0,0-2,0V60a1,1,0,0,0,.54.89l.23.12a2.23,2.23,0,0,1,1,1H55L54,44a1,1,0,0,0-2,0l-1,18H46.23a2.25,2.25,0,0,1,1-1l.21-.11A1,1,0,0,0,48,60V31a1,1,0,0,0-2,0V44a1,1,0,0,1-.54.88l-.08,0A1,1,0,0,1,45,45a1,1,0,0,1-1-1V32a5,5,0,0,1,5-5h8a5,5,0,0,1,5,5Z"
                    />
                </g>
            </svg>
        );
    }
    if (icon === "no-crowd") {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
                <g id="No_Crowd" data-name="No Crowd">
                    <path
                        fill="currentColor"
                        className="cls-1"
                        d="M32,26A13,13,0,1,0,19,13,13,13,0,0,0,32,26ZM32,2A11,11,0,1,1,21,13,11,11,0,0,1,32,2Z"
                    />
                    <path
                        fill="currentColor"
                        className="cls-1"
                        d="M25,18.05h.13a1,1,0,0,0,.71-.29l11-10.95a1,1,0,0,0-.2-1.57A9,9,0,0,0,24.25,17.57,1,1,0,0,0,25,18.05ZM32,6a7,7,0,0,1,2.35.41l-8.94,8.94A7,7,0,0,1,32,6Z"
                    />
                    <path
                        fill="currentColor"
                        className="cls-1"
                        d="M27,20a1,1,0,0,0,.48.73A9,9,0,0,0,39.75,8.43a1,1,0,0,0-1.57-.2l-10.95,11A1,1,0,0,0,27,20Zm11.64-9.37a7,7,0,0,1-8.94,8.94Z"
                    />
                    <path
                        fill="currentColor"
                        className="cls-1"
                        d="M63,62h-.19A2.87,2.87,0,0,0,63,61V49a7,7,0,0,0-6.56-7A6,6,0,0,0,58,38V35a6,6,0,0,0-12,0v3a6,6,0,0,0,1.56,4A7,7,0,0,0,42,45.4,7,7,0,0,0,36.44,42,6,6,0,0,0,38,38V35a6,6,0,1,0-12,0v3a6,6,0,0,0,1.56,4A7,7,0,0,0,22,45.4,7,7,0,0,0,16.44,42,6,6,0,0,0,18,38V35A6,6,0,0,0,6,35v3a6,6,0,0,0,1.56,4A7,7,0,0,0,1,49V61a2.87,2.87,0,0,0,.19,1H1a1,1,0,0,0,0,2H63a1,1,0,0,0,0-2ZM48,35a4,4,0,1,1,8,0v3a4,4,0,1,1-8,0Zm0,9h8a5,5,0,0,1,5,5V61a1,1,0,0,1-1,1,1,1,0,0,1-.38-.08l-.08,0A1,1,0,0,1,59,61V48a1,1,0,0,0-2,0V62H47V48a1,1,0,0,0-2,0V61a1,1,0,0,1-.54.88l-.08,0A1,1,0,0,1,44,62a1,1,0,0,1-1-1V49A5,5,0,0,1,48,44ZM28,35a4,4,0,1,1,8,0v3a4,4,0,1,1-8,0Zm0,9h8a5,5,0,0,1,5,5V61a1,1,0,0,1-1,1,1,1,0,0,1-.38-.08l-.08,0A1,1,0,0,1,39,61V48a1,1,0,0,0-2,0V62H27V48a1,1,0,0,0-2,0V61a1,1,0,0,1-.54.88l-.08,0A1,1,0,0,1,24,62a1,1,0,0,1-1-1V49A5,5,0,0,1,28,44ZM8,35a4,4,0,1,1,8,0v3a4,4,0,0,1-8,0ZM7,61V48a1,1,0,0,0-2,0V61a1,1,0,0,1-.54.88l-.08,0A1,1,0,0,1,4,62a1,1,0,0,1-1-1V49a5,5,0,0,1,5-5h8a5,5,0,0,1,5,5V61a1,1,0,0,1-1,1,1,1,0,0,1-.38-.08l-.08,0A1,1,0,0,1,19,61V48a1,1,0,0,0-2,0V62H7Z"
                    />
                </g>
            </svg>
        );
    }
    if (icon === "do-not-touch") {
        return (
            <svg
                id="Capa_1"
                enableBackground="new 0 0 512.001 512.001"
                height={512}
                viewBox="0 0 512.001 512.001"
                width={512}
                xmlns="http://www.w3.org/2000/svg"
            >
                <g>
                    <path d="m236.021 169.006c3.098 2.75 7.837 2.468 10.588-.629 2.75-3.098 2.469-7.838-.629-10.588-17.464-15.506-27.479-37.776-27.479-61.102 0-45.043 36.645-81.688 81.688-81.688s81.688 36.645 81.688 81.688c0 23.325-10.016 45.597-27.479 61.102-3.098 2.75-3.379 7.49-.629 10.588 1.482 1.669 3.542 2.521 5.611 2.521 1.771 0 3.548-.623 4.977-1.892 20.667-18.35 32.521-44.709 32.521-72.318-.002-53.314-43.376-96.688-96.69-96.688s-96.688 43.374-96.688 96.688c.001 27.608 11.854 53.968 32.521 72.318z" />
                    <path d="m246.304 219.914-31.82-31.82c-2.929-2.929-7.677-2.929-10.607 0l-79.627 79.627-79.627-79.627c-2.929-2.929-7.677-2.929-10.607 0l-31.82 31.821c-2.929 2.929-2.929 7.677 0 10.607l79.627 79.627-79.626 79.626c-2.929 2.929-2.929 7.677 0 10.607l31.82 31.82c2.929 2.929 7.677 2.929 10.607 0l79.627-79.627 79.627 79.627c2.929 2.929 7.677 2.929 10.607 0l31.821-31.821c2.929-2.929 2.929-7.677 0-10.607l-79.627-79.627 79.627-79.627c2.927-2.928 2.927-7.676-.002-10.606zm-95.537 84.931c-2.929 2.929-2.929 7.677 0 10.607l79.627 79.627-21.214 21.214-79.627-79.627c-2.929-2.929-7.677-2.929-10.607 0l-79.626 79.626-21.213-21.213 79.627-79.627c2.929-2.929 2.929-7.677 0-10.607l-79.627-79.627 21.214-21.214 79.627 79.627c2.929 2.929 7.677 2.929 10.607 0l79.627-79.627 21.213 21.213z" />
                    <path d="m475.313 218.5c-9.158 0-17.536 3.381-23.971 8.95-5.189-13.969-18.651-23.95-34.404-23.95-9.158 0-17.536 3.381-23.971 8.95-5.189-13.969-18.651-23.95-34.404-23.95-8.109 0-15.608 2.649-21.688 7.121v-98.933c0-20.229-16.458-36.688-36.688-36.688s-36.688 16.458-36.688 36.688v36.372c-9.463-9.564-15-22.602-15-36.372 0-28.501 23.187-51.688 51.688-51.688s51.688 23.187 51.688 51.688c0 4.143 3.357 7.5 7.5 7.5s7.5-3.357 7.5-7.5c.001-36.772-29.915-66.688-66.687-66.688s-66.688 29.916-66.688 66.688c0 22.446 11.389 43.389 30 55.676v205.386c0 29.798 10.632 58.431 30 80.959v65.791c0 4.143 3.357 7.5 7.5 7.5h173.5c4.143 0 7.5-3.357 7.5-7.5v-65.791c19.368-22.527 30-51.16 30-80.959v-102.562c.001-20.23-16.457-36.688-36.687-36.688zm21.688 139.25c0 27.058-9.97 53.021-28.071 73.108-1.241 1.377-1.929 3.166-1.929 5.021v61.121h-158.5v-61.121c0-1.854-.688-3.644-1.929-5.021-18.102-20.088-28.071-46.052-28.071-73.108v-261.062c0-11.959 9.729-21.688 21.688-21.688s21.688 9.729 21.688 21.688v159.312c0 4.143 3.357 7.5 7.5 7.5s7.5-3.357 7.5-7.5v-30.813c0-11.959 9.729-21.688 21.688-21.688s21.688 9.729 21.688 21.688v30.813c0 4.143 3.357 7.5 7.5 7.5s7.5-3.357 7.5-7.5v-15.813c0-11.959 9.729-21.688 21.688-21.688s21.688 9.729 21.688 21.688v15.813c.451 3.76 3.632 6.688 7.5 6.688s7.049-2.927 7.454-6.688c.046 0 .046-.813.046-.813 0-11.959 9.729-21.688 21.688-21.688s21.688 9.729 21.688 21.688v102.563z" />
                </g>
            </svg>
        );
    }
    if (icon === "mask") {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
                <g id="Masker_N95" data-name="Masker N95">
                    <path
                        fill="currentColor"
                        className="cls-1"
                        d="M24.11,22a8.76,8.76,0,0,0,3.43-1.15,10.36,10.36,0,0,0,2.14-2C30.47,17.88,31.22,17,32,17h0c.76,0,1.51.86,2.3,1.78a10.36,10.36,0,0,0,2.14,2A8.76,8.76,0,0,0,39.89,22H40a1,1,0,0,0,.11-2,7.2,7.2,0,0,1-2.57-.85,9.23,9.23,0,0,1-1.71-1.67C34.83,16.33,33.69,15,32,15H32c-1.67,0-2.81,1.31-3.81,2.47a9.23,9.23,0,0,1-1.71,1.67,7.2,7.2,0,0,1-2.57.85,1,1,0,1,0,.22,2Z"
                    />
                    <path
                        fill="currentColor"
                        className="cls-1"
                        d="M57,11c-3.56,0-7.19,5.37-9.48,9.56C42.24,15.36,34.67,11,32,11s-10.24,4.36-15.52,9.56C14.19,16.37,10.56,11,7,11c-4.71,0-7,6.54-7,20,0,9.23,4.11,19,8,19,2.13,0,5.45-3.2,7.89-5.92C19.39,49,24.76,53,32,53s12.61-4,16.11-8.92C50.55,46.8,53.87,50,56,50c3.89,0,8-9.77,8-19C64,17.54,61.71,11,57,11ZM8,48c-2,0-6-7.87-6-17C2,19.39,3.78,13,7,13c2.4,0,5.65,4.64,8,9.08-2.39,2.66-4,5.45-4,7.92a25.6,25.6,0,0,0,3.76,12.33C12,45.4,9.18,48,8,48Zm24,3c-6.91,0-11.71-3.87-14.76-8.47.71-.85,1.27-1.56,1.55-1.92a1,1,0,0,0-1.58-1.22c-.34.44-.7.88-1.07,1.33A24.38,24.38,0,0,1,13,30c0-1.8,1.2-3.91,3-6,.36.73.68,1.44,1,2.09a1,1,0,1,0,1.84-.79c-.16-.36-.64-1.45-1.35-2.87C22.34,17.49,29.78,13,32,13s9.66,4.49,14.55,9.41c-.71,1.42-1.19,2.51-1.35,2.87a1,1,0,1,0,1.84.79c.28-.65.6-1.36,1-2.09,1.8,2.11,3,4.22,3,6a24.38,24.38,0,0,1-3.14,10.72c-.37-.45-.73-.89-1.07-1.33a1,1,0,1,0-1.58,1.22c.28.36.84,1.07,1.55,1.92C43.71,47.13,38.91,51,32,51Zm24-3c-1.18,0-4-2.6-6.76-5.67A25.6,25.6,0,0,0,53,30c0-2.47-1.64-5.26-4-7.92C51.35,17.64,54.6,13,57,13c3.22,0,5,6.39,5,18C62,40.13,58,48,56,48Z"
                    />
                    <path
                        fill="currentColor"
                        className="cls-1"
                        d="M46.92,33.34a1,1,0,0,0-1.3-.56A38.46,38.46,0,0,1,32,35a38.46,38.46,0,0,1-13.62-2.22,1,1,0,1,0-.74,1.86A40.5,40.5,0,0,0,32,37a40.5,40.5,0,0,0,14.36-2.36A1,1,0,0,0,46.92,33.34Z"
                    />
                    <path
                        fill="currentColor"
                        className="cls-1"
                        d="M38.85,40.51a48.37,48.37,0,0,1-13.7,0,1,1,0,1,0-.3,2A49.32,49.32,0,0,0,32,43a49.32,49.32,0,0,0,7.15-.51,1,1,0,1,0-.3-2Z"
                    />
                    <path
                        fill="currentColor"
                        className="cls-1"
                        d="M38.85,28.51a48.37,48.37,0,0,1-13.7,0,1,1,0,0,0-.3,2A49.32,49.32,0,0,0,32,31a49.32,49.32,0,0,0,7.15-.51,1,1,0,0,0-.3-2Z"
                    />
                </g>
            </svg>
        );
    }

    if (icon === "car") {
        return (
            <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fal"
                data-icon="truck-pickup"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 640 512"
            >
                <path
                    fill="currentColor"
                    d="M632 352h-24V224c0-26.51-21.49-48-48-48h-44.8L419.21 56.02A63.99 63.99 0 0 0 369.24 32H256c-17.67 0-32 14.33-32 32v112H80c-26.51 0-48 21.49-48 48v128H8c-4.42 0-8 3.58-8 8v16c0 4.42 3.58 8 8 8h72c0 53.02 42.98 96 96 96s96-42.98 96-96h96c0 53.02 42.98 96 96 96s96-42.98 96-96h72c4.42 0 8-3.58 8-8v-16c0-4.42-3.58-8-8-8zM256 64h113.24c9.78 0 18.88 4.38 24.99 12.01L474.22 176H256V64zm-80 384c-35.35 0-64-28.65-64-64s28.65-64 64-64 64 28.65 64 64-28.65 64-64 64zm288 0c-35.35 0-64-28.65-64-64s28.65-64 64-64 64 28.65 64 64-28.65 64-64 64zm112-96h-21.88c-13.22-37.2-48.38-64-90.12-64s-76.9 26.8-90.12 64H266.12c-13.22-37.2-48.38-64-90.12-64s-76.9 26.8-90.12 64H64V224c0-8.82 7.18-16 16-16h480c8.82 0 16 7.18 16 16v128z"
                ></path>
            </svg>
        );
    }

    return null;
}

export default Icons;
