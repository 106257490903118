export default {
    title: "Showrooms",
    subTitle: "Visite nosso showroom",
    paragraph:
        "Nossos showrooms no Panamá já estão abertos. A segurança das nossas equipas e clientes continua a ser a nossa prioridade número um, por isso introduzimos algumas novas medidas para recebê-lo com segurança e conforto. Uma vez lá dentro, pedimos que você siga o sistema de mão única; haverá sinalização clara para guiá-lo. Os membros da equipe serão colocados em pontos de informação para ajudá-lo a uma distância segura. Sempre mantenha o distanciamento social quando estiver dentro do showroom e não o visite se estiver doente ou com tosse ou resfriado. Você encontrará essas informações na página do showroom local. Você não pode ir aos nossos showrooms? Não há problema. Agora oferecemos namoro online grátis.",
	paragraphs: [
		"Visitar nosso showroom oferece a você uma oportunidade única de explorar uma seleção exclusiva de produtos da nova coleção e tendências, garantindo a exclusividade em suas lojas.",
		"Temos mais de 4.000 referências disponíveis em todos os estilos e categorias, permitindo que você adquira as quantidades necessárias de acordo com suas necessidades.",
		"Ao comprar nossas coleções, você poderá criar ambientes únicos e diferenciados em suas lojas, garantindo experiências de compra excepcionais para seus consumidores. Oferecemos remessas consolidadas do Panamá e remessas diretas do Oriente, o que permitirá que você economize significativamente no frete.",
		"Se não tiver disponibilidade para viajar, contamos com um showroom digital em tempo real, que você pode visitar virtualmente e receber atendimento personalizado por videochamada.",
		"Convidamos você a nos visitar e descobrir como nossas coleções podem transformar suas lojas.",
	],
    titleActions: "Seguridad Primero",
	titleVirtualShowroom: "Showroom Virtual",
    paragraphVirtualShowroom: "Explore nosso showroom virtual através do seguinte mapa 3D",
    actions: [
        {
            title: "Estación de limpieza",
            icon: "hand-wash",
            paragraph:
                "Por favor, lávese las manos al entrar y salir de la sala de exposición.",
        },
        {
            title: "Distanciamiento social",
            icon: "social-distance",
            paragraph:
                "Respete las normas de distanciamiento social manteniéndose a 2m de distancia.",
        },
        {
            title: "Tú + 1",
            icon: "no-crowd",
            paragraph:
                "Para ayudarnos con esto, visite nuestra sala de exposición solo con otra persona de su hogar.",
        },
        {
            title: "Vea sin tocar",
            icon: "do-not-touch",
            paragraph: "Por favor evite tocar los productos durante su visita.",
        },
        {
            title: "Máscaras gratis",
            icon: "mask",
            paragraph:
                "Hemos proporcionado máscaras faciales para el equipo, los miembros y los clientes.",
        },
    ],
    direction: "Dirección",
    phoneNumber: "Teléfono",
    email: "Correo electrónico",
};
