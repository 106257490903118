import React from "react";
import { Helmet } from "react-helmet";
import ProductsSectionTemplate from "../../templates/ProductsSectionTemplate";
import withTracker from "../../../blueberry/connectPromise";
import { Container } from "../../sdk/Layout";
import Hero from "./components/Hero";
import validate from "../../utils/validator";
import Form from "./components/Form";
import Featured from "./components/Featured";
import Carousel from "./components/Carousel";
import Features from "./components/Features";
import Blueberry from "../../../blueberry";
import Captcha, { CaptchReady } from "../../components/Captcha";

import lang_es from "./lang/lang_es";
import lang_en from "./lang/lang_en";
import lang_pt from "./lang/lang_pt";
import SEOFieldsHelmet from "../../utils/SEOFieldsHelmet";

function getLang(lang) {
    switch (lang) {
        case "es":
            return lang_es;
        case "en":
            return lang_en;
        case "pt":
            return lang_pt;
    }
}

class Page extends ProductsSectionTemplate {
    constructor(props) {
        super(props);
        let formControls = {
            name: {
                value: "",
                valid: false,
                touched: false,
                validationRules: {
                    minLength: 3,
                    isRequired: true,
                },
            },

            website: {
                value: "",
                valid: false,
                touched: false,
                validationRules: {
                    minLength: 3,
                    isRequired: true,
                },
            },
            email: {
                value: "",
                valid: false,
                touched: false,
                validationRules: {
                    minLength: 3,
                    isRequired: true,
                    isEmail: true,
                },
            },
            contactPhoneNumber: {
                value: "",
                valid: false,
                touched: false,
                validationRules: {
                    minLength: 3,
                    isRequired: true,
                },
            },
        };
        this.state = {
            formControls,
        };
    }

    validateForm(formElement) {
        if (formElement.valid && formElement.touched) return "is-valid";
        if (!formElement.valid && formElement.touched) return "is-invalid";
        return "";
    }

    setValue = (event) => {
        const name = event.target.name;
        const value = event.target.value;

        const updatedControls = {
            ...this.state.formControls,
        };
        const updatedFormElement = {
            ...updatedControls[name],
        };

        updatedFormElement.value = value;
        updatedFormElement.valid = validate(
            value,
            updatedFormElement.validationRules
        );
        updatedFormElement.touched = true;

        updatedControls[name] = updatedFormElement;

        this.setState({
            formControls: updatedControls,
        });
    };

    getFormKey = () => {
        let form_key = "EEh25ai8rgWXsGgTC2";

        switch (this.props.match.params.lang) {
            case "en":
                form_key = "EEh25ai8rgWXsGgTC2";
                break;
            case "pt":
                form_key = "EEh25ai8rgWXsGgTC2";
                break;
            default:
                form_key = "EEh25ai8rgWXsGgTC2";
                break;
        }

        return form_key;
    };

    submitForm = (e) => {
        e.preventDefault();
        const { formControls } = this.state;
        let hasErrors = false;
        let formData = {};
        const updatedFormElement = { ...formControls };

        for (let formElementId in formControls) {
            if (!formControls[formElementId].valid) {
                hasErrors = true;
                updatedFormElement[formElementId].touched = true;
            }
            formData[formElementId] = formControls[formElementId].value;
            // formData += `${formElementId}=${formControls[formElementId].value}&`;
        }

        if (hasErrors) {
            this.setState({ formControls: updatedFormElement });
            return;
        }

        const form_key = this.getFormKey();

        this.setState({ isUpdating: true });

        const recordData = () => {
            Blueberry.formInsert({ fields: formData, form_key })
                .then((resp) => {
                    this.setState({ isUpdating: false, success: true });
                })
                .catch((err) => {
                    console.log(err);
                    this.setState({ isUpdating: false, success: true });
                });
        };
        CaptchReady()
            .then((token) => {
                formData.captch_token = token;
                recordData();
            })
            .catch((err) => {
                console.log(err);
                this.setState({ isUpdating: false, hasError: true });
                console.log(err);
            });
    };

    renderBody() {
        const { lang, slug } = this.props.match.params;
        const { name, website, email, contactPhoneNumber } =
            this.state.formControls;
        const { isUpdating, success } = this.state;
        const { pathname } = this.props.location;

        const langJson = getLang(lang);
        return (
            <>
                <SEOFieldsHelmet
                    defaultTitle={langJson.seoTitle}
                    defaultDescription={langJson.seoDescription}
                />
                <Hero
                    langJson={langJson}
                    email={email}
                    setValue={this.setValue}
                />
                <Featured langJson={langJson} />
                {/* <Carousel langJson={langJson} /> */}
                <Features langJson={langJson} />
                <Form
                    name={name}
                    langJson={langJson}
                    email={email}
                    setValue={this.setValue}
                    submitForm={this.submitForm}
                    validateForm={this.validateForm}
                    website={website}
                    contactPhoneNumber={contactPhoneNumber}
                    isUpdating={isUpdating}
                    success={success}
                />
                <Captcha />
            </>
        );
    }
}

export default withTracker()(Page);

// export default withSSR(Page);
