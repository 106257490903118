import styled from "styled-components";
import { Columns } from "../../sdk/Layout";

const primary = '#A5836D';
const secondary = '#EFDCC9';
const complementary = '#535353';

export const Spacer = styled.div`
  height: ${(props) => props.height ?? '10'}px;
`;

export const Layout = styled.div`

    .wrap {
        margin: auto;
        width: 98%;
        max-width: 1400px;
        position: relative;
        z-index: 2;
        &.wrap--800 {
            max-width: 800px;
        }
    }
    .button {
        &.primary {
            background-color: ${primary};
            border-color: ${primary};
            color: white;
        }
        &.big {
            font-size: 18px;
            height: 60px;
            line-height: 56px;
        }
        &.rounded {
            border-radius: 30px;
        }
        &.outline {
            border: solid 1px white;
            &.white {
                color: white;
            }
        }
    }

    .bg-img {
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }
    .bg-gray {
        background-color: #f3f5f6;
    }

    .shape {
        position: absolute;
        left: 0;
        width: 100%;
        z-index: 1;
        &.bottom {
            bottom: -1px;
        }
        &.top {
            top: -1px;
        }
        svg {
            width: 100%;
            display: block;
            color: ${secondary};
        }
    }

    .heading {
        text-align: center;
        margin-bottom: 30px;
        color: ${complementary};
        h2 {
            font-size: 35px;
        }
        h3 {
            font-size: 28px;
            margin-top: 20px;
            font-weight: normal;
        }
        @media (max-width: 580px) {
            h2 {
            font-size: 25px;
        }
            h3 {
                font-size: 18px;
                margin-top: 10px;
            }
        }
    }

    .section {
        padding: 100px 0;
    }

    .header {
        background-color: #fff;
        height: 70px;
        max-height: 70px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px;
        .logo {
            width: 180px;
        }
        .nav {
            ul {
                li {
                    display: inline-block;
                    &:not(:last-child) {
                        margin-right: 30px;
                    }
                    a {
                        cursor: pointer;
                        &:not(.button) {
                            color: ${complementary};
                            font-size: 15px;
                            @media (max-width: 1200px) {
                                font-size: 12px;   
                            }
                        }
                    }
                }
            }   
        }
        @media (max-width: 786px) {
            flex-direction: column;
            min-height: 100px;
            justify-content: center;
            .logo {
                margin-bottom: 10px;
                width: 100px;
            }
            .nav {
                ul {
                    li {
                        &:not(:last-child) {
                            margin-right: 10px;
                        } 
                        a {
                            font-size: 10px !important;
                        }
                    }
                }
            }
        }
    }
    .hero {
        min-height: 700px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: inset 0 -2000px rgba(0,0,0,.5);
        color: white;
        text-align: left;
        position: relative;
        padding-bottom: 100px;
        padding: 0px 50px 100px 50px;
        z-index: 3;
        h2 {
            font-weight: normal;
            letter-spacing: 3px;
            font-size: 18px;
            margin-bottom: 10px;
        }
        h1 {
            font-size: 40px;
        }
        h3 {
            margin-top: 20px;
            font-size: 30px;
            font-weight: normal;
        }
        .video-cover {
            position: relative;
            cursor: pointer;
            background-color: #000;
            &:before {
                content: '';
                display: block;
                padding-top: 60%;
            }
            img {
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                object-fit: cover;
                opacity: .9;
            }
            .play-btn {
                position: absolute;
                z-index: 2;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                svg {
                    width: 60px !important;
                    height: auto !important;
                }
            }
            &:hover {
                img {
                    opacity: 1;
                }
            }
        }
        .video-modal {
            > div {
                max-width: 800px;
            }
            .video {
                position: relative;
                &:before {
                    content: '';
                    display: block;
                    padding-top: 56%;
                }
                iframe {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    left: 0;
                    top: 0;
                }
            }
        }
        ${Columns} {
            align-items: center;
        }
        @media (max-width: 768px) {
            text-align: center;
            padding: 30px 20px 80px 20px;
            min-height: auto;
            .video-cover {
                max-width: 400px;
                margin: auto;
                margin-top: 20px;
            }
            h1 {
                font-size: 30px;
                br {
                    display: none;
                }
            }
            h3 {
                font-size: 20px;
            }
            .spacer {
                height: 20px;
            }
        }

        &:not([data-lang="es"]) {
            text-align: center;
            .col-video {
                display: none;
            }
        }
    }

    .stats {
        background-color: ${secondary};
        padding: 10px 0;
        position: relative;
        .wrap {
            position: relative;
            z-index: 2;
        }
        ul {
            display: flex;
            justify-content: space-around;
            .item {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                color: white;
                background-color: ${complementary};
                width: 200px;
                height: 200px;
                border-radius: 100%;
                h3 {
                    font-size: 35px;
                }
                p {
                    font-size: 22px;
                }
            }
        }
        .shape {
            svg {
                color: #f3f5f6;
            }
        }
        @media (max-width: 580px) {
            ul {
                .item {
                    width: 110px;
                    height: 110px;
                    h3 {
                        font-size: 30px;
                    }
                    p {
                        font-size: 14px;
                    }
                }
            }
        }
    }


    .infocards {
        display: flex;
        justify-content: space-between;
        gap: 20px;
        .infocard {
            background-color: #fff;
            border-radius: 20px;
            padding: 30px;
            padding-right: 120px;
            position: relative; 
            overflow: hidden;
            h3 {
                font-size: 25px;
                position: relative;
                z-index: 2;
                margin-bottom: 20px;
                color: ${primary};
            }
            p {
                position: relative;
                z-index: 2;
                color: gray;
                font-size: 18px;
                line-height: 28px;
            }
            img {
                position: absolute;
                bottom: 0;
                right: 0;
                width: 110px;
                height: 220px;
                max-height: 100%;
                object-fit: contain;
                z-index: 1;
            }
        }

        @media (max-width: 980px) {
            flex-direction: column;
        }

        @media (max-width: 480px) {
            gap: 10px;
            .infocard {
                padding: 20px;
                padding-right: 90px;
                h3 {
                    margin-bottom: 10px;
                }
                img {
                    width: 80px;
                }
                p {
                    font-size: 14px;
                    line-height: 20px;
                }
            }
        }
    }


    .form-section {
        box-shadow: inset 0 -3000px rgba(0,0,0,.5);
        color: white;
        h2 {
            color: white;
            border: none;
        }
        .left-column {
            display: none;
        }
        .right-column {
            max-width: 900px;
            margin: auto;
        }
        .submit-btn {
            height: 40px;
            padding: 0px 10px;
            background-color: ${primary};
        }
    }

    .questions {
        ol {
            li {
                list-style: numbers;
                &:not(:last-child) {
                    margin-bottom: 5px;
                }
            }
        }
        p {
            line-height: 24px;
            font-size: 16px;
        }
        details {
            &:not(:last-child) {
                margin-bottom: 15px;
                padding-bottom: 15px;
                border-bottom: solid 1px #e3e3e3;
            }
            &[open] {
                summary {
                    color: ${primary} !important;
                }
            }
            summary {
                font-weight: bold;
                font-size: 18px;
                color: gray;
                cursor: pointer;
                &:hover {
                    color: ${complementary};
                }
            }
            > div {
                padding: 20px;
            }
        }
    }

    .gallery {
        display: flex;
        flex-wrap: wrap;
        .item {
            position: relative;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            display: block;
            width: 25%;
            box-shadow: inset 0 -1000px rgba(255,255,255,0);
            transition: all 200ms linear;
            color: ${complementary};
            &:before {
                content: '';
                display: block;
                padding-top: 100%;
            }
            h4 {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 0;
                width: 100%;
                padding: 0 10px;
                text-align: center;
                font-size: 30px;
                transition: all 200ms linear;
                opacity: 0;
            }
            &:hover {
                box-shadow: inset 0 -1000px rgba(255,255,255,.7);
                h4 {
                    opacity: 1;
                }
            }
        }

        @media (max-width: 580px) {
            .item {
                width: 50%;
                h4 {
                    font-size: 20px;
                }
            }
        }
    }

    .footer {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px 0;
        background-color: ${secondary};
        p {
            margin-right: 5px;   
        }
        .brand {
            width: 120px;
            color: #112c55;
        }

        @media (max-width: 520px) {
            flex-direction: column;
        }
    }
`;

