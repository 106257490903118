export default {
    menu: {
        item1: 'How to participate',
        item2: 'FAQ',
        item3: 'Get information',
    },
    hero: {
        pre: "STOCK YOUR BUSINESS WITH THE LATEST TRENDS",
        title: "Concepts Life <br /> Home Décor Show",
        sub: "From July 18 to August 19",
        btn: "Get information",
    },
    form: {
        heading: "Get more information",
        btn: "Get information",
    },
    stats: [
        {
            title: '+8k',
            subtitle: 'SKUs'
        },
        {
            title: '+15',
            subtitle: 'Collections'
        },
        {
            title: '+4',
            subtitle: 'Lifestyles'
        },
    ],
    who: {
        heading: 'How to participate',
        items: [
            {
                title: 'Before you start',
                content: 'The Concepts Life Home Décor Show is only for wholesalers who invest more than USD 10,000 in products for their stores.',
                image: 'https://www.conceptslife.com/wp-content/uploads/2021/09/Diseno-sin-titulo-1.png'
            },
            {
                title: 'Request information',
                content: 'Fill the form with your company information. Keep in mind that all fields are necessary so that our executives can contact you.',
                image: 'https://www.conceptslife.com/wp-content/uploads/2021/09/Diseno-sin-titulo-5.png'
            },
            {
                title: 'We will contact you',
                content: 'In less than 48 hours, one of our executives will provide you the necessary information to shop with us. You can visit us in Panama City, schedule a video call or place your order online',
                image: 'https://www.conceptslife.com/wp-content/uploads/2021/09/Diseno-sin-titulo-3.png'
            },
        ],
    },
    faqs: {
        heading: 'FAQ',
        items: [
            {
                title: 'Requirements to participate',
                content: '<ol><li>Your company must have experience buying and importing wholesale products.</li><li>Stores or sales channels (not in project).</li><li>The minimum purchase budget is USD $10,000.</li></ol>'
            },
            {
                title: 'Which businesses can participate?',
                content: 'The Concepts Life Home Décor Show is for: department stores, home décor stores, wholesalers, pharmacies, supermarkets, hardware stores, furniture stores, small retailers, bookstores, etc.'
            },
            {
                title: 'Why buy with us and not in China?',
                content: 'Our products are based on in-depth studies of home décor trends. Our selection has been refined to help save the time you would otherwise spend visiting hundreds of factories in China. <br/><br/> The selection of products that we offer to your business is previously refined, you won’t waste time going through hundreds of factories in China.'
            },
            {
                title: 'What are the benefits of buying with us?',
                content: 'Due to our geographical location, we can manage your shipping in a faster way. We also help you with the consolidation process so that you can receive your grouped merchandise.'
            },
            {
                title: 'Can I make direct shipments of the products that I selected at the Fair from China?',
                content: 'If you prefer, you can also manage with us the direct shipment from China of the merchandise that you have selected at the fair for your order on our website or in our showroom at a better price.'
            },
            {
                title: 'Do I have to buy large quantities by reference?',
                content: 'No. With Noritex you can buy minimum quantities by reference without risking buying large inventories. We offer 4 fairs a year so that you can stock your stores every 3 months, ensuring your business the rotation of merchandise and your customers the possibility of finding the latest trends in decoration in your stores.'
            },
            {
                title: 'Can I participate regardless of my geographic location?',
                content: 'Yes, we have clients in more than 30 countries. After we have enabled your personalized access to our website, you will have the option to enter our platform and process your order online 24/7.'
            },
            {
                title: 'Can I visit the fair in person/physically?',
                content: 'Yes, our Showroom is in Panama City, Panama, 5 minutes from Tocumen International Airport. At the time our sales executive contacts you, he will indicate the protocol so that you can coordinate your face-to-face visit.'
            },
            {
                title: 'If I buy online, will I receive assistance from a sales executive?',
                content: 'Yes, once your access to our website has been approved, you will receive assistance from a designated sales executive to accompany you throughout the process.'
            },
        ],
    },
    gallery: [
        {title: 'Mirrors', image: 'https://www.conceptslife.com/wp-content/uploads/2022/03/1-Espejos.jpg'},
        {title: 'Figurines', image: 'https://www.conceptslife.com/wp-content/uploads/2022/03/2-Figuras-decorativas.jpg'},
        {title: 'Pillows', image: 'https://www.conceptslife.com/wp-content/uploads/2022/03/3-Cojines.jpg'},
        {title: 'Tables', image: 'https://www.conceptslife.com/wp-content/uploads/2022/03/4-Mesas-auxiliares.jpg'},
        {title: 'Plant pots', image: 'https://www.conceptslife.com/wp-content/uploads/2022/03/5-Potes.jpg'},
        {title: 'Watches', image: 'https://www.conceptslife.com/wp-content/uploads/2022/03/6-Relojes.jpg'},
        {title: 'Paintings', image: 'https://www.conceptslife.com/wp-content/uploads/2022/03/7-Cuadros.jpg'},
        {title: 'Vases', image: 'https://www.conceptslife.com/wp-content/uploads/2022/03/8-Floreros.jpg'},
        {title: 'Table accessories', image: 'https://www.conceptslife.com/wp-content/uploads/2022/03/9-Accesorios-de-mesa.jpg'},
        {title: 'Lamps', image: 'https://www.conceptslife.com/wp-content/uploads/2022/03/10-lamparas.jpg'},
        {title: 'Wall décor', image: 'https://www.conceptslife.com/wp-content/uploads/2022/03/11-placas-de-pared.jpg'},
        {title: 'Candleholders', image: 'https://www.conceptslife.com/wp-content/uploads/2022/03/12-Portavelas.jpg'},
    ],
    footer: 'Concepts Life is a brand of '
};












