import React, { Component } from "react";

// App component - represents the whole app
const Loader = props => {
	//     return (
	// 		<svg version="1.1" id="L3" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" style={{margin: "auto", display: "block", width: "100px"}}>
	// 		  viewBox="0 0 100 100" enable-background="new 0 0 0 0" xml:space="preserve">
	// 			<circle fill="none" stroke="black" strokeWidth="4" cx="50" cy="50" r="44" style={{opacity:0.5}}/>
	// 		  	<circle fill="#fff" stroke="#e74c3c" strokeWidth="3" cx="8" cy="54" r="6" >
	// 			    <animateTransform
	// 			      attributeName="transform"
	// 			      dur="2s"
	// 			      type="rotate"
	// 			      from="0 50 48"
	// 			      to="360 50 52"
	// 			      repeatCount="indefinite" />
	//
	// 		  </circle>
	// 		</svg>
	//
	//     );

	return (
		<div className="loader">
			<img
				src="https://www.noritex.com/fMyJKKc6RYf4yL3DdloaderNTX2.gif"
				alt="loader"
			/>
		</div>
	);
};

{
	/* <svg width="38" height="38" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg" stroke="#fff">
		    <g fill="none" fillRule="evenodd">
		        <g transform="translate(1 1)" strokeWidth="2">
		            <circle strokeOpacity=".5" cx="18" cy="18" r="18"/>
		            <path d="M36 18c0-9.94-8.06-18-18-18">
		                <animateTransform
		                    attributeName="transform"
		                    type="rotate"
		                    from="0 18 18"
		                    to="360 18 18"
		                    dur="1s"
		                    repeatCount="indefinite"/>
		            </path>
		        </g>
		    </g>
		</svg>*/
}

export default Loader;
