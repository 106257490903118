import React from "react";
import { Helmet } from "react-helmet";

var captchaClienIdContact = null;
function onRecaptchaLoadCallbackContact() {
    captchaClienIdContact = grecaptcha.render("inline-badge", {
        sitekey: "6LemZq4ZAAAAAN5R0IFmny9jex0HBbz7jbuBFm4A",
        badge: "inline",
        size: "invisible",
    });
}
export function CaptchReady() {
    return new Promise((resolve, reject) => {
        grecaptcha.ready(() => {
            grecaptcha
                .execute(captchaClienIdContact, {
                    action: "submit",
                })
                .then((token) => {
                    resolve(token);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    });
}

function Captcha() {
    React.useEffect(() => {
        if (typeof window !== "undefined")
            window.onRecaptchaLoadCallbackContact =
                onRecaptchaLoadCallbackContact;
    }, []);
    return (
        <>
            <Helmet>
                <script
                    type="text/javascript"
                    src="https://www.google.com/recaptcha/api.js?render=explicit&onload=onRecaptchaLoadCallbackContact"
                />
            </Helmet>
            <div id="inline-badge"></div>
        </>
    );
}

export default Captcha;
