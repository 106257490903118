import styled, { css } from "styled-components";
import { Columns, Column } from "../../sdk/Layout";

import { Modal } from "../../sdk/Elements";
import { Button } from "../../sdk/Button";

export const BigImage = styled.div`
    position: relative;
    &:before {
        content: "";
        display: block;
        padding-top: 100%;
    }
    > div {
        position: absolute !important;
        top: 0;
        /* padding-top: 70%; */
        left: 0;
        width: 100% !important;
        height: 100% !important;
        z-index: 9;
        video {
            max-width: 100%;
            height: 100%;
            max-height: 100%;
            width: 100%;
        }
        iframe {
            height: 100%;
            width: 100%;
            border: 0;

            min-height: 300px;
        }
        .video-js {
            position: absolute;
            top: 0;
            left: 0;
            padding: 0 !important;
            width: auto;
            height: auto;
            overflow: hidden;
            max-height: 100%;
            display: flex;
            max-width: 100%;
        }
    }

    ${Button} {
        position: absolute;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 9;
        box-shadow: 0 2px 14px -7px rgba(0, 0, 0, 0.6);
    }

    @media (max-width: 768px) {
        margin: auto;
        max-width: 400px;

        @media (orientation: landscape) {
            max-width: 200px;
        }
    }
`;

export const Thumbnails = styled(Column)`
    padding-right: 0;
    ul {
        padding: 5px;
        padding-right: 20px;
        margin-left: -5px;
        max-height: 400px;
        overflow-y: auto;
        overflow-x: hidden;
    }
    @media (max-width: 768px) {
        width: 100%;
        padding: 5px 10px;
        ul {
            margin-left: 0;
            padding-right: 0;
            text-align: center;
            white-space: nowrap;
            overflow-x: auto;
            overflow-y: hidden;
        }
    }
`;

export const CurrentTbMixin = css`
    border-color: #98989b;
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.1);
`;

export const ThumbItem = styled.li`
    display: block;
    cursor: pointer;
    border: solid 1px #e3e3e3;
    background-color: #fff;
    width: 70px;
    position: relative;
    height: 70px;

    ${(props) => props.current && CurrentTbMixin}
    &:hover {
        ${CurrentTbMixin}
    }
    + li {
        margin-top: 10px;
    }
    span {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;

        background-color: rgba(0, 0, 0, 0.4);
        svg {
            fill: white;
            color: white;
            width: 30px;
            height: 30px;
        }
    }
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: block;
    }

    @media (max-width: 768px) {
        display: inline-block;
        vertical-align: middle;
        width: 40px;
        height: 40px;
        + li {
            margin-top: 0;
            margin-left: 5px;
        }
    }
`;

export const horizontalMixin = css`
    display: block !important;
    ${Thumbnails} {
        width: 100%;
        padding: 5px 10px;
        ul {
            margin-left: 0;
            padding-right: 0;
            text-align: center;
            white-space: nowrap;
            overflow-x: auto;
            overflow-y: hidden;
        }
    }
    ${ThumbItem} {
        display: inline-block;
        vertical-align: middle;
        + li {
            margin-top: 0;
            margin-left: 5px;
        }
    }
`;

export const Gallery = styled(Columns)`
    flex-direction: row-reverse;
    @media (max-width: 768px) {
        display: flex;
        flex-wrap: wrap;
    }

    ${(props) => props.horizontal && horizontalMixin}
`;

export const FullView = styled(Modal)`
    z-index: 1002;
    > div {
        height: 90%;
        max-width: calc(100% - 150px);
        overflow-y: auto;
    }
    ${Gallery} {
        height: 100%;
    }
    figure {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            max-width: 100%;
            max-height: 100%;
            object-fit: contain;
        }
    }
    @media (max-width: 768px) {
        > div {
            height: auto;
        }
    }
`;
