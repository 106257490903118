import styled from "styled-components";

import {
    Title,
    Section,
    Columns,
    Column,
    PageContent,
    Container,
} from "../../sdk/Layout";
import { Control } from "../../sdk/Form";

export const Wrapper = styled.div`
    font-size: 16px;
`;

export const Hero = styled.div`
    height: 250px;
    position: relative;
    background-color: #112c55;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        opacity: 0.1;
    }
    > div {
        padding: 0px 10px;
        position: absolute;
        left: 0;
        width: 100%;
        text-align: center;
        top: 50%;
        transform: translateY(-50%);
        z-index: 2;
    }
    & ${Title} {
        color: #fff;
    }
    & ${Control} {
        margin: auto;
        max-width: 470px;
        padding-top: 10px;
        position: relative;
        input {
            padding-right: 35px;
        }
        button {
            position: absolute;
            bottom: 0px;
            right: 0px;
            width: 40px;
            color: #112c55;
            opacity: 1;
            border: 0;
            padding: 0;
            background-color: transparent;
        }
        svg {
            width: 20px;
            height: 20px;
            opacity: 0.5;
        }
    }

    @media (max-width: 540px) {
        height: 180px;
        & ${Title} {
            font-size: 20px;
        }
        & ${Control} {
            input {
                font-size: 14px;
            }
            svg {
                bottom: 8px;
            }
        }
    }
`;

export const WrapperTopQuestions = styled(Section)`
    padding: 5% 10px;
    ${(props) =>
        props.isHidden
            ? `
        visibility: hidden;
        opacity: 0;
        padding: 0 !important;
        height: 0;
        overflow: hidden;
        display: block;
        transition: all 0.5s ease;
    `
            : ""}
`;

export const Cols = styled(Columns)`
    @media (max-width: 980px) {
        display: block;
    }

    @media (max-width: 768px) {
        & {
            & ${Column}:not(:last-child) {
                border-bottom: solid 1px ${props => props.theme.borderColor};
                padding-bottom: 20px;
                margin-bottom: 10px;
            }
        }
    }
`;

export const Text = styled(PageContent)`
    p a {
        color: ${props => props.theme.link};
        text-decoration: underline;
    }
`;

export const Left = styled(Column)`
    width: 30%;
    @media (max-width: 980px) {
        width: 100%;
    }
`;

export const Items = styled.ul`
    display: flex;
    flex-direction: column;

    @media (max-width: 980px) {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
    }
`;

export const Item = styled.li`
    display: block;
    padding: 10px 0;
    a {
        display: flex;
        align-items: center;
        color: ${props => props.theme.primaryDark};
        span {
            width: 75px;
            height: 75px;
            border-radius: 100%;
            background-color: ${props => props.theme.grayLight};
            display: inline-flex;
            align-items: center;
            justify-content: center;
            flex: none;
            svg {
                width: 47px;
                height: 47px;
                color: ${props => props.theme.gray};
            }
        }
        p {
            margin: 0;
            padding: 0;
            padding-left: 10px;
            font-family: futura;
            font-size: 16px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: ${props => props.theme.primaryDark};
        }
        > svg {
            width: 12px;
            margin-left: 5px;
        }
        &:hover {
            text-decoration: underline;
        }
    }

    @media (max-width: 980px) {
        margin: 5px 20px;
        padding: 5px 0;
    }

    @media (max-width: 768px) {
        margin: 5px 10px;
        a {
            flex-direction: column;
            > svg {
                display: none;
            }
            p {
                padding-left: 0;
                margin: 10px 0;
                font-size: 12px;
            }
            span {
                width: 50px;
                height: 50px;
                svg {
                    width: 30px;
                    height: 30px;
                }
            }
        }
    }
`;

export const WrapperQuestions = styled(Section)`
    padding: 3% 0;
    background-color: color: ${props => props.theme.grayLight};;
`;

export const Wrap = styled(Container)`
    max-width: 800px !important;
`;

export const Accordeon = styled.div`
    background-color: color: ${props => props.theme.white};;
    border-radius: 10px;
    padding: 30px;

    @media (max-width: 768px) {
        padding: 20px;
    }
`;

export const Question = styled.div`
    &:not(:first-child) {
        padding-top: 20px;
    }
    &:not(:last-child) {
        padding-bottom: 20px;
    }

    & ${Title} {
        cursor: pointer;
        position: relative;
        padding: 0;
        margin: 0;
        opacity: 0.8;
        padding-right: 30px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        user-select: none;
        svg {
            position: absolute;
            right: 5px;
            top: 2px;
            width: 10px;
            transform: rotate(90deg);
            opacity: 0.5;
            transition: opacity 200ms linear;
        }
    }
    p {
        color: ${props => props.theme.primaryDark};
    }
    ${(props) => {
        if (props.isActive) {
            return `
				${Title} {
					opacity: 1;	
					white-space: normal;
					svg {
						transform: rotate(-90deg);
					}
				}
			`;
        }
    }}
    & + & {
        border-top: solid 1px ${props => props.theme.borderColor};
    }

    > div {
        padding: 10px 0;
        padding-right: 30px;
        display: ${(props) => (props.isActive ? "block" : "none")};
    }
    &:hover {
        & ${Title} {
            svg {
                opacity: 1;
            }
        }
    }

    @media (max-width: 768px) {
        & ${Title} {
            font-size: 14px;
            svg {
                width: 8px;
            }
        }
    }
`;
