import React from "react";
import { Items, Item } from "../style";
import InternalLink from "../../../components/InternalLink";
import Icons from "./Icons";

function QuickActions({ langJson }) {
	return (
		<>
			<Items>
				<Item>
					<InternalLink to="/claim-help">
						<span>
							<Icons icon="return-box"></Icons>
						</span>
						<p>{langJson.startAReturn}</p>
						<Icons icon="external-link"></Icons>
					</InternalLink>
				</Item>
				{/* <Item>
                    <InternalLink>
                        <span>
                            <Icons icon="track-order"></Icons>
                        </span>
                        <p>Track an Order</p>
                        <Icons icon="external-link"></Icons>
                    </InternalLink>
                </Item> */}
				<Item>
					<InternalLink to="/contact-us">
						<span>
							<Icons icon="contact-form"></Icons>
						</span>
						<p>{langJson.contactABrand}</p>
						<Icons icon="external-link"></Icons>
					</InternalLink>
				</Item>
				{/* <Item>
                    <InternalLink to="/orders">
                        <span>
                            <Icons icon="cancel"></Icons>
                        </span>
                        <p>{langJson.cancelAnOrder}</p>
                        <Icons icon="external-link"></Icons>
                    </InternalLink>
                </Item> */}
				<Item>
					<InternalLink to="/home-login/image-export/">
						<span>
							<Icons icon="images"></Icons>
						</span>
						<p>{langJson.productImages}</p>
						<Icons icon="external-link"></Icons>
					</InternalLink>
				</Item>
				<Item>
					<InternalLink to="/ntxmedia/">
						<span>
							<Icons icon="media"></Icons>
						</span>
						<p>Noritex Media</p>
						<Icons icon="external-link"></Icons>
					</InternalLink>
				</Item>
				<Item>
					<InternalLink to="/claim-help">
						<span>
							<Icons icon="damaged-box"></Icons>
						</span>
						<p>{langJson.reportDamagedItems}</p>
						<Icons icon="external-link"></Icons>
					</InternalLink>
				</Item>
			</Items>
		</>
	);
}

export default QuickActions;
