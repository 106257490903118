export default {
	yoursalesrep: "Your assigned salesrep",
	positionTitle: "International Sales Exec. ",
	aboutMe: "About Me",
	yearsOfService: "years of service",
	languages: "Languages",
	warehouse: "Warehouse",
	contact: "Contact",
	requestAMeeting: "Schedule Meeting",
	presaleSystem: "Presale",
	benefits: "Benefits",
	socialResponsibility: "Social responsibility",
	moreInformation: "More information",
	socialResponsibilities: [
		{
			img: "https://www.noritex.com/uplds/sYJ2R3L72Ni9ukQ9yPaneles_Solares_Noritex.jpg",
			alt: "Energy Production - Social Responsibility - Noritex",
			paragraph:
				"Noritex produces 85% of our energy, we use solar energy to reduce dependence on non-renewable energy sources.",
		},
		{
			img: "https://www.noritex.com/uplds/rTskTZ8ay3KXXmhCpnavidad-responsabilidad-social.jpg",
			alt: "Christmas Magic - Social Responsibility - Noritex",
			paragraph:
				"Every year we bring the magic of Christmas to our children.",
		},
	],
	moreInformationBlocks: [
		{
			img: "https://www.noritex.com/uplds/2024/7/20/Zw4upgDJ8Wb3dmEnq244-_Serenity_Collection_-_Portada.jpg",
			paragraph: "Our next fair",
			link: "",
			alt: "Next fair",
		},
		{
			img: "https://www.noritex.com/uplds/jpo73JjpaHvdfHTCtOficinas_de_Noritex.jpg",
			paragraph: "Learn more about Noritex.com",
			link: "/about-us",
			alt: "More About Noritex",
		},
	],

	salesSystem: [
		{
			title: "Direct Sales",
			paragaph: [
				"Shipping in containers directly from our factory.",
				"You can choose several references from the same factory.",
			],
			icon: "truck-container",
			benefits: [
				"Freight savings.",
				"Direct shipment to the customer in the port country.",
			],
		},
		{
			title: "Sales from Panama",
			paragaph: [
				"Direct dispatch from Panama to the client country port.",
				"Pick products from several factories.",
			],
			icon: "boat",
			benefits: [
				"Minor amounts by reference.",
				"Consolidated container.",
			],
		},
	],
};
