export default {
    menu: {
        item1: 'Cómo participar',
        item2: 'Preguntas frecuentes',
        item3: 'Solicitar información',
    },
    hero: {
        pre: "SURTA SU NEGOCIO CON LAS NUEVAS TENDENCIAS",
        title: "Feria de Decoración Hogar <br /> Concepts Life",
        sub: "Del 18 de Julio al 19 de Agosto",
        btn: "Solicitar información",
    },
    form: {
        heading: "Solicite más información",
        btn: "Solicitar información",
    },
    stats: [
        {
            title: '+8k',
            subtitle: 'Productos'
        },
        {
            title: '+15',
            subtitle: 'Colecciones'
        },
        {
            title: '+4',
            subtitle: 'Estlos de vida'
        },
    ],
    who: {
        heading: '¿CÓMO PARTICIPAR?',
        items: [
            {
                title: 'Antes de iniciar',
                content: 'La Feria de Decoración de Concepts Life es únicamente para compras al por mayor y está dirigida a negocios con canales de venta establecidos, que invierten desde USD 10,000 en mercancía para sus tiendas.',
                image: 'https://www.conceptslife.com/wp-content/uploads/2021/09/Diseno-sin-titulo-1.png'
            },
            {
                title: 'Regístrese',
                content: 'En el formulario usted podrá dejar datos como nombre de empresa, cargo que ocupa, tipo de negocio entre otros… Tenga en cuenta que todos los campos son necesarios para que nuestros ejecutivos puedan contactarlo.',
                image: 'https://www.conceptslife.com/wp-content/uploads/2021/09/Diseno-sin-titulo-5.png'
            },
            {
                title: 'Sea contactado',
                content: 'En un periodo no mayor a 48 horas uno de nuestros ejecutivos le brindará la información necesaria para acceder a la feria. Podrá visitarnos en Ciudad de Panamá, agendar una videollamada o hacer su pedido en línea.',
                image: 'https://www.conceptslife.com/wp-content/uploads/2021/09/Diseno-sin-titulo-3.png'
            },
        ],
    },
    faqs: {
        heading: 'Preguntas frecuentes',
        items: [
            {
                title: '¿Qué requisitos debo cumplir para participar?',
                content: '<ol><li>Su empresa debe tener experiencia en compras e importación de productos al por mayor.<!-- --> </li><li>Adicionalmente debe contar con tiendas o canales de venta definidos (no en proyecto).</li><li>El presupuesto mínimo de compra es de USD $10.000.</li></ol>'
            },
            {
                title: '¿Qué tipo de negocios pueden participar?',
                content: 'Nuestra feria internacional de decoración está dirigida a negocios como: tiendas por departamentos, tiendas especializadas en decoración, mayoristas, farmacias, supermercados, ferreterías, tiendas de muebles, pequeños detallistas, librerías, tiendas online, entre otros.'
            },
            {
                title: 'Compro en China, ¿qué diferenciador pueden ofrecerme?',
                content: 'En Noritex hacemos un proceso de selección de productos basado en estudios profundos de tendencias de decoración para el hogar. La selección de productos que traemos para ofrecerle a su negocio ya está previamente depurada, para que usted no pierda tiempo recorriendo cientos de fábricas en China. Al comprar con nosotros, usted encontrará los productos organizados en colecciones basadas en los estilos de vida de los consumidores, lo que a usted le facilitará el proceso de selección de productos y la posterior exhibición en su punto de venta, para que sus clientes encuentren con total seguridad las últimas tendencias en decoración.'
            },
            {
                title: '¿Qué beneficios recibe al comprar con nosotros en Panamá?',
                content: 'Por nuestra ubicación geográfica, al estar en Panamá, podemos gestionar el despacho de su mercancía de una forma más rápida. Además le ayudamos con el proceso de consolidación para que usted pueda recibir su mercancía agrupada.'
            },
            {
                title: '¿Puedo hacer envíos directos de los productos que seleccioné en la Feria desde China?',
                content: 'Si usted lo prefiere, también puede gestionar con nosotros el envío directo desde China de la mercancía que haya seleccionado en la feria para su pedido en nuestro sitio web o en nuestro showroom a un mejor precio.'
            },
            {
                title: '¿Debo comprar grandes cantidades por referencia?',
                content: 'No. Con Noritex usted puede comprar cantidades mínimas por referencia sin arriesgarse a comprar grandes inventarios. Nosotros realizamos 4 ferias al año, de manera que usted puede surtir sus puntos de venta cada 3 meses, asegurándole a su negocio la rotación de la mercancía y a sus clientes la posibilidad de encontrar en sus tiendas las últimas tendencias en decoración.'
            },
            {
                title: '¿Puedo participar sin importar mi ubicación geográfica?',
                content: 'Sí, tenemos clientes en más de 30 países. Además, luego de que le hayamos habilitado sus accesos personalizados a nuestro sitio web, usted tendrá la opción de ingresar a nuestra plataforma y trabajar su pedido online 24/7.'
            },
            {
                title: '¿Puedo visitar la feria de manera presencial/física?',
                content: 'Sí, nuestro Showroom se encuentra ubicado en Ciudad de Panamá, Panamá, a 5 minutos del Aeropuerto Internacional de Tocumen. Al momento en el que nuestro ejecutivo de ventas se contacte con usted le indicará el protocolo para que usted pueda coordinar su visita presencial.'
            },
            {
                title: 'Si compro online, ¿voy a recibir asistencia de un representante de ventas?',
                content: 'Sí, una vez haya sido aprobado su acceso a nuestro sitio web, usted recibirá asistencia de un ejecutivo de ventas designado para acompañarlo durante todo el proceso.'
            },
        ],
    },
    gallery: [
        {title: 'Espejos', image: 'https://www.conceptslife.com/wp-content/uploads/2022/03/1-Espejos.jpg'},
        {title: 'Figuras decorativas', image: 'https://www.conceptslife.com/wp-content/uploads/2022/03/2-Figuras-decorativas.jpg'},
        {title: 'Cojines', image: 'https://www.conceptslife.com/wp-content/uploads/2022/03/3-Cojines.jpg'},
        {title: 'Mesas auxuliares', image: 'https://www.conceptslife.com/wp-content/uploads/2022/03/4-Mesas-auxiliares.jpg'},
        {title: 'Potes', image: 'https://www.conceptslife.com/wp-content/uploads/2022/03/5-Potes.jpg'},
        {title: 'Relojes', image: 'https://www.conceptslife.com/wp-content/uploads/2022/03/6-Relojes.jpg'},
        {title: 'Cuadros', image: 'https://www.conceptslife.com/wp-content/uploads/2022/03/7-Cuadros.jpg'},
        {title: 'Floreros', image: 'https://www.conceptslife.com/wp-content/uploads/2022/03/8-Floreros.jpg'},
        {title: 'Accesorios de mesa', image: 'https://www.conceptslife.com/wp-content/uploads/2022/03/9-Accesorios-de-mesa.jpg'},
        {title: 'Lámparas', image: 'https://www.conceptslife.com/wp-content/uploads/2022/03/10-lamparas.jpg'},
        {title: 'Placas de pared', image: 'https://www.conceptslife.com/wp-content/uploads/2022/03/11-placas-de-pared.jpg'},
        {title: 'Portavelas', image: 'https://www.conceptslife.com/wp-content/uploads/2022/03/12-Portavelas.jpg'},
    ],
    footer: 'Concepts Life es una marca de '
};












