import React from "react";
import { Gallery, Thumbnails, ThumbItem, FullView } from "./style";
import ModalPortal from "../ModalPortal";
import { Column, Box } from "../../sdk/Layout";

function FullScreen({
    images,
    toggleModal,
    modal,
    currentImage,
    set,
    alt,
    isVideo,
}) {
    return (
        <ModalPortal>
            <FullView className={modal && "is-active"}>
                <button onClick={toggleModal}></button>
                <span onClick={toggleModal} />
                <Box>
                    <Gallery>
                        <Column>
                            {isVideo ? (
                                <figure>
                                    {currentImage.type === "youtube" ? (
                                        <div>
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: `<iframe src=${currentImage.url} frameborder="0" allow="autoplay; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen />`,
                                                }}
                                            />
                                        </div>
                                    ) : (
                                        <div>
                                            <video
                                                width={400}
                                                controls
                                                autoPlay
                                            >
                                                <source
                                                    src={currentImage.url}
                                                    type="video/mp4"
                                                    autoPlay
                                                />
                                                Your browser does not support
                                                HTML video.
                                            </video>
                                        </div>
                                    )}
                                </figure>
                            ) : (
                                <figure>
                                    <img src={currentImage} alt="" />
                                </figure>
                            )}
                        </Column>
                        <Thumbnails isNarrow>
                            <ul>
                                {images
                                    ? images.map((index, i) => (
                                          <ThumbItem
                                              key={`full screen image ${i}`}
                                              current={
                                                  currentImage === index.url
                                              }
                                              onMouseEnter={() =>
                                                  set(index.url)
                                              }
                                          >
                                              <img
                                                  src={index.thumb}
                                                  alt={alt}
                                              />
                                          </ThumbItem>
                                      ))
                                    : null}
                            </ul>
                        </Thumbnails>
                    </Gallery>
                </Box>
            </FullView>
        </ModalPortal>
    );
}

export default FullScreen;
