const categories = [
	{
		slug: "decoracion-del-hogar",
		thumb: "https://www.noritex.com/uplds/2023/10/27/hLhRonAjNdhzZzncaCocnepts-seccion-(700x500).jpg",
		hero: "https://www.noritex.com/uplds/2023/10/24/jDr2wT5GgzSARhRkJBanner-decoracion-hogar.jpg",
		gallery: [
			"https://www.noritex.com/uplds/2023/10/24/42GtEBn3FKADLFAeYdecohogar-collage02.jpg",
			"https://www.noritex.com/uplds/2023/10/24/YfMPXYP9Qj8E6FQMddecohogar-collage05.jpg",
			"https://www.noritex.com/uplds/2023/10/24/SfXT646GBbmo5EaMddecohogar-collage07.jpg",
			"https://www.noritex.com/uplds/2023/10/24/xJf8wArFXooEbdC9Qdecohogar-collage08.jpg",
			"https://www.noritex.com/uplds/2023/10/24/fEgx8FYYcP79eARqXdecohogar-collage03.jpg",
			"https://www.noritex.com/uplds/2023/10/24/yHeJRv4gaXzMcBpK3decohogar-collage06.jpg",
			"https://www.noritex.com/uplds/2023/10/24/BRCYn7ooN3BRdqQTrdecohogar-collage04.jpg",
			"https://www.noritex.com/uplds/2023/10/24/TTNfzEb4pxgkqvCHLdecohogar-collage09.jpg",
			"https://www.noritex.com/uplds/2023/10/24/RCzZNSucvcu79G33Kdecohogar-collage01.jpg",
		],
		title: "Home Decoration",
		description:
			"Transform your home decoration products with our wide range of accessories and items that add a unique and special touch to every space. From the living room to the dining room, our designs imbue quality, style, and trend.",
		content:
			"<p>In the exciting world of home decoration, standing out is essential to attract modern consumers looking for quality and style. At Noritex, we understand the importance of exceeding expectations and elevating the category of your decoration products.</p><p>Our wide selection of accessories and items is meticulously designed to add a unique and special touch to every corner of your customers' homes, from the living room to the dining room. Our designs not only convey quality and style but also closely follow the latest decorative trends.</p>",
	},
	{
		slug: "decoracion-de-navidad",
		thumb: "https://www.noritex.com/uplds/2023/10/27/cPqaozMCzSN4PZC2hSantini-seccion-(700x500).jpg",
		gallery: [
			"https://www.noritex.com/uplds/2023/10/27/DqsDJQaTZsbfL93g8navidad-collage04.jpg",
			"https://www.noritex.com/uplds/2023/10/27/824oReEGFkHPu2kADnavidad-collage02.jpg",
			"https://www.noritex.com/uplds/2023/10/27/sCS2usWMD4s5rknk9navidad-collage08.jpg",
			"https://www.noritex.com/uplds/2023/10/27/coGWox6YQBfQhXsTWnavidad-collage09.jpg",
			"https://www.noritex.com/uplds/2023/10/27/iDZcsBQoAwr6gAWp2navidad-collage05.jpg",
			"https://www.noritex.com/uplds/2023/10/27/gkqt7kf77itEgdtjXnavidad-collage03.jpg",
			"https://www.noritex.com/uplds/2023/10/27/3A6aRNzDoCptrJBSpnavidad-collage01.jpg",
			"https://www.noritex.com/uplds/2023/10/27/JkqesZd3RNdg8y9cknavidad-collage07.jpg",
			"https://www.noritex.com/uplds/2023/10/27/RWPak7i9uWmQstZjnnavidad-collage06.jpg",
		],
		hero: "https://www.noritex.com/uplds/2023/10/27/2BHfdZswkdPwsXwt5Banner---Decoracion-navidad-(1600x850).jpg",
		title: "Christmas Decoration",
		description:
			"Bring the magic of Christmas to your sales points with our Christmas decoration, which will transform your offerings into charming seasonal experiences. We offer a variety of Christmas products that meet the highest quality standards.",
		content:
			"<p>In the enchanting universe of Christmas, the atmosphere and magic are fundamental to captivate customers and differentiate yourself from your competition. At Noritex, we are experts in bringing that magic to your Christmas products and transforming your offerings into experiences full of the characteristic charm of the season.</p><p>Our wide selection of Christmas decoration is designed to add an exceptional touch to every corner. From traditional figures to the most contemporary trends, our products exceed the most rigorous quality standards.</p><p>Imagine being able to offer your customers a variety of Christmas products that not only meet their expectations but also allow them to fully immerse themselves in the holiday celebration. At Noritex, we give you the opportunity to stand out in Christmas decoration and offer your customers the best of the festive season.</p>",
	},
	{
		slug: "sportwear",
		thumb: "https://www.noritex.com/uplds/2023/10/27/kFrhjgxSAHa5Kn7zCSportwear-seccion-(700x500).jpg",
		gallery: [
			"https://www.noritex.com/uplds/2023/10/24/Ww9Ho9Hr86f2KeuKTsportwear-collage02.jpg",
			"https://www.noritex.com/uplds/2023/10/24/5Wtgrmgt8FWyenSGMsportwear-collage04.jpg",
			"https://www.noritex.com/uplds/2023/10/24/HSY9cYdRGqpeKaojbsportwear-collage08.jpg",
			"https://www.noritex.com/uplds/2023/10/24/W6LXYFAbftdcBbt56sportwear-collage09.jpg",
			"https://www.noritex.com/uplds/2023/10/24/v7X5xLY6qS5w8JkeXsportwear-collage03.jpg",
			"https://www.noritex.com/uplds/2023/10/24/EbqJjtv9GgikPD6TMsportwear-collage06.jpg",
			"https://www.noritex.com/uplds/2023/10/24/sCGQDMb86nnTaFdZYsportwear-collage05.jpg",
			"https://www.noritex.com/uplds/2023/10/24/aS7GehjHhLxSzKJSjsportwear-collage07.jpg",
			"https://www.noritex.com/uplds/2023/10/24/cHWYCNDbAgrYuvGi2sportwear-collage01.jpg",
		],
		hero: "https://www.noritex.com/uplds/2023/10/24/wqH9pYJKhJuepZPB7banner-sportwear.jpg",
		title: "Sportswear",
		description:
			"Our sportswear offers quality and style at attractive prices for your market. We keep an eye on the latest trends and design products with the modern woman in mind, looking for comfort and style.",
		content:
			"<p>In the exciting world of sportswear, standing out is essential through the perfect combination of quality, style, and affordability for your market.</p><p>Our products are meticulously conceived, keeping in mind the modern woman who seeks more than just comfortable clothing; she seeks garments that reflect her active and energetic lifestyle.</p><p>Imagine being able to offer your customers a selection of sportswear that not only enhances their performance in the gym or outdoors but also allows them to do so with style and confidence.</p><p>With Noritex, you have access to a range of products designed to meet the demands of the modern woman and provide her with an unparalleled sportswear fashion experience.</p>",
	},
	{
		slug: "accesorios-deportivos",
		thumb: "https://www.noritex.com/uplds/2023/10/27/Ech9zSKkJL74nKHtuseccion_Accesorios-(700x500).jpg",
		gallery: [
			"https://www.noritex.com/uplds/2023/10/27/Ch9WdJQkg8JjaPrtEaccesorios-deportivos-collage03.jpg",
			"https://www.noritex.com/uplds/2023/10/27/eWAhpwqZvQgGg6qmwaccesorios-deportivos-collage05.jpg",
			"https://www.noritex.com/uplds/2023/10/27/XjiZuqFbMYmp3hCiPaccesorios-deportivos-collage07.jpg",
			"https://www.noritex.com/uplds/2023/10/27/RKHarAwwfFQAuPQ9saccesorios-deportivos-collage08.jpg",
			"https://www.noritex.com/uplds/2023/10/27/wWy4kBuqQKrPx53N6accesorios-deportivos-collage01.jpg",
			"https://www.noritex.com/uplds/2023/10/27/Fqtjq8zjAexMuDYo5accesorios-deportivos-collage06.jpg",
			"https://www.noritex.com/uplds/2023/10/27/vt3dEzxxtpdChCQNfaccesorios-deportivos-collage04.jpg",
			"https://www.noritex.com/uplds/2023/10/27/ZTxvTT5hTm3xy6MyYaccesorios-deportivos-collage09.jpg",
			"https://www.noritex.com/uplds/2023/10/27/o6CpAyfHwN9hHTucLaccesorios-deportivos-collage02.jpg",
		],
		hero: "https://www.noritex.com/uplds/2023/10/27/MpqcoxF6WCAdgANXbBanner-collage-accesorios.jpg",
		title: "Sport Accessories",
		description:
			"We combine style, beauty, and functionality in our sport accessories, from functional equipment to yoga and recovery items. Our bags and backpacks are designed to make life easier and more comfortable.",
		content:
			"<p>We fuse style, beauty, and functionality to offer you a unique experience. From highly functional equipment to items designed specifically for yoga and recovery, each element in our collection is meticulously conceived.</p><p>Our accessories are carefully designed to seamlessly integrate into the active and modern life of your customers.</p><p>Imagine being able to offer your customers a selection of sport accessories that not only complement their workout routines but also reflect their taste for fashion and practicality in every detail.</p><p>With Noritex, you have access to a range of products designed to make the active life more stylish and comfortable. If you're ready to take your sport accessories offering to the next level, we're here to help you achieve it.</p>",
	},
	{
		slug: "utiles-escolares",
		thumb: "https://www.noritex.com/uplds/2023/10/27/6sdrvsLfLCGA8nZJqEscolar-seccion-(700x500).jpg",
		gallery: [
			"https://www.noritex.com/uplds/2023/10/24/m2P3r6iHhLizhEk2outiles_escolares-collage03.jpg",
			"https://www.noritex.com/uplds/2023/10/24/yPkw4suQhXWZdoHX9utiles_escolares-collage05.jpg",
			"https://www.noritex.com/uplds/2023/10/24/G6kR4mqiN3vncFESputiles_escolares-collage09.jpg",
			"https://www.noritex.com/uplds/2023/10/24/vRzJKgWmweEDPbHW6utiles_escolares-collage07.jpg",
			"https://www.noritex.com/uplds/2023/10/24/Jb6NEZPhvD2Sj8Z8Jutiles_escolares-collage02.jpg",
			"https://www.noritex.com/uplds/2023/10/24/g7qy5dCFPP56GKxoFutiles_escolares-collage06.jpg",
			"https://www.noritex.com/uplds/2023/10/24/jhWsi5mTJABPsPr8Tutiles_escolares-collage04.jpg",
			"https://www.noritex.com/uplds/2023/10/24/yWp6SjxmqjB9gxMJRutiles_escolares-collage08.jpg",
			"https://www.noritex.com/uplds/2023/10/24/ymoHhufHeK375wAWxutiles_escolares-collage01.jpg",
		],
		hero: "https://www.noritex.com/uplds/2023/10/24/iNkjxa38FAEvEpBCgbanner_utiles_escolares.jpg",
		title: "School Supplies",
		description:
			"We offer a wide range of school supplies, including backpacks and lunchboxes designed to become favorites of parents and kids. Our constant innovation ensures we always have the right solution for you.",
		content:
			"<p>Our wide range of school supplies includes backpacks and lunchboxes that have become favorites of both parents and kids. Our constant pursuit of innovation ensures we always have the right solution for you.</p><p>Each product has been carefully designed to be functional, durable, and inspiring. We keep a focus on fashion trends to ensure our products stand out in the classroom and on the playground.</p><p>In School Supplies, our goal is to make the school preparation process exciting and hassle-free. We believe that school supplies can be a source of joy for both kids and their parents and teachers. Explore our collection and discover how we make a difference in school life.</p>",
	},
	{
		slug: "oficina",
		thumb: "https://www.noritex.com/uplds/2023/10/27/Rqq8YaxpiF5eZhegfOficina-seccion-(700x500).jpg",
		gallery: [
			"https://www.noritex.com/uplds/2023/10/27/jujRk7Rx2KnDuxvfcoficina-collage01.jpg",
			"https://www.noritex.com/uplds/2023/10/27/6K2KEasvnKPYbCtFkoficina-collage06.jpg",
			"https://www.noritex.com/uplds/2023/10/27/HTyh6mJZjXdDYyYzvoficina-collage09.jpg",
			"https://www.noritex.com/uplds/2023/10/27/eurPaAJFxkmWuEx2Roficina-collage08.jpg",
			"https://www.noritex.com/uplds/2023/10/27/fzQW8jz8LYduoa9Lcoficina-collage03.jpg",
			"https://www.noritex.com/uplds/2023/10/27/MFZXLu9teeWhRGGE7oficina-collage04.jpg",
			"https://www.noritex.com/uplds/2023/10/27/ZduqpESw8cNRQM2RBoficina-collage05.jpg",
			"https://www.noritex.com/uplds/2023/10/27/rBwbLXqfsbkXhe74Coficina-collage07.jpg",
			"https://www.noritex.com/uplds/2023/10/27/CbMcKwTFSKEn5Wsxooficina-collage02.jpg",
		],
		hero: "https://www.noritex.com/uplds/2023/10/27/i8RL8btjPJ5wY6FCEoficina-Banner_Oficina.jpg",
		title: "Office",
		description:
			"Our office offering includes a variety of products that help organize and facilitate for users. Our stylishly designed agendas, created for modern life, feature fashion-forward designs that every woman and man seek.",
		content:
			"<p>Our office product offering includes agendas and organizers designed with style and functionality to meet the demands of modern life. We also offer an exceptional variety of pens and pencils that combine quality and practicality for a unique writing experience.</p><p>Our agendas and organizers are designed for the demands of modern life, with fashion proposals that appeal to both women and men, lovers of productivity and planning.</p><p>Join our community of satisfied customers who value quality and design in every product we offer. Experience the difference our agendas, organizers, pens, and pencils can make in your daily life, helping you stay in control and stand out in style.</p>",
	},
];

const countries = [
	"Antigua y Barbuda",
	"Aruba",
	"Bahamas",
	"Barbados",
	"Belice",
	"Bolivia",
	"Brasil",
	"Chile",
	"Colombia",
	"Costa Rica",
	"Curacao",
	"Dominica",
	"Ecuador",
	"El Salvador",
	"Granada",
	"Guatemala",
	"Guyana",
	"Honduras",
	"Jamaica",
	"México",
	"Nicaragua",
	"Panamá",
	"Puerto Rico",
	"Perú",
	"República Dominicana",
	"Trinidad y Tobago",
	"Uruguay",
	"Venezuela",
];

const featured = [
	{
		icon: "cube",
		title: "Variety of Affordable Products with High Perceived Value",
		description:
			"Each item is designed to provide high perceived value, ensuring you get the best value for your investment.",
	},
	{
		icon: "fire",
		title: "Consistent Availability of Innovations",
		description:
			"We constantly design product collections in various categories that adapt to different lifestyles and design trends. You will always find exciting proposals in our selection.",
	},
	{
		icon: "shopping-cart",
		title: "Simplify Purchasing with Comprehensive Guidance",
		description:
			"We facilitate your purchasing decisions by providing comprehensive advice. Our team will help you develop the perfect.",
	},
	{
		icon: "rocket",
		title: "Market Differentiation",
		description:
			"We stand out in the market due to our uniqueness. Our products shine with originality and quality, offering you a truly unique experience.",
	},
	{
		icon: "chart-bar",
		title: "Improved Inventory Turnover and Financial Enhancement Proposal",
		description:
			"Leverage our expertise in space management and logistics. With us, you can maximize inventory management efficiency, ensuring your products are always in motion and available when you need them the most.",
	},
	{
		icon: "star",
		title: "Quality and Distinctive Designs",
		description:
			"Achieve standing out and making a difference in the market through our attractive, innovative, and durable quality products, creating brand identity and offering your customers a unique experience.",
	},
	{
		icon: "user",
		title: "Point of Sale Support and Strategic Marketing Material",
		description:
			"We accompany you in the in-store support process to attract the consumer to the point of sale, through effective marketing strategies, training, and support material for social networks, through agreements for mutual benefit.",
	},
	{
		icon: "credit-card",
		title: "Credit Facilities",
		description:
			"We offer financing through a credit line, which can be granted based on the fulfillment of certain parameters agreed upon between the parties.",
	},
	{
		icon: "computer",
		title: "Cutting-edge Technology to Facilitate Purchases",
		description:
			"Access important tools through our website noritex.com, such as using the online shopping portal, downloading multimedia images and support material, and taking advantage of our training sessions. Don't miss the opportunity to navigate through our sales room via the 360 virtual tour.",
	},
];

export default {
	home: {
		seo: {
			title: "Empower your Private Brand with Noritex.",
			description:
				"At Noritex, we are leaders in the creation and development of brands, with over 40 years of experience. We are here to help you elevate your brand and maximize your profitability, saving you valuable time and resources.",
			images: [
				"https://www.noritex.com/uplds/2023/10/27/jqefePRbkQ4i8ui2KBanner-Home--(800x800).jpg",
			],
		},
		slider: {
			title: "Empower Your Private Brand with Noritex!",
			subtitle:
				"At Noritex, we are leaders in the creation and development of private brands, accumulating over 40 years of experience. We are here to help you elevate your brand and maximize your profitability, saving you valuable time and resources.",
			btn: "Develop your private brand",
		},
		featured,
		featured_btn:
			"See all benefits of developing your private brand with Noritex",
		section1: {
			title: "Elevate your brand <span>with Noritex</span>",
			paragraph:
				"We are experts in empowering private brands for over 40 years. Maximize your profitability, save valuable time and resources. Unlock a perfected product that reflects excellence and accumulated knowledge. We guide you to make your brand stand out with a unique identity, leveraging customized solutions and differentiated strategies. Join our family of satisfied customers and together build the success of your private brand with decades of knowledge.",
		},
		cat_heading: "<h2>Our areas of <span>specialization</span></h2>",
		categories,
		locations: {
			title: "Commercial presence in <span>Latin America and the Caribbean</span>",
			countries,
		},
		section2: {
			title: "Develop your <span>Private Brand</span>",
			paragraph:
				"We invite you on a journey to success, where your brand will shine with a unique identity. Our personalized solutions and differentiated strategies will help you achieve your goals. Join our family of satisfied customers and together build the success of your private brand with decades of knowledge.",
			btn: "Develop your private brand",
		},
		conoce_mas: "Learn more",
		section3:
			"<p>The minimum order for Private Labels = $50,000. <br /> Additionally, you must comply with filling a mixed 1 x 40STD container.</p>",
	},
	nosotros: {
		seo: {
			title: "Learn more about Noritex Private Brands",
			description:
				"Discover the excellence of Noritex S.A., leaders since 1954 in the Colon Free Zone. Specialized in private label brands that drive businesses, our portfolio offers carefully designed products for market trends. With over 60 annual collections, from home decor to school supplies, we inspire you to stand out in the market with quality and originality. Join us on this exciting journey towards success. The future awaits you at Noritex, where innovation and the latest trends are our essence. Explore our flagship brands, Concepts Life, Santini, Merletto, and Di Angelo, which are constantly refreshed at innovative fairs, offering more than 50,000 references. By partnering with us, you not only gain access to a diverse catalog but also ensure that your business is always up-to-date with the best in the industry. Elevate your business with Noritex and be part of constant evolution!",
		},
		title: "We are Noritex",
		content:
			"<p>Since 1954, at Noritex S.A., we have been leaders in the Colon Free Zone, setting trends and turning challenges into opportunities.</p><p>We specialize in creating private brands that drive businesses. Our portfolio offers trendsetting products, carefully designed to meet the needs of the current market.</p><p>Each year, we launch over 60 collections that include home decor, Christmas decorations, religious figures, as well as school and office supplies. We are here to inspire you, so your business stands out in the market and becomes the natural choice for those seeking quality, style, and originality. Join us on this exciting journey of success. The future awaits you at Noritex!</p>",
		banner: "Our products are sold throughout Latin America and the Caribbean for their fashion, current, and trendy references.",
		heading: "<h2>Variety and <span>Differentiation</span></h2>",
		paragraph1:
			"Noritex is globally recognized for its vast repertoire of products and its firm commitment to innovation. Our highly specialized team works tirelessly in the constant creation of new products. But what really sets us apart is our focus on staying up to date with the latest trends, which is reflected in the complete renewal of our brand catalog every quarter. At Noritex, transformation is our essence.",
		paragraph2:
			"Our flagship brands, Concepts Life, Santini, Merletto, and Di Angelo, organize innovative fairs each year that span over 60 collections and more than 50,000 references. This approach allows us to always be at the forefront, providing you with the ability to offer your customers a wide range of products inspired by the latest decorative trends. By working with us, you not only gain access to our diverse catalog but also ensure that your business is constantly updated with the best the industry has to offer.",
	},
	categorias: {
		seo: {
			title: "Discover Our Categories for Private Brands",
			description:
				"Experience over 40 years of enhancing private labels. Maximize your profitability and save time and resources with our expert approach. Unlock a perfected product that reflects excellence and accumulated knowledge. We will guide you to make your brand stand out with a unique identity, leveraging tailored solutions and differentiated strategies. Join our family of satisfied clients, and let's build the success of your private label together with decades of knowledge. Trust us to propel your brand to new heights!",
		},
		title: "Explore our <span>Categories</span>",
		categories,
		content:
			"<p>We are experts in empowering private brands for over 40 years. Maximize your profitability, save valuable time and resources. Unlock a perfected product that reflects excellence and accumulated knowledge. We guide you to make your brand stand out with a unique identity, leveraging customized solutions and differentiated strategies. Join our family of satisfied customers and together build the success of your private brand with decades of knowledge.</p>",
	},
	beneficios: {
		seo: {
			title: "Discover Our Benefits for Private Brands",
			description: "Explore the benefits of working with us.",
		},
		title: "Get these <span>Benefits</span>",
		featured,
	},
	presencia: {
		seo: {
			title: "Private Brands: Explore Our Commercial Presence",
			description:
				"Discover our commercial presence in Latin America and the Caribbean.",
		},
		title: "Commercial <span>Presence</span>",
		countries,
	},
	registrarse: {
		seo: {
			title: "Sign Up to Develop Your Private Brand",
			description:
				"Discover the benefits of working with us and sign up.",
		},
		title: "Partner with <span>Us</span>",
	},
	menu: {
		home: "Home",
		about: "About Us",
		categories: "Categories",
		benefits: "Benefits",
		presence: "Presence",
		signup: "Sign Up",
	},
};
