const categories = [
	{
		slug: "decoracion-del-hogar",
		thumb: "https://www.noritex.com/uplds/2023/10/27/hLhRonAjNdhzZzncaCocnepts-seccion-(700x500).jpg",
		hero: "https://www.noritex.com/uplds/2023/10/24/jDr2wT5GgzSARhRkJBanner-decoracion-hogar.jpg",
		gallery: [
			"https://www.noritex.com/uplds/2023/10/24/42GtEBn3FKADLFAeYdecohogar-collage02.jpg",
			"https://www.noritex.com/uplds/2023/10/24/YfMPXYP9Qj8E6FQMddecohogar-collage05.jpg",
			"https://www.noritex.com/uplds/2023/10/24/SfXT646GBbmo5EaMddecohogar-collage07.jpg",
			"https://www.noritex.com/uplds/2023/10/24/xJf8wArFXooEbdC9Qdecohogar-collage08.jpg",
			"https://www.noritex.com/uplds/2023/10/24/fEgx8FYYcP79eARqXdecohogar-collage03.jpg",
			"https://www.noritex.com/uplds/2023/10/24/yHeJRv4gaXzMcBpK3decohogar-collage06.jpg",
			"https://www.noritex.com/uplds/2023/10/24/BRCYn7ooN3BRdqQTrdecohogar-collage04.jpg",
			"https://www.noritex.com/uplds/2023/10/24/TTNfzEb4pxgkqvCHLdecohogar-collage09.jpg",
			"https://www.noritex.com/uplds/2023/10/24/RCzZNSucvcu79G33Kdecohogar-collage01.jpg",
		],
		title: "Decoração de Casa",
		description:
			"Transforme seus produtos de decoração de casa com nossa ampla gama de acessórios e itens que adicionam um toque único e especial a cada espaço. Da sala de estar à sala de jantar, nossos designs incorporam qualidade, estilo e tendência.",
		content:
			"<p>No emocionante mundo da decoração de casa, se destacar é essencial para atrair consumidores modernos em busca de qualidade e estilo. Na Noritex, compreendemos a importância de superar as expectativas e elevar a categoria de seus produtos de decoração.</p><p>Nossa ampla seleção de acessórios e itens é meticulosamente projetada para adicionar um toque único e especial a cada canto da casa de seus clientes, da sala de estar à sala de jantar. Nossos designs não apenas transmitem qualidade e estilo, mas também seguem de perto as últimas tendências de decoração.</p>",
	},
	{
		slug: "decoracion-de-navidad",
		thumb: "https://www.noritex.com/uplds/2023/10/27/cPqaozMCzSN4PZC2hSantini-seccion-(700x500).jpg",
		gallery: [
			"https://www.noritex.com/uplds/2023/10/27/DqsDJQaTZsbfL93g8navidad-collage04.jpg",
			"https://www.noritex.com/uplds/2023/10/27/824oReEGFkHPu2kADnavidad-collage02.jpg",
			"https://www.noritex.com/uplds/2023/10/27/sCS2usWMD4s5rknk9navidad-collage08.jpg",
			"https://www.noritex.com/uplds/2023/10/27/coGWox6YQBfQhXsTWnavidad-collage09.jpg",
			"https://www.noritex.com/uplds/2023/10/27/iDZcsBQoAwr6gAWp2navidad-collage05.jpg",
			"https://www.noritex.com/uplds/2023/10/27/gkqt7kf77itEgdtjXnavidad-collage03.jpg",
			"https://www.noritex.com/uplds/2023/10/27/3A6aRNzDoCptrJBSpnavidad-collage01.jpg",
			"https://www.noritex.com/uplds/2023/10/27/JkqesZd3RNdg8y9cknavidad-collage07.jpg",
			"https://www.noritex.com/uplds/2023/10/27/RWPak7i9uWmQstZjnnavidad-collage06.jpg",
		],
		hero: "https://www.noritex.com/uplds/2023/10/27/2BHfdZswkdPwsXwt5Banner---Decoracion-navidad-(1600x850).jpg",
		title: "Decoração de Natal",
		description:
			"Traga a magia do Natal para seus pontos de venda com a nossa decoração de Natal, que transformará suas ofertas em encantadoras experiências sazonais. Oferecemos uma variedade de produtos natalinos que atendem aos mais altos padrões de qualidade.",
		content:
			"<p>No encantador universo do Natal, a atmosfera e a magia são fundamentais para cativar os clientes e se diferenciar da concorrência. Na Noritex, somos especialistas em trazer essa magia para seus produtos de Natal e transformar suas ofertas em experiências cheias do charme característico da temporada.</p><p>Nossa ampla seleção de decoração de Natal é projetada para adicionar um toque excepcional a cada canto. Desde figuras tradicionais até as tendências mais contemporâneas, nossos produtos superam os mais rigorosos padrões de qualidade.</p><p>Imagine poder oferecer aos seus clientes uma variedade de produtos de Natal que não apenas atendam às suas expectativas, mas também permitam que eles mergulhem completamente na celebração natalina. Na Noritex, oferecemos a oportunidade de se destacar na decoração de Natal e oferecer aos seus clientes o melhor da temporada festiva.</p>",
	},
	{
		slug: "sportwear",
		thumb: "https://www.noritex.com/uplds/2023/10/27/kFrhjgxSAHa5Kn7zCSportwear-seccion-(700x500).jpg",
		gallery: [
			"https://www.noritex.com/uplds/2023/10/24/Ww9Ho9Hr86f2KeuKTsportwear-collage02.jpg",
			"https://www.noritex.com/uplds/2023/10/24/5Wtgrmgt8FWyenSGMsportwear-collage04.jpg",
			"https://www.noritex.com/uplds/2023/10/24/HSY9cYdRGqpeKaojbsportwear-collage08.jpg",
			"https://www.noritex.com/uplds/2023/10/24/W6LXYFAbftdcBbt56sportwear-collage09.jpg",
			"https://www.noritex.com/uplds/2023/10/24/v7X5xLY6qS5w8JkeXsportwear-collage03.jpg",
			"https://www.noritex.com/uplds/2023/10/24/EbqJjtv9GgikPD6TMsportwear-collage06.jpg",
			"https://www.noritex.com/uplds/2023/10/24/sCGQDMb86nnTaFdZYsportwear-collage05.jpg",
			"https://www.noritex.com/uplds/2023/10/24/aS7GehjHhLxSzKJSjsportwear-collage07.jpg",
			"https://www.noritex.com/uplds/2023/10/24/cHWYCNDbAgrYuvGi2sportwear-collage01.jpg",
		],
		hero: "https://www.noritex.com/uplds/2023/10/24/wqH9pYJKhJuepZPB7banner-sportwear.jpg",
		title: "Roupas Esportivas",
		description:
			"Nossas roupas esportivas oferecem qualidade e estilo a preços atrativos para o seu mercado. Estamos atentos às últimas tendências e projetamos produtos pensando na mulher moderna em busca de conforto e estilo.",
		content:
			"<p>No emocionante mundo das roupas esportivas, se destacar é essencial com a combinação perfeita de qualidade, estilo e acessibilidade para o seu mercado.</p><p>Nossos produtos são meticulosamente concebidos, pensando na mulher moderna que busca mais do que apenas roupas confortáveis; ela busca peças que reflitam seu estilo de vida ativo e enérgico.</p><p>Imagine poder oferecer aos seus clientes uma seleção de roupas esportivas que não apenas aprimorem seu desempenho na academia ou ao ar livre, mas também permitam que o façam com estilo e confiança.</p><p>Com a Noritex, você tem acesso a uma gama de produtos projetados para atender às demandas da mulher moderna e proporcionar a ela uma experiência de moda esportiva sem igual.</p>",
	},
	{
		slug: "accesorios-deportivos",
		thumb: "https://www.noritex.com/uplds/2023/10/27/Ech9zSKkJL74nKHtuseccion_Accesorios-(700x500).jpg",
		gallery: [
			"https://www.noritex.com/uplds/2023/10/27/Ch9WdJQkg8JjaPrtEaccesorios-deportivos-collage03.jpg",
			"https://www.noritex.com/uplds/2023/10/27/eWAhpwqZvQgGg6qmwaccesorios-deportivos-collage05.jpg",
			"https://www.noritex.com/uplds/2023/10/27/XjiZuqFbMYmp3hCiPaccesorios-deportivos-collage07.jpg",
			"https://www.noritex.com/uplds/2023/10/27/RKHarAwwfFQAuPQ9saccesorios-deportivos-collage08.jpg",
			"https://www.noritex.com/uplds/2023/10/27/wWy4kBuqQKrPx53N6accesorios-deportivos-collage01.jpg",
			"https://www.noritex.com/uplds/2023/10/27/Fqtjq8zjAexMuDYo5accesorios-deportivos-collage06.jpg",
			"https://www.noritex.com/uplds/2023/10/27/vt3dEzxxtpdChCQNfaccesorios-deportivos-collage04.jpg",
			"https://www.noritex.com/uplds/2023/10/27/ZTxvTT5hTm3xy6MyYaccesorios-deportivos-collage09.jpg",
			"https://www.noritex.com/uplds/2023/10/27/o6CpAyfHwN9hHTucLaccesorios-deportivos-collage02.jpg",
		],
		hero: "https://www.noritex.com/uplds/2023/10/27/MpqcoxF6WCAdgANXbBanner-collage-accesorios.jpg",
		title: "Acessórios Esportivos",
		description:
			"Combinamos estilo, beleza e funcionalidade em nossos acessórios esportivos, desde equipamentos funcionais até itens de yoga e recuperação. Nossas malas e bolsas são projetadas para tornar a vida mais fácil e confortável.",
		content:
			"<p>Nós unimos estilo, beleza e funcionalidade para oferecer uma experiência única. Desde equipamentos altamente funcionais até itens projetados especificamente para yoga e recuperação, cada elemento de nossa coleção é meticulosamente concebido.</p><p>Nossos acessórios são cuidadosamente projetados para se encaixar perfeitamente na vida ativa e moderna de seus clientes.</p><p>Imagine poder oferecer aos seus clientes uma seleção de acessórios esportivos que não apenas completem sua rotina de exercícios, mas também reflitam seu gosto pela moda e praticidade em cada detalhe.</p><p>Com a Noritex, você tem acesso a uma variedade de produtos projetados para tornar a vida ativa mais elegante e confortável. Se você está pronto para levar sua oferta de acessórios esportivos ao próximo nível, estamos aqui para ajudá-lo a alcançá-lo.</p>",
	},
	{
		slug: "utiles-escolares",
		thumb: "https://www.noritex.com/uplds/2023/10/27/6sdrvsLfLCGA8nZJqEscolar-seccion-(700x500).jpg",
		gallery: [
			"https://www.noritex.com/uplds/2023/10/24/m2P3r6iHhLizhEk2outiles_escolares-collage03.jpg",
			"https://www.noritex.com/uplds/2023/10/24/yPkw4suQhXWZdoHX9utiles_escolares-collage05.jpg",
			"https://www.noritex.com/uplds/2023/10/24/G6kR4mqiN3vncFESputiles_escolares-collage09.jpg",
			"https://www.noritex.com/uplds/2023/10/24/vRzJKgWmweEDPbHW6utiles_escolares-collage07.jpg",
			"https://www.noritex.com/uplds/2023/10/24/Jb6NEZPhvD2Sj8Z8Jutiles_escolares-collage02.jpg",
			"https://www.noritex.com/uplds/2023/10/24/g7qy5dCFPP56GKxoFutiles_escolares-collage06.jpg",
			"https://www.noritex.com/uplds/2023/10/24/jhWsi5mTJABPsPr8Tutiles_escolares-collage04.jpg",
			"https://www.noritex.com/uplds/2023/10/24/yWp6SjxmqjB9gxMJRutiles_escolares-collage08.jpg",
			"https://www.noritex.com/uplds/2023/10/24/ymoHhufHeK375wAWxutiles_escolares-collage01.jpg",
		],
		hero: "https://www.noritex.com/uplds/2023/10/24/iNkjxa38FAEvEpBCgbanner_utiles_escolares.jpg",
		title: "Material Escolar",
		description:
			"Oferecemos uma ampla gama de material escolar, incluindo mochilas e lancheiras projetadas para se tornarem as favoritas de pais e filhos. Nossa constante inovação garante que sempre tenhamos a solução certa para você.",
		content:
			"<p>Nossa ampla gama de material escolar inclui mochilas e lancheiras que se tornaram favoritas tanto de pais quanto de crianças. Nossa busca constante pela inovação garante que sempre tenhamos a solução certa para você.</p><p>Cada produto foi cuidadosamente projetado para ser funcional, durável e inspirador. Mantemos o foco nas tendências de moda para garantir que nossos produtos se destaquem na sala de aula e no recreio.</p><p>No Material Escolar, nosso objetivo é tornar o processo de preparação para a escola emocionante e sem complicações. Acreditamos que o material escolar pode ser uma fonte de alegria tanto para as crianças quanto para seus pais e professores. Explore nossa coleção e descubra como fazemos a diferença na vida escolar.</p>",
	},
	{
		slug: "oficina",
		thumb: "https://www.noritex.com/uplds/2023/10/27/Rqq8YaxpiF5eZhegfOficina-seccion-(700x500).jpg",
		gallery: [
			"https://www.noritex.com/uplds/2023/10/27/jujRk7Rx2KnDuxvfcoficina-collage01.jpg",
			"https://www.noritex.com/uplds/2023/10/27/6K2KEasvnKPYbCtFkoficina-collage06.jpg",
			"https://www.noritex.com/uplds/2023/10/27/HTyh6mJZjXdDYyYzvoficina-collage09.jpg",
			"https://www.noritex.com/uplds/2023/10/27/eurPaAJFxkmWuEx2Roficina-collage08.jpg",
			"https://www.noritex.com/uplds/2023/10/27/fzQW8jz8LYduoa9Lcoficina-collage03.jpg",
			"https://www.noritex.com/uplds/2023/10/27/MFZXLu9teeWhRGGE7oficina-collage04.jpg",
			"https://www.noritex.com/uplds/2023/10/27/ZduqpESw8cNRQM2RBoficina-collage05.jpg",
			"https://www.noritex.com/uplds/2023/10/27/rBwbLXqfsbkXhe74Coficina-collage07.jpg",
			"https://www.noritex.com/uplds/2023/10/27/CbMcKwTFSKEn5Wsxooficina-collage02.jpg",
		],
		hero: "https://www.noritex.com/uplds/2023/10/27/i8RL8btjPJ5wY6FCEoficina-Banner_Oficina.jpg",
		title: "Escritório",
		description:
			"Nossa oferta de escritório inclui uma variedade de produtos que ajudam a organizar e facilitar para os usuários. Nossas agendas com design elegante, criadas para a vida moderna, apresentam designs de moda que todas as mulheres e homens procuram.",
		content:
			"<p>Nossa oferta de produtos de escritório inclui agendas e organizadores com design elegante e funcional para atender às demandas da vida moderna. Também oferecemos uma variedade excepcional de canetas e lápis que combinam qualidade e praticidade para uma experiência de escrita única.</p><p>Nossas agendas e organizadores são projetados para as demandas da vida moderna, com propostas de moda que agradam tanto às mulheres quanto aos homens, amantes da produtividade e do planejamento.</p><p>Junte-se à nossa comunidade de clientes satisfeitos que valorizam a qualidade e o design em cada produto que oferecemos. Experimente a diferença que nossas agendas, organizadores, canetas e lápis podem fazer em sua vida diária, ajudando você a se manter no controle e se destacar com estilo.</p>",
	},
];

const countries = [
	"Antigua y Barbuda",
	"Aruba",
	"Bahamas",
	"Barbados",
	"Belice",
	"Bolivia",
	"Brasil",
	"Chile",
	"Colombia",
	"Costa Rica",
	"Curacao",
	"Dominica",
	"Ecuador",
	"El Salvador",
	"Granada",
	"Guatemala",
	"Guyana",
	"Honduras",
	"Jamaica",
	"México",
	"Nicaragua",
	"Panamá",
	"Puerto Rico",
	"Perú",
	"República Dominicana",
	"Trinidad y Tobago",
	"Uruguay",
	"Venezuela",
];

const featured = [
	{
		icon: "cube",
		title: "Variedade de Produtos Acessíveis com Alto Valor Percebido",
		description:
			"Cada item é projetado para fornecer alto valor percebido, garantindo que você obtenha o melhor valor pelo seu investimento.",
	},
	{
		icon: "fire",
		title: "Disponibilidade Consistente de Novidades",
		description:
			"Constantemente projetamos coleções de produtos em diversas categorias que se adaptam a diferentes estilos de vida e tendências de design. Você sempre encontrará propostas emocionantes em nossa seleção.",
	},
	{
		icon: "shopping-cart",
		title: "Simplificar a Compra com Orientação Completa",
		description:
			"Facilitamos suas decisões de compra fornecendo orientação completa. Nossa equipe o ajudará a desenvolver os produtos perfeitos para o seu negócio.",
	},
	{
		icon: "rocket",
		title: "Diferenciação no Mercado",
		description:
			"Nos destacamos no mercado devido à nossa singularidade. Nossos produtos se destacam pela originalidade e qualidade, oferecendo uma experiência verdadeiramente única.",
	},
	{
		icon: "chart-bar",
		title: "Proposta de Melhoria na Rotatividade de Estoque e Aprimoramento Financeiro",
		description:
			"Aproveite nossa experiência em gerenciamento de espaço e logística. Conosco, você pode maximizar a eficiência na gestão de estoque, garantindo que seus produtos estejam sempre em movimento e disponíveis quando mais precisar.",
	},
	{
		icon: "star",
		title: "Qualidade e Designs Distintos",
		description:
			"Destaque-se e faça a diferença no mercado através dos nossos produtos atrativos, inovadores e de qualidade duradoura, criando identidade de marca e oferecendo aos seus clientes uma experiência única.",
	},
	{
		icon: "user",
		title: "Suporte no Ponto de Venda e Material Estratégico de Marketing",
		description:
			"Acompanhamos você no processo de suporte em lojas para atrair o consumidor ao ponto de venda, por meio de estratégias de marketing eficazes, treinamento e material de suporte para redes sociais, através de acordos para benefício mútuo.",
	},
	{
		icon: "credit-card",
		title: "Facilidades de Crédito",
		description:
			"Oferecemos financiamento por meio de uma linha de crédito, que pode ser concedida com base no cumprimento de certos parâmetros acordados entre as partes.",
	},
	{
		icon: "computer",
		title: "Tecnologia de ponta para facilitar a compra",
		description:
			"Acesse ferramentas importantes através do nosso site noritex.com, como o uso do portal de compras online, faça o download de imagens multimídia e material de apoio e aproveite nossas capacitações. Não perca a oportunidade de navegar pela nossa sala de vendas através do tour virtual 360.",
	},
];

export default {
	home: {
		seo: {
			title: "Potencialize sua Marca Própria com a Noritex.",
			description:
				"Na Noritex, somos líderes na criação e desenvolvimento de marcas próprias, acumulando mais de 40 anos de experiência. Estamos aqui para ajudar você a elevar sua marca e maximizar sua rentabilidade, economizando tempo e recursos valiosos.",
			images: [
				"https://www.noritex.com/uplds/2023/10/27/jqefePRbkQ4i8ui2KBanner-Home--(800x800).jpg",
			],
		},
		slider: {
			title: "Potencialize Sua Marca Privada com a Noritex!",
			subtitle:
				"Na Noritex, somos líderes na criação e desenvolvimento de marcas privadas, acumulando mais de 40 anos de experiência. Estamos aqui para ajudá-lo a elevar sua marca e maximizar sua lucratividade, economizando tempo e recursos valiosos.",
			btn: "Desenvolva sua marca privada",
		},
		featured,
		featured_btn: "Ver todos os benefícios",
		section1: {
			title: "Eleve sua marca <span>com a Noritex</span>",
			paragraph:
				"Somos especialistas em potencializar marcas privadas há mais de 40 anos. Maximize sua lucratividade, economize tempo e recursos valiosos. Desbloqueie um produto aperfeiçoado que reflete excelência e conhecimento acumulado. Orientamos você a destacar sua marca com identidade única, aproveitando soluções personalizadas e estratégias diferenciadas. Junte-se à nossa família de clientes satisfeitos e construa juntos o sucesso de sua marca privada com décadas de conhecimento.",
		},
		cat_heading: "<h2>Nossas áreas de <span>especialização</span></h2>",
		categories,
		locations: {
			title: "Presença comercial na <span>América Latina e no Caribe</span>",
			countries,
		},
		section2: {
			title: "Desenvolva sua <span>Marca Privada</span>",
			paragraph:
				"Convidamos você para uma jornada de sucesso, onde sua marca se destacará com identidade única. Nossas soluções personalizadas e estratégias diferenciadas o ajudarão a atingir seus objetivos. Junte-se à nossa família de clientes satisfeitos e construa juntos o sucesso de sua marca privada com décadas de conhecimento.",
			btn: "Desenvolva sua marca privada",
		},
		conoce_mas: "Saiba mais",
		section3:
			"<p>O pedido mínimo para Marcas Próprias = $50.000. <br /> Além disso, é necessário cumprir o preenchimento de um contêiner 1 x 40STD sortido.</p>",
	},
	nosotros: {
		seo: {
			title: "Saiba mais sobre a Noritex",
			description:
				"Descubra a excelência da Noritex S.A., líderes desde 1954 na Zona Livre de Colón. Especializados em marcas privadas que impulsionam negócios, nosso portfólio oferece produtos cuidadosamente projetados para as tendências do mercado. Com mais de 60 coleções anuais, desde decoração para o lar até artigos escolares, inspiramos você a se destacar no mercado com qualidade e originalidade. Junte-se a nós nesta emocionante jornada rumo ao sucesso. O futuro espera por você na Noritex, onde a inovação e as últimas tendências são nossa essência. Descubra nossas marcas principais, Concepts Life, Santini, Merletto e Di Angelo, que são constantemente renovadas em feiras inovadoras, oferecendo mais de 50.000 referências. Ao se associar a nós, você não apenas tem acesso a um catálogo diversificado, mas também garante que seu negócio esteja sempre atualizado com o melhor da indústria. Eleve seu negócio com a Noritex e faça parte da evolução constante!",
		},
		title: "Nós somos a Noritex",
		content:
			"<p>Desde 1954, na Noritex S.A., somos líderes na Zona Livre de Colón, definindo tendências e transformando desafios em oportunidades.</p><p>Especializamo-nos em criar marcas privadas que impulsionam negócios. Nosso portfólio oferece produtos inovadores, cuidadosamente projetados para atender às necessidades do mercado atual.</p><p>A cada ano, lançamos mais de 60 coleções que incluem decoração de casa, enfeites de Natal, figuras religiosas, bem como material escolar e de escritório. Estamos aqui para inspirá-lo, para que seu negócio se destaque no mercado e se torne a escolha natural para aqueles que buscam qualidade, estilo e originalidade. Junte-se a nós nesta emocionante jornada de sucesso. O futuro o aguarda na Noritex!",
		banner: "Nossos produtos são vendidos em toda a América Latina e no Caribe por suas referências em moda, atualidade e tendência.",
		heading: "<h2>Variedade e <span>Diferenciação</span></h2>",
		paragraph1:
			"A Noritex é reconhecida globalmente por seu vasto repertório de produtos e por seu firme compromisso com a inovação. Nossa equipe altamente especializada trabalha incansavelmente na criação constante de novos produtos. Mas o que realmente nos destaca é nosso foco em manter-se atualizado com as últimas tendências, o que se reflete na renovação completa de nosso catálogo de marcas a cada trimestre. Na Noritex, a transformação é nossa essência.",
		paragraph2:
			"Nossas marcas de destaque, Concepts Life, Santini, Merletto e Di Angelo, organizam feiras inovadoras a cada ano que abrangem mais de 60 coleções e mais de 50.000 referências. Esse enfoque nos permite estar sempre na vanguarda, proporcionando a você a capacidade de oferecer a seus clientes uma ampla gama de produtos inspirados nas últimas tendências decorativas. Ao trabalhar conosco, você não apenas obtém acesso ao nosso catálogo diversificado, mas também garante que seu negócio esteja constantemente atualizado com o que a indústria tem de melhor a oferecer.",
	},
	categorias: {
		seo: {
			title: "Conheça Nossas Categorias",
			description:
				"Descubra a experiência de mais de 40 anos potencializando marcas privadas. Maximiza sua rentabilidade e economize tempo e recursos com nossa abordagem especializada. Desbloqueie um produto aperfeiçoado que reflete excelência e conhecimento acumulado. Orientaremos você para que sua marca se destaque com uma identidade única, aproveitando soluções personalizadas e estratégias diferenciadas. Junte-se à nossa família de clientes satisfeitos e construamos juntos o sucesso de sua marca privada com décadas de conhecimento. Confie em nós para impulsionar sua marca para novas alturas!",
		},
		title: "Conheça nossas <span>Categorias</span>",
		categories,
		content:
			"<p>Somos especialistas em potencializar marcas privadas há mais de 40 anos. Maximize sua lucratividade, economize tempo e recursos valiosos. Desbloqueie um produto aperfeiçoado que reflete excelência e conhecimento acumulado. Orientamos você a destacar sua marca com identidade única, aproveitando soluções personalizadas e estratégias diferenciadas. Junte-se à nossa família de clientes satisfeitos e construa juntos o sucesso de sua marca privada com décadas de conhecimento.",
	},
	beneficios: {
		seo: {
			title: "Conheça Nossos Benefícios",
			description: "Descubra os benefícios de trabalhar conosco.",
		},
		title: "Receba esses <span>Benefícios</span>",
		featured,
	},
	presencia: {
		seo: {
			title: "Explore Our Commercial Presence",
			description:
				"Discover our commercial presence in Latin America and the Caribbean.",
		},
		title: "Presença <span>Comercial</span>",
		countries,
	},
	registrarse: {
		seo: {
			title: "Comercialize Conosco",
			description: "Descubra os benefícios de trabalhar conosco.",
		},
		title: "Faça parceria conosco",
	},
	menu: {
		home: "Início",
		about: "Sobre nós",
		categories: "Categorias",
		benefits: "Benefícios",
		presence: "Presença",
		signup: "Registrar-se",
	},
};
