import React from "react";
import Helmet from "react-helmet";

function BGColor({ config }) {
    return (
        <Helmet>
            <body class={config && config !== "" ? config : "gray-bg"} />
        </Helmet>
    );
}

export default BGColor;
