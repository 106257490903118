import styled from "styled-components";
import { Container, Section } from "../../sdk/Layout";

import { Button } from "../../sdk/Button";
import { Columns, Column, Title, Heading } from "../../sdk/Layout";

export const SectionExtended = styled(Section)`
    background-color: ${props => props.theme.primary};
    background-image: url(${'../../map-bg.png?v2'});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 70px 0;
    ${Heading} {
        h1, h2, h3 {
            color: white;
            &:before {
                background-color: #fff;
            }
        }
    }
`;

export const ContainerExtended = styled(Container)`
    max-width: 1000px;
    @media (min-width: 769px) {
        padding: 100px 0;
    }
`;

export const Items = styled.div`
    display: flex;
    flex-wrap: wrap;
    @media (max-width: 540px) {
        /* flex-direction: column; */
    }
`;

export const Item = styled.div`
    flex-basis: 20%;
    flex-grow: 1;
    text-align: center;
    padding: 1%;
    margin-bottom: 30px;
    i {
        width: 80px;
        height: 80px;
        border-radius: 100%;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background-color: ${props => props.theme.blueLight};
        margin-bottom: 20px;
    }
    svg {
        width: 30px;
        height: 30px;
        object-fit: contain;
        color: ${props => props.theme.primaryDarkMode};
    }
    p {
        margin: 0;
        padding: 0;
        font-size: 15px;
        color: ${props => props.theme.primaryDarkMode};
        letter-spacing: 0;
        text-align: center;
        line-height: 21px;
        margin: auto;
        display: block;
        max-width: 300px;
    }
    a {
        display: inline-block;
        font-size: 16px;
        text-decoration: underline;
        margin-top: 20px;
        color: ${props => props.theme.primaryDark};
    }

    @media (max-width: 768px) {
        flex-basis: 50%;
        & ${Title} {
            font-size: 14px;
            margin-bottom: 10px;
        }
        p {
            max-width: 200px;
            font-size: 12px;
            line-height: 14px;
        }
        a {
            font-size: 14px;
            margin-top: 10px;
        }
    }
`;


export const Text = styled(Column)`
    > div {
        padding-top: 10%;
        text-align: right;
        color: white;
        h4 {
            font-size: 25px;
            font-weight: normal;
        }
        h3, h2 {
            font-size: 30px;
        }
        h2 {
            color: ${props => props.theme.primary};
            background-color: #fff;
            display: inline-block;
            padding: 5px 10px;
            margin-top: 10px;
        }
    }

    @media (min-width: 769px) {
        padding-right: 40px;
    }
    
    @media (max-width: 768px) {
        text-align: center;
        > div {
            padding-top: 20px;
            text-align: left;
            display: inline-block;
        }
    }
`;

export const Map = styled(Column)`
    padding-right: 20px;
    padding-left: 20px;
    svg {
        max-width: 100%;
    }
    @media (max-width: 768px) {
        svg {
            width: auto;
            height: auto;
            margin: auto;
            display: block;
        }
    }
`;