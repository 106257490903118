import React from "react";
import { Helmet } from "react-helmet";
import ProductsSectionTemplate from "../../templates/ProductsSectionTemplate";
import withTracker from "../../../blueberry/connectPromise";
import Icons from "./components/Icons";
import SEOFieldsHelmet from "../../utils/SEOFieldsHelmet";
import { scrollElementIntoView } from "../../utils/";
import LeadForm from "../../components/LeadForm/index";
import { Layout, Spacer } from "./style";
import { Columns, Column } from "../../sdk/Layout";
import { Modal } from "../../sdk/Elements";
import ConsentManager from "../../components/Footer2/components/ConsentManager";
import lang_es from "./lang/lang_es";
import lang_en from "./lang/lang_en";
import lang_pt from "./lang/lang_pt";

function getLang(lang) {
    switch (lang) {
        case "es":
            return lang_es;
        case "en":
            return lang_en;
        case "pt":
            return lang_pt;
    }
}

// const { lang } = this.props.match.params;

const configLeadForm = [{ formKey: "rzQFD47w5xMwxvWk3" }];
const configLeadForm2 = [{ formKey: "8s6i7jXbCTHrfcYxa" }];
const configLeadForm3 = [{ formKey: "9mPqvKd65hHpGMtwR" }];

class Page extends ProductsSectionTemplate {

    state = {modal: false};

    renderHeader = () => null;
    renderFooter = () => null;

    handleAnchor = (e) => {
        const section = e.target.getAttribute("data-scrollto");
        scrollElementIntoView(document.getElementById(section), "smooth");
    };

    getTreatment = (slug) => {
        const treatmentsA = {
            "feria-conceptslife-223kf": 1,
            "feria-conceptslife-223cr": 2,
            "feria223-noritex": 2,
            "feria-conceptslife-223nf": 3,
        };

        return treatmentsA[slug];
    };

    openModal = () => {
        this.setState({modal: true})
    }
    closeModal = () => {
        this.setState({modal: false})
    }

    gotoThankYouPage = () => {
        const { lang, slug } = this.props.match.params;
        const treatment = this.getTreatment(slug);
        this.props.history.push(`/${lang}/gracias-por-registrarse/${treatment}`);

        // switch (lang) {
        //     case "es":
        //         this.props.history.push(
        //             `/es/gracias-por-registrarse/${treatment}`
        //         );
        //         break;

        //     case "en":
        //         this.props.history.push(
        //             `/en/thanks-for-registering/${treatment}`
        //         );
        //         break;

        //     case "pt":
        //         this.props.history.push(
        //             `/pt/obrigado-por-se-registrar/${treatment}`
        //         );
        //         break;
        // }
    };

    renderBody() {
        const { lang, slug } = this.props.match.params;
        const langJson = getLang(lang);
        // const { lang, menuIndex } = this.props.match.params;
        // const { jsonSettings, history } = this.props;
        // const { user, menus } = this.props.store;

        const treatment = this.getTreatment(slug);

        return (
            <>
                <Layout>
                    <SEOFieldsHelmet
                        seo={null}
                        // lang={lang}
                        defaultTitle={langJson.hero.title.replace('<br />', '')}
                        defaultDescription={langJson.hero.sub}
                        bodyClassName="reset-body back-top-btn-hidden"
                    />
                    <Helmet>
                        <meta name="googlebot" content="noindex" />
                        <meta name="robots" content="noindex" />
                    </Helmet>
                    <header className="header" id="permanentHeader">
                        <div>
                            <img
                                src="https://www.conceptslife.com/wp-content/uploads/2020/05/logo_concepts-life_home.png"
                                alt="Logo Concepts"
                                className="logo"
                            />
                        </div>
                        <div>
                            <nav className="nav">
                                <ul>
                                    <li>
                                        <a
                                            data-scrollto="who"
                                            onClick={this.handleAnchor}
                                        >
                                            {langJson.menu.item1}
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            data-scrollto="faqs"
                                            onClick={this.handleAnchor}
                                        >
                                            {langJson.menu.item2}
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            data-scrollto="form"
                                            onClick={this.handleAnchor}
                                            className="button primary"
                                        >
                                            {langJson.menu.item3}
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </header>

                    <div
                        className="hero bg-img"
                        data-lang={lang}
                        style={{
                            backgroundImage:
                                'url("https://www.conceptslife.com/wp-content/uploads/2022/03/cabecero.jpeg")',
                        }}
                    >
                        <div className="wrap">
                            <Columns>
                                <Column>
                                    <h2>{langJson.hero.pre}</h2>
                                    <h1 dangerouslySetInnerHTML={{__html: langJson.hero.title}} />
                                    <h3>{langJson.hero.sub}</h3>
                                    <Spacer height="50" className="spacer"/>
                                    <div className="buttons">
                                        <a
                                            className="button big rounded primary"
                                            data-scrollto="form"
                                            onClick={this.handleAnchor}
                                        >
                                            {langJson.hero.btn}
                                        </a>
                                    </div>
                                </Column>
                                <Column className="col-video">
                                    <div className="video-cover" onClick={this.openModal}>
                                        <div className="play-btn">
                                            <Icons icon="play" />
                                        </div>
                                        <img src="https://www.noritex.com/uplds/2022/5/15/3gkTBAurDkDkMkDPGbanner-invitacion-feria-de-decoracion-hogar-conceptslife.jpeg" alt="Feria de Decoración Hogar Concepts Life" />
                                    </div>
                                    {this.state.modal && (
                                        <Modal className="is-active video-modal">
                                            <div>
                                                <div className="video">
                                                    <iframe src="https://www.youtube.com/embed/2PLFg18Cf6Q" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                                </div>
                                            </div>
                                            <button onClick={this.closeModal}></button>
                                            <span></span>
                                        </Modal>  
                                    )}
                                </Column>
                            </Columns>
                        </div>

                        <div className="shape bottom">
                            <svg
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 1000 100"
                                preserveAspectRatio="none"
                            >
                                <path
                                    className="elementor-shape-fill"
                                    d="M761.9,40.6L643.1,24L333.9,93.8L0.1,1H0v99h1000V1"
                                />
                            </svg>
                        </div>
                    </div>

                    <div className="stats">
                        <div className="wrap">
                            <ul>
                                {langJson.stats.map(item => (
                                    <li className="item" key={item.title}>
                                        <h3>{item.title}</h3>
                                        <p>{item.subtitle}</p>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className="shape bottom">
                            <svg
                                viewBox="0 0 1000 98"
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M238.1 53.9L356.9 70.8L666.2 0L1000 94.2V98H0V94.1"
                                    fill="currentColor"
                                />
                            </svg>
                        </div>
                    </div>

                    <div className="section bg-gray" id="who">
                        <div className="heading">
                            <h2>{langJson.who.heading}</h2>
                        </div>
                        <div className="wrap">
                            <div className="infocards">
                                {langJson.who.items.map(({title, content, image}) => (
                                    <div className="infocard" key={title}>
                                        <h3>{title}</h3>
                                        <p>{content}</p>
                                        <img
                                            src={image}
                                            alt=""
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    {treatment === 1 ? (
                        <div
                            id="form"
                            className="section bg-img form-section"
                            style={{
                                backgroundImage:
                                    'url("https://www.noritex.com/uplds/2022/4/17/ajBySjGXCEvNGQQtqbg-form-concepts.jpeg")',
                            }}
                        >
                            <LeadForm
                                config={configLeadForm}
                                formTitle={langJson.form.heading}
                                submitLabel={langJson.form.btn}
                                _t={this._t}
                                searchQuery={this.props.location.search}
                                onSuccess={this.gotoThankYouPage}
                                pathname={this.props.location.pathname}
                            />
                        </div>
                    ) : null}
                    <div id="faqs" className="section">
                        <div className="wrap wrap--800">
                            <div className="heading">
                                <h2>{langJson.faqs.heading}</h2>
                            </div>

                            <div className="questions">
                                {langJson.faqs.items.map(({title, content}) => (
                                    <details key={title}>
                                        <summary>{title}</summary>
                                        <div dangerouslySetInnerHTML={{__html: content}} />
                                    </details>
                                ))}
                            </div>
                        </div>
                    </div>

                    <div className="gallery">
                        {langJson.gallery.map(({title, image}) => (
                            <div
                                key={title}
                                className="item"
                                style={{backgroundImage:`url("${image}")`}}
                            >
                                <h4>{title}</h4>
                            </div>
                        ))}
                    </div>

                    {treatment === 2 ? (
                        <div
                            id="form"
                            className="section bg-img form-section"
                            style={{
                                backgroundImage:
                                    'url("https://www.conceptslife.com/wp-content/uploads/2022/03/Pie-de-pagina.jpeg")',
                            }}
                        >
                            <LeadForm
                                config={configLeadForm2}
                                formTitle={langJson.form.heading}
                                submitLabel={langJson.form.btn}
                                _t={this._t}
                                searchQuery={this.props.location.search}
                                onSuccess={this.gotoThankYouPage}
                                pathname={this.props.location.pathname}
                            />
                        </div>
                    ) : null}
                    
                    {treatment === 3 ? (
                        <div
                            id="form"
                            className="section bg-img form-section"
                            style={{
                                backgroundImage:
                                    'url("https://www.conceptslife.com/wp-content/uploads/2022/03/Pie-de-pagina.jpeg")',
                            }}
                        >
                            <LeadForm
                                config={configLeadForm3}
                                formTitle={langJson.form.heading}
                                submitLabel={langJson.form.btn}
                                _t={this._t}
                                searchQuery={this.props.location.search}
                                onSuccess={this.gotoThankYouPage}
                                pathname={this.props.location.pathname}
                            />
                        </div>
                    ) : null}

                    <footer className="footer">
                        <p>{langJson.footer}</p>
                        <Icons icon="logontx" className="brand" />
                        <ConsentManager lang={lang} bypasConsent={true} />
                    </footer> 
                </Layout>
            </>
        );
    }
}

export default withTracker()(Page);
