import React from "react";
import styled from "styled-components";

const SpacerComponent = styled.div`
    width: 100%;
    height: ${(props) => (props.height ? props.height : "30px")};
`;

function Spacer({ config }) {
    return <SpacerComponent config={config} />;
}

export default Spacer;
