import React, { Component } from "react";
import { Message } from "../../sdk/Elements";
// App component - represents the whole app
const ErrorMessage = (props) => {
    return (
        <Message
            success={props.sucess || props.success}
            error={!props.sucess}
            role="alert"
        >
            <span>Message:</span>
            &nbsp;{props.errorMessage}
        </Message>
    );
};

export default ErrorMessage;
