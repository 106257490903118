import React from "react";
import ProductsSectionTemplate from "../../templates/ProductsSectionTemplate";
import SEOFieldsHelmet from "../../utils/SEOFieldsHelmet";
import withTracker from "../../../blueberry/connectPromise";
import { Layout, ColumnsExtended, Left } from "./style";
import Icons from "./components/Icons";
import { Columns, Column } from "../../sdk/Layout";
import lang_es from "./lang/lang_es";
import lang_en from "./lang/lang_en";
import lang_pt from "./lang/lang_pt";
import LeadForm from "../../components/LeadForm/index";
import ConsentManager from "../../components/Footer2/components/ConsentManager";
import PrivateLabelBenefits from "./components/PrivateLabelBenefits";

function getLang(lang) {
    switch (lang) {
        case "es":
            return lang_es;
        case "en":
            return lang_en;
        case "pt":
            return lang_pt;
    }
}

const configLeadForm = [{ formKey: "WfhAreyqx692vCgoi" }];

class LandingPrivateLabel extends ProductsSectionTemplate {
    state = {};

    // renderHeader = () => null;
    // renderFooter = () => null;

    renderBody() {
        const { lang } = this.props.match.params;

        const {
            title,
            products,
            formTitle,
            privateLabelTitle,
            privateLabelDescription,
        } = getLang(lang);

        return (
            <>
                <PrivateLabelBenefits />
                <Layout>
                    <SEOFieldsHelmet
                        seo={null}
                        // lang={lang}
                        defaultDescription={privateLabelDescription}
                        defaultTitle={privateLabelTitle}
                        bodyClassName=" back-top-btn-hidden"
                    />

                    <div className="container">
                        <ColumnsExtended>
                            <Left
                                style={{
                                    backgroundImage:
                                        "url('https://www.noritex.com/uplds/2022/4/27/pmWT3znsTyo45jt9bCapadocia.jpeg')",
                                }}
                            >
                                <div className="inner">
                                    <h1>{title}</h1>
                                    <ul>
                                        {products.map((item) => (
                                            <li key={item}>{item}</li>
                                        ))}
                                    </ul>
                                </div>
                            </Left>

                            <Column>
                                <div className="form">
                                    <LeadForm
                                        config={configLeadForm}
                                        formTitle={formTitle}
                                        // submitLabel={langJson.form.btn}
                                        _t={this._t}
                                        searchQuery={this.props.location.search}
                                        onSuccess={this.gotoThankYouPage}
                                        pathname={this.props.location.pathname}
                                    />
                                </div>
                            </Column>
                        </ColumnsExtended>
                    </div>
                    <ConsentManager lang={lang} bypasConsent={true} />
                </Layout>
            </>
        );
    }
}

export default withTracker()(LandingPrivateLabel);
