import React, { useState } from "react";
import styled from "styled-components";
import {
    Container,
    Columns,
    Column,
    Title,
    Section,
    Heading
} from "../../../sdk/Layout";

const ColumnsExtended = styled(Columns)`
  align-items: center;
`;

const TabItems = styled.ul`
    display: block;
    width: 400px;
    li {
        font-size: 16px;
        padding: 20px 0 20px 10px;
        position: relative;
        &:not(.is-active) {
            cursor: pointer;
        }
        &:before {
            display: block;
            height: 20px;
            width: 3px;
            background-color: ${props => props.theme.primary};
            position: absolute;
            left: 0;
            top: 22px;
        }
        &.is-active {
            font-size: 18px;
            font-weight: bold;
            color: ${props => props.theme.primary};
            &:before {
                content: '';
            }
        }
    }
  @media (max-width: 768px) {
    width: auto;
    li {
        padding: 6px 0 6px 10px;
        font-size: 14px !important;
        &:before {
            top: 5px;
        }
    }
  }
`;


const TabContent = styled.figure`
    padding-left: 5%;
    figure {
        position: relative;
        border-radius: 10px;
        box-shadow: 50px 50px 25px -41px rgba(0, 0, 0, 0.05);
        overflow: hidden;
        display: block;
        border: solid 1px ${props => props.theme.primary};
        &:before {
            content: '';
            display: block;
            padding-top: 60%;
        }
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    @media (max-width: 768px) {
        padding-left: 0;
    }
`;

function Features({ langJson }) {

    const [currentScreen, setCurrentScreen] = useState({
        index: 0,
        image: langJson.features[0].image
    })

    const handleCurrentScreen = (i) => {
        return (
            setCurrentScreen({
                index: i,
                image: langJson.features[i].image
            })
        )
    }

    return (
        <Section>
            <Container>
                <Heading isCentered>
                    <h3>{langJson.featuresTitle}</h3>
                </Heading>
                <ColumnsExtended>
                    <Column isNarrow>
                        <TabItems>
                            {langJson.features.map((index, i) => (
                                <li 
                                    className={currentScreen.index == i && 'is-active'}
                                    onClick={() => handleCurrentScreen(i)}>{index.title}</li>
                            ))}
                        </TabItems>
                    </Column>
                    <Column>
                        <TabContent>
                            <figure>
                                <img src={currentScreen.image} alt="" />
                            </figure>
                        </TabContent>
                    </Column>
                </ColumnsExtended>
            </Container>
        </Section>
    );
}

export default Features;
