import React from "react";
import styled from "styled-components";
import { Container } from "../../../sdk/Layout";
import { scrollElementIntoView } from "../../../utils";

const HeroSection = styled.div`
    background-color: ${(props) => props.theme.primary};
    text-align: center;
    color: white;
    position: relative;
    min-height: 450px;
    margin-bottom: 100px;
    display: flex;
    align-items: center;
    padding-bottom: 100px;
    .text {
        width: 95%;
        max-width: 650px;
        margin: auto;
        h1 {
            font-size: 40px;
            z-index: 2;
            position: relative;
        }
        h2 {
            margin-bottom: 10px;
            font-size: 18px;
            font-weight: normal;
            z-index: 2;
            position: relative;
        }
    }
    .images {
        display: flex;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        opacity: 0.2;
        img {
            width: 50%;
            height: 100%;
            object-fit: cover;
        }
    }
    ${Container} {
        position: static;
    }

    @media (max-width: 768px) {
        padding-bottom: 70px;
        min-height: 350px;
        .text {
            h1 {
                font-size: 28px;
            }
        }
    }
`;

const InputWrapper = styled.div`
    position: absolute;
    bottom: -45px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    width: 95%;
    max-width: 500px;
    margin: auto;
    h3 {
        font-weight: normal;
        font-size: 20px;
        margin-bottom: 20px;
    }
    @media (max-width: 768px) {
        h3 {
            font-size: 14px;
            margin-bottom: 10px;
        }
    }
`;

const Input = styled.div`
    background-color: ${(props) => props.theme.primary};
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    padding: 20px;
    width: 100%;
    display: flex;
    input {
        border: none;
        height: 50px;
        width: 100%;
        background-color: rgba(255, 255, 255, 0.1);
        padding: 5px 10px;
        color: white;
    }
`;

const Btn = styled.div`
    flex: none;
    height: 50px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border: none;
    background-color: #fff;
    margin-left: 10px;
    padding: 0px 20px;
    cursor: pointer;
    opacity: 0.9;
    color: ${(props) => props.theme.primary};
    &:hover {
        opacity: 1;
    }
`;

function Hero({ email, setValue, langJson }) {
    const handleScrollIntoForm = () => {
        console.log(document.getElementById("form-for-brands"));
        scrollElementIntoView(document.getElementById("form-for-brands"));
    };
    return (
        <HeroSection>
            <Container>
                <div className="images">
                    <img
                        src="https://www.noritex.com/uplds/2021/6/28/39i2YMM9N3CYxezXrdesk-house_(1).jpeg"
                        alt=""
                    />
                    <img
                        src="https://www.noritex.com/uplds/2021/6/28/Z93Hn87q6ByBfETYDdesk-home_(1).jpg"
                        alt=""
                    />
                </div>
                <div className="text">
                    <h2>{langJson.title}</h2>
                    <h1>{langJson.subTitle}</h1>
                </div>

                <InputWrapper>
                    <h3>{langJson.cta}</h3>
                    <Input>
                        <input
                            type="text"
                            value={email.value}
                            onChange={setValue}
                            name="email"
                            placeholder="Tu correo electrónico"
                        />
                        <Btn onClick={handleScrollIntoForm}>
                            {langJson.ctaButton}
                        </Btn>
                    </Input>
                </InputWrapper>
            </Container>
        </HeroSection>
    );
}

export default Hero;
