import React from "react";

function Icons({ icon, className }) {
    if (icon === "whatsapp") {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={28}
                height="28.229"
                viewBox="0 0 28 28.229"
            >
                <g transform="translate(-1 -1)">
                    <path
                        d="M9.124,26.344l.469.234A12.242,12.242,0,0,0,15.92,28.3h0A12.483,12.483,0,0,0,24.747,6.971,12.429,12.429,0,0,0,3.5,15.876a12.811,12.811,0,0,0,1.875,6.562l.312.469-1.25,4.609Z"
                        transform="translate(-0.592 -0.545)"
                        fill="#00e676"
                    />
                    <path
                        d="M24.956,5.12A13.735,13.735,0,0,0,15.114,1,13.936,13.936,0,0,0,1.153,15.038a14.732,14.732,0,0,0,1.831,6.943L1,29.229l7.4-1.907A13.623,13.623,0,0,0,15.038,29h0A14.034,14.034,0,0,0,29,14.962,13.846,13.846,0,0,0,24.956,5.12ZM15.114,26.635h0a11.335,11.335,0,0,1-5.875-1.6L8.782,24.8,4.357,25.948,5.5,21.6,5.2,21.142A11.581,11.581,0,1,1,21.142,24.88,11.123,11.123,0,0,1,15.114,26.635Zm6.714-8.469-.839-.381s-1.221-.534-1.984-.916c-.076,0-.153-.076-.229-.076a1.1,1.1,0,0,0-.534.153h0s-.076.076-1.144,1.3a.42.42,0,0,1-.381.229H16.64a.578.578,0,0,1-.305-.153l-.381-.153h0a7.417,7.417,0,0,1-2.213-1.45c-.153-.153-.381-.305-.534-.458a8.521,8.521,0,0,1-1.45-1.831l-.076-.153c-.076-.076-.076-.153-.153-.305a.572.572,0,0,1,.076-.381s.305-.381.534-.61c.153-.153.229-.381.381-.534a.94.94,0,0,0,.153-.763,29.7,29.7,0,0,0-1.221-2.9.883.883,0,0,0-.534-.381h-.839c-.153,0-.305.076-.458.076l-.076.076c-.153.076-.305.229-.458.305-.153.153-.229.305-.381.458a3.891,3.891,0,0,0-.839,2.365h0a4.526,4.526,0,0,0,.381,1.755l.076.229a12.71,12.71,0,0,0,2.823,3.891l.305.305a4.6,4.6,0,0,1,.61.61,13.633,13.633,0,0,0,5.493,2.9c.229.076.534.076.763.153h.763a2.829,2.829,0,0,0,1.144-.305c.229-.153.381-.153.534-.305l.153-.153c.153-.153.305-.229.458-.381a2.023,2.023,0,0,0,.381-.458,4.373,4.373,0,0,0,.305-1.068v-.534A.944.944,0,0,0,21.828,18.166Z"
                        transform="translate(0 0)"
                        fill="#fff"
                    />
                </g>
            </svg>
        );
    }
    if (icon === "calendly") {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28.666"
                height={30}
                viewBox="0 0 28.666 30"
            >
                <path
                    d="M32.9,44.074H10.57A3.175,3.175,0,0,1,7.4,40.9V18.57a3.175,3.175,0,0,1,3.17-3.17H32.9a3.175,3.175,0,0,1,3.17,3.17V40.9A3.166,3.166,0,0,1,32.9,44.074ZM10.57,17.156A1.413,1.413,0,0,0,9.156,18.57V40.9A1.413,1.413,0,0,0,10.57,42.31H32.9A1.413,1.413,0,0,0,34.31,40.9V18.57A1.413,1.413,0,0,0,32.9,17.156Z"
                    transform="translate(-7.4 -14.074)"
                    fill="#09a4ff"
                />
                <path
                    d="M107.82,6.235a.7.7,0,0,1-.7-.7V.7a.7.7,0,0,1,1.4,0V5.529a.7.7,0,0,1-.7.706Zm-13.322,0a.7.7,0,0,1-.7-.7V.7a.7.7,0,1,1,1.4,0V5.529a.7.7,0,0,1-.7.706Zm7.535,15.621a5.244,5.244,0,1,1,3.522-9.128.286.286,0,1,1-.388.422,4.667,4.667,0,1,0,0,6.906.286.286,0,1,1,.388.422A5.149,5.149,0,0,1,102.033,21.856Z"
                    transform="translate(-86.837 0)"
                    fill="#09a4ff"
                />
            </svg>
        );
    }
    if (icon === "user-tie") {
        return (
            <svg
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
                width="{20}"
                height="22.857"
                viewBox="0 0 20 22.857"
            >
                <path d="M10,11.429A5.714,5.714,0,1,0,4.286,5.714,5.714,5.714,0,0,0,10,11.429Zm4.277,1.455-2.134,8.545-1.429-6.071,1.429-2.5H7.857l1.429,2.5L7.857,21.429,5.723,12.884A5.99,5.99,0,0,0,0,18.857v1.857a2.143,2.143,0,0,0,2.143,2.143H17.857A2.143,2.143,0,0,0,20,20.714V18.857a5.99,5.99,0,0,0-5.723-5.973Z" />
            </svg>
        );
    }
    if (icon === "globe") {
        return (
            <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="globe"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 496 512"
            >
                <path
                    fill="currentColor"
                    d="M336.5 160C322 70.7 287.8 8 248 8s-74 62.7-88.5 152h177zM152 256c0 22.2 1.2 43.5 3.3 64h185.3c2.1-20.5 3.3-41.8 3.3-64s-1.2-43.5-3.3-64H155.3c-2.1 20.5-3.3 41.8-3.3 64zm324.7-96c-28.6-67.9-86.5-120.4-158-141.6 24.4 33.8 41.2 84.7 50 141.6h108zM177.2 18.4C105.8 39.6 47.8 92.1 19.3 160h108c8.7-56.9 25.5-107.8 49.9-141.6zM487.4 192H372.7c2.1 21 3.3 42.5 3.3 64s-1.2 43-3.3 64h114.6c5.5-20.5 8.6-41.8 8.6-64s-3.1-43.5-8.5-64zM120 256c0-21.5 1.2-43 3.3-64H8.6C3.2 212.5 0 233.8 0 256s3.2 43.5 8.6 64h114.6c-2-21-3.2-42.5-3.2-64zm39.5 96c14.5 89.3 48.7 152 88.5 152s74-62.7 88.5-152h-177zm159.3 141.6c71.4-21.2 129.4-73.7 158-141.6h-108c-8.8 56.9-25.6 107.8-50 141.6zM19.3 352c28.6 67.9 86.5 120.4 158 141.6-24.4-33.8-41.2-84.7-50-141.6h-108z"
                />
            </svg>
        );
    }

    if (icon === "lamp") {
        return (
            <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="lamp"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
            >
                <path
                    fill="currentColor"
                    d="M445.5 211.4L368 19.4C363.3 7.6 352.4 0 340.4 0H120.6c-11.4 0-21.8 6.8-27 17.7l-90.4 192c-10 21.3 4.6 46.3 27 46.3h387.7c21.6 0 36.2-23.5 27.6-44.6zM158.3 288C121 325.2 96 386.8 96 428.8c0 28.4 11.5 54.3 30.5 74.3 5.7 6 13.9 8.9 22.2 8.9h150.6c8.3 0 16.5-2.9 22.2-8.9 19-20.1 30.5-45.9 30.5-74.3 0-42-25-103.6-62.3-140.8H158.3z"
                />
            </svg>
        );
    }
    if (icon === "warehouse") {
        return (
            <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="warehouse-alt"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 640 512"
            >
                <path
                    fill="currentColor"
                    d="M610.5 117L338.4 3.7a48.15 48.15 0 0 0-36.9 0L29.5 117C11.7 124.5 0 141.9 0 161.3V504c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V208c0-8.8 7.2-16 16-16h480c8.8 0 16 7.2 16 16v296c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V161.3c0-19.4-11.7-36.8-29.5-44.3zM304 416H144c-8.8 0-16 7.2-16 16v64c0 8.8 7.2 16 16 16h160c8.8 0 16-7.2 16-16v-64c0-8.8-7.2-16-16-16zm192 0H368c-8.8 0-16 7.2-16 16v64c0 8.8 7.2 16 16 16h128c8.8 0 16-7.2 16-16v-64c0-8.8-7.2-16-16-16zM304 288H144c-8.8 0-16 7.2-16 16v64c0 8.8 7.2 16 16 16h160c8.8 0 16-7.2 16-16v-64c0-8.8-7.2-16-16-16z"
                />
            </svg>
        );
    }

    if (icon === "warehouse-alt") {
        return (
            <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="warehouse"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 640 512"
            >
                <path
                    fill="currentColor"
                    d="M504 352H136.4c-4.4 0-8 3.6-8 8l-.1 48c0 4.4 3.6 8 8 8H504c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8zm0 96H136.1c-4.4 0-8 3.6-8 8l-.1 48c0 4.4 3.6 8 8 8h368c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8zm0-192H136.6c-4.4 0-8 3.6-8 8l-.1 48c0 4.4 3.6 8 8 8H504c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8zm106.5-139L338.4 3.7a48.15 48.15 0 0 0-36.9 0L29.5 117C11.7 124.5 0 141.9 0 161.3V504c0 4.4 3.6 8 8 8h80c4.4 0 8-3.6 8-8V256c0-17.6 14.6-32 32.6-32h382.8c18 0 32.6 14.4 32.6 32v248c0 4.4 3.6 8 8 8h80c4.4 0 8-3.6 8-8V161.3c0-19.4-11.7-36.8-29.5-44.3z"
                />
            </svg>
        );
    }

    if (icon === "envelope") {
        return (
            <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="envelope"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
            >
                <path
                    fill="currentColor"
                    d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z"
                />
            </svg>
        );
    }
    if (icon === "phone") {
        return (
            <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="phone-alt"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
            >
                <path
                    fill="currentColor"
                    d="M497.39 361.8l-112-48a24 24 0 0 0-28 6.9l-49.6 60.6A370.66 370.66 0 0 1 130.6 204.11l60.6-49.6a23.94 23.94 0 0 0 6.9-28l-48-112A24.16 24.16 0 0 0 122.6.61l-104 24A24 24 0 0 0 0 48c0 256.5 207.9 464 464 464a24 24 0 0 0 23.4-18.6l24-104a24.29 24.29 0 0 0-14.01-27.6z"
                />
            </svg>
        );
    }
    if (icon === "play") {
        return (
            <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="far"
                data-icon="play-circle"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
            >
                <path
                    fill="currentColor"
                    d="M371.7 238l-176-107c-15.8-8.8-35.7 2.5-35.7 21v208c0 18.4 19.8 29.8 35.7 21l176-101c16.4-9.1 16.4-32.8 0-42zM504 256C504 119 393 8 256 8S8 119 8 256s111 248 248 248 248-111 248-248zm-448 0c0-110.5 89.5-200 200-200s200 89.5 200 200-89.5 200-200 200S56 366.5 56 256z"
                />
            </svg>
        );
    }
    if (icon === "truck-container") {
        return (
            <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="truck-container"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 640 512"
            >
                <path
                    fill="currentColor"
                    d="M621.3 237.3l-58.5-58.5c-12-12-28.3-18.7-45.3-18.7H464c-17.7 0-32 14.3-32 32v144H32c-17.7 0-32 14.3-32 32v27.8c0 40.8 28.7 78.1 69.1 83.5 30.7 4.1 58.3-9.5 74.9-31.7 18.4 24.7 50.4 38.7 85.3 29.7 25.2-6.5 46.1-26.2 54.4-50.8 4.9-14.8 5.4-29.2 2.8-42.4h163.2c-2.7 13.2-2.2 27.6 2.8 42.4 8.4 25.1 29.9 44.9 55.6 51.1 52.8 12.8 100-26.9 100-77.6 0-5.5-.6-10.8-1.6-16H624c8.8 0 16-7.2 16-16v-85.5c0-17.1-6.7-33.3-18.7-45.3zM80 432c-17.6 0-32-14.4-32-32s14.4-32 32-32 32 14.4 32 32-14.4 32-32 32zm128 0c-17.6 0-32-14.4-32-32s14.4-32 32-32 32 14.4 32 32-14.4 32-32 32zm320 0c-17.6 0-32-14.4-32-32s14.4-32 32-32 32 14.4 32 32-14.4 32-32 32zm-48-176v-48h37.5c4.2 0 8.3 1.7 11.3 4.7l43.3 43.3H480zM32 304h336c17.7 0 32-14.3 32-32V64c0-17.7-14.3-32-32-32H32C14.3 32 0 46.3 0 64v208c0 17.7 14.3 32 32 32zM304 80h32v176h-32V80zm-80 0h32v176h-32V80zm-80 0h32v176h-32V80zm-80 0h32v176H64V80z"
                />
            </svg>
        );
    }
    if (icon === "boat") {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30.003"
                height={33}
                viewBox="0 0 30.003 33"
            >
                <path
                    fill="currentColor"
                    data-name="Icon material-directions-boat"
                    d="M30,31.5a10.548,10.548,0,0,1-6-1.98,10.319,10.319,0,0,1-12,0A10.548,10.548,0,0,1,6,31.5H3v3H6a13.242,13.242,0,0,0,6-1.485,13.129,13.129,0,0,0,12,0A13.053,13.053,0,0,0,30,34.5h3v-3Zm-24.075-3H6a8.178,8.178,0,0,0,6-3,8.178,8.178,0,0,0,6,3,8.178,8.178,0,0,0,6-3,8.178,8.178,0,0,0,6,3h.075L32.91,18.48a1.579,1.579,0,0,0-.09-1.17,1.5,1.5,0,0,0-.9-.75L30,15.93V9a3.009,3.009,0,0,0-3-3H22.5V1.5h-9V6H9A3.009,3.009,0,0,0,6,9v6.93l-1.935.63a1.5,1.5,0,0,0-.9.75,1.51,1.51,0,0,0-.09,1.17ZM9,9H27v5.955L18,12,9,14.955Z"
                    transform="translate(-2.997 -1.5)"
                />
            </svg>
        );
    }

    return null;
}

export default Icons;
