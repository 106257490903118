import React from "react";
import { BlueberryData } from "./BlueberryData";
import dynamicReducer from "./dataReducer";

export default function connect(options) {
    const { pure = false } = options ? options : { pure: false };

    const BaseComponent = pure ? React.PureComponent : React.Component;
    return (WrappedComponent) =>
        class ReactBlueberryDataComponent extends BaseComponent {
            static getInitialData(ctx) {
                // Need to call the wrapped components getInitialData if it exists
                return WrappedComponent.getInitialData
                    ? WrappedComponent.getInitialData(ctx)
                    : Promise.resolve(null);
            }

            constructor(props) {
                super(props);

                dynamicReducer.listener(this.forceUpdate);

                this.state = {
                    change: 0,
                    data: props.initialData, // NEVER DELETE THIS line
                    isLoading: false,
                    userChange: 0,
                };
                this.ignoreLastFetch = false;
                this.ignoreLastDataChange = false;
            }

            componentDidMount() {
                // if (!this.state.data) {
                this.fetchData();
                // }
            }

            componentDidUpdate(prevProps, prevState) {
                if (
                    this.props.location.pathname !== prevProps.location.pathname
                ) {
                    this.fetchData(this.props);
                }

                if (this.state.userChange !== prevState.userChange) {
                    this.fetchData(this.props);
                }
            }

            componentWillUnmount() {
                this.ignoreLastFetch = true;
                this.ignoreLastDataChange = true;
            }

            fetchData = () => {
                // if this.state.data is null, that means that the we are on the client.
                // To get the data we need, we just call getInitialData again on mount.
                if (!this.ignoreLastFetch) {
                    this.setState({ isLoading: true });
                    const data = {};

                    this.constructor
                        .getInitialData({
                            match: this.props.match,
                            location: this.props.location,
                            isServer: false,
                        })
                        .then(
                            (data) => {
                                this.setState({ data, isLoading: false });
                            },
                            (error) => {
                                this.setState((state) => ({
                                    data: { error },
                                    isLoading: false,
                                }));
                            }
                        );
                }
            };

            forceUpdate = () => {
                this.setState((prev) => ({
                    change: prev.change ? prev.change + 1 : 1,
                }));
            };

            render() {
                return (
                    <WrappedComponent
                        {...this.props}
                        change={this.state.change}
                        store={BlueberryData}
                        refetch={this.fetchData}
                        isLoading={this.state.isLoading}
                        data={this.state.data}
                    />
                );
            }
        };
}
