import React from "react";
import CustomFields from "../../components/CustomFields";
import Blueberry from "../../../blueberry/index";
import { Helmet } from "react-helmet";
import ProductsSectionTemplate from "../../templates/ProductsSectionTemplate";
import withTracker from "../../../blueberry/connectPromise";
import { Hero, Wrapper } from "./style";
import { Title } from "../../sdk/Layout";
import { Control } from "../../sdk/Form";
import Icons from "../../components/Icons2";
import TopQuestions from "./components/TopQuestions";
import Questions from "./components/Questions";
import lang_es from "./lang/lang_es";
import lang_en from "./lang/lang_en";
import lang_pt from "./lang/lang_pt";
import { Button } from "../../sdk/Button";

function getLang(lang) {
    switch (lang) {
        case "es":
            return lang_es;
        case "en":
            return lang_en;
        case "pt":
            return lang_pt;
    }
}

class Page extends ProductsSectionTemplate {
    state = {
        faqs: [],
        featured: [],
        isLoadingFags: true,
        isLoadingFeatured: true,
        isSearching: false,
    };

    static async getInitialData(props) {
        // const token = props.initialToken ? props.initialToken : (props.token ? props.token.token : "")
        let { lang } = props.match.params;

        const slugF = "help";

        return new Promise((resolve, reject) => {
            return Blueberry.page({ slug: slugF, lang })
                .then((resp) => {
                    if (
                        resp &&
                        resp.customFields &&
                        resp.customFields.ambientBlock &&
                        resp.customFields.ambientBlock.length > 0
                    ) {
                        Blueberry.collectionChildren({
                            limit: 6,
                            slug: resp.customFields.ambientBlock[0].slug,
                        }).catch((err) => {});
                    }

                    if (
                        resp &&
                        resp.customFields &&
                        resp.customFields.recomendedProducts &&
                        resp.customFields.recomendedProducts !== ""
                    ) {
                        Blueberry.itemToUser({
                            limit: 25,
                            filter: resp.customFields.recomendedProducts,
                        }).catch(() => {});
                    }
                    resolve(resp);
                })
                .catch((err) => {
                    resolve({
                        title: "Ayuda - Noritex",
                        content: "",
                        protected: true,
                        status: "Ayuda",
                        seo: { title: "", description: "" },
                    });
                });
        });
    }

    componentDidMount = () => {
        this.getFaqs();
        this.getFaqsFeatured();
        if (typeof window !== "undefined" && analytics)
            analytics.track("Help Visited");
    };

    gotoThankYouPage = () => {
        const { lang } = this.props.match.params;

        switch (lang) {
            case "es":
                this.props.history.push(`/es/gracias-por-registrarse-full/`);
                break;
            case "en":
                this.props.history.push(`/en/gracias-por-registrarse-full/`);
                break;
            case "pt":
                this.props.history.push(`/pt/gracias-por-registrarse-full/`);
                break;
        }
    };

    timeout = null;

    handleSearch = (e) => {
        this.setState({ isSearching: true });
        const { value } = e.target;
        this.getFaqs(value);
        this.setState({ value });

        if (this.timeout != null) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
            if (typeof window !== "undefined" && analytics)
                analytics.track("Help Searched", {
                    query: value,
                });
        }, 200);
    };

    handleResetSearch = () => {
        this.setState({ isSearching: false, value: "" });
    };

    getFaqs = (query) => {
        this.setState({ isLoadingFags: true });
        Blueberry.faqs({ featured: false, query })
            .then((resp) => {
                this.setState({ isLoadingFags: false });
                this.setState({ faqs: resp });
            })
            .catch((err) => {});
    };

    getFaqsFeatured = () => {
        this.setState({ isLoadingFeatured: true });
        Blueberry.faqs({ featured: true })
            .then((resp) => {
                this.setState({ isLoadingFeatured: false });
                this.setState({ featured: resp });
            })
            .catch((err) => {});
    };

    removeHtml(html) {
        return html ? html.replace(/<\/?[^>]+(>|$)/g, "").trim() : "";
    }

    renderBody() {
        const { lang, slug } = this.props.match.params;
        const { childCollection, recomendations } = this.props.store;
        const {
            faqs,
            featured,
            isLoadingFeatured,
            isLoadingFags,
            isSearching,
            value,
        } = this.state;

        const langJson = getLang(lang);

        const data = this.props.data
            ? this.props.data
            : {
                  content: "",
                  title: "Ayuda",
                  seo: {
                      title: "Ayuda - Noritex",
                      page: "Pagina de Ayuda de Noritex. Una tienda en linea para comprar mayorista de decoracion",
                  },
              };
        const { pathname } = this.props.location;
        const title =
            data.seo && data.seo.title != "" ? data.seo.title : data.title;
        const description =
            data.seo && data.seo.description != ""
                ? data.seo.description
                : this.removeHtml(data.content);
        const image =
            data.seo && data.seo.images && data.seo.images.length > 0 != ""
                ? data.seo.images[0]
                : "https://www.noritex.com/facebook-ntx.jpg";
        const structured =
            data.seo && data.seo.strcutured != "" ? data.seo.strcutured : "";

        const baseUrl = this.stripLangDir(lang, pathname);
        const enUrl = this.addLangDir("en", pathname, baseUrl);
        const ptUrl = this.addLangDir("pt", pathname, baseUrl);
        const esUrl = this.addLangDir("es", pathname, baseUrl);

        // if (data.customFields) {
        // 	customfieldsKeys = Object.keys(data.customFields);
        // 	if (customfieldsKeys.indexOf("Slider") > -1) {
        // 		customfieldsKeys = customfieldsKeys.filter(
        // 			index => index !== "Slider"
        // 		);
        // 		topCustomFields.push("Slider");
        // 	}
        // }

        return (
            <>
                <Helmet htmlAttributes={{ lang: lang }}>
                    <title>{title}</title>
                    <meta name="description" content={description} />
                    <meta
                        name="keywords"
                        content="noritex,wholesale,decoracion,mayorista,hogar,navidad,textil,religioso"
                    />
                    {/*Alternate*/}
                    <link rel="alternate" hreflang="es" href={`${esUrl}`} />
                    <link rel="alternate" hreflang="en" href={`${enUrl}`} />
                    <link rel="alternate" hreflang="pt" href={`${ptUrl}`} />
                    <meta http-equiv="content-language" content={lang} />
                    {/*Facebook*/}
                    <meta property="og:site_name" content="Noritex" />
                    <meta
                        property="og:url"
                        content={`https://www.noritex.com${pathname}`}
                    />
                    <meta property="og:title" content={title} />
                    <meta property="og:image" content={image} />
                    <meta property="og:type" content="website" />
                    <meta property="og:description" content={description} />
                    {/*Twitter*/}
                    <meta name="twitter:title" content={title} />
                    <meta name="twitter:description" content={description} />
                    <script type="application/ld+json">
                        {`
	                        {
	                          "@context": "https://schema.org",
	                          "@type": "Organization",
	                          "url": "https://www.noritex.com/${lang}/",
	                          "name": "Noritex",
	                          "contactPoint": {
	                            "@type": "ContactPoint",
	                            "telephone": "+507-300-3003",
	                            "contactType": "Customer service"
	                          },
	                          "potentialAction": {
						        "@type": "SearchAction",
						        "target": "https://www.noritex.com/es/search?qry={search_term_string}",
						        "query-input": "required name=search_term_string"
						      }
	                        }
                    	`}
                    </script>
                    <script type="application/ld+json">{structured}</script>
                </Helmet>
                <Wrapper>
                    <Hero>
                        <div>
                            <Title size="3">{langJson.howCanWeHelpYou}</Title>
                            <Control isExpanded>
                                <input
                                    onChange={this.handleSearch}
                                    type="text"
                                    value={value}
                                    placeholder={langJson.inputPlaceholder}
                                />
                                <Button onClick={this.handleResetSearch}>
                                    <Icons
                                        icon={`${
                                            isSearching ? "close" : "search"
                                        }`}
                                    ></Icons>
                                </Button>
                            </Control>
                        </div>
                        <img
                            src="https://www.noritex.com/mu6z7n6RMjtLjmcpPMAUVE_SPLENDOR.jpg"
                            alt=""
                        />
                    </Hero>
                    <TopQuestions
                        langJson={langJson}
                        isSearching={isSearching}
                        featured={featured}
                        removeHtml={this.removeHtml}
                        isLoading={isLoadingFeatured}
                    ></TopQuestions>
                    <Questions
                        faqs={faqs}
                        isLoading={isLoadingFags}
                        langJson={langJson}
                    />
                </Wrapper>

                <CustomFields
                    customFields={data.customFields}
                    _t={this._t}
                    recomendations={recomendations}
                    location={this.props.location}
                    childCollection={childCollection}
                    gotoThankYouPage={this.gotoThankYouPage}
                    viewItem={this.viewItem}
                    toggleModalProduct={this.toggleModalProduct}
                />
            </>
        );
    }
}

export default withTracker()(Page);

// export default withSSR(Page);
