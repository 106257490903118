import React, { Component } from "react";
import ReactDOM from "react-dom";

class ModalPortal extends React.Component {
    constructor(props) {
        super(props);
        if (typeof window !== "undefined") {
            this.el = document.createElement("div");
        }
    }

    componentDidMount() {
        if (typeof window !== "undefined") {
            const modalRoot = document.getElementById("modal-root");
            modalRoot.appendChild(this.el);
        }
    }

    componentWillUnmount() {
        if (typeof window !== "undefined") {
            const modalRoot = document.getElementById("modal-root");
            modalRoot.removeChild(this.el);
        }
    }

    render() {
        if (typeof window === "undefined") return null;
        return ReactDOM.createPortal(this.props.children, this.el);
    }
}

export default ModalPortal;
