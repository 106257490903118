import React from "react";
import CustomFields from "../../components/CustomFields";
import Blueberry from "../../../blueberry/index";
import Icons from "./components/Icons";
import { Helmet } from "react-helmet";
import ProductsSectionTemplate from "../../templates/ProductsSectionTemplate";
import withTracker from "../../../blueberry/connectPromise";
import {
    Wrapper,
    Block,
    PageTitle,
    Text,
    Links,
    WrapperActions,
    Items,
    Item,
    WrapperInfo,
    Info,
    Row,
    Map,
    LinksInfo,
} from "./style";
import { Icon } from "../../sdk/Button";
import {
	Columns, Column, Title, Heading, Box, Container, PageContent,
} from "../../sdk/Layout";
import InternalLink from "../../components/InternalLink";
import lang_es from "./lang/lang_es";
import lang_en from "./lang/lang_en";
import lang_pt from "./lang/lang_pt";

function getLang(lang) {
    switch (lang) {
        case "es":
            return lang_es;
        case "en":
            return lang_en;
        case "pt":
            return lang_pt;
    }
}

class Page extends ProductsSectionTemplate {
    state = {
        menus: [],
        days: "00",
        hours: "00",
        minutes: "00",
        seconds: "00",
    };

    static async getInitialData(props) {
        // const token = props.initialToken ? props.initialToken : (props.token ? props.token.token : "")
        let { lang } = props.match.params;

        const slugF = "showroom";

        return new Promise((resolve, reject) => {
            return Blueberry.page({ slug: slugF, lang })
                .then((resp) => {
                    if (
                        resp &&
                        resp.customFields &&
                        resp.customFields.ambientBlock &&
                        resp.customFields.ambientBlock.length > 0
                    ) {
                        Blueberry.collectionChildren({
                            limit: 6,
                            slug: resp.customFields.ambientBlock[0].slug,
                        }).catch((err) => {});
                    }

                    if (
                        resp &&
                        resp.customFields &&
                        resp.customFields.recomendedProducts &&
                        resp.customFields.recomendedProducts !== ""
                    ) {
                        Blueberry.itemToUser({
                            limit: 25,
                            filter: resp.customFields.recomendedProducts,
                        }).catch(() => {});
                    }
                    resolve(resp);
                })
                .catch((err) => {
                    resolve({
                        title: "Black Friday - Noritex",
                        content: "",
                        protected: true,
                        status: "Black Friday",
                        seo: { title: "", description: "" },
                    });
                });
        });
    }

    componentWillUnmount() {
        if (this.inverval) {
            clearInterval(this.inverval);
        }
    }

    componentDidMount() {
        this.setCountDown();
    }

    gotoThankYouPage = () => {
        const { lang } = this.props.match.params;

        switch (lang) {
            case "es":
                this.props.history.push(`/es/gracias-por-registrarse-full/`);
                break;
            case "en":
                this.props.history.push(`/en/gracias-por-registrarse-full/`);
                break;
            case "pt":
                this.props.history.push(`/pt/gracias-por-registrarse-full/`);
                break;
        }
    };

    setCountDown = () => {
        const countDownDate = new Date("Nov 23, 2020 00:00:00").getTime();
        const self = this;
        // Update the count down every 1 second
        this.inverval = setInterval(function () {
            // Get today's date and time
            const now = new Date().getTime();

            // Find the distance between now and the count down date
            const distance = countDownDate - now;

            // Time calculations for days, hours, minutes and seconds
            const days = Math.floor(distance / (1000 * 60 * 60 * 24));
            const hours = Math.floor(
                (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
            );
            const minutes = Math.floor(
                (distance % (1000 * 60 * 60)) / (1000 * 60)
            );
            const seconds = Math.floor((distance % (1000 * 60)) / 1000);

            // // Display the result in the element with id="demo"
            // document.getElementById("demo").innerHTML =
            //     days + "d " + hours + "h " + minutes + "m " + seconds + "s ";

            self.setState({ days, hours, minutes, seconds });

            // If the count down is finished, write some text
            if (distance < 0) {
                clearInterval(this.inverval);
                // document.getElementById("demo").innerHTML = "EXPIRED";
            }
        }, 1000);
    };

    removeHtml(html) {
        return html ? html.replace(/<\/?[^>]+(>|$)/g, "").trim() : "";
    }

    renderBody() {
        const { lang, slug } = this.props.match.params;
        const { childCollection, recomendations, user } = this.props.store;
        const { days, hours, minutes, seconds } = this.state;

        const langJson = getLang(lang);

        const data = this.props.data
            ? this.props.data
            : {
                  content: "",
                  title: "Showroom - Noritex",
                  seo: {
                      title: "Showroom - Noritex",
                      page: "Visita nuestro showroom en Panama y compra al por mayor en Noritex",
                  },
              };
        const { pathname } = this.props.location;
        const title =
            data.seo && data.seo.title != "" ? data.seo.title : data.title;
        const description =
            data.seo && data.seo.description != ""
                ? data.seo.description
                : this.removeHtml(data.content);
        const image =
            data.seo && data.seo.images && data.seo.images.length > 0 != ""
                ? data.seo.images[0]
                : "https://www.noritex.com/facebook-ntx.jpg";
        const structured =
            data.seo && data.seo.strcutured != "" ? data.seo.strcutured : "";

        const baseUrl = this.stripLangDir(lang, pathname);
        const enUrl = this.addLangDir("en", pathname, baseUrl);
        const ptUrl = this.addLangDir("pt", pathname, baseUrl);
        const esUrl = this.addLangDir("es", pathname, baseUrl);

        return (
            <>
                <Helmet htmlAttributes={{ lang: lang }}>
                    <title>{title}</title>
                    <meta name="description" content={description} />
                    <meta
                        name="keywords"
                        content="noritex,wholesale,decoracion,mayorista,hogar,navidad,textil,religioso"
                    />
                    {/*Alternate*/}
                    <link rel="alternate" hreflang="es" href={`${esUrl}`} />
                    <link rel="alternate" hreflang="en" href={`${enUrl}`} />
                    <link rel="alternate" hreflang="pt" href={`${ptUrl}`} />
                    <meta http-equiv="content-language" content={lang} />
                    {/*Facebook*/}
                    <meta property="og:site_name" content="Noritex" />
                    <meta
                        property="og:url"
                        content={`https://www.noritex.com${pathname}`}
                    />
                    <meta property="og:title" content={title} />
                    <meta property="og:image" content={image} />
                    <meta property="og:type" content="website" />
                    <meta property="og:description" content={description} />
                    {/*Twitter*/}
                    <meta name="twitter:title" content={title} />
                    <meta name="twitter:description" content={description} />
                    <script type="application/ld+json">
                        {`
	                        {
	                          "@context": "https://schema.org",
	                          "@type": "Organization",
	                          "url": "https://www.noritex.com/${lang}/",
	                          "name": "Noritex",
	                          "contactPoint": {
	                            "@type": "ContactPoint",
	                            "telephone": "+507-300-3003",
	                            "contactType": "Customer service"
	                          },
	                          "potentialAction": {
						        "@type": "SearchAction",
						        "target": "https://www.noritex.com/es/search?qry={search_term_string}",
						        "query-input": "required name=search_term_string"
						      }
	                        }
                    	`}
                    </script>
                    <script type="application/ld+json">{structured}</script>
                </Helmet>

                <Wrapper>
                    <PageTitle isCentered>
                        <h1>Showrooms</h1>
                    </PageTitle>
                    <Block>
                        <Container>
                            <Columns>
                                <Column>
                                    <figure>
                                        <img
                                            src="https://www.noritex.com/4Pk2J56nmhafZDw3D1.jpg"
                                            alt=""
                                        />
                                    </figure>
                                </Column>
                                <Column>
                                    <Text>
                                        <div>
                                            <Title as="h3" size="5">
                                                {langJson.subTitle}
                                            </Title>
                                            {/* <p>{langJson.paragraph}</p> */}
											{ langJson.paragraphs.map((p) => <p>{p}</p>) }
                                            <Links>
                                                {/* <span>
                                                    Reserve su cita ahora:
                                                </span>{" "} */}
                                                {/* <a href="">presencial</a>,{" "}
                                                <a href="">ONLINE</a> */}
                                            </Links>
                                        </div>
                                    </Text>
                                </Column>
                            </Columns>
                        </Container>
                    </Block>
                </Wrapper>
				{/* <Container>
                    <WrapperActions>
                        <Heading isCentered>
                            <h3>{langJson.titleActions}</h3>
                        </Heading>
                        <Items>
                            {langJson.actions.map((index, i) => (
                                <Item key={`action-item-${index.icon}-${i}`}>
                                    <Icons icon={index.icon}></Icons>
                                    <Title as="h4" size="6">
                                        {index.title}
                                    </Title>
                                    <p>{index.paragraph}</p>
                                </Item>
                            ))}
                        </Items>
                    </WrapperActions>
                </Container> */}
				{
					((user.id && data.content) || true) && (
						<Container>
							<WrapperActions>
								<Heading isCentered>
									<h3>{langJson.titleVirtualShowroom}</h3>
									<p>{langJson.paragraphVirtualShowroom}</p>
								</Heading>
								<Wrapper>
									<PageContent
										dangerouslySetInnerHTML={{
											__html: data ? data.content : "",
										}}
									/>
								</Wrapper>
							</WrapperActions>
						</Container>
					)
				}
                <WrapperInfo>
                    <Container>
                        <Box>
                            <Columns>
                                <Info>
                                    <div>
                                        <Row>
                                            <h2>
                                                <Icon>
                                                    <Icons icon="map-marker-alt"></Icons>
                                                </Icon>
                                                {langJson.direction}
                                            </h2>
                                            <p>
                                                Costa del este, Parque
                                                Industrial Panama, Ciudad de
                                                Panama, Panama
                                            </p>
                                        </Row>
                                        <Row>
                                            <h2>
                                                <Icon>
                                                    <Icons icon="phone-alt"></Icons>
                                                </Icon>
                                                {langJson.phoneNumber}
                                            </h2>
                                            <p>
                                                <a href="tel:507-300-3003">
                                                    +507-300-3003
                                                </a>
                                            </p>
                                        </Row>
                                        <Row>
                                            <h2>
                                                <Icon>
                                                    <Icons icon="envelope"></Icons>
                                                </Icon>
                                                {langJson.email}
                                            </h2>
                                            <p>
                                                <a href="mailto:info@noritex.com">
                                                    info@noritex.com
                                                </a>
                                            </p>
                                        </Row>
                                        <Row>
                                            <LinksInfo>
                                                {/* <span>
                                                    Reserve su cita ahora:
                                                </span>{" "} */}
                                                {/* <a href="">presencial</a>,{" "}
                                                <a href="">ONLINE</a> */}
                                            </LinksInfo>
                                        </Row>
                                    </div>
                                </Info>
                                <Map>
                                    <iframe
                                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3940.5570006937864!2d-79.48522558484052!3d9.012845991722365!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8faca91d7f1b7a0d%3A0x76f955ffb2007834!2sNoritex%2C%20S.A.!5e0!3m2!1ses-419!2spe!4v1605053399514!5m2!1ses-419!2spe"
                                        width={600}
                                        height={450}
                                        frameBorder={0}
                                        allowFullScreen
                                        aria-hidden="false"
                                        tabIndex={0}
                                    />
                                </Map>
                            </Columns>
                        </Box>
                    </Container>
                </WrapperInfo>
				<CustomFields
                    customFields={data.customFields}
                    _t={this._t}
                    recomendations={recomendations}
                    location={this.props.location}
                    childCollection={childCollection}
                    gotoThankYouPage={this.gotoThankYouPage}
                    viewItem={this.viewItem}
                    toggleModalProduct={this.toggleModalProduct}
                />
            </>
        );
    }
}

export default withTracker()(Page);

// export default withSSR(Page);
