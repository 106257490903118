const categories = [
	{
		slug: "decoracion-del-hogar",
		thumb: "https://www.noritex.com/uplds/2023/10/27/hLhRonAjNdhzZzncaCocnepts-seccion-(700x500).jpg",
		hero: "https://www.noritex.com/uplds/2023/10/24/jDr2wT5GgzSARhRkJBanner-decoracion-hogar.jpg",
		gallery: [
			"https://www.noritex.com/uplds/2023/10/24/42GtEBn3FKADLFAeYdecohogar-collage02.jpg",
			"https://www.noritex.com/uplds/2023/10/24/YfMPXYP9Qj8E6FQMddecohogar-collage05.jpg",
			"https://www.noritex.com/uplds/2023/10/24/SfXT646GBbmo5EaMddecohogar-collage07.jpg",
			"https://www.noritex.com/uplds/2023/10/24/xJf8wArFXooEbdC9Qdecohogar-collage08.jpg",
			"https://www.noritex.com/uplds/2023/10/24/fEgx8FYYcP79eARqXdecohogar-collage03.jpg",
			"https://www.noritex.com/uplds/2023/10/24/yHeJRv4gaXzMcBpK3decohogar-collage06.jpg",
			"https://www.noritex.com/uplds/2023/10/24/BRCYn7ooN3BRdqQTrdecohogar-collage04.jpg",
			"https://www.noritex.com/uplds/2023/10/24/TTNfzEb4pxgkqvCHLdecohogar-collage09.jpg",
			"https://www.noritex.com/uplds/2023/10/24/RCzZNSucvcu79G33Kdecohogar-collage01.jpg",
		],
		title: "Decoración del Hogar",
		description:
			"Transforma tus productos de decoración del hogar con nuestra amplia gama de accesorios y artículos que añaden un toque único y especial a cada espacio. Desde la sala hasta el comedor, nuestros diseños infunden calidad, estilo y tendencia.",
		content:
			"<p>En el emocionante mundo de la decoración del hogar, destacar es esencial para atraer a los consumidores modernos que buscan calidad y estilo. En Noritex, comprendemos la importancia de superar las expectativas y elevar la categoría de tus productos de decoración.</p><p>Nuestra amplia selección de accesorios y artículos está meticulosamente diseñada para agregar un toque único y especial a cada rincón de los hogares de tus clientes, desde la sala hasta el comedor. Nuestros diseños no solo transmiten calidad y estilo, sino que también siguen de cerca las últimas tendencias decorativas.</p>",
	},
	{
		slug: "decoracion-de-navidad",
		thumb: "https://www.noritex.com/uplds/2023/10/27/cPqaozMCzSN4PZC2hSantini-seccion-(700x500).jpg",
		gallery: [
			"https://www.noritex.com/uplds/2023/10/27/DqsDJQaTZsbfL93g8navidad-collage04.jpg",
			"https://www.noritex.com/uplds/2023/10/27/824oReEGFkHPu2kADnavidad-collage02.jpg",
			"https://www.noritex.com/uplds/2023/10/27/sCS2usWMD4s5rknk9navidad-collage08.jpg",
			"https://www.noritex.com/uplds/2023/10/27/coGWox6YQBfQhXsTWnavidad-collage09.jpg",
			"https://www.noritex.com/uplds/2023/10/27/iDZcsBQoAwr6gAWp2navidad-collage05.jpg",
			"https://www.noritex.com/uplds/2023/10/27/gkqt7kf77itEgdtjXnavidad-collage03.jpg",
			"https://www.noritex.com/uplds/2023/10/27/3A6aRNzDoCptrJBSpnavidad-collage01.jpg",
			"https://www.noritex.com/uplds/2023/10/27/JkqesZd3RNdg8y9cknavidad-collage07.jpg",
			"https://www.noritex.com/uplds/2023/10/27/RWPak7i9uWmQstZjnnavidad-collage06.jpg",
		],
		hero: "https://www.noritex.com/uplds/2023/10/27/2BHfdZswkdPwsXwt5Banner---Decoracion-navidad-(1600x850).jpg",
		title: "Decoración de Navidad",
		description:
			"Lleva la magia de la Navidad a tus puntos de venta con nuestra decoración navideña, que transformará tus ofertas en experiencias llenas del encanto de la temporada. Ofrecemos una variedad de productos navideños que cumplen con los estándares de calidad más exigentes.",
		content:
			"<p>En el encantador universo de la Navidad, la atmósfera y la magia son fundamentales para cautivar a los clientes y diferenciarse de su competencia. En Noritex, somos expertos en llevar esa magia a sus productos navideños y transformar sus ofertas en experiencias llenas del encanto característico de la temporada.</p><p>Nuestra amplia selección de decoración navideña está diseñada para agregar un toque excepcional a cada rincón. Desde las figuras tradicionales hasta las tendencias más contemporáneas, nuestros productos superan los estándares de calidad más rigurosos. </p><p>Imagina poder ofrecer a tus clientes una variedad de productos navideños que no solo cumplan con sus expectativas, sino que también les permitan sumergirse completamente en la celebración navideña. En Noritex, te brindamos la oportunidad de destacar en la decoración navideña y ofrecer a tus clientes lo mejor de la temporada festiva.</p>",
	},
	{
		slug: "sportwear",
		thumb: "https://www.noritex.com/uplds/2023/10/27/kFrhjgxSAHa5Kn7zCSportwear-seccion-(700x500).jpg",
		gallery: [
			"https://www.noritex.com/uplds/2023/10/24/Ww9Ho9Hr86f2KeuKTsportwear-collage02.jpg",
			"https://www.noritex.com/uplds/2023/10/24/5Wtgrmgt8FWyenSGMsportwear-collage04.jpg",
			"https://www.noritex.com/uplds/2023/10/24/HSY9cYdRGqpeKaojbsportwear-collage08.jpg",
			"https://www.noritex.com/uplds/2023/10/24/W6LXYFAbftdcBbt56sportwear-collage09.jpg",
			"https://www.noritex.com/uplds/2023/10/24/v7X5xLY6qS5w8JkeXsportwear-collage03.jpg",
			"https://www.noritex.com/uplds/2023/10/24/EbqJjtv9GgikPD6TMsportwear-collage06.jpg",
			"https://www.noritex.com/uplds/2023/10/24/sCGQDMb86nnTaFdZYsportwear-collage05.jpg",
			"https://www.noritex.com/uplds/2023/10/24/aS7GehjHhLxSzKJSjsportwear-collage07.jpg",
			"https://www.noritex.com/uplds/2023/10/24/cHWYCNDbAgrYuvGi2sportwear-collage01.jpg",
		],
		hero: "https://www.noritex.com/uplds/2023/10/24/wqH9pYJKhJuepZPB7banner-sportwear.jpg",
		title: "Sportwear",
		description:
			"Nuestra ropa deportiva ofrece calidad y estilo a precios atractivos para su mercado. Estamos al tanto de las últimas tendencias y diseñamos productos pensando en la mujer moderna que busca comodidad y estilo.",
		content:
			"<p>En el apasionante mundo del sportwear, destaca gracias combinación perfecta de calidad, estilo y accesibilidad para su mercado.</p><p>Nuestros productos están meticulosamente concebidos, pensando en la mujer moderna que busca más que simplemente ropa cómoda; busca prendas que reflejen su estilo de vida activo y enérgico</p><p>Imagina poder ofrecer a tus clientes una selección de ropa deportiva que no solo potencie su rendimiento en el gimnasio o al aire libre, sino que también les permita hacerlo con estilo y confianza.</p><p>Con Noritex, tienes acceso a una gama de productos que están diseñados para satisfacer las demandas de la mujer moderna y brindarle una experiencia de moda deportiva sin igual. </p>",
	},
	{
		slug: "accesorios-deportivos",
		thumb: "https://www.noritex.com/uplds/2023/10/27/Ech9zSKkJL74nKHtuseccion_Accesorios-(700x500).jpg",
		gallery: [
			"https://www.noritex.com/uplds/2023/10/27/Ch9WdJQkg8JjaPrtEaccesorios-deportivos-collage03.jpg",
			"https://www.noritex.com/uplds/2023/10/27/eWAhpwqZvQgGg6qmwaccesorios-deportivos-collage05.jpg",
			"https://www.noritex.com/uplds/2023/10/27/XjiZuqFbMYmp3hCiPaccesorios-deportivos-collage07.jpg",
			"https://www.noritex.com/uplds/2023/10/27/RKHarAwwfFQAuPQ9saccesorios-deportivos-collage08.jpg",
			"https://www.noritex.com/uplds/2023/10/27/wWy4kBuqQKrPx53N6accesorios-deportivos-collage01.jpg",
			"https://www.noritex.com/uplds/2023/10/27/Fqtjq8zjAexMuDYo5accesorios-deportivos-collage06.jpg",
			"https://www.noritex.com/uplds/2023/10/27/vt3dEzxxtpdChCQNfaccesorios-deportivos-collage04.jpg",
			"https://www.noritex.com/uplds/2023/10/27/ZTxvTT5hTm3xy6MyYaccesorios-deportivos-collage09.jpg",
			"https://www.noritex.com/uplds/2023/10/27/o6CpAyfHwN9hHTucLaccesorios-deportivos-collage02.jpg",
		],
		hero: "https://www.noritex.com/uplds/2023/10/27/MpqcoxF6WCAdgANXbBanner-collage-accesorios.jpg",
		title: "Accesorios Deportivos",
		description:
			"Combinamos estilo, belleza y funcionalidad en nuestros accesorios deportivos, desde equipos funcionales hasta artículos de yoga y recuperación. Nuestras maletas y bolsos están diseñados para hacer la vida más fácil y cómoda.",
		content:
			"<p>Fusionamos estilo, belleza y funcionalidad para ofrecerte una experiencia única. Desde equipos altamente funcionales hasta artículos diseñados específicamente para yoga y recuperación, cada elemento de nuestra colección está meticulosamente concebido.</p><p>Nuestros accesorios están cuidadosamente diseñados para integrarse perfectamente en la vida activa y moderna de tus clientes. </p><p>Imagina poder ofrecer a tus clientes una selección de accesorios deportivos que no solo complementen su rutina de ejercicios, sino que también reflejen su gusto por la moda y la practicidad en cada detalle.</p><p>Con Noritex, tienes acceso a una gama de productos que están diseñados para hacer que la vida activa sea más elegante y cómoda. Si estás listo para llevar tu oferta de accesorios deportivos al siguiente nivel, estamos aquí para ayudarte a lograrlo.</p>",
	},
	{
		slug: "utiles-escolares",
		thumb: "https://www.noritex.com/uplds/2023/10/27/6sdrvsLfLCGA8nZJqEscolar-seccion-(700x500).jpg",
		gallery: [
			"https://www.noritex.com/uplds/2023/10/24/m2P3r6iHhLizhEk2outiles_escolares-collage03.jpg",
			"https://www.noritex.com/uplds/2023/10/24/yPkw4suQhXWZdoHX9utiles_escolares-collage05.jpg",
			"https://www.noritex.com/uplds/2023/10/24/G6kR4mqiN3vncFESputiles_escolares-collage09.jpg",
			"https://www.noritex.com/uplds/2023/10/24/vRzJKgWmweEDPbHW6utiles_escolares-collage07.jpg",
			"https://www.noritex.com/uplds/2023/10/24/Jb6NEZPhvD2Sj8Z8Jutiles_escolares-collage02.jpg",
			"https://www.noritex.com/uplds/2023/10/24/g7qy5dCFPP56GKxoFutiles_escolares-collage06.jpg",
			"https://www.noritex.com/uplds/2023/10/24/jhWsi5mTJABPsPr8Tutiles_escolares-collage04.jpg",
			"https://www.noritex.com/uplds/2023/10/24/yWp6SjxmqjB9gxMJRutiles_escolares-collage08.jpg",
			"https://www.noritex.com/uplds/2023/10/24/ymoHhufHeK375wAWxutiles_escolares-collage01.jpg",
		],
		hero: "https://www.noritex.com/uplds/2023/10/24/iNkjxa38FAEvEpBCgbanner_utiles_escolares.jpg",
		title: "Útiles Escolares",
		description:
			"Ofrecemos una amplia gama de útiles escolares, incluyendo mochilas y loncheras diseñadas para convertirse en los favoritos de los padres y niños. Nuestra innovación constante garantiza que siempre tengamos la solución adecuada para ti.",
		content:
			"<p>Nuestra amplia gama de útiles escolares incluye mochilas y loncheras que se han convertido en favoritas tanto de padres como de niños. Nuestra constante búsqueda de la innovación garantiza que siempre tengamos la solución adecuada para usted.</p><p>Cada producto ha sido cuidadosamente diseñado para ser funcional, duradero e inspirador. Mantenemos un enfoque en las tendencias de moda para asegurarnos de que nuestros productos se destaquen en el aula y en el recreo.</p><p>En Útiles Escolares, nuestro objetivo es hacer que el proceso de preparación para la escuela sea emocionante y sin complicaciones. Creemos que los útiles escolares pueden ser una fuente de alegría tanto para los niños como para sus padres y maestros. Explora nuestra colección y descubre cómo marcamos la diferencia en la vida escolar.</p>",
	},
	{
		slug: "oficina",
		thumb: "https://www.noritex.com/uplds/2023/10/27/Rqq8YaxpiF5eZhegfOficina-seccion-(700x500).jpg",
		gallery: [
			"https://www.noritex.com/uplds/2023/10/27/jujRk7Rx2KnDuxvfcoficina-collage01.jpg",
			"https://www.noritex.com/uplds/2023/10/27/6K2KEasvnKPYbCtFkoficina-collage06.jpg",
			"https://www.noritex.com/uplds/2023/10/27/HTyh6mJZjXdDYyYzvoficina-collage09.jpg",
			"https://www.noritex.com/uplds/2023/10/27/eurPaAJFxkmWuEx2Roficina-collage08.jpg",
			"https://www.noritex.com/uplds/2023/10/27/fzQW8jz8LYduoa9Lcoficina-collage03.jpg",
			"https://www.noritex.com/uplds/2023/10/27/MFZXLu9teeWhRGGE7oficina-collage04.jpg",
			"https://www.noritex.com/uplds/2023/10/27/ZduqpESw8cNRQM2RBoficina-collage05.jpg",
			"https://www.noritex.com/uplds/2023/10/27/rBwbLXqfsbkXhe74Coficina-collage07.jpg",
			"https://www.noritex.com/uplds/2023/10/27/CbMcKwTFSKEn5Wsxooficina-collage02.jpg",
		],
		hero: "https://www.noritex.com/uplds/2023/10/27/i8RL8btjPJ5wY6FCEoficina-Banner_Oficina.jpg",
		title: "Oficina",
		description:
			"Nuestra oferta para oficina incluye variedad de productos que ayudan a organizar y facilitar a sus usuarios. Nuestras agendas detalladamente diseñadas y pensadas para la vida actual llevan diseños de moda que toda mujer y hombre busca.",
		content:
			"<p>Nuestra oferta de productos de oficina incluye agendas y organizadores diseñados con estilo y funcionalidad para satisfacer las demandas de la vida actual. Además, ofrecemos una variedad excepcional de plumas y lápices que combinan calidad y practicidad para una experiencia de escritura única.</p><p>Nuestras agendas y organizadores están diseñados para las exigencias de la vida moderna, con propuestas de moda que atraen tanto a mujeres como a hombres, amantes de la productividad y la planificación.</p><p>Únete a nuestra comunidad de clientes satisfechos que valoran la calidad y el diseño en cada producto que ofrecemos. Experimenta la diferencia que nuestras agendas, organizadores, plumas y lápices pueden hacer en tu día a día, ayudándote a mantener el control y destacar en estilo.</p>",
	},
];

const countries = [
	"Antigua y Barbuda",
	"Aruba",
	"Bahamas",
	"Barbados",
	"Belice",
	"Bolivia",
	"Brasil",
	"Chile",
	"Colombia",
	"Costa Rica",
	"Curacao",
	"Dominica",
	"Ecuador",
	"El Salvador",
	"Granada",
	"Guatemala",
	"Guyana",
	"Honduras",
	"Jamaica",
	"México",
	"Nicaragua",
	"Panamá",
	"Puerto Rico",
	"Perú",
	"República Dominicana",
	"Trinidad y Tobago",
	"Uruguay",
	"Venezuela",
];

const featured = [
	{
		icon: "stack",
		title: "Variedad de Productos asequibles con alto valor percibido",
		description:
			"Cada artículo está diseñado para brindar un alto valor percibido, asegurando que obtengas lo mejor por tu inversión.",
	},
	{
		icon: "fire",
		title: "Disponibilidad constante de novedades",
		description:
			"Diseñamos constantemente colecciones de productos de distintias categorías que se adaptan a diferentes estilos de vida y tendencias de diseño. Siempre encontrarás propuestas emocionantes en nuestra selección.",
	},
	{
		icon: "shopping-cart",
		title: "Simplificar la compra con asesoramiento completo",
		description:
			"Facilitamos tus decisiones de compra proporcionándote asesoramiento completo. Nuestro equipo te ayudara a desarrollar los productos perfectos para tu negocio.",
	},
	{
		icon: "rocket",
		title: "Diferenciación en el mercado",
		description:
			"Destacamos en el mercado gracias a nuestra singularidad. Nuestros productos se destacan por su originalidad y calidad, ofreciéndote una experiencia verdaderamente única.",
	},
	{
		icon: "chart-bar",
		title: "Propuesta de mejora en la rotación de inventario y mejora financiera",
		description:
			"Aprovecha nuestra experiencia en gestión de espacios y logística. Con nosotros, podrás maximizar la eficiencia en la gestión de tu inventario, garantizando que tus productos estén siempre en movimiento y disponibles cuando más los necesites.",
	},
	{
		icon: "star",
		title: "Calidad y diseños distintivos",
		description:
			"Logra destacar y marcar una diferencia en el mercado, a través de nuestros productos atractivos, innovadores y de calidad duradera, creando identidad de marca y ofreciéndole a sus clientes una experiencia única.",
	},
	{
		icon: "user",
		title: "Apoyo en el punto de venta y Material Estratégico de Marketing",
		description:
			"Te acompañamos en el proceso de soporte en tiendas para lograr atraer al consumidor al punto de venta, a través de estrategias de mercadeo efectivas, capacitaciones, y material de apoyo para redes sociales, a través de acuerdos para mutuo beneficio.",
	},
	{
		icon: "credit-card",
		title: "Facilidades de Crédito",
		description:
			"Ofrecemos financiamiento a través de una línea de crédito, que puede ser otorgada, mediante el cumplimiento de ciertos parámetros acordados entre las partes.",
	},
	{
		icon: "computer",
		title: "Tecnología de punta para facilitar la compra",
		description:
			"Accede a través de nuestra página noritex.com a importantes herramientas  como el uso del portal de compras online, descarga imágenes y material de apoyo de  multimedia,  sacándole  provecho a nuestras capacitaciones. No pierda la oportunidad de navegar por nuestra sala de ventas a través del tour virtual  360.",
	},
];

export default {
	home: {
		seo: {
			title: "Potencia tu marca Privada con Noritex.",
			description:
				"En Noritex, somos líderes en la creación y desarrollo de marcas privadas, acumulando más de 40 años de experiencia. Estamos aquí para ayudarte a elevar tu marca y maximizar tu rentabilidad, ahorrándote tiempo y recursos valiosos.",
			images: [
				"https://www.noritex.com/uplds/2023/10/27/jqefePRbkQ4i8ui2KBanner-Home--(800x800).jpg",
			],
		},
		slider: {
			title: "¡Potencia Tu Marca Privada con Noritex!",
			subtitle:
				"En Noritex, somos líderes en la creación y desarrollo de marcas privadas, acumulando más de 40 años de experiencia. Estamos aquí para ayudarte a elevar tu marca y maximizar tu rentabilidad, ahorrándote tiempo y recursos valiosos.",
			btn: "Desarrolla tu marca privada",
		},
		featured,
		featured_btn: "Ver todos los beneficios",
		section1: {
			title: "Eleva tu marca <span>con noritex</span>",
			paragraph:
				"Somos expertos en potenciar marcas privadas desde hace más de 40 años. Maximiza tu rentabilidad, ahorra tiempo y recursos valiosos. Desbloquea un producto perfeccionado que refleja excelencia y conocimiento acumulado. Te guiamos para que tu marca destaque con identidad única, aprovechando soluciones a medida y estrategias diferenciadas. Únete a nuestra familia de clientes satisfechos y construyamos juntos el éxito de tu marca privada con décadas de conocimiento.",
		},
		cat_heading: "<h2>Nuestras áreas de <span>especialización</span></h2>",
		categories,
		locations: {
			title: "Presencia comercial en <span>Latinoamérica y El Caribe</span>",
			countries,
		},
		section2: {
			title: "Desarrolla tu <span>Marca Privada</span>",
			paragraph:
				"Te invitamos a un viaje hacia el éxito, donde tu marca destacará con una identidad única. Nuestras soluciones personalizadas y estrategias diferenciadas te ayudarán a alcanzar tus objetivos. Únete a nuestra familia de clientes satisfechos y construyamos juntos el éxito de tu marca privada con décadas de conocimiento.",
			btn: "Desarrolla tu marca privada",
		},
		conoce_mas: "Conocer más",
		section3:
			"<p>El pedido mínimo para marcas privadas = $50,000. <br /> Adicionalmente deberás cumplir con llenar un contenedor mixto 1 x 40STD.</p>",
	},
	nosotros: {
		seo: {
			title: "Marca Privada - Conoce más sobre Noritex",
			description:
				"Descubre la excelencia de Noritex S.A., líderes desde 1954 en la Zona Libre de Colón. Especializados en marcas privadas que impulsan negocios, nuestro portafolio ofrece productos cuidadosamente diseñados para las tendencias del mercado. Con más de 60 colecciones anuales, desde decoración del hogar hasta artículos escolares, te inspiramos a destacar en el mercado con calidad y originalidad. Únete a nosotros en esta emocionante travesía hacia el éxito. El futuro te espera en Noritex, donde la innovación y las últimas tendencias son nuestra esencia. Descubre nuestras marcas insignias, Concepts Life, Santini, Merletto y Di Angelo, que constantemente se renuevan en ferias innovadoras, ofreciendo más de 50,000 referencias. Al asociarte con nosotros, no solo accedes a un catálogo diverso, sino que garantizas que tu negocio esté siempre actualizado con lo mejor de la industria. ¡Eleva tu negocio con Noritex y sé parte de la evolución constante!",
		},
		title: "Somos noritex",
		content:
			"<p>Desde 1954, en Noritex S.A., hemos sido líderes en la Zona Libre de Colón, forjando tendencias y transformando desafíos en oportunidades.</p><p>Nos especializamos en crear marcas privadas que impulsan negocios. Nuestro portafolio ofrece productos de tendencia, cuidadosamente diseñados para satisfacer las necesidades del mercado actual.</p><p>Cada año, lanzamos más de 60 colecciones que incluyen decoración del hogar, decoración navideña, figuras religiosas, así como artículos escolares y de oficina. Estamos aquí para inspirarte, para que tu negocio destaque en el mercado y se convierta en la elección natural para aquellos que buscan calidad, estilo y originalidad. Únete a nosotros en esta emocionante travesía de éxito. ¡El futuro te espera en Noritex!</p>",
		banner: "Nuestros productos son vendidos en toda América Latina y el Caribe por su referencia en moda, actualidad y tendencia.",
		heading: "<h2>Variedad y <span>Diferenciación</span></h2>",
		paragraph1:
			"Noritex es mundialmente reconocido por su vasto repertorio de productos y su firme compromiso con la innovación. Nuestro equipo altamente especializado trabaja sin descanso en la creación constante de nuevos productos. Pero lo que realmente nos destaca es nuestro enfoque en mantenernos al día con las últimas tendencias, lo cual se refleja en la renovación total de nuestro catálogo de marcas cada trimestre. En Noritex, la transformación es nuestra esencia.",
		paragraph2:
			"Nuestras marcas insignias, Concepts Life, Santini, Merletto y Di Angelo, organizan ferias innovadoras cada año que abarcan más de 60 colecciones y más de 50,000 referencias. Este enfoque nos permite estar siempre a la vanguardia, proporcionándote la capacidad de ofrecer a tus clientes una amplia gama de productos inspirados en las últimas tendencias decorativas. Al trabajar con nosotros, no solo obtienes acceso a nuestro catálogo diverso, sino que también te aseguras de que tu negocio esté constantemente actualizado con lo mejor de lo que la industria tiene para ofrecer.",
	},
	categorias: {
		seo: {
			title: "Conoce nuestras categorías para desarrollar tu marca privada",
			description:
				"Descubre la experiencia de más de 40 años potenciando marcas privadas. Maximiza tu rentabilidad y ahorra tiempo y recursos con nuestro enfoque experto. Desbloquea un producto perfeccionado que refleja excelencia y conocimiento acumulado. Te guiaremos para que tu marca destaque con una identidad única, aprovechando soluciones a medida y estrategias diferenciadas. Únete a nuestra familia de clientes satisfechos y construyamos juntos el éxito de tu marca privada con décadas de conocimiento. ¡Confía en nosotros para impulsar tu marca hacia nuevas alturas!",
		},
		title: "Conoce nuestras <span>Categorías</span>",
		categories,
		content:
			"<p>Somos expertos en potenciar marcas privadas desde hace más de 40 años. Maximiza tu rentabilidad, ahorra tiempo y recursos valiosos. Desbloquea un producto perfeccionado que refleja excelencia y conocimiento acumulado. Te guiamos para que tu marca destaque con identidad única, aprovechando soluciones a medida y estrategias diferenciadas. Únete a nuestra familia de clientes satisfechos y construyamos juntos el éxito de tu marca privada con décadas de conocimiento.</p>",
	},
	beneficios: {
		seo: {
			title: "Conoce nuestros beneficios para tu marca privada",
			description: "Descubre los beneficios de trabajar con nosotros.",
		},
		title: "Recibe estos <span>Beneficios</span>",
		featured,
	},
	presencia: {
		seo: {
			title: "Conoce nuestra presencia comercial de marca privada",
			description:
				"Descubre nuestra presencia comercial en Latinoamérica y el Caribe.",
		},
		title: "Presencia <span>Comercial</span>",
		countries,
	},
	registrarse: {
		seo: {
			title: "Marca Privada - Comercializa con nosotros",
			description:
				"Desarrolla tu marca privada con Noritex y comercializa con nosotros.",
		},
		title: "Comercializa con <span>nosotros</span>",
	},
	menu: {
		home: "Inicio",
		about: "Nosotros",
		categories: "Categorías",
		benefits: "Beneficios",
		presence: "Presencia",
		signup: "Registrarse",
	},
};
