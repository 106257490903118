/* global grecaptcha, analytics */
/* eslint-disable jsx-a11y/aria-proptypes */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
import React from "react";
import Helmet from "react-helmet";
import PhoneInput from "react-phone-number-input";
import SelectCountries from "../SelectCountries";
import validate from "../../utils/validator";
import Blueberry from "../../../blueberry/index";
import ErrorMessage from "../ErrorMessage/index";
import {
	Section,
	Heading,
	Container,
	Box,
	Columns,
	// Column,
	Title,
} from "../../sdk/Layout";
import CloseIcon from "../icons/CloseIcon";
import { Button, Buttons } from "../../sdk/Button";
import { Control, Field, FieldBody, SelectBox, Radio } from "../../sdk/Form";

import {
	Left, Right, Wrap, ErrorLabel,
	FieldExtended, ControlExtended,
	InputExtended, TitleExtended,
} from "./style";
import { isEmailValid } from "../../utils";
// import { config } from "../../../config";

let captchaClienId = null;

function onRecaptchaLoadCallback() {
	captchaClienId = grecaptcha.render("inline-badge", {
		sitekey: "6LemZq4ZAAAAAN5R0IFmny9jex0HBbz7jbuBFm4A",
		badge: "inline",
		size: "invisible",
	});
}

if (typeof window !== "undefined")
	window.onRecaptchaLoadCallback = onRecaptchaLoadCallback;

class LeadForm extends React.PureComponent {
	didSubmit = false;

	constructor(props) {
		super(props);
		const { _t } = this.props;

		const isQuotation = !!(
			props.searchQuery && props.searchQuery.indexOf("cookie_token=") > -1
		);

		const formControls = {
			firstName: {
				value: "",
				error: _t("errorFirstName"),
				valid: false,
				touched: false,
				validationRules: {
					minLength: 3,
					isRequired: true,
				},
			},
			lastName: {
				value: "",
				error: _t("errorLastName"),
				valid: false,
				touched: false,
				validationRules: {
					minLength: 3,
					isRequired: true,
				},
			},
			phoneNumber: {
				value: "",
				error: _t("errorPhoneNumber"),
				valid: false,
				touched: false,
				validationRules: {
					isRequired: true,
					isPhone: true,
				},
			},
			email: {
				value: "",
				error: _t("errorEmail"),
				valid: false,
				touched: false,
				validationRules: {
					minLength: 3,
					isRequired: true,
					isEmail: true,
				},
			},
			productsOfInterest: {
				value: "",
				error: _t("errorProducts"),
				valid: isQuotation,
				hidden: isQuotation,
				touched: isQuotation,
				validationRules: {
					minLength: 3,
					isRequired: true,
				},
			},
			country: {
				value: "",
				error: _t("errorCountry"),
				valid: false,
				touched: false,
				validationRules: {
					minLength: 3,
					isRequired: true,
				},
			},
			city: {
				value: "",
				error: _t("errorCity"),
				valid: false,
				touched: false,
				validationRules: {
					minLength: 3,
					isRequired: true,
				},
			},
			nameOfCompany: {
				value: "",
				error: _t("errorCompanyName"),
				valid: false,
				touched: false,
				validationRules: {
					minLength: 3,
					isRequired: true,
				},
			},
			typeOfBusiness: {
				value: "",
				error: _t("errorCompanyType"),
				valid: false,
				touched: false,
				validationRules: {
					minLength: 3,
					isRequired: true,
				},
			},
			website: {
				value: "",
				error: _t("errorCompanyWebsite"),
				valid: false,
				touched: false,
				validationRules: {
					isRequired: false,
				},
			},
			importedBefore: {
				value: "",
				error: _t("errorOption"),
				valid: false,
				touched: false,
				validationRules: {
					minLength: 2,
					isRequired: true,
				},
			},
			contactOptions: {
				value: "",
				error: _t("errorOption"),
				valid: false,
				touched: false,
				validationRules: {
					minLength: 2,
					isRequired: true,
				},
			},
			howFoundUs: {
				value: "",
				error: _t("errorOption"),
				valid: false,
				touched: false,
				validationRules: {
					minLength: 2,
					isRequired: true,
				},
			},
			howFoundUsOther: {
				value: "",
				error: _t("errorOption"),
				valid: false,
				touched: false,
				validationRules: {
					minLength: 0,
					isRequired: false,
				},
			},
		};

		this.state = {
			formControls,
			isUpdating: false,
		};
	}

	validateForm = (formElement) => {
		if (!this.isInvalid(formElement)) return "is-valid";
		if (this.isInvalid(formElement)) return "is-invalid";
		return "";
	};

	setValue = (event) => {
		const { name, value } = event.target;
		const { formControls } = this.state;

		const updatedControls = {
			...formControls,
		};
		const updatedFormElement = {
			...updatedControls[name],
		};

		//custom validation for phone number
		if (name === "email" && isEmailValid(value)) {
			this.collectionEmailBeforeForm(value);
		}

		//custom validation for howFoundUsOther, if other is selected
		if (name === "howFoundUs") {
			const isOther = (value === "other");
			updatedControls.howFoundUsOther.valid = !isOther;
			updatedControls.howFoundUsOther.validationRules.minLength = isOther ? 3 : 0;
			updatedControls.howFoundUsOther.validationRules.isRequired = isOther;
		}

		updatedFormElement.value = value;
		updatedFormElement.valid = validate(
			value,
			updatedFormElement.validationRules,
		);
		updatedFormElement.touched = true;

		updatedControls[name] = updatedFormElement;

		this.setState({
			formControls: updatedControls,
		});
	};

	// eslint-disable-next-line consistent-return
	collectionEmailBeforeForm = (value) => {
		if (this.didSubmit) return null;
		const { formControls } = this.state;

		const formData = {};

		// eslint-disable-next-line guard-for-in, no-restricted-syntax
		for (const formElementId in formControls) {
			formData[formElementId] = formControls[formElementId].value;
		}

		formData.email = value;
	};

	submitForm = (e) => {
		e.preventDefault();
		e.stopPropagation();
		const { formControls, isUpdating } = this.state;
		if (isUpdating) return;
		let hasErrors = false;
		const formData = {};
		const updatedFormElement = { ...formControls };

		// eslint-disable-next-line guard-for-in, no-restricted-syntax
		for (const formElementId in formControls) {
			if (!formControls[formElementId].valid) {
				hasErrors = true;
				updatedFormElement[formElementId].touched = true;
			}
			formData[formElementId] = formControls[formElementId].value;
		}

		if (hasErrors) {
			this.setState({ formControls: updatedFormElement });
			const elmnt = document.getElementById("leadform");
			elmnt.scrollIntoView();
			return;
		}

		this.setState({ isUpdating: true });
		const self = this;
		const { config, pathname, searchQuery } = this.props;
		const { formKey } = config[0];

		if (searchQuery) {
			formData.searchQuery = searchQuery;
		}
		if (pathname) {
			formData.pathname = pathname;
		}

		const recordData = () => {
			if (analytics) {
				analytics.track(
					"Lead Form",
					{
						emailForm: formControls.email.value,
						properties: {
							...formData,
						},
					},
					{
						traits: {
							name: `${formData.firstName} ${formData.lastName}`,
							email: formData.email,
						},
					},
				);
			}

			Blueberry.formInsert({ fields: formData, form_key: formKey })
				.then(() => {
					self.setState({ isUpdating: false, success: true });
					self.props.onSuccess();
				})
				.catch(() => {
					self.setState({ isUpdating: false, success: true });
				});
		};

		grecaptcha.ready(() => {
			grecaptcha
				.execute(captchaClienId, {
					action: "submit",
				})
				.then((token) => {
					formData.captch_token = token;
					recordData();
				})
				.catch((err) => {
					this.setState({ isUpdating: false, hasError: true });
					console.log(err);
				});
		});
	};

	isInvalid(formElement) {
		return !formElement.valid && formElement.touched;
	}

	render() {
		const { formControls } = this.state;
		const {
			firstName,
			lastName,
			email,
			// contactPhoneNumber,
			// comments,
			productsOfInterest,
			phoneNumber,
			country,
			city,
			nameOfCompany,
			// positionInCompany,
			typeOfBusiness,
			website,
			importedBefore,
			// direction,
			contactOptions,
			howFoundUs,
			howFoundUsOther,
		} = formControls;
		const { isUpdating, success, hasError } = this.state;
		const { config, formTitle, submitLabel, _t } = this.props;

		if (config === "" || config.length === 0) return null;

		return (
			<Section id="leadform">
				<Helmet>
					<script
						defer
						type="text/javascript"
						src="https://www.google.com/recaptcha/api.js?render=explicit&onload=onRecaptchaLoadCallback"
					/>
				</Helmet>

				<Container>
					<Columns>
						<Left isNarrow className="left-column">
							<TitleExtended primary as="h2" size="1">
								{config && config.length > 0 && config[0].title
									? config[0].title
									: _t("leadFormTitle")}
							</TitleExtended>
						</Left>
						<Right className="right-column">
							<Box as="form" aria-autocomplete="on">
								<Heading isCentered className="heading">
									<h3 className="form-title">
										{formTitle ?? _t("registerHere")}
									</h3>
								</Heading>
								<Wrap className="wrap">
									<input
										type="hidden"
										value={config[0].formKey}
										name="form_key"
									/>
									<input
										type="hidden"
										value="https://www.noritex.com/es/pages/gracias-por-registrarse/"
										name="redirect"
									/>
									{success && (
										<ErrorMessage
											sucess
											errorMessage={_t("successContact")}
										/>
									)}
									{hasError && (
										<ErrorMessage
											errorMessage={this._t(
												"errorContact",
											)}
										/>
									)}
									<Field>
										<FieldBody>
											<Field>
												<Control isExpanded>
													<label htmlFor="customer_password">
														{_t("firstName")}
													</label>
													<input
														className={`${this.validateForm(
															firstName,
														)}`}
														name="firstName"
														value={firstName.value}
														onChange={this.setValue}
														type="text"
														aria-required
													/>
												</Control>
												{this.isInvalid(firstName) ? (
													<ErrorLabel>
														{firstName.error}
													</ErrorLabel>
												) : null}
											</Field>
											<Field>
												<Control isExpanded>
													<label htmlFor="customer_password">
														{_t("lastName")}
													</label>
													<input
														className={`${this.validateForm(
															lastName,
														)}`}
														name="lastName"
														value={lastName.value}
														onChange={this.setValue}
														type="text"
														aria-required
													/>
												</Control>
												{this.isInvalid(lastName) ? (
													<ErrorLabel>
														{lastName.error}
													</ErrorLabel>
												) : null}
											</Field>
										</FieldBody>
									</Field>
									<Field>
										<FieldBody>
											<Field>
												<Control isExpanded>
													<label htmlFor="customer_password">
														{_t("email")}
													</label>
													<input
														className={`${this.validateForm(
															email,
														)}`}
														name="email"
														value={email.value}
														onChange={this.setValue}
														type="text"
														aria-required
													/>
												</Control>
												{this.isInvalid(email) ? (
													<ErrorLabel>
														{email.error}
													</ErrorLabel>
												) : null}
											</Field>
											<FieldExtended>
												{/* <Control isExpanded>
													<label htmlFor="customer_password">
														{_t("contactPhoneNumber")}
													</label>
													<input
														className={`${this.validateForm(phoneNumber)}`}
														name="phoneNumber"
														value={
															phoneNumber.value
														}
														onChange={this.setValue}
														type="text"
														aria-required
													/>
													</Control> */}
												<ControlExtended isExpanded>
													<label htmlFor="phoneNumber">
														{_t(
															"contactPhoneNumber",
														)}
													</label>
													<PhoneInput
														className={`${this.validateForm(
															phoneNumber,
														)}`}
														name="phoneNumber"
														autoComplete="off"
														aria-label="Phone Number"
														value={
															phoneNumber.value
														}
														onChange={(val) =>
															this.setValue({
																target: {
																	name: "phoneNumber",
																	value:
																		val ??
																		"",
																},
															})
														}
														placeholder={_t(
															"contactPhoneNumber",
														)}
													/>
												</ControlExtended>
												{this.isInvalid(phoneNumber) ? (
													<ErrorLabel>
														{phoneNumber.error}
													</ErrorLabel>
												) : null}
											</FieldExtended>
										</FieldBody>
									</Field>
									<Field>
										<FieldBody>
											<FieldExtended>
												{/* <Control isExpanded>
													<label htmlFor="customer_password">
														{_t("country")}
													</label>
													<input
														className={`${this.validateForm(country)}`}
														name="country"
														value={country.value}
														onChange={this.setValue}
														type="text"
														aria-required
													/>
												</Control> */}
												<Control isExpanded>
													<label htmlFor="customer_password">
														{_t("country")}
													</label>
													<SelectBox isFullWidth>
														<SelectCountries
															name="country"
															className={`${this.validateForm(
																country,
															)}`}
															onChange={
																this.setValue
															}
															value={
																country.value
															}
															placeholder=""
														/>
													</SelectBox>
												</Control>
												{this.isInvalid(country) ? (
													<ErrorLabel>
														{country.error}
													</ErrorLabel>
												) : null}
											</FieldExtended>
											<Field>
												<Control isExpanded>
													<label htmlFor="customer_password">
														{_t("city")}
													</label>
													<input
														className={`${this.validateForm(
															city,
														)}`}
														name="city"
														value={city.value}
														onChange={this.setValue}
														type="text"
														aria-required
													/>
												</Control>
												{this.isInvalid(city) ? (
													<ErrorLabel>
														{city.error}
													</ErrorLabel>
												) : null}
											</Field>
										</FieldBody>
									</Field>
									<Field>
										<FieldBody>
											<Field>
												<Control isExpanded>
													<label htmlFor="customer_password">
														{_t("nameOfCompany")}
													</label>
													<input
														className={`${this.validateForm(
															nameOfCompany,
														)}`}
														name="nameOfCompany"
														value={
															nameOfCompany.value
														}
														onChange={this.setValue}
														type="text"
														aria-required
													/>
												</Control>
												{this.isInvalid(
													nameOfCompany,
												) ? (
													<ErrorLabel>
														{nameOfCompany.error}
													</ErrorLabel>
												) : null}
											</Field>
										</FieldBody>
									</Field>
									<Field>
										<FieldBody>
											<FieldExtended>
												<label htmlFor="customer_password">
													{_t("typeOfBusiness")}
												</label>
												<Control isExpanded>
													<SelectBox isFullWidth>
														<select
															type="text"
															name="typeOfBusiness"
															onChange={
																this.setValue
															}
															className={this.validateForm(
																typeOfBusiness,
															)}
															value={
																typeOfBusiness.value
															}>
															<option value="">
																---Select---
															</option>
															<option value="homeDecorStore">
																{_t(
																	"homeDecorStore",
																)}
															</option>
															<option value="wholesaler">
																{_t(
																	"wholesaler",
																)}
															</option>
															<option value="furnitureStore">
																{_t(
																	"furnitureStore",
																)}
															</option>
															<option value="farmacies">
																{_t(
																	"farmacies",
																)}
															</option>
															<option value="smallDetailers">
																{_t(
																	"smallDetailers",
																)}
															</option>
															<option value="superMarket">
																{_t(
																	"superMarket",
																)}
															</option>
															<option value="hardwareStore">
																{_t(
																	"hardwareStore",
																)}
															</option>
															<option value="departmentStore">
																{_t(
																	"departmentStore",
																)}
															</option>
															<option value="bookStores">
																{_t(
																	"bookStore",
																)}
															</option>
															<option value="neighborhoodStore">
																{_t("entrepreneurship")}
															</option>
															<option value="other">
																{_t("other")}
															</option>
														</select>
													</SelectBox>
												</Control>
												{this.isInvalid(
													typeOfBusiness,
												) ? (
													<ErrorLabel>
														{typeOfBusiness.error}
													</ErrorLabel>
												) : null}
											</FieldExtended>
											<Field>
												<label htmlFor="customer_password">
													{_t("website")}
												</label>
												<Control isExpanded>
													<input
														className={`${this.validateForm(
															website,
														)}`}
														name="website"
														value={website.value}
														onChange={this.setValue}
														type="text"
														aria-required
													/>
												</Control>
												{this.isInvalid(website) ? (
													<ErrorLabel>
														{website.error}
													</ErrorLabel>
												) : null}
											</Field>
										</FieldBody>
									</Field>
									<Field>
										<FieldBody>
											<FieldExtended>
												<label htmlFor="customer_password">
													{_t("contactOptions")}
												</label>
												<Control isExpanded>
													<SelectBox className="fit-height" isFullWidth>
														<select
															type="text"
															name="contactOptions"
															onChange={this.setValue}
															className={this.validateForm(contactOptions)}
															value={contactOptions.value}
														>
															<option value="">
                                                                ---Select---
															</option>
															<option value="whatsappChat">
																{_t("whatsappChat")}
															</option>
															<option value="whatsappCall">
																{_t("whatsappCall")}
															</option>
														</select>
													</SelectBox>
												</Control>
												{this.isInvalid(typeOfBusiness) ? (
													<ErrorLabel>
														{typeOfBusiness.error}
													</ErrorLabel>
												) : null}
											</FieldExtended>
											<FieldExtended>
												<label htmlFor="customer_password">
													{_t("howFoundUs")}
												</label>
												<Control isExpanded>
													{
														(howFoundUs.value === "other") ? (
															<InputExtended>
																<input
																	className={`${this.validateForm(howFoundUsOther)}`}
																	name="howFoundUsOther"
																	value={howFoundUsOther.value}
																	onChange={this.setValue}
																	type="text"
																	placeholder={_t("howFoundUsPlaceholder")}
																	autoFocus={howFoundUs.value === "other"}
																	aria-required
																/>
																<button onClick={(e) => {
																	e.preventDefault();
																	this.setValue({
																		target: {
																			name: "howFoundUs",
																			value: "",
																		},
																	});
																}}>
																	<CloseIcon />
																</button>
															</InputExtended>
														) : (
															<SelectBox className="fit-height" isFullWidth>
																<select
																	type="text"
																	name="howFoundUs"
																	onChange={this.setValue}
																	className={this.validateForm(howFoundUs)}
																	value={howFoundUs.value}
																>
																	<option value="">
																		---Select---
																	</option>
																	<option value="google">
																		Google
																	</option>
																	<option value="referredByCustomer">
																		{_t("referredByCustomer")}
																	</option>
																	<option value="email">
																		{_t("Email")}
																	</option>
																	<option value="instagram">
																		Instagram
																	</option>
																	<option value="facebook">
																		Facebook
																	</option>
																	<option value="linkedin">
																		LinkedIn
																	</option>
																	<option value="other">
																		{_t("other")}
																	</option>
																</select>
															</SelectBox>
														)
													}
												</Control>
												{this.isInvalid(typeOfBusiness) ? (
													<ErrorLabel>
														{typeOfBusiness.error}
													</ErrorLabel>
												) : null}
											</FieldExtended>
										</FieldBody>
									</Field>
									<Field>
										<Field>
											<label>
												{_t("importedBefore")}
											</label>
											<Radio>
												<label>
													<input
														type="radio"
														name="importedBefore"
														value="exw"
														onChange={this.setValue}
														className={`${this.validateForm(
															importedBefore,
														)}`}
													/>
													<span />
													<p>{_t("exw")}</p>
												</label>
											</Radio>
											<Radio>
												<label>
													<input
														type="radio"
														name="importedBefore"
														value="fob"
														onChange={this.setValue}
														className={`${this.validateForm(
															importedBefore,
														)}`}
													/>
													<span />
													<p>{_t("fob")}</p>
												</label>
											</Radio>
											<Radio>
												<label>
													<input
														type="radio"
														name="importedBefore"
														value="No realizo"
														onChange={this.setValue}
														className={`${this.validateForm(
															importedBefore,
														)}`}
													/>
													<span />
													<p>{_t("No realizo")}</p>
												</label>
											</Radio>
											{this.isInvalid(importedBefore) ? (
												<ErrorLabel>
													{importedBefore.error}
												</ErrorLabel>
											) : null}
										</Field>
									</Field>
									{productsOfInterest.hidden ? null : (
										<Field>
											<Control isExpanded>
												<label htmlFor="customer_password">
													{_t("productsOfInterest")}
												</label>
												<input
													className={`${this.validateForm(
														productsOfInterest,
													)}`}
													name="productsOfInterest"
													value={
														productsOfInterest.value
													}
													onChange={this.setValue}
													type="text"
													aria-required
												/>
											</Control>
											{this.isInvalid(
												productsOfInterest,
											) ? (
												<ErrorLabel>
													{productsOfInterest.error}
												</ErrorLabel>
											) : null}
										</Field>
									)}
									<Buttons isCentered>
										<Button
											className="submit-btn"
											primary
											type="submit"
											loading={isUpdating}
											onClick={this.submitForm}>
											{submitLabel ?? _t("register")}
										</Button>
									</Buttons>
									<div id="inline-badge" />
								</Wrap>
							</Box>
						</Right>
					</Columns>
				</Container>
			</Section>
		);
	}
}

export default LeadForm;
