export default {
    seoTitle: "Mayoreo - Venda al por mayor en línea a todo LATAM",
    seoDescription:
        "Mayorea ayuda a las marcas a descubrir miles de clientes en toda la región de LATAM.",
    title: "Vende en Mayorea",
    subTitle: "Haz crecer tu marca con mas de 2,000 cadenas",
    cta: "Aplica para entrar a mayorea.com con tu marca",
    ctaButton: "Aplicar →",
    actions: [
        {
            title: "Crece tu red de clientes en todo LATAM",
            paragraph: `Con una red de más de 2000 clientes en LATAM, nos especializamos en los mercados más complejos de América Latina. Construimos una red de las tiendas más grandes desde México hasta Argentina.`,
            image: "https://www.noritex.com/uplds/2021/7/9/A4MwayTWrKYDRZywQliving-rooom.jpeg",
            alt: " Crece tu red de clientes en todo LATAM",
        },
        {
            title: "Ofrece a tus vendedores las mejores herramientas para vender",
            paragraph: `Ofrecemos a sus representantes de ventas herramientas de vanguardia que ayudan a automatizar sus flujos de trabajo. Al unirse a Mayorea, tendrá acceso a nuestras herramientas para ayudar a su equipo a vender más`,
            image: "https://images.mayorea.com/uplds/2021/9/12/QMdSoaNRoJJ6zYx4Syour_team_mayorea.jpg",
            alt: "Mayorea Para Vendedores - Herramientas para vendedores",
        },
        {
            title: "Venda en línea con un sistema de autoservicio",
            paragraph: `Venda en línea con un sistema de autoservicio que permite a los clientes iniciar sesión en cualquier momento con su nivel de precio personalizado`,
            image: "https://www.noritex.com/uplds/2021/7/9/CLqWKsyy74SafBmG2store-2.jpg",
            alt: "Precio a la medida - Ecommerce Mayorea",
        },
    ],
    featuresTitle: "Venta al por mayor, todo en un solo paquete",
    features: [
        // {
        //     image: "https://www.noritex.com/uplds/2021/7/9/Q9rKh4AWgoHLaTQhSui-1.jpg",
        //     title: "Sistema de gestión de pedidos",
        //     pagraph: "Gestiona tus pedidos en un solo lugar",
        // },
        {
            image: "https://www.noritex.com/uplds/2021/7/9/LP2tGMHnF7TYLBuXBui-2.jpg",
            title: "Administrador de Relaciones con el Cliente",
            pagraph:
                "Tenga acceso al CRM más reciente creado especialmente para clientes B2B",
        },
        // {
        //     image: "https://www.noritex.com/uplds/2021/7/9/93AYfCAP3LBNQTf3Cui-3.jpg",
        //     title: "Sistema de pedidos de sala de exposición",
        //     pagraph: "Toma pedidos en la sala de exposición",
        // },
        {
            image: "https://www.noritex.com/uplds/2021/7/9/2DHGpMKT82CKGZxirui-4.jpg",
            title: "Maneja los accesos de clientes",
            pagraph:
                "Administrar los permisos y el acceso de los clientes, todo en un solo lugar",
        },
        {
            image: "https://www.noritex.com/uplds/2021/7/9/HHjqs2tkY6xTsgtppui-5.jpg",
            title: "Automatización de correo electrónico",
            pagraph: "Tenga acceso a lo último en automatización de mercadeo",
        },
    ],

    form: {
        title: "Aplica para estar en Mayorea.com con tu marca",
        website: "Website",
        name: "Name",
        email: "Correo Electronico",
        phone: "Teléfono",
        button: "Aplicar",
    },
};
