import React from "react";
import styled from "styled-components";
import {
    Container,
    Columns,
    Column,
    Title,
    Section,
} from "../../../sdk/Layout";

const ContainerExtended = styled(Container)`
    max-width: 900px;
    padding: 0;
`;

const SectionExtended = styled(Section)`
    padding-right: 0;
    padding-left: 0;
    padding-bottom: 80px;
`;

const Item = styled.div`
    position: relative;
    img {
        position: absolute;
        top: 0;
        left: 50%;
        width: 50%;
        height: 100%;
        object-fit: cover;
    }
    &:not(:last-child) {
        margin-bottom: 50px;
    }
    @media (max-width: 768px) {
        img {
            left: 70%;
            width: 30%;
        }
    }
`;

const Text = styled(Column)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
    background-color: ${(props) => props.theme.grayLight};
    padding: 5%;
    min-height: 400px;
    p {
        font-size: 18px;
        line-height: 26px;
        color: ${(props) => props.theme.gray};
    }

    @media (max-width: 768px) {
        width: 70%;
        min-height: auto;
        ${Title} {
            font-size: 20px;
            margin-bottom: 10px;
        }
        p {
            font-size: 14px;
            line-height: 20px;
        }
    }
`;

const ItemList = styled.div`
    ${Item}:nth-child(even) {
        ${Text} {
            margin-left: 50%;
        }
        img {
            left: initial;
            right: 50%;
        }
        @media (max-width: 768px) {
            ${Text} {
                margin-left: 30%;
            }
            img {
                right: 70%;
            }
        }
    }
`;

function Featured({ langJson }) {
    return (
        <SectionExtended>
            <ItemList>
                {langJson.actions.map((index, i) => (
                    <Item key={`featured-items-brand-${i}`}>
                        <ContainerExtended>
                            <Columns>
                                <Text isNarrow>
                                    <Title as="h2" size="4">
                                        {index.title}
                                    </Title>
                                    <p>{index.paragraph}</p>
                                </Text>
                            </Columns>
                        </ContainerExtended>
                        <img src={index.image} alt={index.alt} />
                    </Item>
                ))}
            </ItemList>
        </SectionExtended>
    );
}

export default Featured;
