import styled, { keyframes } from "styled-components";
import { Section } from "../../../../sdk/Layout";

const FlipinX = keyframes`

  from {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }

  40% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  60% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }

  80% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }

  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }

`;

export const SectionExtended = styled(Section)`
    padding-top: 50px;
    padding-bottom: 50px;
    &.gray {
        background-color: ${(props) => props.theme.grayLight};
    }
    @media (max-width: 768px) {
        padding-top: 30px;
        padding-bottom: 30px;
    }
`;

export const InfoItem = styled.div`
    border-radius: 10px;
    background-color: ${(props) => props.theme.white};
    border: solid 1px ${(props) => props.theme.grayLight};
    box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.02);
    padding: 30px;
    .inner {
        position: relative;
        padding-left: 60px;
        padding-top: 6px;
        .icon {
            width: 35px !important;
            height: 35px !important;
            position: absolute;
            left: 0px;
            top: 0px;
            color: ${(props) => props.theme.primaryDarkMode};
        }
        h3 {
            color: ${(props) => props.theme.primaryDarkMode};
            font-size: 18px;
            margin-bottom: 10px;
        }
        p {
            color: ${(props) => props.theme.gray};
            font-size: 15px;
            line-height: 22px;
        }
    }
    &.contact-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        text-transform: uppercase;
        color: ${(props) => props.theme.primaryDarkMode};
        transition: all 200ms ease-in-out;
        &:hover {
            text-decoration: underline;
        }
    }
`;

export const InfoItemList = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: -1%;
    ${InfoItem} {
        margin: 1%;
        width: 31.333%;
    }
    @media (max-width: 768px) {
        ${InfoItem} {
            margin: 0.5%;
            width: 49%;
        }
    }
    @media (max-width: 540px) {
        ${InfoItem} {
            width: 99%;
        }
    }
`;

export const Hero = styled.div`
    padding: 100px 0;
    background-image: url("https://www.noritex.com/uplds/2022/6/5/bX3tnHw4nTSA4sTMKisologo-pattern.png");
    background-repeat: no-repeat;
    background-position: center;
    .inner {
        text-align: left;

        h1 {
            font-size: 45px;
            color: ${(props) => props.theme.primaryDarkMode};
            margin-bottom: 30px;
            max-width: 900px;
        }
        h2 {
            font-size: 20px;
            color: ${(props) => props.theme.text};
            font-weight: normal;
            max-width: 900px;
        }
        .animated {
            height: 37px;
            position: relative;

            h2 {
                animation-name: ${FlipinX};
                animation-duration: 1s;
                animation-iteration-count: 1;
                font-weight: bold;
                position: absolute;
                top: 0;
                left: 0;
            }
        }
        .button {
            margin-top: 40px;
        }
    }

    @media (max-width: 768px) {
        padding: 30px 0;
        background-position: center left;
        .inner {
            text-align: center;
            h1 {
                font-size: 24px;
                margin-bottom: 20px;
            }
            h2 {
                font-size: 18px;
                width: 100%;
            }
            .animated {
                h2 {
                    /* left: 50%; */
                    /* transform: translateX(-50%); */
                }
            }
            .button {
                margin: auto;
                margin-top: 10px;
            }
        }
    }
`;

export const InfoSlider = styled.div`
    .slider-container {
        .slide {
            display: flex;
            gap: 50px;
            align-items: center;
            .image {
                width: 50%;
                figure {
                    position: relative;
                    border-radius: ${(props) => props.theme.radio};
                    overflow: hidden;
                    &:before {
                        content: "";
                        display: block;
                        padding-top: 60%;
                    }
                    img {
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        top: 0;
                        left: 0;
                    }
                }
            }
            .content {
                width: 50%;
                .indicator {
                    font-size: 20px;
                    color: ${(props) => props.theme.gray};
                }
                .headline {
                    font-size: 30px;
                    padding: 20px 0;
                }
                .text {
                    p {
                        font-size: 18px;
                        line-height: 26px;
                    }
                }
                .navigator {
                    padding-top: 30px;
                    display: flex;
                    flex-wrap: wrap;
                    flex-direction: row;
                    .arrow {
                        width: 40px !important;
                        height: auto !important;
                        color: ${(props) => props.theme.gray};
                        cursor: pointer;
                        &:not(:last-child) {
                            margin-right: 10px;
                        }
                        &:hover {
                            color: ${(props) => props.theme.primaryDarkMode};
                        }
                    }
                }
            }
        }
    }

    @media (max-width: 768px) {
        .slider-container {
            .slide {
                flex-direction: column-reverse;
                gap: 20px;
                .image {
                    width: 95%;
                }
                .content {
                    .headline {
                        font-size: 22px;
                    }
                    .text {
                        p {
                            font-size: 16px;
                        }
                    }
                    width: 95%;
                }
            }
        }
    }
`;
