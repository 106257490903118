import styled from "styled-components";
import { Container } from "../sdk/Layout";

export const Wrapper = styled(Container)`
    max-width: 700px !important;

    label {
        font-size: 14px;
        color: ${(props) => props.theme.black};
    }

    button[type="submit"] {
        margin-top: 20px;
        text-transform: uppercase;
        font-size: 18px;
    }
`;
