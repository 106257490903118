export default {
    title: "Por qué Nosotros",
    subTitle: "Distribución Regional",
    since: "Desde 1980",
    distribution: "Distribución en",
    places: ["América del norte", "Centroamérica", "Sudamerica", "El Caribe"],
    actions: [
        {
            title: "",
            icon: "wharehouse",
            paragraph:
                "Centro logístico automatizado en la Zona Franca más grande de la región (Colón-Panamá)",
        },
        {
            title: "",
            icon: "refresh",
            paragraph:
                "Cuatro ferias anuales y renovación de catalogo cada tres meses (Más de 20.000 SKU).",
        },
        {
            title: "You + 1",
            icon: "ruler",
            paragraph:
                "Productos Únicos con más de 2.000 m2 de Sala de Ventas ubicados en Panamá",
        },
        {
            title: "See without touching",
            icon: "trend",
            paragraph: "Siempre en la tendencia mundial de decoración y diseño",
        },
    ],
};
