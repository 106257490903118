export default {
    title: "Why Us",
    subTitle: "Regional Distribution",
    since: "Since 1980",
    distribution: "Distribution in all",
    places: [
        "North America",
        "Central America",
        "South America",
        "The Caribbean",
    ],
    actions: [
        {
            title: "",
            icon: "wharehouse",
            paragraph:
                "Automated logistic center in the largest Free Zone in the región (Colón-Panamá)",
        },
        {
            title: "",
            icon: "refresh",
            paragraph:
                "Four annual faires and renewal of inventory every three months (More than 20.000 SKUs).",
        },
        {
            title: "You + 1",
            icon: "ruler",
            paragraph:
                "Unique Products with more than 2.000 m2 of Sales Room located in Panama",
        },
        {
            title: "See without touching",
            icon: "trend",
            paragraph: "Always in the world trend of decoration and design",
        },
    ],
};
