import React from "react";
import Blueberry from "../../blueberry/index";
import { Helmet } from "react-helmet";
import ProductsSectionTemplate from "../templates/ProductsSectionTemplate";
import withTracker from "../../blueberry/connectPromise";
import CustomFields from "../components/CustomFields";
import { Wrapper } from "./style";
import { Section, PageContent, Heading } from "../sdk/Layout";
import { NotFound } from "../NoMatch";
import Listener from "../components/Listener";
import SEOFieldsHelmet from "../utils/SEOFieldsHelmet";

const getSlug = (slug, lang, brandSlug) => {
    if (brandSlug != null && brandSlug != "" && slug === null) return "home";
    return slug == null
        ? lang === "en"
            ? "home_eng"
            : lang === "pt"
            ? "home-pt"
            : "home"
        : slug;
};

class Page extends ProductsSectionTemplate {
    state = {
        menus: [],
    };

    static async getInitialData(props) {
        // const token = props.initialToken ? props.initialToken : (props.token ? props.token.token : "")
        let { lang, slug, brandSlug } = props.match.params;

        if (slug != null && slug.indexOf("?") > -1)
            slug = slug.substring(0, slug.indexOf("?"));

        const langF = lang ? lang : "es";
        const slugF = getSlug(slug, lang, brandSlug);

        return new Promise((resolve, reject) => {
            return Blueberry.page({ slug: slugF, lang: langF, brandSlug })
                .then((resp) => {
                    if (
                        resp &&
                        resp.customFields &&
                        resp.customFields.ambientBlock &&
                        resp.customFields.ambientBlock.length > 0
                    ) {
                        Blueberry.collectionChildren({
                            limit: 6,
                            slug: resp.customFields.ambientBlock[0].slug,
                        }).catch((err) => {});
                    }

                    if (
                        resp &&
                        resp.customFields &&
                        resp.customFields.recomendedProducts &&
                        resp.customFields.recomendedProducts !== ""
                    ) {
                        Blueberry.itemToUser({
                            limit: 14,
                            filter: resp.customFields.recomendedProducts,
                        }).catch(() => {});
                    }
                    resolve(resp);
                })
                .catch((err) => {
                    if (err.reason === "404") {
                        if (typeof window === "undefined") {
                            // reject("404")
                        }
                        resolve({
                            title: "404",
                            content: "",
                            protected: true,
                            status: "404",
                            seo: { title: "", description: "" },
                        });
                    }
                    resolve({
                        title: "Protected page",
                        content: "",
                        protected: true,
                        status: "not logged in",
                        seo: { title: "", description: "" },
                    });
                });
        });
    }

    componentDidUpdate(prevProps) {
        let { slug, brandSlug } = this.props.match.params;
        if (
            this.props.store.user &&
            this.props.store.user.id &&
            slug == null &&
            brandSlug == null
        ) {
            this.props.history.push(
                `/${this.props.match.params.lang}/account/check`
            );
        }
    }

    gotoThankYouPage = () => {
        const { lang } = this.props.match.params;

        switch (lang) {
            case "es":
                this.props.history.push(`/es/gracias-por-registrarse-full/`);
                break;
            case "en":
                this.props.history.push(`/en/gracias-por-registrarse-full/`);
                break;
            case "pt":
                this.props.history.push(`/pt/gracias-por-registrarse-full/`);
                break;
        }
    };

    removeHtml(html) {
        return html ? html.replace(/<\/?[^>]+(>|$)/g, "").trim() : "";
    }

    renderBody() {
        const { lang, slug } = this.props.match.params;
        const { childCollection, recomendations, user } = this.props.store;
        const { menusData } = this.props;

        const data = this.props.data;

        if (data && data.status === "404") {
            return (
                <>
                    <NotFound
                        menusData={menusData}
                        goToSearch={this.goToSearch}
                    />
                    <Listener user={user} action={this.props.refetch} />
                </>
            );
        }

        if (data && data.protected && this.props.store.user.id == null) {
            return <Listener user={user} action={this.props.refetch} />;
        }

        return (
            <>
                <SEOFieldsHelmet
                    seo={data ? data.seo : null}
                    lang={lang}
                    defaultTitle={data ? data.title : null}
                    defaultDescription={
                        data ? this.removeHtml(data.content) : null
                    }
                />
                <CustomFields
                    customFields={data ? data.customFields : {}}
                    _t={this._t}
                    top
                    location={this.props.location}
                />

                {data && data.content != null && data.content !== "" ? (
                    <Section>
                        {slug != null ? (
                            <Heading isCentered>
                                <h3>{data.title}</h3>
                            </Heading>
                        ) : null}
                        <Wrapper>
                            <PageContent
                                dangerouslySetInnerHTML={{
                                    __html: data ? data.content : "",
                                }}
                            />
                        </Wrapper>
                    </Section>
                ) : null}

                <CustomFields
                    customFields={data ? data.customFields : {}}
                    _t={this._t}
                    recomendations={recomendations}
                    location={this.props.location}
                    childCollection={childCollection}
                    gotoThankYouPage={this.gotoThankYouPage}
                    viewItem={this.viewItem}
                    toggleModalProduct={this.toggleModalProduct}
                />
                <Listener user={user} action={this.props.refetch} />
            </>
        );
    }
}

export default withTracker()(Page);

// export default withSSR(Page);
