import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ProductsSectionTemplate from "../../templates/ProductsSectionTemplate";
import SEOFieldsHelmet from "../../utils/SEOFieldsHelmet";
import withTracker from "../../../blueberry/connectPromise";
import { Hero, Section, Heading, Form } from "./style";
import { Container } from "../../sdk/Layout";
import lang_es from "./lang/lang_es";
import lang_en from "./lang/lang_en";
import lang_pt from "./lang/lang_pt";
import LeadForm from "../../components/LeadForm/index";
import ConsentManager from "../../components/Footer2/components/ConsentManager";
import Layout from "./components/Layout";

function getLang(lang) {
    switch (lang) {
        case "es":
            return lang_es;
        case "en":
            return lang_en;
        case "pt":
            return lang_pt;
    }
}

const configLeadForm = [{ formKey: "fGCZ5Y92n5SB6g4G7" }];

class MarcaPrivadaHome extends ProductsSectionTemplate {
    state = {};

    renderHeader = () => null;
    // renderFooter = () => null;

    renderBody() {
        const { lang } = this.props.match.params;

        const {
            seo,
            title
        } = getLang(lang).registrarse;

        return (
            <Layout whitelogo>

                <Hero>
                    <LazyLoadImage 
                        src="https://www.noritex.com/uplds/2023/8/21/XYPop8MLPZrt3gzWGntx-hero5.jpg"
                        alt="Noritex"
                    />
                    <div className="inner">
                        <Heading>
                            <h1 dangerouslySetInnerHTML={{ __html: title }} />
                        </Heading>
                    </div>
                </Hero>

                <Section>
                    <Container>
                        <Form>
                            <LeadForm
                                config={configLeadForm}
                                formTitle=""
                                // submitLabel={langJson.form.btn}
                                _t={this._t}
                                searchQuery={this.props.location.search}
                                onSuccess={this.gotoThankYouPage}
                                pathname={this.props.location.pathname}
                            />
                        </Form>
                    </Container>
                </Section>
            
                <SEOFieldsHelmet
                    seo={null}
                    lang={lang}
                    defaultDescription={seo.description}
                    defaultTitle={seo.title}
                    bodyClassName="reset-body back-top-btn-hidden"
                />

                <ConsentManager lang={lang} bypasConsent={true} />
            </Layout>
        );
    }
}

export default withTracker()(MarcaPrivadaHome);
