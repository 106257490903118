import React, { useState } from 'react';
import { Container } from "../../../sdk/Layout";
import { Header, Nav, Btn } from '../style';
import InternalLink from "../../../components/InternalLink";
import Icon from "./Icons";
import langEs from "../lang/lang_es";
import langEn from "../lang/lang_en";
import langPt from "../lang/lang_pt";

function getLang(lang) {
	switch (lang) {
		case "en":
			return langEn;
		case "pt":
			return langPt;
		default:
			return langEs;
	}
}

export default function HeaderComponent({ lang }) {
	const [open, setOpen] = useState(false);

	const { menu } = getLang(lang);

  return (
    <Header className="header-marca-privada">
      <div className="inner">
        <div>
            <InternalLink to="/marca-privada">
              <Icon className="logo" icon={lang === "en" ? "logo-en" : "logo-es"} />
            </InternalLink>
        </div>
        <div>
          <Nav className={open ? "open" : ""}>
            <ul>
              <li>
                <InternalLink to="/marca-privada">
                  {menu.home}
                </InternalLink>
              </li>
              <li>
                <InternalLink to="/marca-privada/nosotros">
				{menu.about}
                </InternalLink>
              </li>
              <li>
                <InternalLink to="/marca-privada/categorias">
				{menu.categories}
                </InternalLink>
              </li>
              <li>
                <InternalLink to="/marca-privada/beneficios">
				{menu.benefits}
                </InternalLink>
              </li>
              <li>
                <InternalLink to="/marca-privada/presencia">
				{menu.presence}
                </InternalLink>
              </li>
              <li className="btn">
                <InternalLink to="/marca-privada/registrarse">
				{menu.signup}
                </InternalLink>
              </li>
            </ul>
          </Nav>
          <span className="navicon" onClick={() => setOpen(prev => !prev)}>
            <Icon icon="bars" />
          </span>
        </div>
      </div>
    </Header>
  );
}