import styled from "styled-components";
import { Container, Section } from "../../sdk/Layout";

import { Button } from "../../sdk/Button";
import { Columns, Column, Title, Heading } from "../../sdk/Layout";

export const Wrapper = styled(Section)`
    background-color: ${props => props.theme.white};
    padding: 3% 0;
`;

export const Block = styled.div`
    figure {
        height: 100%;
        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
`;

export const PageTitle = styled(Heading)`
    h1 {
        font-size: 30px;
    }
`;

export const Text = styled.div`
    display: flex;
    height: 100%;
    align-items: center;
    background-color: ${props => props.theme.grayLight};
    > div {
        padding: 10%;
    }
    p {
        color: ${props => props.theme.primaryDark};
        font-size: 15px;
        line-height: 26px;
        + p {
            margin-top: 20px;
        }
    }
    @media (max-width: 768px) {
        p {
            font-size: 14px;
            line-height: 20px;
        }
    }
`;

export const Links = styled.div`
    padding-top: 20px;
    font-family: futura;
    font-size: 16px;
    a {
        font-size: 14px;
        letter-spacing: 1px;
        color: #000;
        padding: 5px 0;
        display: inline-block;
        text-transform: uppercase;
        text-decoration: underline;
        &:hover {
            text-decoration: none;
        }
    }
    @media (max-width: 768px) {
        span {
            display: block;
        }
        font-size: 14px;
    }
`;

export const WrapperActions = styled(Section)`
    padding: 3% 0;
    border-top: solid 1px ${props => props.theme.borderColor};
`;

export const Items = styled.div`
    display: flex;
    flex-wrap: wrap;
    @media (max-width: 540px) {
        flex-direction: column;
    }
`;

export const Item = styled.div`
    flex-basis: 20%;
    flex-grow: 1;
    text-align: center;
    padding: 1%;
    svg {
        width: 40px;
        height: 40px;
        object-fit: contain;
        margin-bottom: 10px;
        color: ${props => props.theme.primaryDark};
    }
    p {
        margin: 0;
        padding: 0;
        font-size: 15px;
        color: ${props => props.theme.gray};
        letter-spacing: 0;
        text-align: center;
        line-height: 21px;
        margin: auto;
        display: block;
        max-width: 300px;
    }
    a {
        display: inline-block;
        font-size: 16px;
        text-decoration: underline;
        margin-top: 20px;
        color: ${props => props.theme.primaryDark};
    }

    @media (max-width: 768px) {
        flex-basis: 33.333%;
        & ${Title} {
            font-size: 14px;
            margin-bottom: 10px;
        }
        p {
            max-width: 200px;
            font-size: 12px;
            line-height: 14px;
        }
        a {
            font-size: 14px;
            margin-top: 10px;
        }
    }

    @media (max-width: 540px) {
        & + & {
            margin-top: 20px;
        }
    }
`;

export const WrapperInfo = styled(Section)`
    background-color: ${props => props.theme.grayLight};
    padding: 5% 0;
`;

export const Info = styled(Column)`
    display: flex;
    align-items: center;
    padding: 5%;
    flex-basis: 50%;
`;

export const Row = styled.div`
    h2 {
        font-family: futura;
        font-size: 20px;
        padding: 0;
        margin-bottom: 5px;
        display: flex;
        align-items: center;
        margin-left: -5px;
    }
    & + & {
        margin-top: 30px;
    }
    p {
        font-size: 16px;
        margin: 0;
        padding: 0;
        a {
            color: ${props => props.theme.primaryDark};
            text-decoration: underline;
            &:hover {
                text-decoration: none;
            }
        }
    }

    @media (max-width: 768px) {
        & + & {
            margin-top: 20px;
        }
        h2 {
            font-size: 16px;
        }
        p {
            font-size: 14px;
        }
    }
`;

export const Map = styled(Column)`
    flex-basis: 50%;
    position: relative;
    &:before {
        display: block;
        content: "";
        padding-top: 80%;
    }
    iframe {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 10px;
        overflow: hidden;
    }

    @media (max-width: 768px) {
        margin-top: 20px;
    }
`;

export const LinksInfo = styled.div`
    padding-top: 20px;
    font-family: futura;
    font-size: 16px;
    a {
        font-size: 14px;
        letter-spacing: 1px;
        color: #000;
        padding: 5px 0;
        display: inline-block;
        text-transform: uppercase;
        text-decoration: underline;
        &:hover {
            text-decoration: none;
        }
    }
    @media (max-width: 768px) {
        span {
            display: block;
        }
        font-size: 14px;
    }
`;
