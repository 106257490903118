export default {
    privateLabelTitle: "Develop your private brand with us",
    privateLabelDescription:
        "Develop your private brand with Noritex, a company with more than 50 years of experience in the development of private brands.",
    title: "Our categories",
    products: [
        "Home Decor",
        "Back to School",
        "Activewear",
        "Christmas Decor",
        "Textile",
        "Religious Figurines",
    ],
    formTitle: "Contact us",
};
