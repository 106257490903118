import React from "react";
import MainTemplate from "../templates/MainTemplate";
import withTracker from "../../blueberry/connectPromise";
import { Helmet } from "react-helmet";
import CustomFields from "../components/CustomFields";
import InternalLink from "../components/InternalLink";
import Blueberry from "../../blueberry/index";
import Loader from "../components/Loader";

import validate from "../utils/validator";
import {
    Article,
    Wrapper,
    Top,
    Author,
    Share,
    Body,
    Image,
    Status,
} from "./stylePost";
import { Title, PageContent } from "../sdk/Layout";
import { Field, Control } from "../sdk/Form";
import { Button } from "../sdk/Button";
import { getDate } from "../utils";
import Icons from "./Icons";

import lang_es from "./lang/lang_es";
import lang_en from "./lang/lang_en";
import lang_pt from "./lang/lang_pt";
import { NotFound } from "../NoMatch";

function getLang(lang) {
    switch (lang) {
        case "es":
            return lang_es;
        case "en":
            return lang_en;
        case "pt":
            return lang_pt;
    }
}

class BlogPost extends MainTemplate {
    state = {
        menus: [],
        isFormSubmitted: false,
        formControls: {
            firstName: {
                value: "",
                valid: false,
                touched: false,
                validationRules: {
                    minLength: 3,
                    isRequired: true,
                },
            },
            lastName: {
                value: "",
                valid: false,
                touched: false,
                validationRules: {
                    minLength: 3,
                    isRequired: true,
                },
            },

            email: {
                value: "",
                valid: false,
                touched: false,
                validationRules: {
                    minLength: 3,
                    isRequired: true,
                    isEmail: true,
                },
            },
        },
    };

    static async getInitialData(props) {
        // const token = props.initialToken ? props.initialToken : (props.token ? props.token.token : "")
        let { lang, slug } = props.match.params;
        if (slug != null && slug.indexOf("?") > -1)
            slug = slug.substring(0, slug.indexOf("?"));
        const slugF =
            slug == null
                ? lang === "en"
                    ? "home_eng"
                    : lang === "pt"
                    ? "home-pt"
                    : "home"
                : slug;

        return new Promise((resolve, reject) => {
            return Blueberry.post(slugF)
                .then((resp) => {
                    resolve(resp);
                    if (typeof window !== "undefined" && analytics)
                        analytics.track("Blog Read", {
                            title: resp ? resp.title : "",
                            slug: resp ? resp.slug : "",
                        });
                })
                .catch((err) => {
                    console.log(err);
                    resolve({
                        data: {
                            title: "Protected page",
                            content: "",
                            protected: true,
                            status: "not logged in",
                            seo: { title: "", description: "" },
                        },
                    });
                });
        });
    }

    removeHtml(html) {
        return html ? html.replace(/<\/?[^>]+(>|$)/g, "").trim() : "";
    }

    validateForm(formElement) {
        if (formElement.valid && formElement.touched) return "is-valid";
        if (!formElement.valid && formElement.touched) return "is-invalid";
        return "";
    }

    setValue = (event) => {
        const name = event.target.name;
        const value = event.target.value;

        const updatedControls = {
            ...this.state.formControls,
        };
        const updatedFormElement = {
            ...updatedControls[name],
        };

        updatedFormElement.value = value;
        updatedFormElement.valid = validate(
            value,
            updatedFormElement.validationRules
        );
        updatedFormElement.touched = true;

        updatedControls[name] = updatedFormElement;

        this.setState({
            formControls: updatedControls,
        });
    };

    submitForm = (e) => {
        const { formControls } = this.state;
        let hasErrors = false;
        let formData = {};
        const updatedFormElement = { ...formControls };

        for (let formElementId in formControls) {
            if (!formControls[formElementId].valid) {
                hasErrors = true;
                updatedFormElement[formElementId].touched = true;
            }
            formData[formElementId] = formControls[formElementId].value;
            // formData += `${formElementId}=${formControls[formElementId].value}&`;
        }

        if (hasErrors) {
            this.setState({ formControls: updatedFormElement });
            return;
        }

        this.setState({ isUpdating: true });
        let form_key = this.props.data.form_key;

        Blueberry.formInsert({ fields: formData, form_key })
            .then((resp) => {
                if (resp !== "inserted") throw "error";
                if (typeof window !== "undefined" && analytics)
                    analytics.track("Blog Form");
                this.setState({
                    isUpdating: false,
                    success: true,
                    isFormSubmitted: true,
                });
            })
            .catch((err) => {
                this.setState({ isUpdating: false, success: true });
            });
    };

    share = (data, type) => {
        const shareData = {
            title: data.title,
            text: `Lee este blog en Noritex.com`,
            url: window.location.href,
        };

        if (typeof window !== "undefined" && analytics)
            analytics.track("Blog Shared");
        let url = null;
        switch (type) {
            case "twitter":
                url = "https://twitter.com/intent/tweet/";
                break;
            case "facebook":
                url = "https://www.facebook.com/sharer/sharer.php";
                break;
            case "pinterest":
                url = "https://www.pinterest.com/pin/create/button/";
                break;
            case "whatsapp":
                url = "https://api.whatsapp.com/send";
                shareData.text += ` ${shareData.url}`;
                break;
            default:
                url = null;
                break;
        }

        if (url == null) {
            try {
                navigator.share(shareData);
            } catch (err) {}
        } else {
            var popWidth = 600,
                popHeight = 480,
                left = window.innerWidth / 2 - popWidth / 2 + window.screenX,
                top = window.innerHeight / 2 - popHeight / 2 + window.screenY,
                popParams =
                    "scrollbars=no, width=" +
                    popWidth +
                    ", height=" +
                    popHeight +
                    ", top=" +
                    top +
                    ", left=" +
                    left,
                newWindow = window.open(
                    `${url}?text=${shareData.text}&url=${shareData.url}&title=${
                        shareData.title
                    }&u=${encodeURIComponent(shareData.url)}`,
                    "",
                    popParams
                );

            if (window.focus) {
                newWindow.focus();
            }
        }
    };

    renderLeadForm = () => {
        const langT = getLang(this.props.match.params.lang);
        const { firstName, lastName, email, contactPhoneNumber, comments } =
            this.state.formControls;
        if (this.state.isUpdating) {
            return (
                <div className="lead-form">
                    <Loader />
                </div>
            );
        }
        return (
            <Status>
                <div>
                    <h2>
                        Para continuar leyendo por favor, <br /> rellene el
                        formulario
                    </h2>
                    <form>
                        <Field>
                            <label>{this._t("firstName")}</label>
                            <Control isExpanded>
                                <input
                                    type="text"
                                    name="firstName"
                                    className={this.validateForm(firstName)}
                                    value={firstName.value}
                                    onChange={this.setValue}
                                    required
                                />
                            </Control>
                        </Field>
                        <Field>
                            <label>{this._t("lastName")}</label>
                            <Control isExpanded>
                                <input
                                    type="text"
                                    name="lastName"
                                    className={this.validateForm(lastName)}
                                    value={lastName.value}
                                    onChange={this.setValue}
                                    required
                                />
                            </Control>
                        </Field>

                        <Field>
                            <label>Email</label>
                            <Control isExpanded>
                                <input
                                    type="email"
                                    name="email"
                                    className={this.validateForm(email)}
                                    value={email.value}
                                    onChange={this.setValue}
                                    required
                                />
                            </Control>
                        </Field>

                        <Field>
                            <Button isFullWidth onClick={this.submitForm}>
                                {langT["acceptAndContinueReading"]}
                            </Button>
                        </Field>
                    </form>
                </div>
            </Status>
        );
    };

    renderPrivate = () => {
        const langT = getLang(this.props.match.params.lang);
        return (
            <Status>
                <div>
                    <h2>
                        {langT["continueReading"]}{" "}
                        <a
                            href={`/${this.props.match.params.lang}/pages/registro-es/`}
                            onClick={this.gotoRegister}
                        >
                            {this._t("registerHere")}
                        </a>
                    </h2>
                    <p>
                        {langT["doYouHaveAnAccount"]}{" "}
                        <InternalLink to="/account/login/">
                            {langT["loginHere"]}
                        </InternalLink>
                    </p>
                </div>
            </Status>
        );
    };

    renderPost = (data) => {
        if (
            data.showOnStatus === "private" &&
            this.props.store.user.id == null
        ) {
            return <div>{this.renderPrivate()}</div>;
        } else if (
            data.showOnStatus === "form" &&
            !this.state.isFormSubmitted
        ) {
            return (
                <>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: data ? data.content : "",
                        }}
                    />
                    {this.renderLeadForm()}
                </>
            );
        } else if (data.showOnStatus === "form" && this.state.isFormSubmitted) {
            return (
                <div
                    dangerouslySetInnerHTML={{
                        __html: data ? data.thankYouContent : "",
                    }}
                />
            );
        }

        return (
            <div
                dangerouslySetInnerHTML={{ __html: data ? data.content : "" }}
            />
        );
    };

    renderBody() {
        const { lang, slug } = this.props.match.params;
        const { menusData } = this.props;
        const { recomendations, childCollection } = this.props.store;

        if (this.props.data && this.props.data.status === "not-found") {
            return (
                <NotFound
                    menusData={menusData}
                    _t={this._t}
                    goToSearch={this.goToSearch}
                />
            );
        }

        const data = this.props.data
            ? this.props.data
            : {
                  content: "",
                  title: "",
                  seo: {
                      title: "Noritex",
                      page: "Noritex es una tienda en linea para comprar mayorista de decoracion",
                  },
              };
        const { pathname } = this.props.location;

        const title =
            data.seo && data.seo.title != "" ? data.seo.title : data.title;
        const description =
            data.seo && data.seo.description != ""
                ? data.seo.description
                : this.removeHtml(data.content);
        const image =
            data.seo && data.seo.images && data.seo.images.length > 0 != ""
                ? data.seo.images[0]
                : "https://www.noritex.com/facebook-ntx.jpg";
        const structured =
            data.seo && data.seo.strcutured != "" ? data.seo.strcutured : "";

        const baseUrl = this.stripLangDir(lang, pathname);
        const enUrl = this.addLangDir("en", pathname, baseUrl);
        const esUrl = this.addLangDir("es", pathname, baseUrl);

        return (
            <>
                <Helmet htmlAttributes={{ lang: lang }}>
                    <title>{title}</title>
                    <meta name="description" content={description} />
                    <meta
                        name="keywords"
                        content="noritex,wholesale,decoracion,mayorista,hogar,navidad,textil,religioso"
                    />
                    {/*Alternate*/}
                    <link rel="alternate" hreflang="es" href={`${esUrl}`} />
                    <link rel="alternate" hreflang="en" href={`${enUrl}`} />
                    {/*Facebook*/}
                    <meta property="og:site_name" content="Noritex" />
                    <meta
                        property="og:url"
                        content={`https://www.noritex.com${pathname}`}
                    />
                    <meta property="og:title" content={title} />
                    <meta property="og:image" content={image} />
                    <meta property="og:type" content="website" />
                    <meta property="og:description" content={description} />
                    {/*Twitter*/}
                    <meta name="twitter:title" content={title} />
                    <meta name="twitter:description" content={description} />

                    <script type="application/ld+json">{structured}</script>
                </Helmet>

                <Article>
                    <Wrapper>
                        {slug != null ? (
                            <Top>
                                <Author>
                                    {/* <img src="./nessim.jpg" alt="Nessim Btesh" /> */}
                                    <div>
                                        <p>Noritex S.A</p>
                                        <span>
                                            {data.createdAt
                                                ? getDate(
                                                      new Date(data.createdAt)
                                                  )
                                                : null}
                                        </span>
                                    </div>
                                </Author>
                                <div>
                                    <Share>
                                        <span>
                                            <Icons
                                                onClick={() =>
                                                    this.share(data, "native")
                                                }
                                                icon="share-square"
                                            ></Icons>
                                        </span>
                                        <div>
                                            <Icons
                                                onClick={() =>
                                                    this.share(data, "twitter")
                                                }
                                                icon="twitter-square"
                                            ></Icons>
                                            <Icons
                                                onClick={() =>
                                                    this.share(data, "facebook")
                                                }
                                                icon="facebook-square"
                                            ></Icons>
                                            <Icons
                                                onClick={() =>
                                                    this.share(
                                                        data,
                                                        "pinterest"
                                                    )
                                                }
                                                icon="pinterest-square"
                                            ></Icons>
                                            <Icons
                                                onClick={() =>
                                                    this.share(data, "whatsapp")
                                                }
                                                icon="whatsapp-square"
                                            ></Icons>
                                        </div>
                                    </Share>
                                </div>
                            </Top>
                        ) : null}
                        <Body>
                            <Title size="2">{data.title}</Title>
                            <Image>
                                {/* <a href=""> */}
                                {data.images && data.images.length > 0 ? (
                                    <img
                                        src={
                                            data.images &&
                                            data.images.length > 0
                                                ? data.images[0]
                                                : ""
                                        }
                                        alt={`${data.title}`}
                                    />
                                ) : null}
                                {/* </a> */}
                            </Image>
                            <PageContent>{this.renderPost(data)}</PageContent>
                        </Body>
                    </Wrapper>
                </Article>
                <CustomFields
                    customFields={data.customFields}
                    _t={this._t}
                    recomendations={recomendations}
                    location={this.props.location}
                    childCollection={childCollection}
                    gotoThankYouPage={this.gotoThankYouPage}
                    viewItem={this.viewItem}
                    toggleModalProduct={this.toggleModalProduct}
                />
            </>
        );
    }
}

export default withTracker()(BlogPost);

// export default withSSR(Page);
