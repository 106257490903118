import React from "react";
import { Preloader } from "../../../sdk/Elements";
import { Heading, Container, Column, Row } from "../../../sdk/Layout";
import { WrapperTopQuestions, Cols, Left, Text } from "../style";
import QuickActions from "./QuickActions";

function TopQuestions({
    featured,
    removeHtml,
    isLoading,
    isSearching,
    langJson,
}) {
    const questionsColumn1 = [];
    const questionsColumn2 = [];

    if (isLoading) {
        for (let index = 0; index < 3; index++) {
            questionsColumn1.push(
                <Row key={`left-faq-top-${index}`}>
                    <h5>
                        <Preloader half />
                    </h5>
                    <p>
                        <Preloader />
                    </p>
                </Row>
            );
            questionsColumn2.push(
                <Row key={`right-faq-top-${index}`}>
                    <h5>
                        <Preloader half />
                    </h5>
                    <p>
                        <Preloader />
                    </p>
                </Row>
            );
        }
    } else {
        featured.map((index, i) => {
            if (i % 2 === 0) {
                questionsColumn1.push(
                    <Row key={index._id}>
                        <h5>{index.title}</h5>
                        <p>{removeHtml(index.content)}</p>
                    </Row>
                );
            } else {
                questionsColumn2.push(
                    <Row key={index._id}>
                        <h5>{index.title}</h5>
                        <p>{removeHtml(index.content)}</p>
                    </Row>
                );
            }
        });
    }

    return (
        <WrapperTopQuestions isHidden={isSearching}>
            <Container>
                <Cols>
                    <Left isNarrow>
                        <QuickActions langJson={langJson}></QuickActions>
                    </Left>
                    <Column>
                        <Heading isCentered>
                            <h3>{langJson.frequentQuestions}</h3>
                        </Heading>
                        <Cols>
                            <Column>
                                <Text>{questionsColumn1}</Text>
                            </Column>
                            <Column>
                                <Text>{questionsColumn2}</Text>
                            </Column>
                        </Cols>
                    </Column>
                </Cols>
            </Container>
        </WrapperTopQuestions>
    );
}

export default TopQuestions;
