import React from "react";
import styled from "styled-components";
import withTracker from "../../blueberry/connect";
import MainTemplate from "../templates/MainTemplate";
import SearchBox from "./components/SearchBox";
import Logo from "../components/Logo";
import InternalLink from "../components/InternalLink";
import { useTranslation } from "../../lang";

const Wrapper = styled.div`
    height: 100vh;
    display: flex;
    align-items: center;
    background-color: #112c55;
    background-image: url("https://www.noritex.com/REBBpdezk8FZ874X5Depositphotos_63750971_l-2015.jpg");
    background-size: cover;
    background-position: center;
    padding: 0 10%;

    @media (max-width: 768px) {
        padding: 0px 10px;
    }
`;

const Content = styled.div`
    font-size: 16px;
    color: #fff;
    margin-top: -100px;
    width: 100%;
    max-width: 500px;
    h1 {
        margin-bottom: 10px;
    }
    p {
        font-size: 16px;
        padding-bottom: 20px;
        color: #bfc2c2;
    }
    a {
        color: #fff;
        text-decoration: none;
        span {
            display: inline-block;
            padding-bottom: 2px;
            color: #fff;
            border-bottom: solid 1px rgba(255, 255, 255, 0.5);
            transition: all 200ms linear;
        }
        &:hover span {
            border-bottom-color: white;
        }
    }
    form {
        margin-bottom: 50px;
    }
`;

const Brand = styled.div`
    position: absolute;
    bottom: 10px;
    left: 10px;
    width: 100%;
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 100px;
        color: #fff;
        fill: #fff;
        margin-right: 20px;
        path {
            color: #fff;
            fill: #fff;
        }
    }
    nav {
        display: inline-block;
        vertical-align: middle;
        a {
            color: #fff;
            font-size: 14px;
            display: inline-block;
            vertical-align: baseline;
            padding: 3px 7px;
            font-size: 12px;
            opacity: 0.6;
            transition: all 200ms linear;
            &:hover {
                opacity: 1;
            }
        }
    }

    @media (max-width: 768px) {
        text-align: center;

        nav {
            display: block;
            padding-top: 10px;
        }
    }
`;

export const NotFound = ({ goToSearch, menusData }) => {
    const [_t] = useTranslation();

    return (
        <Wrapper>
            <div id="is-HTML-detect-404-ID-99986464" />
            <Content>
                <h1>{_t("somethingWengWrong")}</h1>
                <p>{_t("sorryYouEndedUpHere")}</p>
                <SearchBox
                    goToSearch={goToSearch}
                    placeholder={_t("searchPlaceholder")}
                />
                <p>
                    {_t("goto")}{" "}
                    <InternalLink to="/">
                        <span>{_t("home")}</span>
                    </InternalLink>
                </p>
            </Content>

            <Brand>
                <Logo className="white-logo" />
                {menusData && menusData[0] ? (
                    <nav>
                        {menusData[0].menu.map((index, i) => (
                            <InternalLink key={`index-no-match${i}`} {...index}>
                                {index.title}
                            </InternalLink>
                        ))}
                    </nav>
                ) : null}
            </Brand>
        </Wrapper>
    );
};

class NoMatch extends MainTemplate {
    constructor(props) {
        super(props);
        const pathname = props.location.pathname;
        const paths = pathname.split("/");
        props.match.params.lang = paths[1];
    }

    // renderBody = () => {};

    renderBody() {
        const { menusData } = this.props;
        return (
            <NotFound
                menusData={menusData}
                _t={this._t}
                goToSearch={this.goToSearch}
            />
        );
    }
}

export default withTracker()(NoMatch);
