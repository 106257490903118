import React from "react";

function Icons({ icon, className }) {
    if (icon === "sparkles") {
        return (
            <svg className={className} width={42} height={42} viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.5138 29.0082L14.8462 34.8462L13.1785 29.0082C12.7474 27.5002 11.9393 26.1269 10.8303 25.0179C9.72127 23.9089 8.34798 23.1008 6.84 22.6697L1 21L6.83795 19.3323C8.34593 18.9013 9.71922 18.0931 10.8282 16.9841C11.9372 15.8751 12.7454 14.5018 13.1764 12.9938L14.8462 7.15385L16.5138 12.9918C16.9449 14.4998 17.753 15.8731 18.862 16.9821C19.971 18.0911 21.3443 18.8992 22.8523 19.3303L28.6923 21L22.8544 22.6677C21.3464 23.0987 19.9731 23.9069 18.8641 25.0159C17.7551 26.1249 16.9469 27.4982 16.5159 29.0062L16.5138 29.0082ZM33.839 14.2615L33.3077 16.3846L32.7764 14.2615C32.4723 13.0443 31.8431 11.9326 30.9561 11.0452C30.0691 10.1578 28.9576 9.52819 27.7405 9.22359L25.6154 8.69231L27.7405 8.16103C28.9576 7.85643 30.0691 7.22679 30.9561 6.33943C31.8431 5.45206 32.4723 4.34033 32.7764 3.12308L33.3077 1L33.839 3.12308C34.1433 4.34059 34.7727 5.45249 35.6601 6.33988C36.5475 7.22726 37.6594 7.85675 38.8769 8.16103L41 8.69231L38.8769 9.22359C37.6594 9.52786 36.5475 10.1574 35.6601 11.0447C34.7727 11.9321 34.1433 13.044 33.839 14.2615ZM31.039 38.5733L30.2308 41L29.4226 38.5733C29.196 37.8936 28.8143 37.276 28.3076 36.7693C27.801 36.2627 27.1833 35.8809 26.5036 35.6544L24.0769 34.8462L26.5036 34.038C27.1833 33.8114 27.801 33.4296 28.3076 32.923C28.8143 32.4164 29.196 31.7987 29.4226 31.119L30.2308 28.6923L31.039 31.119C31.2656 31.7987 31.6473 32.4164 32.1539 32.923C32.6606 33.4296 33.2782 33.8114 33.9579 34.038L36.3846 34.8462L33.9579 35.6544C33.2782 35.8809 32.6606 36.2627 32.1539 36.7693C31.6473 37.276 31.2656 37.8936 31.039 38.5733Z" stroke="#112C55" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
            </svg>

        );
    }
    if (icon === "stack") {
        return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M6 6.878V6a2.25 2.25 0 012.25-2.25h7.5A2.25 2.25 0 0118 6v.878m-12 0c.235-.083.487-.128.75-.128h10.5c.263 0 .515.045.75.128m-12 0A2.25 2.25 0 004.5 9v.878m13.5-3A2.25 2.25 0 0119.5 9v.878m0 0a2.246 2.246 0 00-.75-.128H5.25c-.263 0-.515.045-.75.128m15 0A2.25 2.25 0 0121 12v6a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 18v-6c0-.98.626-1.813 1.5-2.122" />
        </svg>
        );
    }
    if (icon === "like") {
        return (
            <svg className={className} width={42} height={42} viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.99077 19.3333C11.6441 19.3333 13.1354 18.3422 14.1569 16.9333C15.7492 14.7325 17.7481 12.916 20.0256 11.6C21.5087 10.7467 22.7949 9.47556 23.4164 7.78889C23.8529 6.60722 24.0771 5.34611 24.0769 4.07333V2.66667C24.0769 2.22464 24.239 1.80072 24.5275 1.48816C24.816 1.17559 25.2073 1 25.6154 1C26.8394 1 28.0134 1.52678 28.8789 2.46447C29.7445 3.40215 30.2308 4.67392 30.2308 6C30.2308 8.56 29.6974 10.9844 28.7477 13.1511C28.202 14.3911 28.9672 16 30.2349 16H36.6472C38.7518 16 40.6369 17.5422 40.8605 19.8111C40.9528 20.7489 41 21.7 41 22.6667C41.0085 28.7474 39.0903 34.6472 35.5661 39.38C34.7702 40.4511 33.5415 41 32.2738 41H24.0359C23.0451 41 22.0585 40.8267 21.1169 40.4889L14.7292 38.1778C13.7879 37.8386 12.8023 37.6661 11.8103 37.6667H8.49539M8.49539 37.6667C8.66564 38.1222 8.85026 38.5667 9.04923 39.0044C9.45333 39.8933 8.88923 41 7.97641 41H6.11385C4.29026 41 2.60001 39.8489 2.06872 37.96C1.35825 35.4339 0.998082 32.8074 1.00001 30.1667C1.00001 26.7156 1.60514 23.42 2.70462 20.3933C3.33231 18.6733 4.93231 17.6667 6.64103 17.6667H8.80103C9.76923 17.6667 10.3292 18.9022 9.82667 19.8C8.07465 22.9243 7.15062 26.511 7.1559 30.1667C7.1559 32.82 7.6318 35.3511 8.49744 37.6667H8.49539ZM25.6154 16H30.2308" stroke="#112C55" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        );
    }
    if (icon === "rocket") {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M15.59 14.37a6 6 0 01-5.84 7.38v-4.8m5.84-2.58a14.98 14.98 0 006.16-12.12A14.98 14.98 0 009.631 8.41m5.96 5.96a14.926 14.926 0 01-5.841 2.58m-.119-8.54a6 6 0 00-7.381 5.84h4.8m2.581-5.84a14.927 14.927 0 00-2.58 5.84m2.699 2.7c-.103.021-.207.041-.311.06a15.09 15.09 0 01-2.448-2.448 14.9 14.9 0 01.06-.312m-2.24 2.39a4.493 4.493 0 00-1.757 4.306 4.493 4.493 0 004.306-1.758M16.5 9a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z" />
            </svg>
        );
    }

	if (icon === "logo-es") {
		return (
			<svg className={className} viewBox="0 0 731.37 177.15">
				<g>
					<polygon points="62.89 62.89 45.81 62.89 35.71 62.89 0 62.89 0 109.93 35.71 109.93 45.81 109.93 62.89 109.93 62.89 110.77 62.89 130.5 62.89 131.34 62.89 156.57 62.89 177.15 108.7 177.15 108.7 156.57 108.7 131.34 108.7 130.5 108.7 110.77 108.7 109.93 108.7 84.69 108.7 62.89 81.52 62.89 62.89 62.89"/>
					<polygon points="143.63 0 125.78 0 115.68 0 97.83 0 87.73 0 69.88 0 59.78 0 45.81 0 41.93 0 13.97 0 0 0 0 45.81 13.97 45.81 41.93 45.81 45.81 45.81 59.78 45.81 69.88 45.81 87.73 45.81 97.83 45.81 115.68 45.81 125.78 45.81 125.78 59.14 125.78 80.87 125.78 83.2 125.78 104.94 125.78 107.27 125.78 129.01 125.78 131.34 125.78 153.08 125.78 177.15 171.59 177.15 171.59 153.08 171.59 131.34 171.59 129.01 171.59 107.27 171.59 104.94 171.59 83.2 171.59 80.87 171.59 59.14 171.59 45.81 171.59 35.07 171.59 0 143.63 0"/>
					<rect x="0" y="131.74" width="45.81" height="45.4" />
				</g>
				<g>
					<path d="M215.64,76.18h-15.54V1.11h13.38l27.03,32.95L267.49,1.11h13.43v75.08h-15.59V26.15l-23.66,27.97h-2.49l-23.55-27.97v50.03Z"/>
					<path d="M316.37,76.18h-16.86L329.86,1.22h18.08l30.35,74.97h-16.86l-5.92-14.82h-33.39l-5.75,14.82ZM338.88,17.41l-12.55,31.46h25.15l-12.6-31.46Z"/>
					<path d="M414.53,76.18h-15.87V1.11h35.77c7.55,0,13.85,2.37,18.88,7.1,5.03,4.74,7.55,10.72,7.55,17.94,0,5.31-1.41,9.99-4.23,14.04-2.82,4.05-6.59,7.04-11.31,8.96l15.98,27.03h-18.02l-14.48-25.04h-14.26v25.04ZM414.53,14.71v23.11h17.75c3.61,0,6.58-1.11,8.9-3.32,2.32-2.21,3.48-4.99,3.48-8.35s-1.2-6.19-3.59-8.29c-2.4-2.1-5.33-3.15-8.79-3.15h-17.75Z"/>
					<path d="M540.84,47.1h16.86c-1.7,9.1-6,16.41-12.91,21.92-6.91,5.51-15.21,8.26-24.9,8.26-7.59,0-14.43-1.66-20.51-4.98-6.08-3.32-10.83-7.92-14.24-13.82-3.41-5.9-5.11-12.53-5.11-19.9s1.7-13.94,5.11-19.82c3.41-5.88,8.16-10.48,14.26-13.79,6.1-3.32,12.96-4.98,20.59-4.98,9.47,0,17.64,2.64,24.52,7.93,6.87,5.29,11.16,12.26,12.85,20.92h-16.75c-1.51-4.42-4.16-7.89-7.93-10.39-3.78-2.51-8.08-3.76-12.91-3.76-6.49,0-11.94,2.21-16.36,6.63-4.42,4.42-6.63,10.17-6.63,17.25,0,3.54.61,6.82,1.82,9.84,1.22,3.02,2.86,5.56,4.92,7.6,2.06,2.05,4.51,3.65,7.33,4.81,2.82,1.16,5.8,1.74,8.93,1.74,5.01,0,9.42-1.35,13.21-4.06,3.8-2.71,6.41-6.51,7.85-11.42Z"/>
					<path d="M592.56,76.18h-16.86L606.05,1.22h18.08l30.35,74.97h-16.86l-5.92-14.82h-33.39l-5.75,14.82ZM615.06,17.41l-12.55,31.46h25.15l-12.6-31.46Z"/>
					<path d="M672.05,52.85h16.64c.26,3.39,1.67,6.05,4.23,7.99,2.56,1.94,5.72,2.9,9.48,2.9,3.54,0,6.53-.82,8.98-2.46,2.45-1.64,3.68-3.92,3.68-6.83,0-1.29-.34-2.47-1.02-3.54-.68-1.07-1.42-1.92-2.21-2.54-.79-.63-2.01-1.24-3.65-1.85s-2.9-1.03-3.79-1.27c-.88-.24-2.27-.58-4.15-1.02-.63-.15-1.09-.26-1.38-.33-1.73-.41-3.07-.73-4.01-.97-.94-.24-2.26-.61-3.95-1.11-1.7-.5-3.02-.96-3.98-1.38-.96-.42-2.15-.99-3.57-1.69-1.42-.7-2.55-1.41-3.4-2.13-.85-.72-1.75-1.59-2.71-2.63-.96-1.03-1.71-2.13-2.24-3.29-.53-1.16-.98-2.5-1.33-4.01-.35-1.51-.53-3.13-.53-4.87,0-6.82,2.52-12.16,7.55-16.03,5.03-3.87,11.91-5.8,20.65-5.8s15.1,2.04,19.99,6.11c4.88,4.07,7.47,9.61,7.77,16.61h-16.53c-.15-2.91-1.34-5.21-3.57-6.88-2.23-1.68-4.97-2.52-8.21-2.52s-6.03.71-8.24,2.13c-2.21,1.42-3.32,3.44-3.32,6.05,0,.74.1,1.42.3,2.05.2.63.53,1.2,1,1.71.46.52.92.99,1.38,1.41.46.42,1.09.82,1.88,1.19.79.37,1.47.67,2.05.91.57.24,1.35.51,2.35.8,1,.3,1.77.52,2.32.66.55.15,1.35.34,2.4.58,1.05.24,1.78.41,2.18.53,2.17.52,3.94.95,5.31,1.3,1.36.35,3.07.88,5.11,1.6,2.05.72,3.72,1.44,5.03,2.16s2.71,1.66,4.2,2.82c1.49,1.16,2.67,2.41,3.54,3.76.87,1.35,1.59,2.96,2.18,4.84.59,1.88.88,3.94.88,6.19,0,7.3-2.7,13-8.1,17.11-5.4,4.11-12.5,6.16-21.31,6.16s-16.01-2.22-21.39-6.66c-5.38-4.44-8.22-10.37-8.51-17.77Z"/>
					<g>
						<path d="M215.97,176.04h-15.87v-75.08h35.22c7.55,0,13.84,2.4,18.85,7.21s7.52,10.9,7.52,18.27-2.5,13.53-7.49,18.35c-4.99,4.83-11.29,7.24-18.88,7.24h-19.35v23.99ZM215.97,114.68v23.66h17.19c3.61,0,6.57-1.11,8.87-3.32,2.3-2.21,3.46-5.03,3.46-8.46s-1.15-6.27-3.46-8.51c-2.3-2.25-5.26-3.37-8.87-3.37h-17.19Z"/>
						<path d="M288.17,176.04h-15.87v-75.08h35.77c7.55,0,13.85,2.37,18.88,7.1,5.03,4.74,7.55,10.72,7.55,17.94,0,5.31-1.41,9.99-4.23,14.04-2.82,4.05-6.59,7.04-11.31,8.96l15.98,27.03h-18.02l-14.48-25.04h-14.26v25.04ZM288.17,114.57v23.11h17.75c3.61,0,6.58-1.11,8.9-3.32,2.32-2.21,3.48-4.99,3.48-8.35s-1.2-6.19-3.59-8.29c-2.4-2.1-5.33-3.15-8.79-3.15h-17.75Z"/>
						<path d="M347.33,176.04v-75.08h15.87v75.08h-15.87Z"/>
						<path d="M387.81,100.97l20.73,57.55,20.84-57.55h16.75l-28.86,75.08h-17.47l-28.75-75.08h16.75Z"/>
						<path d="M455.12,176.04h-16.86l30.35-74.97h18.08l30.35,74.97h-16.86l-5.92-14.82h-33.39l-5.75,14.82ZM477.62,117.28l-12.55,31.46h25.15l-12.6-31.46Z"/>
						<path d="M556.19,176.04h-33.5v-75.08h33.39c7.3,0,13.75,1.62,19.35,4.86,5.6,3.24,9.9,7.7,12.88,13.38s4.48,12.13,4.48,19.35-1.49,13.61-4.48,19.27c-2.99,5.66-7.27,10.11-12.85,13.35-5.58,3.24-12.01,4.87-19.27,4.87ZM538.56,114.68v47.66h16.97c4.39,0,8.19-1.07,11.42-3.21,3.22-2.14,5.62-4.98,7.19-8.51,1.57-3.54,2.35-7.55,2.35-12.05s-.78-8.58-2.35-12.14c-1.57-3.56-3.95-6.4-7.16-8.54-3.21-2.14-7.02-3.21-11.44-3.21h-16.97Z"/>
						<path d="M607.44,176.04h-16.86l30.35-74.97h18.08l30.35,74.97h-16.86l-5.92-14.82h-33.39l-5.75,14.82ZM629.94,117.28l-12.55,31.46h25.15l-12.6-31.46Z"/>
						<path d="M671.07,152.71h16.64c.26,3.39,1.67,6.05,4.23,7.99,2.56,1.93,5.72,2.9,9.48,2.9,3.54,0,6.53-.82,8.98-2.46,2.45-1.64,3.68-3.92,3.68-6.83,0-1.29-.34-2.47-1.02-3.54s-1.42-1.92-2.21-2.54c-.79-.63-2.01-1.24-3.65-1.85-1.64-.61-2.9-1.03-3.79-1.27-.88-.24-2.27-.58-4.15-1.02-.63-.15-1.09-.26-1.38-.33-1.73-.41-3.07-.73-4.01-.97-.94-.24-2.26-.61-3.95-1.11-1.7-.5-3.02-.96-3.98-1.38-.96-.42-2.15-.99-3.57-1.69s-2.55-1.41-3.4-2.13c-.85-.72-1.75-1.59-2.71-2.63-.96-1.03-1.7-2.13-2.24-3.29-.53-1.16-.98-2.5-1.33-4.01-.35-1.51-.53-3.13-.53-4.87,0-6.82,2.52-12.16,7.55-16.03,5.03-3.87,11.91-5.81,20.65-5.81s15.1,2.04,19.99,6.11c4.88,4.07,7.47,9.61,7.77,16.61h-16.53c-.15-2.91-1.34-5.21-3.57-6.88-2.23-1.68-4.97-2.52-8.21-2.52s-6.03.71-8.24,2.13c-2.21,1.42-3.32,3.44-3.32,6.05,0,.74.1,1.42.3,2.05.2.63.53,1.2.99,1.71.46.52.92.99,1.38,1.41.46.42,1.09.82,1.88,1.19.79.37,1.47.67,2.05.91.57.24,1.35.51,2.35.8,1,.29,1.77.52,2.32.66.55.15,1.35.34,2.41.58,1.05.24,1.78.41,2.18.53,2.17.52,3.94.95,5.31,1.3,1.36.35,3.07.88,5.11,1.6,2.05.72,3.72,1.44,5.03,2.16,1.31.72,2.71,1.66,4.2,2.82s2.67,2.41,3.54,3.76c.87,1.35,1.59,2.96,2.18,4.84.59,1.88.88,3.94.88,6.19,0,7.3-2.7,13-8.1,17.11-5.4,4.11-12.5,6.16-21.31,6.16s-16.01-2.22-21.4-6.66c-5.38-4.44-8.22-10.37-8.51-17.77Z"/>
					</g>
				</g>
			</svg>
		);
	}

	if (icon === "logo-en") {
		return (
			<svg className={className} viewBox="0 0 792 612">
				<g>
					<polygon points="111.81 279.29 94.05 279.29 83.56 279.29 46.42 279.29 46.42 328.2 83.56 328.2 94.05 328.2 111.81 328.2 111.81 329.08 111.81 349.59 111.81 350.47 111.81 376.7 111.81 398.1 159.44 398.1 159.44 376.7 159.44 350.47 159.44 349.59 159.44 329.08 159.44 328.2 159.44 301.97 159.44 279.29 131.19 279.29 111.81 279.29"/>
					<polygon points="195.76 213.91 177.2 213.91 166.7 213.91 148.14 213.91 137.64 213.91 119.08 213.91 108.58 213.91 94.05 213.91 90.02 213.91 60.95 213.91 46.42 213.91 46.42 261.54 60.95 261.54 90.02 261.54 94.05 261.54 108.58 261.54 119.08 261.54 137.64 261.54 148.14 261.54 166.7 261.54 177.2 261.54 177.2 275.39 177.2 298 177.2 300.42 177.2 323.02 177.2 325.44 177.2 348.05 177.2 350.47 177.2 373.07 177.2 398.1 224.83 398.1 224.83 373.07 224.83 350.47 224.83 348.05 224.83 325.44 224.83 323.02 224.83 300.42 224.83 298 224.83 275.39 224.83 261.54 224.83 250.37 224.83 213.91 195.76 213.91"/>
					<rect x="46.42" y="350.89" width="47.63" height="47.21"/>
				</g>
				<g>
					<path d="M271.66,295.23h-17.19v-81.32h38.15c8.18,0,14.99,2.61,20.42,7.82,5.43,5.21,8.14,11.81,8.14,19.79s-2.71,14.65-8.11,19.88c-5.41,5.23-12.23,7.84-20.45,7.84h-20.96v25.99ZM271.66,228.76v25.63h18.62c3.91,0,7.12-1.2,9.61-3.59,2.49-2.4,3.74-5.45,3.74-9.16s-1.25-6.79-3.74-9.22c-2.5-2.43-5.7-3.65-9.61-3.65h-18.62Z"/>
					<path d="M353.55,295.23h-17.19v-81.32h38.74c8.18,0,15,2.57,20.45,7.69,5.45,5.13,8.17,11.61,8.17,19.43,0,5.75-1.53,10.82-4.58,15.21-3.05,4.39-7.14,7.63-12.25,9.7l17.31,29.28h-19.52l-15.69-27.13h-15.45v27.13ZM353.55,228.64v25.03h19.22c3.91,0,7.13-1.2,9.64-3.59,2.51-2.4,3.77-5.41,3.77-9.04s-1.3-6.71-3.89-8.98c-2.6-2.28-5.77-3.41-9.52-3.41h-19.22Z"/>
					<path d="M417.63,295.23v-81.32h17.19v81.32h-17.19Z"/>
					<path d="M468.53,213.9l22.46,62.34,22.58-62.34h18.14l-31.26,81.32h-18.92l-31.14-81.32h18.14Z"/>
					<path d="M540.99,295.23h-18.26l32.88-81.2h19.58l32.88,81.2h-18.27l-6.41-16.05h-36.17l-6.23,16.05ZM565.36,231.57l-13.59,34.07h27.25l-13.65-34.07Z"/>
					<path d="M630.93,228.88h-25.27v-14.97h67.97v14.97h-25.63v66.35h-17.07v-66.35Z"/>
					<path d="M739.99,260.79h-35.21v19.7h40.36v14.73h-57.43v-81.32h56.41v14.73h-39.34v17.91h35.21v14.25Z"/>
				</g>
				<path d="M313.1,396.9h-58.63v-81.32h17.19v66.59h41.44v14.73Z"/>
				<path d="M347.21,396.9h-18.26l32.88-81.2h19.58l32.88,81.2h-18.27l-6.41-16.05h-36.17l-6.23,16.05ZM371.59,333.24l-13.59,34.07h27.25l-13.65-34.07Z"/>
				<path d="M471.53,396.9h-41.32v-81.32h37.19c7.46,0,13.54,2.1,18.23,6.29,4.69,4.19,7.04,9.62,7.04,16.29,0,7.15-3.07,12.75-9.22,16.83,4.47,1.64,7.83,4.15,10.09,7.55,2.25,3.39,3.38,7.29,3.38,11.68,0,6.67-2.33,12.12-7.01,16.35-4.67,4.23-10.8,6.35-18.38,6.35ZM447.28,329.59v19.7h17.9c3.23,0,5.78-.93,7.64-2.78,1.86-1.86,2.78-4.24,2.78-7.16s-1.04-5.37-3.11-7.13c-2.08-1.76-4.71-2.64-7.9-2.64h-17.31ZM447.28,362.46v20.72h21.2c3.27,0,5.89-.95,7.85-2.84,1.96-1.9,2.93-4.3,2.93-7.22,0-3.11-.98-5.67-2.93-7.66-1.96-2-4.57-2.99-7.85-2.99h-21.2Z"/>
				<path d="M572.13,362.46h-35.21v19.7h40.36v14.73h-57.43v-81.32h56.41v14.73h-39.34v17.9h35.21v14.25Z"/>
				<path d="M661.7,396.9h-58.63v-81.32h17.19v66.59h41.44v14.73Z"/>
				<path d="M681.32,371.63h18.02c.28,3.67,1.81,6.56,4.58,8.65,2.77,2.1,6.2,3.14,10.27,3.14,3.83,0,7.08-.89,9.73-2.66,2.65-1.78,3.98-4.24,3.98-7.4,0-1.4-.37-2.67-1.11-3.83s-1.54-2.08-2.39-2.76c-.86-.68-2.18-1.35-3.95-2.01-1.78-.66-3.14-1.12-4.1-1.38-.96-.26-2.45-.63-4.49-1.11-.68-.16-1.18-.28-1.5-.36-1.88-.44-3.32-.79-4.34-1.05-1.02-.26-2.45-.66-4.28-1.2s-3.27-1.04-4.31-1.5c-1.04-.46-2.33-1.07-3.86-1.83-1.54-.76-2.77-1.53-3.68-2.31-.92-.78-1.9-1.73-2.93-2.84-1.04-1.12-1.85-2.31-2.43-3.56-.58-1.26-1.06-2.7-1.44-4.34-.38-1.64-.57-3.39-.57-5.27,0-7.39,2.72-13.17,8.17-17.37,5.45-4.19,12.91-6.29,22.37-6.29s16.36,2.21,21.65,6.62c5.29,4.41,8.09,10.41,8.41,18h-17.91c-.16-3.15-1.45-5.64-3.86-7.46-2.42-1.82-5.38-2.73-8.89-2.73s-6.53.77-8.92,2.31c-2.39,1.54-3.59,3.72-3.59,6.56,0,.8.11,1.54.33,2.22.22.68.58,1.3,1.08,1.86.5.56,1,1.07,1.5,1.53.5.46,1.18.89,2.04,1.29s1.6.73,2.22.99c.62.26,1.47.55,2.55.87,1.08.32,1.92.56,2.51.72s1.47.37,2.6.63c1.14.26,1.93.45,2.37.57,2.36.56,4.27,1.03,5.75,1.41,1.48.38,3.32.96,5.54,1.74,2.22.78,4.03,1.56,5.45,2.34,1.42.78,2.93,1.8,4.55,3.05,1.62,1.26,2.89,2.61,3.83,4.07.94,1.46,1.73,3.2,2.37,5.24.64,2.04.96,4.27.96,6.71,0,7.9-2.92,14.08-8.77,18.53-5.85,4.45-13.54,6.68-23.09,6.68s-17.35-2.41-23.17-7.22c-5.83-4.81-8.9-11.23-9.22-19.25Z"/>
			</svg>
		);
	}

    if (icon === "truck") {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 18.75a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m3 0h6m-9 0H3.375a1.125 1.125 0 01-1.125-1.125V14.25m17.25 4.5a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m3 0h1.125c.621 0 1.129-.504 1.09-1.124a17.902 17.902 0 00-3.213-9.193 2.056 2.056 0 00-1.58-.86H14.25M16.5 18.75h-2.25m0-11.177v-.958c0-.568-.422-1.048-.987-1.106a48.554 48.554 0 00-10.026 0 1.106 1.106 0 00-.987 1.106v7.635m12-6.677v6.677m0 4.5v-4.5m0 0h-12" />
            </svg>

        );
    }
    if (icon === "arrow-path") {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
            </svg>
        );
    }
    if (icon === "fire") {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M15.362 5.214A8.252 8.252 0 0112 21 8.25 8.25 0 016.038 7.048 8.287 8.287 0 009 9.6a8.983 8.983 0 013.361-6.867 8.21 8.21 0 003 2.48z" />
                <path strokeLinecap="round" strokeLinejoin="round" d="M12 18a3.75 3.75 0 00.495-7.467 5.99 5.99 0 00-1.925 3.546 5.974 5.974 0 01-2.133-1A3.75 3.75 0 0012 18z" />
            </svg>
        );
    }
    if (icon === "star") {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z" />
            </svg>
        );
    }
    if (icon === "banknotes") {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z" />
            </svg>
        );
    }
    if (icon === "storefront") {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 21v-7.5a.75.75 0 01.75-.75h3a.75.75 0 01.75.75V21m-4.5 0H2.36m11.14 0H18m0 0h3.64m-1.39 0V9.349m-16.5 11.65V9.35m0 0a3.001 3.001 0 003.75-.615A2.993 2.993 0 009.75 9.75c.896 0 1.7-.393 2.25-1.016a2.993 2.993 0 002.25 1.016c.896 0 1.7-.393 2.25-1.016a3.001 3.001 0 003.75.614m-16.5 0a3.004 3.004 0 01-.621-4.72L4.318 3.44A1.5 1.5 0 015.378 3h13.243a1.5 1.5 0 011.06.44l1.19 1.189a3 3 0 01-.621 4.72m-13.5 8.65h3.75a.75.75 0 00.75-.75V13.5a.75.75 0 00-.75-.75H6.75a.75.75 0 00-.75.75v3.75c0 .415.336.75.75.75z" />
            </svg>
        );
    }
    if (icon === "clipboard-document-check") {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M11.35 3.836c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m8.9-4.414c.376.023.75.05 1.124.08 1.131.094 1.976 1.057 1.976 2.192V16.5A2.25 2.25 0 0118 18.75h-2.25m-7.5-10.5H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V18.75m-7.5-10.5h6.375c.621 0 1.125.504 1.125 1.125v9.375m-8.25-3l1.5 1.5 3-3.75" />
            </svg>
        );
    }
    if (icon === "user") {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
            </svg>
        );
    }
    if (icon === "credit-card") {
        return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5z" />
        </svg>
        );
    }
    if (icon === "sparkles") {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z" />
            </svg>
        );
    }
    if (icon === "bars") {
        return (
            <svg width={24} height={24} className={className} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
            </svg>
        );
    }
    if (icon === "shopping-cart") {
        return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={className}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 00-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 00-16.536-1.84M7.5 14.25L5.106 5.272M6 20.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm12.75 0a.75.75 0 11-1.5 0 .75.75 0 011.5 0z" />
        </svg>
        );
    }
    if (icon === "chart-bar") {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={className}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 013 19.875v-6.75zM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V8.625zM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V4.125z" />
            </svg>
        );
    }
    if (icon === "cube") {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={className}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M21 7.5l-9-5.25L3 7.5m18 0l-9 5.25m9-5.25v9l-9 5.25M3 7.5l9 5.25M3 7.5v9l9 5.25m0-9v9" />
            </svg>
        );
    }
    if (icon === "computer") {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={className}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M9 17.25v1.007a3 3 0 01-.879 2.122L7.5 21h9l-.621-.621A3 3 0 0115 18.257V17.25m6-12V15a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 15V5.25m18 0A2.25 2.25 0 0018.75 3H5.25A2.25 2.25 0 003 5.25m18 0V12a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 12V5.25" />
            </svg>
        );
    }
    return null;
}

export default Icons;
