export default {
    hero: {
        title: "Desenvolva sua marca própria sem precisar investir em infraestrutura e pessoal",
        subtitle:
            "A Noritex ajuda você a desenvolver sua marca própria, aumentando sua lucratividade, economizando tempo e recursos. Você opta por um produto aperfeiçoado aproveitando todo o conhecimento e experiência de 40 anos no mercado de desenvolvimento de marcas.",
        chunks: ["cadeia de Suprimentos", "marca"],
        btnLabel: "Inscreva-se e desenvolva novos produtos",
    },
    title: "Obtenha esses benefícios e muito mais",
    data: [
        {
            icon: "truck-cargo-container",
            title: "Despachos diretos do leste",
            description:
                "Use o Panamá ou a China como seu armazém para manter suas lojas abastecidas",
        },
        {
            icon: "fire",
            title: "Lançamento constante de novas coleções",
            description: "Fique por dentro das últimas tendências do mercado",
        },
        {
            icon: "seal",
            title: "Produtos com qualidade e suporte",
            description: "Gerenciamento de defeitos de mercadorias",
        },
        {
            icon: "cash-register",
            title: "Suporte a vendas",
            description:
                "Receba suporte na lotação da mercadoria no ponto de venda",
        },
        {
            icon: "warehouse",
            title: "Distribuição do Panamá",
            description:
                "Use o Panamá como seu armazém, para manter suas lojas abastecidas",
        },
        {
            icon: "refresh-circle",
            title: "Alta rotatividade de produtos",
            description:
                "Compre menos quantidade de produto e gire a mercadoria mais rapidamente",
        },
        {
            icon: "briefcase-account",
            title: "Simplifique a compra",
            description:
                "Compre de fornecedores pré-filtrados com alta qualidade e de forma digital e ordenada",
        },
        {
            icon: "face-agent",
            title: "Assessoria na sua compra",
            description: "Receba conselhos sobre a compra de seus produtos",
        },
        {
            icon: "cash-fast",
            title: "Aumente sua caixa recebendo crédito",
            description: "Financiamento sem juros de 60 a 90 dias",
        },
        {
            icon: "bullseye-arrow",
            title: "Posicionamento de sua marca privada",
            description:
                "Cumprimos as diretrizes da sua marca em custos, design e rotulagem de produtos",
        },
        {
            icon: "sofa-single",
            title: "Diferenciação",
            description:
                "Através de uma vasta gama de produtos exclusivos e competitivos.",
        },
    ],
    slider: [
        {
            title: "Decoração de casa",
            text: "Construa sua marca de decoração conosco e tenha acesso a milhares de produtos lifestyle",
            img: "https://www.noritex.com/uplds/2022/6/13/y8FT5mfMs5H7nBAR2CLL-bloque_colecciones_Cantaloupe.jpg",
        },
        {
            title: "Decoração de Natal",
            text: "Desenvolvemos sua marca de decoração natalina conosco e temos acesso a milhares de produtos com as últimas tendências da moda",
            img: "https://www.noritex.com/uplds/2022/0/27/7JFvsnHZSuktnyq3yarbol_dec5.jpg",
        },
        {
            title: "Produtos Escolares",
            text: "Desenvolva sua marca escolar conosco e tenha acesso a um amplo portfólio de produtos com as últimas tendências escolares",
            img: "https://www.noritex.com/uplds/2022/4/24/fy3G5rpSanXyR5eotCopia_de_DESTACADA.jpg",
        },
        {
            title: "Roupa Esportiva e Acessórios",
            text: "Desenvolvemos sua marca de sportswear conosco e temos acesso a milhares de produtos com as últimas tendências da moda",
            img: "https://www.noritex.com/uplds/2022/6/22/qf9nASaEApA6WCvdMropa-deportiva-marca-privada.jpeg",
        },
        {
            title: "Imagens religiosas",
            text: "Desenvolvemos sua marca de figuras religiosas e recebemos os mais recentes produtos religiosos",
            img: "https://www.noritex.com/uplds/2021/10/1/JtNGzibbtarqyDvjT_Nacimiento_2.jpg",
        },
    ],
};
