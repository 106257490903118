import React from "react";

function Icons({ icon, className }) {
    if (icon === "logontx") {
        return (
            <svg fill="currentColor" className={className} version={1.0} xmlns="http://www.w3.org/2000/svg" width={209} height={45} viewBox="0 0 3028.000000 658.000000" preserveAspectRatio="xMidYMid meet"><g className="fill-logo" transform="translate(0.000000,658.000000) scale(0.100000,-0.100000)" stroke="none"><path className="an" d="M11730 5129 c-405 -42 -751 -200 -1015 -464 -172 -172 -290 -357
-380 -600 -94 -250 -129 -469 -130 -795 0 -402 60 -692 204 -985 164 -332 391
-561 716 -720 419 -206 997 -241 1464 -89 617 201 1005 695 1118 1424 25 161
25 558 0 720 -136 884 -683 1426 -1526 1510 -120 12 -329 11 -451 -1z m420
-564 c282 -42 508 -182 662 -408 57 -84 132 -247 163 -352 87 -303 93 -728 15
-1035 -72 -279 -226 -518 -420 -650 -248 -168 -588 -221 -904 -140 -385 98
-652 416 -748 893 -21 101 -23 140 -23 392 0 268 2 286 28 409 88 408 301 696
612 825 176 74 405 98 615 66z" /><path d="M1454 5051 c-58 -35 -59 -45 -59 -501 0 -378 2 -418 18 -447 36 -67
-47 -63 1267 -63 1297 0 1238 3 1277 -56 17 -27 18 -88 23 -1239 5 -1167 6
-1211 24 -1238 39 -57 38 -57 493 -57 468 0 471 0 505 70 17 33 18 137 18
1738 0 1874 4 1751 -62 1792 -33 20 -45 20 -1753 20 -1665 0 -1721 -1 -1751
-19z" /><path className="an" d="M6330 3260 l0 -1810 330 0 330 0 0 968 c0 873 -6 1229 -26 1586 -4
80 -3 117 4 115 6 -2 63 -92 128 -199 138 -229 267 -431 372 -585 75 -109
1263 -1790 1309 -1852 l24 -33 350 0 349 0 0 1810 0 1810 -336 0 -336 0 5
-1052 c3 -585 10 -1153 17 -1278 6 -124 10 -252 8 -284 l-3 -60 -120 200
c-212 353 -328 522 -1150 1684 -115 162 -287 406 -384 543 l-174 247 -349 0
-348 0 0 -1810z" /><path className="an" d="M14430 3261 l0 -1811 335 0 335 0 0 700 0 700 228 0 227 0 212 -303
c117 -166 307 -437 423 -602 116 -165 242 -344 278 -397 l67 -97 398 -1 c316
0 397 3 391 13 -9 14 -97 137 -239 332 -539 741 -818 1131 -819 1143 -1 9 30
24 84 43 47 15 139 55 205 88 217 109 362 251 456 446 58 121 87 228 97 362
44 600 -265 999 -880 1132 -235 51 -287 54 -1065 58 l-733 4 0 -1810z m1500
1235 c350 -57 500 -208 500 -501 0 -259 -124 -428 -377 -516 -137 -47 -258
-60 -615 -66 l-338 -5 0 557 0 558 368 -6 c261 -4 394 -10 462 -21z" /><path className="an" d="M17920 3260 l0 -1810 330 0 330 0 0 1810 0 1810 -330 0 -330 0 0
-1810z" /><path className="an" d="M19090 4795 l0 -275 585 0 585 0 0 -1535 0 -1535 335 0 335 0 0 1535
0 1535 580 0 580 0 0 275 0 275 -1500 0 -1500 0 0 -275z" /><path className="an" d="M22610 3260 l0 -1810 1220 0 1220 0 0 275 0 275 -890 0 -890 0 0 515
0 515 725 0 725 0 0 275 0 275 -725 0 -725 0 0 470 0 470 845 0 845 0 0 275 0
275 -1175 0 -1175 0 0 -1810z" /><path className="an" d="M25440 5066 c0 -4 224 -322 830 -1176 91 -129 224 -316 293 -414 70
-99 126 -185 123 -191 -3 -9 -1078 -1517 -1273 -1788 l-35 -47 378 2 378 3 47
66 c108 152 655 938 773 1112 70 103 130 187 134 187 4 -1 45 -58 92 -128 80
-120 303 -442 689 -994 l173 -248 375 0 375 0 -18 27 c-10 16 -305 430 -656
921 l-638 893 118 167 c65 92 146 205 179 252 33 47 253 357 488 690 236 333
439 620 453 638 l24 32 -383 0 -384 0 -195 -282 c-107 -155 -256 -371 -330
-479 -75 -108 -185 -271 -245 -362 -61 -92 -113 -166 -117 -164 -4 1 -74 101
-155 222 -82 121 -280 410 -442 643 l-293 422 -379 0 c-208 0 -379 -2 -379 -4z" /><path d="M1471 3763 c-19 -10 -44 -31 -55 -46 -20 -28 -21 -39 -21 -456 0
-391 1 -429 18 -454 42 -64 15 -62 632 -67 522 -5 563 -6 589 -23 56 -37 56
-38 56 -629 0 -521 1 -544 20 -575 39 -64 34 -64 511 -61 l431 3 34 37 34 38
0 1077 c0 836 -3 1083 -13 1102 -6 13 -25 34 -40 45 -28 21 -30 21 -1095 24
-1001 2 -1069 1 -1101 -15z" /><path d="M1435 2446 c-18 -19 -35 -49 -39 -66 -10 -53 -7 -791 4 -831 5 -19
24 -48 41 -65 l30 -29 432 -3 c419 -2 433 -2 465 18 62 38 63 45 60 516 l-3
426 -37 34 -38 34 -441 0 -442 0 -32 -34z" /></g></svg>

        );
    }

    if (icon === 'play') {
        return(
            <svg style={{width: 24, height: 24}} viewBox="0 0 24 24">
                <path fill="currentColor" d="M10,16.5V7.5L16,12M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />
            </svg>
        );
    }

    return null;
}

export default Icons;
