import React from "react";
import loadable from "@loadable/component";
const DirectoComponent = loadable(() => import("./index.js"));

export default function DirectoLoadableImport(props) {
    if (
        props.user &&
        props.user.id &&
        props.user.tags &&
        props.user.tags.indexOf("directos") > -1
    )
        return <DirectoComponent {...props} />;

    return null;
}
