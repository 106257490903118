import React from 'react';
import { InfoItem } from './style';
import Icons from './Icons';

function Item({icon, title, description}) {
    return (
        <InfoItem>
            <div className="inner">
                <Icons icon={icon} className="icon"/>
                <h3>{title}</h3>
                <p>{description}</p>
            </div>
        </InfoItem>
    )
}

export default Item;