import styled from "styled-components";
import {
    Container,
    Section,
    Title,
    Subtitle,
    Columns,
    Column,
    Box,
    Heading,
} from "../../sdk/Layout";
import { Button, Icon } from "../../sdk/Button";
import { Field, Control } from "../../sdk/Form";
import { Modal } from "../../sdk/Elements";

export const PleaseLogin = styled.div`
    max-width: 300px;
    padding: 20px 0px;
    button {
        margin-top: 10px;
    }
`;

export const StatusBar = styled.div`
    background-color: #112c55;
    & ${Container} {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 90px;
        padding: 0px 10px;
        > div {
            display: flex;
            align-items: center;
        }
    }
    h2 {
        font-weight: bold;
        color: white;
        font-size: 23px;
    }
    & ${Button} {
        margin-left: 40px;
    }

    @media (max-width: 768px) {
        & ${Container} {
            flex-direction: column;
            justify-content: center;
            height: auto;
            padding: 20px 10px;
            > div {
                flex-direction: column;
                & ${Button} {
                    margin-left: 0;
                }
            }
        }
        h2 {
            font-size: 20px;
        }
    }
`;
export const Info = styled.div`
    display: flex;
    align-items: center;
    li {
        &:not(:last-child) {
            margin-right: 40px;
        }
        color: white;
        display: inline-flex;
        align-items: center;
        & ${Icon} {
            background-color: #30537a;
            border-radius: 100%;
            width: 45px;
            height: 45px;
            svg {
                color: white;
            }
        }
        > div {
            span {
                font-size: 14px;
                display: block;
                opacity: 0.7;
            }
            p {
                font-size: 16px;
                font-weight: bold;
                display: block;
                padding-top: 5px;
            }
        }
    }

    @media (max-width: 768px) {
        padding: 20px 0;
    }
`;

export const Featured = styled.div`
    background-image: url("https://www.noritex.com/yJjmrAS4opSac842yReferidos_solo_fondo.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    @media (max-width: 768px) {
        & ${Section} {
            padding: 20px;
        }
    }
    @media (max-width: 907px) {
        background-position: right;
    }
`;

export const Invite = styled.div`
    background-color: #fff;
    padding: 80px;
    max-width: 676px;
    h1 {
        font-size: 32px;
        line-height: 34px;
        color: #112c55;
        max-width: 420px;
    }
    h2 {
        font-size: 18px;
        margin-top: 20px;
        color: #112c55;
        font-weight: normal;
        line-height: 22px;
        max-width: 450px;
    }
    .empty-space {
        height: 70px;
    }
    .input-form {
        padding-top: 0px;
        display: flex;
        padding-bottom: 20px;
        input {
            width: 100%;
            height: 60px;
            margin-right: 10px;
            background-color: #efefef;
            padding: 0px 20px;
            font-size: 14px;
            color: #112c55;
            border: none;
        }
        button {
            background-color: #112c55;
            flex: none;
            color: white;
            height: 60px;
            border: none;
            font-size: 14px;
            font-weight: bold;
            cursor: pointer;
            padding: 0px 30px;
            @media (max-width: 768px) {
                margin-top: 10px;
            }
        }
    }
    p {
        color: #112c55;
        font-size: 14px;
        a {
            color: #112c55;
            text-decoration: underline;
            cursor: pointer;
            &:hover {
                text-decoration: none;
            }
        }
    }

    @media (max-width: 980px) {
        padding: 40px;
        max-width: 500px;
    }

    @media (max-width: 768px) {
        padding: 20px;
        max-width: 500px;

        h1 {
            font-size: 20px;
            line-height: 24px;
        }
        h2 {
            font-size: 16px;
            margin-top: 10px;
            line-height: 18px;
        }
        > div {
            padding-top: 30px;
            flex-direction: column;
        }

        p {
            font-size: 12px;
        }
    }
    @media (max-width: 534px) {
        background-color: rgba(255, 255, 255, 0.8);
    }
`;

export const Popup = styled(Modal)`
    padding: 60px 0;
    z-index: 9999;
    > div {
        height: 100%;
    }
    & ${Column} {
        padding-top: 0;
    }
    & ${Box} {
        padding-right: 0;
        @media (max-width: 768px) and (max-height: 740px) {
            height: 100%;
        }
        > div {
            padding-right: 20px;
            height: 100%;
            overflow-y: auto;
            overflow-x: hidden;
        }
    }
`;

export const Brands = styled.div`
    padding-bottom: 20px;
`;

export const Brand = styled.div`
    border-radius: 100%;
    border: solid 2px #e3e3e3;
    overflow: hidden;
    width: 40px;
    height: 40px;
    margin-left: 0 !important;
    display: inline-block;
    vertical-align: top;
    cursor: pointer;
    opacity: 0.5;
    &:hover {
        opacity: 1;
    }
    &:not(:last-child) {
        margin-right: 10px;
    }
    svg {
        width: 38px;
        height: 38px;
        color: initial !important;
    }
    ${(props) => props.isChecked && "border-color:#112c55;opacity: 1;"}
`;

export const HowSection = styled(Section)`
    padding: 5% 0;
    & ${Container} {
        max-width: 900px;
    }
    & ${Title} {
        color: #112c55;
        text-align: center;
    }

    ul {
        display: flex;
        align-items: baseline;
        justify-content: space-between;
        padding-top: 20px;
        li {
            max-width: 244px;
            text-align: center;
            margin: 0 2%;
            svg {
                width: 50px;
                height: 50px;
                color: #112c55;
            }
            h4 {
                color: #112c55;
                display: block;
                margin-top: 10px;
                font-size: 18px;
                letter-spacing: 1px;
            }
            p {
                font-size: 14px;
                line-height: 22px;
                color: #525050;
                margin-top: 20px;
            }
        }
    }

    @media (max-width: 768px) {
        & ${Title} {
            font-size: 26px;
        }
        ul {
            padding-top: 0;
            li {
                h4 {
                    margin-top: 0;
                }
                p {
                    margin-top: 10px;
                    font-size: 13px;
                    line-height: 18px;
                }
                svg {
                    width: 30px;
                    height: 30px;
                }
            }
        }
    }

    @media (max-width: 580px) {
        ul {
            flex-direction: column;
            align-items: center;
            li {
                &:not(:last-child) {
                    margin-bottom: 20px;
                }
            }
        }
    }
`;

export const Calculator = styled.div`
    background-color: #112c55;
    color: white;
    padding: 5% 0;
    position: relative;
    & ${Title} {
        color: white;
    }

    & ${Subtitle} {
        margin-bottom: 0;
    }
    & ${Column} {
        padding-top: 20px;
        padding-bottom: 20px;
        position: relative;
        &:last-child {
            @media (min-width: 981px) {
                border-left: solid 1px rgba(255, 255, 255, 0.3);
            }
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    @media (max-width: 980px) {
        text-align: center;
        & ${Columns} {
            flex-direction: column;
        }
    }

    @media (max-width: 580px) {
        & ${Title} {
            font-size: 23px;
        }
    }
`;

export const Input = styled.div`
    display: flex;
    align-items: center;
    padding-top: 10px;
    & ${Title} {
        margin-bottom: 0;
    }
    input {
        width: 150px;
        text-align: center;
        height: 60px;
        margin: 0px 10px;
        background-color: #efefef;
        padding: 0px 20px;
        font-size: 24px;
        color: #112c55;
        border: none;
    }
    button {
        background-color: white;
        flex: none;
        color: #112c55;
        height: 60px;
        border: none;
        font-size: 14px;
        font-weight: bold;
        cursor: pointer;
        padding: 0px 30px;
        border-radius: 0;
    }

    @media (min-width: 981px) {
        > span {
            width: 24px;
            position: absolute;
            background-color: #112c55;
            right: -12px;
            top: 50%;
            transform: translateY(-50%);
            z-index: 2;
            padding: 15px 0;
            span {
                display: block;
                border-top: solid 3px white;
                border-bottom: solid 3px white;
                height: 15px;
                opacity: 0.7;
            }
        }
    }

    @media (max-width: 980px) {
        justify-content: center;
    }

    @media (max-width: 580px) {
        flex-direction: column;
        button {
            margin-top: 10px;
            width: 150px;
        }
        & ${Title} {
            margin-bottom: 10px;
            font-size: 18px;
        }
    }
`;

export const List = styled.div`
    li {
        padding: 10px;
        justify-content: space-between;
        display: flex;
        span {
            text-transform: uppercase;
        }
    }
    .center-div {
        color: rgb(170, 170, 170);
        font-size: 14px;
        width: 100%;
        margin-top: 100px;
        margin-bottom: 100px;
        display: flex;
        -webkit-box-flex: 1;
        flex-grow: 1;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        align-items: center;
    }
`;

export const ControlExtended = styled(Control)`
    .PhoneInput {
        display: flex;
        align-items: center;
    }

    .PhoneInputInput {
        /* The phone number input stretches to fill all empty space */
        /* flex: 1; */
        /* The phone number input should shrink
 to make room for the extension input */
        /* min-width: 0;
        border: 0;
        outline: none; */
    }

    .is-invalid.PhoneInput > .PhoneInputInput {
        border-color: #cc0f35;
    }

    .PhoneInputCountryIcon {
        width: calc(1em * 1.5);
        height: 1em;
    }

    .PhoneInputCountryIcon--square {
        width: 1em;
    }

    .PhoneInputCountryIcon--border {
        background-color: rgba(0, 0, 0, 0.1);

        box-shadow: 0 0 0 var(1px) rgba(0, 0, 0, 0.5),
            inset 0 0 0 var(1px) rgba(0, 0, 0, 0.5);
    }

    .PhoneInputCountryIconImg {
        display: block;
        max-width: 50px;
        width: 100%;
        height: 100%;
    }

    .PhoneInputInternationalIconPhone {
        opacity: 0.8;
    }

    .PhoneInputInternationalIconGlobe {
        opacity: 0.65;
    }

    .PhoneInputCountry {
        position: relative;
        align-self: stretch;
        display: flex;
        align-items: center;
        margin-right: 0.35em;
    }

    .PhoneInputCountrySelect {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 1;
        border: 0;
        opacity: 0;
        cursor: pointer;
    }

    .PhoneInputCountrySelect[disabled] {
        cursor: default;
    }

    .PhoneInputCountrySelectArrow {
        display: block;
        content: "";
        width: 0.3em;
        height: 0.3em;
        margin-left: 0.35em;
        border-style: solid;
        border-color: inherit;
        border-top-width: 0;
        border-bottom-width: 1px;
        border-left-width: 0;
        border-right-width: 1px;
        transform: rotate(45deg);
        opacity: 0.45;
    }

    .PhoneInputCountrySelect:focus
        + .PhoneInputCountryIcon
        + .PhoneInputCountrySelectArrow {
        opacity: 1;
        color: inherit;
    }

    .PhoneInputCountrySelect:focus + .PhoneInputCountryIcon--border {
        box-shadow: 0 0 0 var(1px) #03b2cb, inset 0 0 0 var(1px) #03b2cb;
    }

    .PhoneInputCountrySelect:focus
        + .PhoneInputCountryIcon
        .PhoneInputInternationalIconGlobe {
        opacity: 1;
        color: #03b2cb;
    }
`;
