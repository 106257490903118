import React from "react";
import styled from "styled-components";
import ModalPortal from "../../../../components/ModalPortal";
import { Subtitle, Box, Heading } from "../../../../sdk/Layout";
import { Tabs } from "../../../../sdk/Elements";
import { Popup, List } from "../../style";

const TabsExtended = styled(Tabs)`
    ul {
        justify-content: space-around;
    }
`;

function ModalDetails({ isVisible, toggleModal, referrals, langJson }) {
    const [currentTab, setCurrentTab] = React.useState("pending");
    return (
        <ModalPortal>
            {isVisible ? (
                <Popup className={isVisible && "is-active"}>
                    <span onClick={toggleModal}></span>
                    <button onClick={toggleModal}></button>
                    <div>
                        <Box>
                            <div>
                                <Heading isCentered>
                                    <h3>{langJson.inviteDetails}</h3>
                                </Heading>

                                {/* <Subtitle as="p" size="6">
                                    Aqui puede ver los detalles de sus
                                    invitaciones
                                </Subtitle> */}
                                <TabsExtended>
                                    <ul>
                                        <li
                                            onClick={() =>
                                                setCurrentTab("pending")
                                            }
                                            className={
                                                currentTab === "pending" &&
                                                "is-active"
                                            }
                                        >
                                            <a>{langJson.pendingPlural}</a>
                                        </li>
                                        <li
                                            onClick={() => setCurrentTab("won")}
                                            className={
                                                currentTab === "won" &&
                                                "is-active"
                                            }
                                        >
                                            <a>{langJson.won}</a>
                                        </li>
                                        <li
                                            onClick={() =>
                                                setCurrentTab("inelegible")
                                            }
                                            className={
                                                currentTab === "inelegible" &&
                                                "is-active"
                                            }
                                        >
                                            <a>{langJson.inelegible}</a>
                                        </li>
                                    </ul>
                                </TabsExtended>
                                <List>
                                    <ul>
                                        {referrals[currentTab] &&
                                        referrals[currentTab].length > 0 ? (
                                            referrals[currentTab].map(
                                                (index) => (
                                                    <li key={index.key}>
                                                        {index.email}
                                                        <span>
                                                            {index.status}
                                                        </span>
                                                    </li>
                                                )
                                            )
                                        ) : (
                                            <div className="center-div">
                                                Nothing Yet
                                            </div>
                                        )}
                                    </ul>
                                </List>
                            </div>
                        </Box>
                    </div>
                </Popup>
            ) : null}
        </ModalPortal>
    );
}

export default ModalDetails;
