import React from "react";
import { BlueberryData } from "./BlueberryData";
import dynamicReducer from "./dataReducer";

export default function connect() {
    return (WrappedComponent) =>
        class ReactBlueberryDataComponent extends React.Component {
            constructor() {
                super();

                dynamicReducer.listener(this.forceUpdate);

                this.state = {
                    change: 0,
                };
            }

            forceUpdate = () => {
                this.setState((prev) => ({
                    change: prev.change ? prev.change + 1 : 1,
                }));
            };

            render() {
                return (
                    <WrappedComponent
                        {...this.props}
                        {...this.state}
                        store={BlueberryData}
                    />
                );
            }
        };
}
