export default {
	hero: {
		title: "Develop your private brand without having to invest in large infrastructures and staff",
		subtitle:
			"With Noritex, you can establish your private brand efficiently and cost-effectively. We leverage our 40 years of expertise in brand development to increase your profitability and save you time and resources. With a vast product selection at your disposal, you can build your brand without the need for significant infrastructure or staffing investment.",
		chunks: ["supply chain", "brand"],
		btnLabel: "Register and develop your brand",
	},
	title: "Get these benefits and more",
	data: [
		{
			icon: "refresh-circle",
			title: "Efficient Inventory Management",
			description:
				"Our model enables high product turnover. By purchasing smaller quantities per product, you can rotate merchandise faster, ensuring your stock is always fresh and relevant.",
		},

		{
			icon: "fire",
			title: "Trend-Conscious Collections",
			description:
				"We keep pace with the latest market trends. Every quarter, we launch new collections that reflect the latest consumer behaviors and preferences.",
		},
		{
			icon: "cash-fast",
			title: "Financial Flexibility:",
			description:
				"We offer interest-free financing for 60 to 90 days, giving you the flexibility to manage your budget more effectively.",
		},
		{
			icon: "truck-cargo-container",
			title: "Convenient Dispatching",
			description:
				"We offer direct dispatches from China or Panama. Use these locations as your extended warehouse and ensure your stores are always stocked.",
		},
		{
			icon: "seal",
			title: "Guaranteed Quality",
			description:
				"Our products are of high quality, and we stand by this promise with our comprehensive quality guarantees.",
		},
		{
			icon: "cash-register",
			title: "Sellout Support",
			description:
				"We provide support at the point of sale, helping you to effectively sell the merchandise.",
		},

		{
			icon: "face-agent",
			title: "Expert Advice",
			description:
				"Receive guidance on product purchasing, helping you make informed decisions that align with your business objectives.",
		},
		{
			icon: "briefcase-account",
			title: "Streamlined Purchasing",
			description:
				"Buy from our pre-vetted suppliers who are known for their high quality. Our digital, organized platform makes the purchasing process simple and efficient.",
		},

		{
			icon: "bullseye-arrow",
			title: "Brand Positioning",
			description:
				"We adhere to your brand guidelines in all aspects, including cost, design, and product labeling, ensuring your brand's integrity is maintained.",
		},
		{
			icon: "sofa-single",
			title: "Product Differentiation",
			description:
				"Stand out in the market with our wide range of exclusive and competitive products, designed to give your brand a unique edge.",
		},
	],
	slider: [
		{
			title: "Home Decor",
			text: "Partner with us to create a compelling home decor brand. By working with Noritex, you gain access to thousands of products, constantly updated to reflect the latest trends and consumer behaviors. Stay ahead of the competition with our extensive product selection and market insights​.",
			img: "https://www.noritex.com/uplds/2022/6/13/y8FT5mfMs5H7nBAR2CLL-bloque_colecciones_Cantaloupe.jpg",
		},
		{
			title: "Christmas decoration",
			text: "Develop your Christmas brand with us and get access to thousands of products with the latest trends in Christmas decor",
			img: "https://www.noritex.com/uplds/2022/0/27/7JFvsnHZSuktnyq3yarbol_dec5.jpg",
		},
		{
			title: "School Products",
			text: "Develop your back to school brand with us and get access to a wide product portfolio with the latest back to school trends",
			img: "https://www.noritex.com/uplds/2022/4/24/fy3G5rpSanXyR5eotCopia_de_DESTACADA.jpg",
		},
		{
			title: "Sportswear and Accessories",
			text: "Develop your sportswear brand with us and get access to thousands of products with the latest fashion trends",
			img: "https://www.noritex.com/uplds/2022/6/22/qf9nASaEApA6WCvdMropa-deportiva-marca-privada.jpeg",
		},
		{
			title: "Religious images",
			text: "We develop your brand of religious figurines and get the latest trends in religious images",
			img: "https://www.noritex.com/uplds/2021/10/1/JtNGzibbtarqyDvjT_Nacimiento_2.jpg",
		},
	],
};
