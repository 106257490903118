export default {
    startAReturn: "Cómo iniciar un reclamo",
    contactABrand: "Contactar una Marca",
    cancelAnOrder: "Cancelar una Orden",
    productImages: "Imagenes de Productos",
    reportDamagedItems: "Reportar artículos dañados",
    howCanWeHelpYou: "¿Cómo podemos ayudarle?",
    inputPlaceholder: "Escriba aquí para encontrar respuestas",
    otherQuestions: "Otras preguntas",
    frequentQuestions: "Preguntas más frecuentes",
};
