import React from "react";
import { useParams } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import SwipeableViews from "react-swipeable-views";
import { Section, Container, Heading } from "../../../../sdk/Layout";
import { Button, Buttons } from "../../../../sdk/Button";

import Item from "./Item";
import { SectionExtended, InfoItemList, Hero, InfoSlider } from "./style";
import Icons from "./Icons";
import data_es from "./data_es";
import data_en from "./data_en";
import data_pt from "./data_pt";
import { scrollElementIntoView } from "../../../../utils";

// const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

function getLang(lang) {
    const languages = {
        es: data_es,
        en: data_en,
        pt: data_pt,
    };
    return languages[lang];
}

function PrivateLabelBenefits() {
    const [currentChcunk, setChunk] = React.useState(0);
    const [activeItemIndex, setActiveItemIndex] = React.useState(0);
    const refTimer = React.useRef();
    const { lang } = useParams();
    const { hero, title, data, slider } = getLang(lang);

    const changeActiveItem = (activeIndex) => {
        setActiveItemIndex(activeIndex);
    };

    const imagesCount = slider.length;

    const handleChunkChange = (chunk) => {
        setChunk((prev) => {
            if (prev >= hero.chunks.length - 1) {
                return 0;
            }
            return prev + 1;
        });
    };

    const handleScrollToLeadForm = () => {
        scrollElementIntoView(document.getElementById("leadform"));
    };

    const nextSlide = () => {
        let newActiveItemIndex = activeItemIndex + 1;
        console.log(newActiveItemIndex);
        if (newActiveItemIndex >= imagesCount) {
            newActiveItemIndex = 0;
        }

        setActiveItemIndex(newActiveItemIndex);
    };

    const lastSlide = () => {
        let newActiveItemIndex = activeItemIndex - 1;
        if (newActiveItemIndex <= -1) {
            newActiveItemIndex = imagesCount - 1;
        }
        setActiveItemIndex(newActiveItemIndex);
    };

    React.useEffect(() => {
        refTimer.current = setInterval(() => {
            handleChunkChange();
        }, 3000);
        return () => {
            clearInterval(refTimer.current);
        };
    }, []);

    return (
        <>
            <Hero>
                <div className="inner">
                    <Container>
                        <h1>{hero.title}</h1>
                        <h2>{hero.subtitle}</h2>

                        <Button
                            primary
                            className="button"
                            onClick={handleScrollToLeadForm}
                        >
                            {hero.btnLabel}
                        </Button>
                    </Container>
                </div>
            </Hero>
            <SectionExtended>
                <Container>
                    <Heading isCentered>
                        <h3>{title}</h3>
                    </Heading>
                    <InfoItemList>
                        {data.map(({ icon, title, description }) => (
                            <Item
                                icon={icon}
                                title={title}
                                description={description}
                            />
                        ))}
                    </InfoItemList>
                </Container>
            </SectionExtended>
            <SectionExtended className="gray">
                <Container>
                    <InfoSlider>
                        <SwipeableViews
                            enableMouseEvents
                            interval={6000}
                            index={activeItemIndex}
                            onChangeIndex={changeActiveItem}
                            className="slider-container"
                        >
                            {slider.map((slide, i) => {
                                return (
                                    <div
                                        key={`slide-benefits-${i}`}
                                        className="slide"
                                    >
                                        <div className="image">
                                            <figure>
                                                <LazyLoadImage
                                                    src={`${slide.img}?d=500x500`}
                                                    alt={slide.title}
                                                />
                                            </figure>
                                        </div>
                                        <div className="content">
                                            <div className="indicator">
                                                {i + 1}/5
                                            </div>
                                            <h3 className="headline">
                                                {slide.title}
                                            </h3>
                                            <div className="text">
                                                <p>{slide.text}</p>
                                            </div>

                                            <div className="navigator">
                                                <Button
                                                    onClick={lastSlide}
                                                    white
                                                    className="arrow"
                                                >
                                                    <Icons icon="arrow-left" />
                                                </Button>
                                                <Button
                                                    onClick={nextSlide}
                                                    className="arrow"
                                                >
                                                    <Icons icon="arrow-right" />
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </SwipeableViews>
                    </InfoSlider>
                </Container>
            </SectionExtended>
        </>
    );
}

export default PrivateLabelBenefits;
